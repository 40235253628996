import React from 'react'
import {ExzaLogo} from './../../base/exza_logo/ExzaLogo'


const OwnEntitiesView = () => {
    return(
        <ExzaLogo/>
    )
}

export {OwnEntitiesView}