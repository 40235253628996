import { Button, Icon } from "@ui5/webcomponents-react";
import React, { useState } from "react";
import "../CommentCard/CommentCard.css";
import "@ui5/webcomponents-icons/dist/response.js";

const CommentCardReply = ({
  comment,
  is_reply,
  setReplyComment,
  scrollToComment,
}) => {
  function getFileType(fileName) {
    const match = fileName.match(/\.[^.]+$/);
    if (!match) return "unknown";

    const extension = match[0].toLowerCase().slice(1);
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];

    if (imageExtensions.includes(extension)) {
      return "image";
    } else {
      return "other";
    }
  }

  function extractFileName(path) {
    const match = path.match(/[^/]+$/);
    return match ? match[0] : null;
  }

  return (
    <div
      onClick={() => scrollToComment(comment.id)}
      className="comment-card-view-content-reply-content"
      title="Перейти к сообщению"
    >
      <img
        className="comment-card-view-content-user-icon"
        src="https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg"
        alt="USER LOGO"
      />
      <div className="comment-card-view-body-reply">
        <span className="comment-card-view-body-username">
          {`В ответ ${comment.creator.last_name} ${comment.creator.first_name}`}
        </span>
        {!comment.deleted ? (
          <>
            <span className="comment-card-view-body-text-reply">
              {comment.text}
            </span>
            {comment.attachments &&
              comment.attachments.map(
                (file) =>
                  getFileType(file?.file?.name) === "image" && (
                    <img
                      key={file.file.id}
                      className="comment-card-view-body-image-reply"
                      src={file.file.url}
                      alt="COMMENT IMAGE"
                    />
                  )
              )}
            {comment.attachments &&
              comment.attachments.map(
                (file) =>
                  getFileType(file?.file?.name) !== "image" && (
                    <div className="file-preview-current">
                      <Icon
                        name="document-text"
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                      <div className="file-preview-current-file-info">
                        <span style={{ fontWeight: "bold" }}>
                          {extractFileName(file.file.name)}
                        </span>
                        <span>{(file.file.size / 1024).toFixed(0)} КБ</span>
                      </div>
                    </div>
                  )
              )}
          </>
        ) : (
          <span className="comment-card-view-body-text-reply" style={{ opacity: 0.8 }}>
            Сообщение удалено
          </span>
        )}
      </div>
      <div 
        title={comment.updated_at ? `создан ${comment.create_date_time_stamp}` : null}
        className="comment-card-view-content-date">
        {comment.updated_at ? `изменено ${comment.updated_at}` : comment.create_date_time_stamp}
      </div>
      {is_reply && (
        <Button
          onClick={() => setReplyComment(null)}
          tooltip="Не отвечать"
          className="comment-card-view-body-reply-decline-btn"
          icon="decline"
        />
      )}
    </div>
  );
};

export default CommentCardReply;
