import React, { useEffect, useState } from 'react'
import { FlexBox, ThemeProvider, Loader, MultiComboBox, MultiComboBoxItem, Form, FormGroup, FormItem, Input, Title, ObjectStatus, Icon, Button, Text, DatePicker, Option, Select } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';


const RegStep1 = (props) => {

    const [emailValueState, setEmailValueState] = useState('');

    const emailValidator = require("email-validator");

    async function performIdentification() {
        props.setIsLoading(true);
        await axios_instance
            .get(`/identification/${props.registrationData.email}`)
            .then(({ data, status }) => {
                console.log(data);
                switch (data?.error_code) {
                    case "NO_USER":
                        setEmailValueState('');
                        props.setStep(2);
                        break
                    case "NO_ACTIVE_USER":
                        setEmailValueState('');
                        props.setStep(9);
                        break
                    case "NO_MARKET_USER":
                        setEmailValueState('');
                        props.setStep(9);
                        break
                    case "NOT_APPROVED":
                        setEmailValueState('Аккаунт ожидает подтверждения');
                        break
                    case "USER-BLOCKED":
                        setEmailValueState('Ваша учетная запись заблокирована');
                        break
                    case null || undefined:
                        setEmailValueState("Аккаунт уже существует");
                        break
                }
                props.setIsLoading(false);
            })
            .catch(error => {
                props.setIsLoading(false);
            });
    }


    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Введите электронную почту
                </Title>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >
                <FormGroup>
                    <FormItem>
                        <Input
                            name="email"
                            valueState={emailValueState ? "Error" : ""}
                            required
                            placeholder='Почта'
                            type='Email'
                            //value={login}
                            //onInput={handleLoginInput}
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0,
                                marginTop: 50,
                                marginBottom: emailValueState ? 0 : 50

                            }}
                            value={props.registrationData.email}
                            onInput={(e) => {
                                setEmailValueState("");
                                props.setRegistrationData({
                                    ...props.registrationData,
                                    email: e.target.typedInValue.trim()
                                });
                            }}
                        />
                    </FormItem>
                    {emailValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                                style={{ marginBottom: 50 }}
                            >
                                {emailValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                </FormGroup>

                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="SpaceBetween"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                icon="arrow-left"
                                onClick={() => {
                                    props.onCancel()
                                }}
                                design="Emphasized" style={{ width: 100, fontWeight: 'normal' }}>
                                Вход
                            </Button>
                            <Button
                                onClick={() => {
                                    if (props.registrationData.email === "") {
                                        setEmailValueState("Введите почту");
                                    }
                                    else if (!emailValidator.validate(props.registrationData.email)) {
                                        setEmailValueState("Неверный формат");
                                    }
                                    else {
                                        performIdentification();
                                    }
                                }}
                                design="Emphasized" style={{ width: 100, fontWeight: 'normal' }}>
                                Далее
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export default RegStep1