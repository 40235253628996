import React from 'react'
import "./MainPage.css";
import Header from './Header/header';
import Body from './Body/body';
import Footer from './Footer/footer';

const MainPage = () => {
  return (
    <div className='main-page-main-container'>
      <Header/>
      <Body/>
      <Footer/>
    </div>
  )
}

export default MainPage