import { useState } from "react";
import { Button, StepInput } from "@ui5/webcomponents-react";
import axios_instance from "../../../../requests/config_defaults";

const OrderManagerStepInput = (props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [endPrice, setEndPrice] = useState(props.price_with_delivery_price);

  const updateOrderItem = async () => {
    if (endPrice - props.delivery_cost > 0) {
      await axios_instance
        .put(`/update/order_item/by_id/${props.order_item_id}`, {
          total_cost: endPrice,
        })
        .then((response) => {
          if (props.getOrderData) {
            props.getOrderData()
          }
        })
        .catch((error) => {});
    }
  };

  return props.is_selected ? (
    <div>
      <StepInput
        style={{ width: 180 }}
        valuePrecision={2}
        value={endPrice}
        min={props.delivery_cost}
        onChange={(e) => {
          setIsEditing(true);
          setEndPrice(e.target.value);
        }}
        onInput={(e) => {
          setIsEditing(true);
          setEndPrice(e.target.value);
        }}
      />
      {isEditing && (
        <>
          <Button
            disabled={endPrice <= props.delivery_cost}
            onClick={() => {
              setIsEditing(false);
              if (endPrice != props.price_with_delivery_price) {
                updateOrderItem();
              }
            }}
            tooltip="Сохранить изменения"
            style={{ color: "green" }}
            icon="accept"
          />
          <Button
            onClick={() => {
              setIsEditing(false);
              setEndPrice(props.price_with_delivery_price);
            }}
            tooltip="Отменить изменения"
            style={{ color: "red" }}
            icon="decline"
          />
        </>
      )}
    </div>
  ) : (
    <>{props.price_with_delivery_price}</>
  );
};

export default OrderManagerStepInput;
