import { useMemo, useState, useEffect } from 'react';
import PermissionRequestsActiveTable from './permission-requests-active-table/PermissionRequestsActiveTable';
import PermissionRequestsHistoryTable from './permission-requests-history-table/PermissionRequestsHistoryTable';
import './ManagerInviteManagementTable.css';



const ManagerInviteManagementTable = (props) => {
    const [permissionRequestsUpdate, setPermissionRequestsUpdate] = useState(false);


    return (
        <>
            <div className='permission-requests-active-table-wrapper'>
                <PermissionRequestsActiveTable
                    manager={props.manager}
                    activeCompany={props.activeCompany}
                    permissionRequestsUpdate={permissionRequestsUpdate}
                    setPermissionRequestsUpdate={setPermissionRequestsUpdate}
                    setActivePermissionRequestsCount={props.setActivePermissionRequestsCount}
                />
            </div>
            <div className='permission-requests-history-table-wrapper'>
                <PermissionRequestsHistoryTable
                    manager={props.manager}
                    activeCompany={props.activeCompany}
                    permissionRequestsUpdate={permissionRequestsUpdate}
                />
            </div>

        </>
    )
}

export default ManagerInviteManagementTable