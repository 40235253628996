import "./CatalogMainPage.css";
import "@ui5/webcomponents-icons/dist/arrow-right.js";
import { Button } from '@ui5/webcomponents-react'
import CatalogMainPageCatalogsCard from "./CatalogMainPageCatalogsCard/CatalogMainPageCatalogsCard";
import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNavigate } from "react-router-dom";
import axios_instance from "../../../requests/config_defaults";
import { useState } from "react";

const CatalogMainPage = () => {
  const nav = useNavigate();
  const [mouseEnter, setMouseEnter] = useState(false)
  const imageUrl = mouseEnter
  ? '/static/Arrow.svg'
  : '/static/ArrowRed.svg'

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    centerPadding: "60px",
    slidesToScroll: 5,
  };

  const [products, setProducts] = useState([]);
  const catalog_name = "Карусель-товаров"

  const getCatalogProducts = async () => {
    await axios_instance
      .get(`catalog/products/by_catalog_name/${catalog_name}`)
      .then(({ data }) => {
        const data_columns = [];
        data?.results.map((product) => {
          data_columns.push({
            id: product.id,
            article: product.code,
            image: product.image,
            name: product.name,
          });
        });
        setProducts(data_columns)
      })
      .catch((error) => {});
  };

  const handleMouseEnter = () => {
    setMouseEnter(true);
  };

  const handleMouseLeave = () => {
    setMouseEnter(false);
  };

  useEffect(() => {
    getCatalogProducts();
  }, []);

  return (
    <div className="catalog-main-page-container">
      <div className="catalog-main-page-container-header">
        Каталог
        <Button
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="catalog-main-page-container-header-button" onClick={() => nav("/search")}>
          <div className="main-page-body-first-elem-input-btn-btn">
            Перейти
            <img className="arrow-icon" src={imageUrl} alt="EXZA"/>
          </div>
        </Button>
      </div>
      <div className="catalog-main-page-container-cards">
        <Slider {...settings}>
          {products.map(product => (
            <CatalogMainPageCatalogsCard
              image={product?.image?.find(image => image.is_selected === true)}
              article={product.article}
              key={product.id}
              product={product}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
}
export default CatalogMainPage
