import React, { useEffect, useState } from "react";
import axios_instance from "../requests/config_defaults";
import {
  Button,
  FlexBox,
  Input,
  SuggestionItem,
} from "@ui5/webcomponents-react";

const usePvzSearcher = (style, className, aggregator, geo_object, sender_pvz) => {
  const [_aggregator, setAggregator] = useState(aggregator);
  const [suggestions, setSuggestions] = useState([]);
  const [q, setQ] = useState("");
  const [_geo_object, setGeo_object] = useState(geo_object);
  const [value, setValue] = useState(null);

  const pvzSearch = async () => {
    let data = {
      name: q,
    };
    data.aggregator_ids =
      _aggregator !== null && _aggregator !== undefined ? [_aggregator] : [];
    if (_geo_object) {
      data.geo_object = _geo_object;
    }
    console.log(data);
    await axios_instance
      .post(`/aggregator/pvz/list?name=${q}&sender_pvz=${sender_pvz}`, data)
      .then(({ data }) => {
        setSuggestions(data?.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    pvzSearch();
  }, [q]);

  return {
    pvzSearcher: (
      <FlexBox
        alignItems="Center"
        className={className || ""}
        style={style || {}}
      >
        <Input
          noTypeahead
          placeholder="Введите название или адрес ПВЗ"
          icon={null}
          type="Text"
          showSuggestions
          style={{ width: "100%" }}
          onChange={(e) => {
            setQ(e.target.value);
          }}
          onInput={(e) => {
            setQ(e.target.value);
          }}
          value={q}
          readonly={value !== null || undefined}
          onSuggestionItemSelect={(e) => {
            setValue(suggestions[e.target.Suggestions.selectedItemIndex]);
            e.target.value =
              suggestions[e.target.Suggestions.selectedItemIndex]?.name;
          }}
        >
          {suggestions &&
            suggestions.map((sugg) => (
              <SuggestionItem
                id={sugg.pvz.id}
                text={sugg.pvz.name}
                description={sugg?.exact_address || ""}
              />
            ))}
        </Input>
        <Button
          icon="decline"
          onClick={() => {
            setValue(null);
            setSuggestions([]);
            setQ("");
          }}
          design="Negative"
        />
      </FlexBox>
    ),
    pvzValue: value,
    pvzValue: value,
    pvzSetValue: setValue,
    geoObjectSet: setGeo_object,
    aggregatorSet: setAggregator,
  };
};

export default usePvzSearcher;
