import {
  Button,
  ButtonDesign,
  DynamicPageHeader,
  FlexBox,
  Label,
  Link,
  ObjectPage,
  ObjectPageSection,
  ObjectPageSubSection,
  Input,
  Select,
  Option,
  CheckBox,
  Text,
  Title,
  Toolbar,
  ToolbarDesign,
  ToolbarSpacer,
} from "@ui5/webcomponents-react"
import React, { useEffect, useState } from "react"
import { ExzaLogo } from "../../../base/exza_logo/ExzaLogo"
import { ModelsScene } from "../../../dashboard/catalog/3d-model-scene/ModelsScene"
import { VolumeModel } from "../../../dashboard/catalog/3d-model-scene/3d-model/3DModel"
import axios_instance from "../../../../requests/config_defaults"
import "./ProductsAdminDetailView.css"
import ProductAnalogsView from "./ProductAnalogsView"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductImageCard from "./ProductImageCard";

const FormData = require("form-data");

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerPadding: "60px",
  slidesToScroll: 1,
  arrows: false,
  centerMode: false,
};


const ProductsAdminDetailView = (props) => {
  const [productData, setProductData] = useState({})
  const [propertiesData, setPropertiesData] = useState([])

  const [codeUpdate, setCodeUpdate] = useState("")
  const [codeUpdateOpen, setCodeUpdateOpen] = useState(false)
  const [codeUpdateError, setCodeUpdateError] = useState(false)

  const [standardParametr, setStandardParametr] = useState(null)
  const [standardParametrUpdateOpen, setStandardParametrUpdateOpen] =
    useState(false)
  const [standardParametrError, setStandardParametrError] = useState(false)

  const [brands, setBrands] = useState([])
  const [activeBrand, setActiveBrand] = useState("")
  const [brandUpdateOpen, setBrandUpdateOpen] = useState(false)
  const [changedBrand, setChangedBrand] = useState(false)

  const [manufactures, setManufactures] = useState([])
  const [activeManufacture, setActiveManufacture] = useState("")
  const [manufactureUpdateOpen, setManufactureUpdateOpen] = useState(false)
  const [changedManufacture, setChangedManufacture] = useState(false)

  const [codeBrandManufactureError, setCodeBrandManufactureError] =
    useState(false)

  const [productNameError, setProductNameError] = useState(false)

  const [updatePropertiesIndex, setUpdatePropertiesIndex] = useState([])
  const [updatePropertyError, setUpdatePropertyError] = useState(false)
  const [productName, setProductName] = useState("");
  const [productNameUpdateOpen, setProductNameUpdateOpen] = useState(false);
  const [differentInputPlaceholder, setDifferentInputPlaceholder] =
    useState(false)

  const [prevProperties, setPrevProperties] = useState({})

  const [uploadedImage, setUploadedImage] = useState({
    image: null,
  });
  const [displayNone, setDisplayNone] = useState({
    image: true,
  });
  const [bigImageError, setBigImageError] = useState(false);

  const getBrands = async () => {
    await axios_instance
      .get(`brand_list`)
      .then(({ data }) => {
        setBrands(data)
      })
      .catch((error) => {})
  }

  const getManufactures = async () => {
    await axios_instance
      .get(`manufacture_list`)
      .then(({ data }) => {
        setManufactures(data)
      })
      .catch((error) => {})
  }

  const CodeUpdate = async () => {
    await axios_instance
      .put(`/code/update`, {
        product: props?.activeProductID,
        code: codeUpdate,
      })
      .then(({ data }) => {
        props.getCatalogs()
        setCodeUpdateOpen(false)
        productData.article = codeUpdate
      })
      .catch((error) => {
        setCodeBrandManufactureError(true)
      })
  }

  const codeUpdateHandler = () => {
    if (codeUpdate === productData.article) {
      setCodeUpdateOpen(false)
    } else if (codeUpdate === "") {
      setCodeUpdateError(true)
    } else CodeUpdate()
  }

  const BrandUpdate = async () => {
    await axios_instance
      .put(`/brand/update`, {
        product: props?.activeProductID,
        brand: activeBrand,
      })
      .then(({ data }) => {
        props.getCatalogs()
        setBrandUpdateOpen(false)
        productData.brand = data.brand.name
        productData.brandID = data.brand.id
        setActiveBrand(data.brand.id)
      })
      .catch((error) => {
        setCodeBrandManufactureError(true)
      })
  }

  const brandUpdateHandler = () => {
    if (
      changedBrand === false ||
      activeBrand === productData.brandID ||
      (productData.brandID === "" && activeBrand === "Не указан")
    ) {
      setBrandUpdateOpen(false)
    } else {
      BrandUpdate()
    }
  }

  const ManufactureUpdate = async () => {
    await axios_instance
      .put(`/manufacture/update`, {
        product: props?.activeProductID,
        manufacture: activeManufacture,
      })
      .then(({ data }) => {
        props.getCatalogs()
        setManufactureUpdateOpen(false)
        productData.manufacture = data.manufacture.name
        productData.manufactureID = data.manufacture.id
        setActiveManufacture(data.manufacture.id)
      })
      .catch((error) => {
        setCodeBrandManufactureError(true)
      })
  }

  const manufactureUpdateHandler = () => {
    if (
      changedManufacture === false ||
      activeManufacture === productData.manufactureID ||
      (productData.manufactureID === "" && activeManufacture === "Не указан")
    ) {
      setManufactureUpdateOpen(false)
    } else {
      ManufactureUpdate()
    }
  }

  const UpdateStandardParametr = async () => {
    await axios_instance
      .put(`product/set_standard_parametr`, {
        product_id: props?.activeProductID,
        is_standard: standardParametr,
      })
      .then(({ data }) => {
        props.getCatalogs()
        setStandardParametrUpdateOpen(false)
        productData.is_standard = standardParametr
      })
      .catch((error) => {})
  }

  const standardParametrUpdateHandler = () => {
    if (standardParametr === null) {
      setStandardParametrUpdateOpen(false)
    } else {
      const primaryPropertyList = []
      productData.properties.map((property) => {
        if (
          property.name === "Высота" ||
          property.name === "Длина" ||
          property.name === "Ширина" ||
          property.name === "Вес"
        ) {
          primaryPropertyList.push(property.name)
        }
      })
      if (
        standardParametr === true &&
        new Set(primaryPropertyList).size !== 4
      ) {
        setStandardParametrError(true)
      } else UpdateStandardParametr()
    }
  }

  const UpdateProperty = async (index) => {
    await axios_instance
      .post(`product/property/update/` + props?.activeProductID, {
        product_id: props?.activeProductID,
        name: propertiesData[index].name,
        value: propertiesData[index].value.content,
        measure: propertiesData[index].measure.name,
      })
      .then(({ data }) => {
        removeUpdatePropertyIndex(index)
      })
      .catch((error) => {})
  }

  const propertyUpdateHandler = (target, name, index, content) => {
    let property = [...propertiesData]
    property[index][name][content] = target
    setPropertiesData(property)
  }

  const propertySaveHandler = (index) => {
    if (
      prevProperties[index].value === propertiesData[index].value.content &&
      prevProperties[index].measure === propertiesData[index].measure.name
    ) {
      removeUpdatePropertyIndex(index)
    } else if (
      propertiesData[index].value.content === "" ||
      propertiesData[index].measure.name === ""
    ) {
      setUpdatePropertyError(true)
    } else {
      UpdateProperty(index)
      prevProperties[index] = {
        name: propertiesData[index].name,
        value: propertiesData[index].value.content,
        measure: propertiesData[index].measure.name,
      }
    }
  }

  const prevValueMeasureHandler = () => {
    productData?.properties?.map(
      (prop, index) =>
        (prevProperties[index] = {
          name: prop?.name,
          value: prop?.value?.content,
          measure: prop?.measure?.name,
        })
    )
  }

  const DeleteProperty = async (property_id) => {
    await axios_instance
      .delete(
        `product/property/delete/` + props.activeProductID + `/` + property_id
      )
      .then(({ data }) => {
        getProduct()
      })
      .catch((error) => {})
  }

  const getProduct = async () => {
    await axios_instance
      .get(`product/by_id/` + props.activeProductID)
      .then(({ data }) => {
        setProductData({
          id: data.id,
          article: data.code,
          name: data.name,
          brand: data.brand?.name ? data.brand?.name : "Не указан",
          manufacture: data.manufacture?.name
            ? data.manufacture?.name
            : "Не указан",
          is_standard: data.is_standard,
          properties: data.properties,
          analogs: data.analogs,
          brandID: data.brand?.id ? data.brand?.id : "",
          manufactureID: data.manufacture?.id ? data.manufacture?.id : "",
          image: data.image,
        })
      })
      .catch((error) => {})
  }

  const imageCreate = async () => {
    const formData = new FormData();
    uploadedImage.image && formData.append("image", uploadedImage.image?.image);
    props?.activeProductID &&
      formData.append("product_id", props?.activeProductID);
    await axios_instance
      .post(`image/bind`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        props.showToastSuccess();
        getProduct();
        setUploadedImage({});
      })
      .catch((error) => {
        props.showToastError();
      });
  };

  const handleImageCreate = (image) => {
    const file = image;
    const maxSizeInBytes = 1 * 1024 * 1024;

    if (file) {
      if (file.size > maxSizeInBytes) {
        setBigImageError(true);
      }
    }
  };

  const addUpdatePropertyIndex = (index) => {
    let indexes = [...updatePropertiesIndex, index]
    setUpdatePropertiesIndex(indexes)
  }

  const removeUpdatePropertyIndex = (index) => {
    let indexes = updatePropertiesIndex.filter((indx) => indx !== index)
    setUpdatePropertiesIndex(indexes)
  }

  useEffect(() => {
    setPropertiesData(productData.properties)
    prevValueMeasureHandler()
  }, [productData])

  const ProductNameUpdate = async () => {
    await axios_instance
      .put(`/name/update`, { // Заменить на актуальный URL для обновления имени продукта
        product: props?.activeProductID,
        name: productName,
      })
      .then(({ data }) => {
        props.getCatalogs();
        setProductNameUpdateOpen(false);
        getProduct();
      })
      .catch((error) => {});
  };

  const productNameUpdateHandler = () => {
    if (productName === productData.name) {
      setProductNameUpdateOpen(false);
    } else if (productName === "") {
      setProductNameError(true);   
    } else {
      ProductNameUpdate();
    }
  };

  useEffect(() => {
    props.setOpenAddPropertiesForm(false)
    setUpdatePropertiesIndex([])
    props.setManufactureCreateFormOpen(false)
    props.setBrandCreateFormOpen(false)
    setCodeUpdateOpen(false)
    setBrandUpdateOpen(false)
    setStandardParametrUpdateOpen(false)
    setManufactureUpdateOpen(false)
    setCodeUpdate("")
    setActiveBrand("")
    setActiveManufacture("")
    setStandardParametr(null)
  }, [props?.activeProductID, props?.activeCatalog])

  useEffect(() => {
    getProduct()
  }, [props.activeProductID])

  useEffect(() => {
    getProduct()
  }, [props.openAddPropertiesForm])

  useEffect(() => {
    getBrands()
  }, [props.brandCreateFormOpen])

  useEffect(() => {
    getManufactures()
  }, [props.manufactureCreateFormOpen])

  return (
    <>
      <Toolbar design={ToolbarDesign.Solid}>
        <Title>{productData?.article}</Title>
        <ToolbarSpacer />
        <Button
          icon="decline"
          design={ButtonDesign.Transparent}
          onClick={() => {
            setUpdatePropertiesIndex([])
            setCodeUpdateOpen(false)
            setBrandUpdateOpen(false)
            setStandardParametrUpdateOpen(false)
            setManufactureUpdateOpen(false)
            props.setActiveProduct({})
          }}
        />
      </Toolbar>
      <ObjectPage
        headerContent={
          <DynamicPageHeader>
            <FlexBox alignItems="Center" wrap="Wrap">
              <FlexBox direction="Column">
                <Link>+79178864756</Link>
                <Link>invoice@exzamarket.ru</Link>
                <Link href="https://exzamarket.ru">https://exzamarket.ru</Link>
              </FlexBox>
              <FlexBox direction="Column" style={{ padding: "10px" }}>
                <Label>Набережные Челны</Label>
                <Label>Респ. Татарстан, Россия</Label>
              </FlexBox>
            </FlexBox>
          </DynamicPageHeader>
        }
        headerContentPinnable
        image="/static/exza.png"
        imageShapeCircle
        selectedSectionId="goals"
        showHideHeaderButton
        style={{
          height: "100%",
        }}>
        <ObjectPageSection
          aria-label="properties"
          id="properties"
          titleText="Характеристики">
          <ObjectPageSubSection
            actions={
              <Button
                design="Emphasized"
                style={{ minWidth: "120px" }}
                icon="edit"
                onClick={() => {
                  props.setOpenAddPropertiesForm(true)
                }}>
                Добавить характеристику
              </Button>
            }
            aria-label="baseProperties"
            id="baseProperties"
            titleText="Основные характеристики">
            <FlexBox
              justifyContent="SpaceBetween"
              style={{ alignItems: "center" }}>
              <div>
                <FlexBox
                  style={{
                    gap: "5px",
                  }}>
                  <Label
                    style={{
                      fontSize: "1.1rem",
                      alignItems: "center",
                    }}>
                    Артикул:
                  </Label>
                  {!codeUpdateOpen ? (
                    <Text
                      style={{
                        fontSize: "1.1rem",
                      }}>
                      {productData.article || "Не указан"}
                    </Text>
                  ) : (
                    <Input
                      value={productData.article}
                      valueState={
                        codeBrandManufactureError ||
                        codeUpdateError ||
                        differentInputPlaceholder
                          ? codeBrandManufactureError || codeUpdateError
                            ? "Error"
                            : "Success"
                          : "None"
                      }
                      valueStateMessage={
                        !codeUpdateError ? (
                          <div>
                            Товар с такой связкой парметров:
                            Артикул-Бренд-Производитель уже существует!
                          </div>
                        ) : (
                          <div>Обязательное поле!</div>
                        )
                      }
                      placeholder={productData.article}
                      onInput={(e) => {
                        setCodeUpdate(e.target.value)
                        setCodeUpdateError(false)
                        setCodeBrandManufactureError(false)
                        setDifferentInputPlaceholder(
                          productData.article === e.target.value ? false : true
                        )
                      }}
                    />
                  )}
                </FlexBox>
              </div>
              <div>
                {!codeUpdateOpen ? (
                  <Button
                    onClick={() => {
                      setCodeUpdateOpen(true)
                      setCodeUpdate(productData.article)
                    }}
                    icon="edit"
                  />
                ) : (
                  <Button
                    style={{
                      color: "green",
                    }}
                    onClick={() => codeUpdateHandler()}
                    icon="accept"
                  />
                )}
              </div>
            </FlexBox>
            <FlexBox
              justifyContent="SpaceBetween"
              style={{ alignItems: "center" }}>
              <div>
                <FlexBox
                  style={{
                    gap: "5px",
                  }}>
                  <Label
                    style={{
                      fontSize: "1.1rem",
                      alignItems: "center",
                    }}>
                    Бренд:
                  </Label>
                  {!brandUpdateOpen ? (
                    <Text
                      style={{
                        fontSize: "1.1rem",
                      }}>
                      {productData.brand || "Не указан"}
                    </Text>
                  ) : (
                    <FlexBox
                      style={{
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      <Select
                        valueState={
                          codeBrandManufactureError ? "Error" : "None"
                        }
                        valueStateMessage={
                          <div>
                            Товар с такой связкой парметров:
                            Артикул-Бренд-Производитель уже существует!
                          </div>
                        }
                        onChange={(e) => {
                          setActiveBrand(
                            e.target.value !== "Не указан"
                              ? e.target.value
                              : "Не указан"
                          )
                          setCodeBrandManufactureError(false)
                          setChangedBrand(true)
                        }}>
                        {productData.brandID ? (
                          <>
                            <Option value={productData.brandID}>
                              {productData.brand}
                            </Option>
                            <Option>Не указан</Option>
                          </>
                        ) : (
                          <>
                            <Option>Не указан</Option>
                          </>
                        )}
                        {brands
                          .filter((brand) => brand.id !== productData.brandID)
                          .map((brand) => (
                            <Option value={brand?.id}>{brand.name}</Option>
                          ))}
                      </Select>
                      <Button
                        icon="add"
                        onClick={() => props.setBrandCreateFormOpen(true)}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </div>
              <div>
                {!brandUpdateOpen ? (
                  <Button
                    onClick={() => {
                      setBrandUpdateOpen(true)
                      setActiveBrand(
                        productData.brandID ? productData.brandID : "Не указан"
                      )
                      getBrands()
                    }}
                    icon="edit"
                  />
                ) : (
                  <Button
                    style={{
                      color: "green",
                    }}
                    onClick={() => brandUpdateHandler()}
                    icon="accept"
                  />
                )}
              </div>
            </FlexBox>
            <FlexBox
              justifyContent="SpaceBetween"
              style={{ alignItems: "center" }}>
              <div>
                <FlexBox
                  style={{
                    gap: "5px",
                  }}>
                  <Label
                    style={{
                      fontSize: "1.1rem",
                      alignItems: "center",
                    }}>
                    Производитель:
                  </Label>
                  {!manufactureUpdateOpen ? (
                    <Text
                      style={{
                        fontSize: "1.1rem",
                      }}>
                      {productData.manufacture || "Не указан"}
                    </Text>
                  ) : (
                    <FlexBox
                      style={{
                        alignItems: "center",
                        gap: "5px",
                      }}>
                      <Select
                        onChange={(e) => {
                          setActiveManufacture(
                            e.target.value !== "Не указан"
                              ? e.target.value
                              : "Не указан"
                          )
                          setCodeBrandManufactureError(false)
                          setChangedManufacture(true)
                        }}
                        valueState={
                          codeBrandManufactureError ? "Error" : "None"
                        }
                        valueStateMessage={
                          <div>
                            Товар с такой связкой парметров:
                            Артикул-Бренд-Производитель уже существует!
                          </div>
                        }>
                        {productData.manufactureID ? (
                          <>
                            <Option value={productData.manufactureID}>
                              {productData.manufacture}
                            </Option>
                            <Option>Не указан</Option>
                          </>
                        ) : (
                          <>
                            <Option>Не указан</Option>
                          </>
                        )}
                        {manufactures
                          .filter(
                            (manufacture) =>
                              manufacture.id !== productData.manufactureID
                          )
                          .map((manufacture) => (
                            <Option value={manufacture.id}>
                              {manufacture.name}
                            </Option>
                          ))}
                      </Select>
                      <Button
                        icon="add"
                        onClick={() => props.setManufactureCreateFormOpen(true)}
                      />
                    </FlexBox>
                  )}
                </FlexBox>
              </div>
              <div>
                {!manufactureUpdateOpen ? (
                  <Button
                    onClick={() => {
                      setManufactureUpdateOpen(true)
                      setActiveManufacture(
                        productData.manufactureID
                          ? productData.manufactureID
                          : "Не указан"
                      )
                      getManufactures()
                    }}
                    icon="edit"
                  />
                ) : (
                  <Button
                    style={{
                      color: "green",
                    }}
                    onClick={() => manufactureUpdateHandler()}
                    icon="accept"
                  />
                )}
              </div>
            </FlexBox>
            {codeBrandManufactureError ? (
              <FlexBox>
                <Text
                  style={{
                    fontSize: "1.1rem",
                    color: "red",
                    width: "70%",
                  }}>
                  Товар с такой связкой парметров: Артикул-Бренд-Производитель
                  уже существует!
                </Text>
              </FlexBox>
            ) : null}
            <FlexBox
              justifyContent="SpaceBetween"
              style={{ alignItems: "center" }}>
              <div>
                <FlexBox style={{ gap: "5px" }}>
                  <Label
                    style={{
                      fontSize: "1.1rem",
                      alignItems: "center",
                    }}>
                    Эталонный товар:
                  </Label>
                  {!standardParametrUpdateOpen ? (
                    <>
                      {productData.is_standard ? (
                        <Text style={{ fontSize: "1.1rem" }}>Да</Text>
                      ) : (
                        <Text style={{ fontSize: "1.1rem" }}>Нет</Text>
                      )}
                    </>
                  ) : (
                    <CheckBox
                      onChange={(e) => {
                        setStandardParametr(e.target.checked)
                        setStandardParametrError(false)
                      }}
                      valueState={standardParametrError ? "Error" : "None"}
                      checked={productData.is_standard}
                    />
                  )}
                </FlexBox>
              </div>
              <div>
                {!standardParametrUpdateOpen ? (
                  <Button
                    onClick={() => setStandardParametrUpdateOpen(true)}
                    icon="edit"
                  />
                ) : (
                  <Button
                    style={{
                      color: "green",
                    }}
                    onClick={() => standardParametrUpdateHandler()}
                    icon="accept"
                  />
                )}
              </div>
            </FlexBox>
            <FlexBox
              justifyContent="SpaceBetween"
              style={{ alignItems: "center" }}>
              <div>
                <FlexBox style={{ gap: "5px" }}>
                  <Label
                    style={{
                      fontSize: "1.1rem",
                      alignItems: "center",
                    }}>
                    Наименование:
                  </Label>
                  {!productNameUpdateOpen ? (
                    <Text
                      style={{
                        fontSize: "1.1rem",
                      }}>
                      {productData.name || "Не указано"}
                    </Text>
                  ) : (
                    <Input
                      value={productData.name}
                      valueState={productNameError ? "Error" : "None"}
                      valueStateMessage={<div>Наименование не может быть пустым!</div>}
                      placeholder={productData.name}
                      onInput={(e) => {
                        setProductName(e.target.value);
                        setProductNameError(false);
                      }}
                    />
                  )}
                </FlexBox>
              </div>
              <div>
                {!productNameUpdateOpen ? (
                  <Button
                    onClick={() => {
                      setProductNameUpdateOpen(true);
                    }}
                    icon="edit"
                  />
                ) : (
                  <Button
                    style={{
                      color: "green",
                    }}
                    onClick={() => productNameUpdateHandler()}
                    icon="accept"
                  />
                )}
              </div>
            </FlexBox>
            {standardParametrError ? (
              <Text
                style={{
                  fontSize: "1.1rem",
                  color: "red",
                  width: "70%",
                }}>
                У эталонного товара должны быть заполнены 4 основные
                характеристики: Вес, Длина, Ширина, Высота!
              </Text>
            ) : null}
          </ObjectPageSubSection>

          <ObjectPageSubSection
            aria-label="dimentions"
            id="dimentions"
            titleText="Весогабаритные характеристики (ВГХ)">
            {productData.properties?.map((prop, index) =>
              prop.is_primary ? (
                <FlexBox
                  justifyContent="SpaceBetween"
                  style={{
                    alignItems: "center",
                  }}>
                  <div>
                    <FlexBox
                      style={{
                        gap: "5px",
                        alignItems: "center",
                      }}>
                      <Label
                        style={{
                          fontSize: "1.1rem",
                        }}>
                        {prop.name}:
                      </Label>
                      {!updatePropertiesIndex.includes(index) ? (
                        <Text
                          style={{
                            fontSize: "1.1rem",
                          }}>
                          {prop?.value?.content} {prop?.measure?.name}
                        </Text>
                      ) : (
                        <>
                          <Input
                            className="AdminUpdatePropertyFormInputItem"
                            placeholder={prevProperties[index].value}
                            value={prop.value?.content}
                            valueState={
                              (updatePropertyError &&
                                prop.value?.content === "") ||
                              (differentInputPlaceholder &&
                                prop.value?.content !==
                                  prevProperties[index].value)
                                ? updatePropertyError &&
                                  prop.value?.content === ""
                                  ? "Error"
                                  : "Success"
                                : "None"
                            }
                            valueStateMessage={<div>Обязательное поле</div>}
                            onInput={(e) => {
                              setUpdatePropertyError(false)
                              propertyUpdateHandler(
                                e.target.value,
                                e.target.name,
                                index,
                                "content"
                              )
                              setDifferentInputPlaceholder(
                                prevProperties[index].value === e.target.value
                                  ? false
                                  : true
                              )
                            }}
                            name="value"
                          />
                          <Input
                            className="AdminUpdatePropertyFormInputItem"
                            placeholder={prevProperties[index].measure}
                            value={prevProperties[index].measure}
                            valueState={
                              (updatePropertyError &&
                                prop.measure?.name === "") ||
                              (differentInputPlaceholder &&
                                prop.measure?.name !==
                                  prevProperties[index].measure)
                                ? updatePropertyError &&
                                  prop.measure?.name === ""
                                  ? "Error"
                                  : "Success"
                                : "None"
                            }
                            valueStateMessage={<div>Обязательное поле</div>}
                            onInput={(e) => {
                              setUpdatePropertyError(false)
                              propertyUpdateHandler(
                                e.target.value,
                                e.target.name,
                                index,
                                "name"
                              )
                              setDifferentInputPlaceholder(
                                prevProperties[index].measure === e.target.value
                                  ? false
                                  : true
                              )
                            }}
                            name="measure"
                          />
                        </>
                      )}
                    </FlexBox>
                  </div>
                  <div>
                    {!updatePropertiesIndex.includes(index) ? (
                      <>
                        <Button
                          onClick={() => {
                            addUpdatePropertyIndex(index)
                          }}
                          icon="edit"
                        />
                      </>
                    ) : (
                      <Button
                        style={{
                          color: "green",
                        }}
                        onClick={() => {
                          propertySaveHandler(index)
                        }}
                        icon="accept"
                      />
                    )}
                  </div>
                </FlexBox>
              ) : null
            )}
          </ObjectPageSubSection>
          <ObjectPageSubSection
            aria-label="additionalProperties"
            id="additionalProperties"
            titleText="Дополнительные характеристики">
            {productData.properties?.map((prop, index) =>
              !prop.is_primary ? (
                <FlexBox
                  justifyContent="SpaceBetween"
                  style={{
                    alignItems: "center",
                    lineHeight: 1.4,
                  }}>
                  <div>
                    <FlexBox
                      style={{
                        gap: "5px",
                        alignItems: "center",
                      }}>
                      <Label
                        style={{
                          fontSize: "1.1rem",
                        }}>
                        {prop.name}:
                      </Label>
                      {!updatePropertiesIndex.includes(index) ? (
                        <Text
                          style={{
                            fontSize: "1.1rem",
                          }}>
                          {prop?.value?.content}
                        </Text>
                      ) : (
                        <>
                          <Input
                            className="AdminUpdatePropertyFormInputItem"
                            placeholder={prevProperties[index].value}
                            value={prevProperties[index].value}
                            valueState={
                              (updatePropertyError &&
                                prop.value?.content === "") ||
                              (differentInputPlaceholder &&
                                prop.value?.content !==
                                  prevProperties[index].value)
                                ? updatePropertyError &&
                                  prop.value?.content === ""
                                  ? "Error"
                                  : "Success"
                                : "None"
                            }
                            valueStateMessage={<div>Обязательное поле</div>}
                            onInput={(e) => {
                              setUpdatePropertyError(false)
                              propertyUpdateHandler(
                                e.target.value,
                                e.target.name,
                                index,
                                "content"
                              )
                              setDifferentInputPlaceholder(
                                prevProperties[index].value === e.target.value
                                  ? false
                                  : true
                              )
                            }}
                            name="value"
                            onChange={(e) => {
                              propertyUpdateHandler(
                                e.target.value,
                                e.target.name,
                                index,
                                "content"
                              )
                            }}
                          />
                        </>
                      )}
                    </FlexBox>
                  </div>
                  <FlexBox
                    style={{
                      alignItems: "center",
                    }}>
                    {!updatePropertiesIndex.includes(index) ? (
                      <>
                        <Button
                          onClick={() => {
                            addUpdatePropertyIndex(index)
                          }}
                          icon="edit"
                        />
                        <Button
                          onClick={() => {
                            DeleteProperty(productData.properties[index].id)
                          }}
                          icon="delete"
                        />
                      </>
                    ) : (
                      <Button
                        style={{
                          color: "green",
                        }}
                        onClick={() => {
                          propertySaveHandler(index)
                        }}
                        icon="accept"
                      />
                    )}
                  </FlexBox>
                </FlexBox>
              ) : null
            )}
          </ObjectPageSubSection>

          <ObjectPageSubSection
            id="catalog-product-analogs"
            aria-label={"Аналоги"}
            titleText={"Аналоги"}>
            <ProductAnalogsView
              activeProductID={props.activeProductID}
              activeProduct={props.activeProduct}
              setActiveProduct={props.setActiveProduct}
              getProduct={getProduct}
            />
          </ObjectPageSubSection>

          <ObjectPageSubSection
            id="catalog-product-properties-card-section-3d-model"
            aria-label={"3d модель"}
            titleText={"3D модель"}>
            <ModelsScene style={{ height: 500 }} model={<VolumeModel />} />
          </ObjectPageSubSection>

          <ObjectPageSubSection
            id="catalog-product-properties-card-section-photo"
            aria-label={"Фото"}
            titleText={"Фото"}
          >
            <div className="catalog-product-properties-card-section-photo-card1">
              {uploadedImage.image ? (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center" }}>
                  <div
                    className="setting-field"
                    onMouseEnter={() =>
                      setDisplayNone((prev) => ({ ...prev, image: false }))
                    }
                    onMouseLeave={() =>
                      setDisplayNone((prev) => ({ ...prev, image: true }))
                    }
                  >
                    <img
                      style={{ height: "100%", width: "100%", margin: 20 }}
                      src={uploadedImage.image.image_interface}
                      alt="EXZA"
                    />
                    <div
                      className={
                        displayNone.image
                          ? "icon-uploader-none"
                          : "icon-uploader"
                      }
                    >
                      <div
                        onClick={() => {
                          setUploadedImage((prev) => ({
                            ...prev,
                            image: null,
                          }));
                          setBigImageError(false);
                        }}
                        className="cancel-upload"
                      >
                        <Label style={{ color: "#fff" }}>
                          Нажмите для отмены
                        </Label>
                      </div>
                    </div>
                  </div>
                  {bigImageError ? (
                    <span className="big-image-error-text">
                      *Размер загружаемого фото не должен превышать 1МБ.
                    </span>
                  ) : null}
                  <Button
                    disabled={bigImageError ? true : false}
                    style={{ display: "flex", marginTop: 25 }}
                    onClick={() => imageCreate()}
                  >
                    Сохранить
                  </Button>
                </div>
              ) : (
                <>
                  {productData?.image?.length !== 1 ? (
                    <Slider {...settings}>
                      {productData?.image?.map((image) => (
                        <ProductImageCard
                          image={image}
                          key={productData.id}
                          productData={productData}
                          getProduct={getProduct}
                          setImageDeleteOpen={props.setImageDeleteOpen}
                          setActiveImageID={props.setActiveImageID}
                          imageDeleted={props.imageDeleted}
                          setImageDeleted={props.setImageDeleted}
                          catalogView={false}
                        />
                      ))}
                    </Slider>
                  ) : (
                    <ProductImageCard
                      image={productData?.image[0]}
                      key={productData?.id}
                      productData={productData}
                      getProduct={getProduct}
                      setImageDeleteOpen={props.setImageDeleteOpen}
                      setActiveImageID={props.setActiveImageID}
                      imageDeleted={props.imageDeleted}
                      setImageDeleted={props.setImageDeleted}
                      catalogView={false}
                    />
                  )}
                  <Button
                    tooltip="Размер загружаемого фото не должен превышать 1МБ."
                    style={{ display: "flex", marginTop: 25 }}
                    onclick={"document.getElementById('imageUpload').click()"}
                  >
                    Загрузить фото
                  </Button>
                </>
              )}
            </div>
            <input
              style={{ display: "none" }}
              id="imageUpload"
              type="file"
              accept="image/*"
              onChange={(e) => {
                setUploadedImage((prev) => ({
                  ...prev,
                  image: {
                    image_interface: URL.createObjectURL(e.target.files[0]),
                    image: e.target.files[0],
                  },
                }));
                handleImageCreate(e.target.files[0]);
              }}
            />
          </ObjectPageSubSection>

        </ObjectPageSection>
      </ObjectPage>
    </>
  )
}

export default ProductsAdminDetailView
