import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import './SideBarProfile.css'
import {
    Avatar,
    SideNavigation,
    SideNavigationItem,
    SideNavigationSubItem
} from '@ui5/webcomponents-react'
import '@ui5/webcomponents-icons/dist/customer.js';
import '@ui5/webcomponents-icons/dist/bell.js';
import '@ui5/webcomponents-icons/dist/manager.js';
import '@ui5/webcomponents-icons/dist/unlocked.js';
import { useNavigate } from 'react-router-dom';
import axios_instance from '../../../../requests/config_defaults';
import { useAtom } from 'jotai';
import { activeCompanyAtom, emailAtom, fullNameAtom, isAdminAtom, activeCompanyAddressAtom } from '../../../../hoc/GlobalAtoms';

const ProfileCardMini = (props) => {
    


    return (
        <div className={classNames(
            'side-bar-profile',
        )}>
            <div className='side-bar-profile-content mini-version'>
                <div className="side-bar-profile-mini">
                    <Avatar
                        colorScheme="Accent6"
                        icon="employee"
                        shape="Circle"
                        size="S"
                    >
                        <img src="https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg" alt="" />
                    </Avatar>

                    <div className="user-info">
                        <h1>{props.fullName && props.fullName}</h1>
                        <h4>{props.email && props.email}</h4>
                    </div>
                </div>
            </div>


        </div>
    )
}

const ProfileCard = (props) => {
    const [email, setEmail] = useAtom(emailAtom);
    const [fullName, setFullName] = useAtom(fullNameAtom);
    const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
    const [activeCompany, setActiveCompany] = useAtom(activeCompanyAtom);
    const [activeCompanyAddress, setActiveCompanyAddress] = useAtom(activeCompanyAddressAtom);

    const nav = useNavigate()

    const handleClickLogOutButton = async (event) => {
        await axios_instance
            .get('/logout')
            .then((response) => {
                setEmail('');
                setFullName('');
                setIsAdmin(false);
                setActiveCompany({});
                setActiveCompanyAddress({});
                nav("/login");
                props.closeMenu();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className={classNames(
            'side-bar-profile',
            "profile-full-height"
        )}>
            <div className='side-bar-profile-content max-version'>
                <div className="side-bar-profile-max">
                    <img className='side-bar-profile-max-img' src="https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg" alt="" />
                    <div className="user-info">
                        <h1>{props.fullName && props.fullName}</h1>
                        <h4>{props.email && props.email}</h4>
                    </div>
                </div>

                <SideNavigation
                    fixedItems={
                        <>
                            <SideNavigationItem icon="unlocked" text="Выход" onClick={handleClickLogOutButton} />
                        </>
                    }
                    onSelectionChange={function _a() { }}
                    className='user-menu'
                    style={{
                        width: "100%"
                    }}
                >
                    <SideNavigationItem
                        icon="customer"
                        text="Профиль"
                        style={{
                            width: "100%"
                        }}
                        onClick={() => {
                            nav("/account");
                            props.closeMenu();
                        }}
                    />
                    <SideNavigationItem
                        icon="manager"
                        text="Мои компании"
                        onClick={() => {
                            nav("/legal-entities");
                            props.closeMenu();
                        }}
                    />
                </SideNavigation>

            </div>

        </div>
    )
}
export { ProfileCardMini, ProfileCard }