import "./CatalogMainPageCatalogsCard.css";
import { useAtom } from "jotai";
import {
  marketIconsAtom,
} from "../../../../hoc/GlobalAtoms";

const CatalogMainPageCatalogsCard = (props) => {
  const [icons, setIcons] = useAtom(marketIconsAtom);
  return (
    // <div className="catalog-main-page-catalog-card">
    <a href={`/search?pid=${props.product.id}`} className="catalog-main-page-catalog-card1">
      <div className="catalog-main-page-catalog-card-photo">
        <img
          className="catalog-main-page-catalog-card-photo-img"
          src={
            props?.image?.is_selected
              ? props?.image?.image
              : icons?.default_product_img
            }
          alt="EXZA"
        />
      </div>
      <div className="catalog-main-page-catalog-card-name">
        <text>{props.article}</text>
        <text>{props.product.name}</text>
      </div>
    </a>
    // </div>
  );
};
export default CatalogMainPageCatalogsCard;
