import React, { useEffect, useState } from 'react'
import { FlexBox, ThemeProvider, Loader, MultiComboBox, MultiComboBoxItem, Form, FormGroup, FormItem, Input, Title, ObjectStatus, Icon, Button, Text, DatePicker, Option, Select } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';

const RegStep6 = (props) => {

    const [companyValueState, setCompanyValueState] = useState('');

    async function registrateLegalEntity() {
        props.setIsLoading(true);
        await axios_instance
            .post(
                `/entity/registrate`,
                {
                    ...props.entityData, 
                    email: props.registrationData.email
                }
            )
            .then(response => {
                console.log(response);
                props.setIsLoading(false);
                props.setStep(7);
            })
            .catch(error => {
                console.log(error);
                props.setIsLoading(false);
            });
    }

    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Введите название компании
                </Title>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >
                <FormGroup>
                    <FormItem>
                        <Input
                            name="company"
                            valueState={companyValueState ? "Error" : ""}
                            required
                            placeholder='Название'
                            //value={login}
                            //onInput={handleLoginInput}
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0,
                                marginTop: 50,
                                marginBottom: 50

                            }}
                            onChange={e => {
                                setCompanyValueState("");
                                props.setEntityData({
                                    ...props.entityData,
                                    name: e.target.typedInValue
                                });
                            }}
                        />
                    </FormItem>
                    {companyValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {companyValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                </FormGroup>

                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="End"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                onClick={() => { registrateLegalEntity(); }}
                                design="Emphasized" style={{ width: 100, fontWeight: 'normal' }}>
                                Далее
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export default RegStep6