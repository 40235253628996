import "@ui5/webcomponents-icons/dist/search.js";
import { Button } from '@ui5/webcomponents-react'
import "./header.css";
import MainPageMainContainer from "../MainPageMainContainer/MainPageMainContainer"
import { useNavigate } from "react-router-dom";
import { checkIsAuthenticated } from "../../../requests/authorization"
import { useEffect, useState } from "react";
import UserProfileActionSheet from "../../base/user-profile-action-sheet/UserProfileActionSheet";
import { emailAtom } from "../../../hoc/GlobalAtoms";
import { useAtom } from "jotai";
import SideBar from "../../container/side-bar/SideBar"

const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [email] = useAtom(emailAtom)
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const nav = useNavigate();
  const [isAuth, setIsAuth] = useState(false)

  const isAuthenticated = async() => {
      let status = await checkIsAuthenticated();
      setIsAuth(status)
  }

  useEffect(() => {
      isAuthenticated();
  }, [isAuth])

  return (
    <MainPageMainContainer>
      <div className="main-page-header">
        <div className="main-page-header-content">
          <div className="main-page-header-left-side">
              <a href="/" className="main-logo-a">
                  <img
                    alt="EXZA"
                    className="main-logo"
                    src={"/static/EXZA_LOGO_NAME_slogan.svg"}
                  />
              </a>
              <Button design="Transparent" className="main-page-header-right-side-button" onClick={() => nav("/search")}>
                ПРЕДЛОЖЕНИЯ
              </Button>
              <Button design="Transparent" className="main-page-header-right-side-button" onClick={() => nav("/search")}>
                ДОСТАВКА
              </Button>
              <Button design="Transparent" className="main-page-header-right-side-button" onClick={() => nav("/documents")}>
                ДОКУМЕНТЫ
              </Button>
              <Button design="Transparent" className="main-page-header-right-side-button" onClick={() => openInNewTab("https://gitlab.it-psg.com/")}>
                ДЛЯ РАЗРАБОТЧИКОВ
              </Button>
          </div>
          <div className="main-page-header-right-side">
            <Button 
              icon="search"
              design="Transparent" 
              className="main-page-header-right-side-button"
              onClick={() => nav("/search")}
            >
              <text className="main-page-text-button">
                ПОИСК ЗАПЧАСТЕЙ
              </text>
            </Button>
            {/* <Button icon="cart" design="Transparent" className="main-page-header-right-side-button">
              <text className="main-page-text-button">
                КОРЗИНА
              </text>
            </Button> */}
            {!isAuth ? (
              <Button
                design="Transparent"
                className="main-page-header-right-side-button"
                onClick={() => nav("/login")}
              >
                <text className="main-page-text-button">
                  ВОЙТИ
                </text>
              </Button>
            ) : (
              <>
                <UserProfileActionSheet
                  email={email}
                  onLogout={() => {
                    nav("/login");
                  }}
                />
                {!menuIsOpen && (
                  <Button
                    className="main-header-controller"
                    icon="menu2"
                    onClick={() => setMenuIsOpen(true)}
                  />
                )}
                <SideBar
                  menuIsOpen={menuIsOpen}
                  setMenuIsOpen={setMenuIsOpen}
                />
              </>
            )}
          </div>     
        </div>
      </div>
    </MainPageMainContainer>
  )
}

export default Header