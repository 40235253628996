import { useAtom } from "jotai";
import React, { useEffect, useState } from "react";
import {
  YMaps,
  Map,
  Placemark,
  ZoomControl,
  FullscreenControl,
} from "@pbe/react-yandex-maps";
import { addressesRefresh } from "../company-detail-view/CompanyDetailView";
import {
  Button,
  ButtonDesign,
  ComboBox,
  ComboBoxItem,
  FlexBox,
  Form,
  FormGroup,
  FormItem,
  Input,
  Link,
  MessageStrip,
  SuggestionItem,
  Switch,
  Text,
} from "@ui5/webcomponents-react";
import axios_instance from "../../../requests/config_defaults";
import useGeoSearcher from "../../../hooks/GeoSearcher";

const CompanyAddressCreateForm = (props) => {
  const onSuccess = props.onSuccess || (() => {});
  const [refresher, setRefresher] = useAtom(addressesRefresh);
  const [activeCompany, setActiveCompany] = useState(props.activeCompany);
  const { searcher, geo_object, setValue } = useGeoSearcher(
    {
      width: "100%",
      gap: "10px",
    },
    ""
  );
  const [formData, setFormData] = useState({
    geo_object: null,
    exact_address: null,
    name: null,
    is_pvz: false,
    entity: props.activeCompany,
  });
  const [formValidation, setFormValidation] = useState({
    geo_object: true,
    exact_address: true,
    name: true,
  });
  const [coodrs, setCoords] = useState([55.75, 37.57]);

  useEffect(() => {
    setFormData((prev) => ({ ...prev, geo_object: geo_object?.id }));
  }, [geo_object]);

  useEffect(() => {
    setActiveCompany(props.activeCompany);
  }, [props.activeCompany]);

  const getByName = async (name) => {
    await axios_instance
      .get(`/address/${props.activeCompany}/get?name=${name}`)
      .then(({ data }) => {
        setFormValidation((prev) => ({ ...prev, name: false }));
      })
      .catch((error) => {
        setFormValidation((prev) => ({ ...prev, name: true }));
      });
  };

  const validate = () => {
    setFormData((prev) => ({ ...prev, geo_object: geo_object?.id }));
    if (formData.geo_object !== null || undefined) {
      setFormValidation((prev) => ({ ...prev, geo_object: true }));
    } else {
      setFormValidation((prev) => ({ ...prev, geo_object: false }));
    }

    if (
      formData.exact_address !== null ||
      (undefined && formData.exact_address !== "")
    ) {
      setFormValidation((prev) => ({ ...prev, exact_address: true }));
    } else {
      setFormValidation((prev) => ({ ...prev, exact_address: false }));
    }

    if (formData.name !== null || undefined || "") {
      setFormValidation((prev) => ({ ...prev, name: true }));
    } else {
      setFormValidation((prev) => ({ ...prev, name: false }));
    }

    return (
      formValidation.exact_address &&
      formValidation.geo_object &&
      formValidation.name
    );
  };

  const create = async () => {
    console.log(formData, geo_object);
    let is_valid = validate();
    console.log(is_valid, formData);
    if (is_valid === true) {
      await axios_instance
        .post(`/address/create`, formData)
        .then(({ data }) => {
          setRefresher(!refresher);
          if (props.setState) {
            props.setState(false);
          } else props.setUserState("orderCard");
          onSuccess();
        })
        .catch((error) => {});
    }
  };

  return (
    <div style={{margin: 20}}>
      <Form
        columnsL={1}
        columnsM={1}
        columnsS={1}
        columnsXL={2}
        labelSpanL={4}
        labelSpanM={2}
        labelSpanS={12}
        labelSpanXL={4}
        style={{
          alignItems: "left",
        }}
        // className='MyOfferCreateForm-form'
        titleText={
          props.searchPage !== "searchPage" ? (
            "Заполните форму"
          ) : (
            <FlexBox
              direction="Row"
              style={{
                justifyContent: "space-between",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <div>Создание склада</div>
              <Button
                onClick={() => {
                  props.setNewAddressCreateOpen(false);
                }}
              >
                Вернуться к выбору склада
              </Button>
            </FlexBox>
          )
        }
      >
        <FormGroup titleText="Местоположение">
          <FormItem label="Населенный пункт">{searcher}</FormItem>
          {/* <FormItem label={""}>
                        <YMaps>
                            <Map width={"100%"} state={{ center: coodrs }} defaultState={{ center: coodrs, zoom: 9 }} >
                                <Placemark geometry={coodrs} />
                                <ZoomControl options={{ float: "right" }} />
                                <FullscreenControl />
                            </Map>
                        </YMaps>
                    </FormItem> */}
          <FormItem label={"Точный адрес"}>
            <Input
              style={{ width: "100%" }}
              onChange={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  exact_address: e.target.value,
                }));
              }}
              onInput={(e) => {
                setFormData((prev) => ({
                  ...prev,
                  exact_address: e.target.value,
                }));
              }}
              value={formData.exact_address}
              valueState={formValidation.exact_address ? "None" : "Error"}
            />
          </FormItem>
        </FormGroup>
        <FormGroup titleText="Общая информация">
          <FormItem label={"Наименование"}>
            <Input
              style={{ width: "100%" }}
              valueStateMessage={<div>Адрес с таким именем уже существует</div>}
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, name: e.target.value }));
                getByName(e.target.value);
              }}
              onInput={(e) => {
                setFormData((prev) => ({ ...prev, name: e.target.value }));
                getByName(e.target.value);
              }}
              value={formData.name}
              valueState={
                formData?.name && formValidation?.name
                  ? "Success"
                  : !formData?.name
                  ? "None"
                  : "Error"
              }
            />
          </FormItem>
          <FormItem label={"Режим доставки ПВЗ"}>
            <Switch
              onChange={(e) => {
                setFormData((prev) => ({ ...prev, is_pvz: e.target.checked }));
              }}
              textOff={"Нет"}
              textOn={"Да"}
              checked={formData.is_pvz}
            />
          </FormItem>
          {formData?.is_pvz && (
            <FormItem>
              <MessageStrip hideCloseButton color="10">
                При включении режима доставки "ПВЗ" забор товара будет
                осуществляться с пунтка выдачи заказов, который указывается при
                подтверждении продажи. Режим доставки "ПВЗ" используется в
                случаях когда забор товара поставщика невозможен по некоторым
                причинам.
              </MessageStrip>
            </FormItem>
          )}
        </FormGroup>

        <FormItem style={{ width: "100%" }} alignItems={"left"} label="">
          <Button
            design="Emphasized"
            style={{
              padding: "0.5rem 1rem",
              width: "100%",
            }}
            onClick={async (e) => {
              await create();
            }}
          >
            Создать
          </Button>
        </FormItem>
        <FormGroup titleText="Контактная информация">
          <FormItem label="Веб-сайт">
            <Link href="https://exzamarket.ru">https://exzamarket.ru</Link>
          </FormItem>
          <FormItem label="E-mail">
            <Link>invoice@exzamarket.ru</Link>
          </FormItem>
          <FormItem label="Телефон">
            <Link>+7 917 886-47-56</Link>
          </FormItem>
          <FormItem label="Компания">
            <Text>
              ООО "Экспресс Запчасть" (ООО "ЭКЗА", ИНН 1650384691) – дочерняя
              компания ООО "Цифровая платформа КАМАЗ", учредитель ПАО КАМАЗ
            </Text>
          </FormItem>
          <FormItem label="Деятельность">
            <Text>
              ООО "ЭКЗА" осуществляет свою деятельность совместно и в интересах
              ООО "Автозапчасть КАМАЗ" и его партнёров.
            </Text>
          </FormItem>
          <FormItem label="Головной офис">
            <Text>Россия, Респ. Татарстан, Набережные Челны</Text>
          </FormItem>
        </FormGroup>
      </Form>
    </div>
  );
};

export default CompanyAddressCreateForm;
