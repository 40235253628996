import React, {useEffect} from "react"
import {
    AnalyticalTable,
    AnalyticalTableHooks,
    AnalyticalTableVisibleRowCountMode,
    Button,
    FlexBox,
    ThemeProvider
} from "@ui5/webcomponents-react";



const DATA = [
    {
        article: "R3966244",
        count: "12",
        stock_price: "580,0",
        sum_price: "6 960,0"
    },
    {
        article: "54115-1203031-40",
        count: "7",
        stock_price: "5247,0",
        sum_price: "36 729,0"
    },
    {
        article: "3660020945",
        count: "98",
        stock_price: "339,0",
        sum_price: "33 222,0"
    },
    {
        article: "8560-04-2918050",
        count: "3",
        stock_price: "1844,0",
        sum_price: "5 532,0"
    },
    {
        article: "4310-2912112",
        count: "1",
        stock_price: "143,0",
        sum_price: "143,0"
    },
    {
        article: "43114-8403020-50",
        count: "8",
        stock_price: "11212,0",
        sum_price: "89 696,0"
    },
]

const SupplierSalesView = (props) => {
    const COLUMNS = [
        {
            Header: "Артикул",
            accessor: "article",
            enableMultiSort: true
        },

        {
            Header: "Количество",
            accessor: "count",
            enableMultiSort: true
        },
        {
            Header: "Цена за единицу товара",
            accessor: "stock_price",
            enableMultiSort: true
        },
        {
            Header: "Суммарная стоимость",
            accessor: "sum_price",
            enableMultiSort: true
        },
        {
            Header: 'Подтвердить наличие',
            accessor: '.',
            disableFilters: true,
            disableGroupBy: true,
            disableResizing: true,
            disableSortBy: true,
            id: 'action_accept',
            minWidth: 50,
            Cell: (instance) => {
                const {cell, row, webComponentsReactProperties} = instance;
                // disable buttons if overlay is active to prevent focus
                const isOverlay = webComponentsReactProperties.showOverlay;
                // console.log('This is your row data', row.original);
                return (
                    <FlexBox
                        justifyContent="SpaceAround"
                        fitContainer={true}
                        alignItems="Center"
                    >
                        <Button design="Positive" icon="accept"/>

                    </FlexBox>
                );
            },
        },
        {
            Header: 'Отказ',
            accessor: '.',

            headerTooltip: "В случае отказа сервис обнулит Ваши остатки.",
            disableFilters: true,
            disableGroupBy: true,
            disableResizing: true,
            disableSortBy: true,
            id: 'action_decline',
            minWidth: 50,
            Cell: (instance) => {
                const {cell, row, webComponentsReactProperties} = instance;
                // disable buttons if overlay is active to prevent focus
                const isOverlay = webComponentsReactProperties.showOverlay;
                // console.log('This is your row data', row.original);
                return (
                    <FlexBox
                        justifyContent="SpaceAround"
                        fitContainer={true}
                        alignItems="Center"
                    >
                        <Button design="Negative" icon="decline"/>

                    </FlexBox>
                );
            },
        },
    ];

    const useManualRowSelect = (hooks) => {
        const useInstanceAfterData = (instance) => {
            const {flatRows, toggleRowSelected} = instance;
            useEffect(() => {
                flatRows.forEach(({id, original}) => {
                    if ("isSelected" in original) {
                        toggleRowSelected(id, original.isSelected);
                    }
                });
            }, [flatRows, toggleRowSelected]);
        };
        hooks.useInstanceAfterData.push(useInstanceAfterData);
    };
    let orderedIds = []
    COLUMNS.map((item) => {
        orderedIds.push(item.accessor)
    })
    return (
        <ThemeProvider
            style={{
                minHeight: "100%"
            }}>
            <AnalyticalTable
                style={{
                    minHeight: "100%"
                }}
                columns={COLUMNS}
                data={DATA}
                selectionMode="MultiSelect"
                isTreeTable
                sortable
                selectionBehavior="RowSelector"
                tableHooks={
                    [AnalyticalTableHooks.useOrderedMultiSort(orderedIds)]
                }
                reactTableOptions={{selectSubRows: true}}
                withNavigationHighlight
                visibleRowCountMode={AnalyticalTableVisibleRowCountMode.Auto}
                draggable={false}
                onDragStart={() => {
                }}
                containerWidth={"auto"}
                minRows={10}
            />
        </ThemeProvider>
    )
}

export {SupplierSalesView}