import { MaterialReactTable, MRT_TableHeadCell } from "material-react-table";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import Paginator from "../paginator/Paginator";
import classNames from "classnames";
import useWindowSize from "../../../hooks/Window";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Icon } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/pushpin-off.js";

const Table = ({
  className,

  columns,
  data,
  defaultColumn,

  enableGlobalFilter,
  editDisplayMode,
  enableEditing,
  enablePagination,
  enableColumnFilters,
  enableColumnOrdering,
  enableGrouping,
  enableColumnPinning,
  enableFacetedValues,
  columnFilterDisplayMode,
  layoutMode,
  enableColumnResizing,
  enableRowActions,
  enableStickyHeader,
  enableStickyFooter,
  enableFullScreenToggle,
  enableRowDragging,
  isLoading,
  isFullScreen,
  draggingRow,
  muiTableBodyCellProps,
  muiTableBodyRowProps,
  rowPinning,
  columnFilters,
  showColumnFilters,
  initialState,
  columnPinHeaderIcon,

  muiTablePaperProps,
  muiRowDragHandleProps,

  table,
  onColumnFiltersChange,
  onRowPinningChange,
  onIsFullScreenChange,
  renderDetailPanel,
  renderRowActions,
  onDraggingRowChange,
  onGlobalFilterChange,

  topToolbarCustomActions,
  rowActionMenuItems,

  pagination,
}) => {
  const data_columns = [];
  const bounds = useRef(null);
  const [height, setHeight] = useState(0);
  const window = useWindowSize();

  for (let main_key in columns) {
    data_columns.push({
      accessorKey: columns[main_key].key, //simple recommended way to define a column
      header: columns[main_key].header,
      size: columns[main_key]?.size || 400,
      enableEditing: columns[main_key].editable,
      enableColumnFilter: columns[main_key]?.filterable,
      enableClickToCopy: columns[main_key]?.enableClickToCopy ?? true,
      onColumnFilterFnsChange:
        columns[main_key]?.onColumnFilterFnsChange || (() => {}),
      Cell:
        columns[main_key]?.Cell ||
        (({ renderedCellValue, row }) => renderedCellValue),
      Header:
        columns[main_key]?.Header ||
        (({ column, header, table }) => column.columnDef.header),
      muiEditTextFieldProps: columns[main_key]?.muiEditTextFieldProps,
      Edit: columns[main_key]?.Edit,
      filterFn: columns[main_key].filterFn || "",
      filterVariant: columns[main_key].filterVariant || "",
      Filter: columns[main_key]?.Filter || "",
    });
  }

  useEffect(() => {
    setHeight(bounds.current.clientHeight);
  }, [window.height]);

  useEffect(() => {
    setHeight(bounds.current.clientHeight);
  }, [data_columns]);

  return (
    <div
      className={classNames("table-bound-container", className || "")}
      ref={bounds}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MaterialReactTable
          displayColumnDefOptions={{
            "mrt-row-pin": {
              enableOrdering: false,
              enablePinning: true,
              enableColumnActions: false,
              size: columnPinHeaderIcon ? 5 : 100,
              grow: true,
              Header: ({}) => (
                <div
                    style={{ cursor: "help" }}
                    title="Закрепить"
                >
                    {columnPinHeaderIcon ? <Icon name="pushpin-off"/> : "Закрепить"}
                </div>
              ),
            },
            "mrt-row-actions": {
              enableOrdering: false,
              enablePinning: false,
              enableColumnActions: false,
              size: 90,
              grow: true,
              Header: ({}) => (
                <div
                    style={{ cursor: "help" }}
                    title="Действия"
                >
                    {"Действия"}
                </div>
              ),
            },
          }}
          enableGlobalFilter={enableGlobalFilter || false}
          onGlobalFilterChange={onGlobalFilterChange || (() => {})}
          columns={data_columns || []}
          data={data || []}
          localization={MRT_Localization_RU}
          editDisplayMode={editDisplayMode || "cell"}
          table={table}
          onColumnFilterFnsChange={(e) => console.log(e)}
          enableRowPinning={true}
          enableEditing={enableEditing || false}
          enablePagination={enablePagination || false}
          enableColumnFilters={enableColumnFilters || false}
          enableColumnOrdering={enableColumnOrdering || false}
          enableGrouping={enableGrouping || false}
          enableColumnPinning={enableColumnPinning || false}
          enableFacetedValues={enableFacetedValues || false}
          renderDetailPanel={renderDetailPanel || null}
          renderRowActions={renderRowActions || null}
          enableColumnResizing={enableColumnResizing || false}
          enableRowActions={enableRowActions || false}
          enableStickyHeader={enableStickyHeader || false}
          enableStickyFooter={enableStickyFooter || false}
          enableRowDragging={enableRowDragging || false}
          enableFullScreenToggle={enableFullScreenToggle || false}
          columnResizeMode={"onEnd"}
          onIsFullScreenChange={onIsFullScreenChange}
          layoutMode={layoutMode || "grid"}
          muiRowDragHandleProps={muiRowDragHandleProps || null}
          muiTablePaperProps={muiTablePaperProps || null}
          onDraggingRowChange={onDraggingRowChange || (() => {})}
          onColumnFiltersChange={onColumnFiltersChange || (() => {})}
          onRowPinningChange={onRowPinningChange || (() => {})}
          defaultColumn={defaultColumn || {}}
          muiTableBodyCellProps={muiTableBodyCellProps}
          muiTableBodyRowProps={muiTableBodyRowProps}
          state={{
            isLoading: isLoading || false,
            isFullScreen: isFullScreen || false,
            draggingRow: draggingRow || null,
            rowPinning: rowPinning ? rowPinning : [],
            columnFilters: columnFilters ? columnFilters : [],
          }}
          initialState={{
            columnPinning: { left: ["mrt-row-actions", "mrt-row-pin"] },
            showColumnFilters: showColumnFilters ? showColumnFilters : false,
            ...initialState,
          }}
          muiTableContainerProps={{
            sx: {
              height: `${height - 120}px`,
              borderRadius: "10px",
              scrollbarColor: "#7b91a8",
              scrollbarWidth: "4px",
              /* width */
              "::-webkit-scrollbar": {
                width: "10px !important",
                height: "10px !important",
              },

              /* Track */
              "::-webkit-scrollbar-track": {
                boxShadow: "inset 0 0 2px grey",
                borderRadius: "5px",
              },

              /* Handle */
              "::-webkit-scrollbar-thumb": {
                background: "#7b91a8",
                borderRadius: "5px",
              },
            },
          }}
          renderTopToolbarCustomActions={({ table }) =>
            topToolbarCustomActions || <></>
          }
          renderBottomToolbar={({ table }) => {
            return (
              <Paginator
                page={pagination?.page || 1}
                setPage={pagination?.setPage || (() => {})}
                pages_count={pagination?.pageCount || 1}
                disabled={isLoading || false}
              />
            );
          }}
          muiTableHeadCellProps={({ column }) => ({
            sx: {
              bgcolor: "#d5e7fb !important",
              color: "#0057d2",
              fontWeight: "bold !important",
            },
          })}
          muiColumnActionsButtonProps={({ column }) => ({
            sx: {
              color: "#0057d2",
            },
          })}
          muiColumnDragHandleProps={({ column }) => ({
            sx: {
              color: "#0057d2",
            },
          })}
          muiColumnSortButtonProps={({ column }) => ({
            sx: {
              color: "#0057d2",
            },
          })}
          muiFilterSliderProps={({ column }) => ({
            sx: {
              color: "#0057d2",
            },
          })}
          muiTableHeadRowProps={({ column }) => ({
            sx: {
              bgcolor: "#0070f2",
              borderRadius: "10px",
            },
          })}
          muiTableProps={({ column }) => ({
            sx: {
              borderRadius: "10px !important",
              ".MuiTableContainer-root::-webkit-scrollbar": {
                width: "4px !important",
              },
            },
          })}
          renderRowActionMenuItems={
            rowActionMenuItems || (({ row, closeMenu }) => [])
          }
        />
      </LocalizationProvider>
    </div>
  );
};

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  pagination: PropTypes.object,
  rowActionMenuItems: PropTypes.func,
  topToolbarCustomActions: PropTypes.node,

  editDisplayMode: PropTypes.string,
  enableEditing: PropTypes.bool,
  enablePagination: PropTypes.bool,
  enableColumnFilters: PropTypes.bool,
  enableColumnOrdering: PropTypes.bool,
  enableGrouping: PropTypes.bool,
  enableColumnPinning: PropTypes.bool,
  enableFacetedValues: PropTypes.bool,
  enableColumnResizing: PropTypes.bool,
  enableRowActions: PropTypes.bool,
  enableStickyHeader: PropTypes.bool,
  enableStickyFooter: PropTypes.bool,
  isLoading: PropTypes.bool,

  onColumnFiltersChange: PropTypes.func,
};

export default Table;
