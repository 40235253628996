import { React, useState, useEffect, useRef } from 'react';
import './NavBar.css';
import { SideNavigation, SideNavigationItem, SideNavigationSubItem, Toast, MessageBox, ThemeProvider } from '@ui5/webcomponents-react';
import '@ui5/webcomponents-icons/dist/cart.js';
import '@ui5/webcomponents-icons/dist/account.js';
import '@ui5/webcomponents-icons/dist/action-settings.js';
import '@ui5/webcomponents-icons/dist/arrow-left.js';
import '@ui5/webcomponents-icons/dist/arrow-right.js';
import '@ui5/webcomponents-icons/dist/show.js';
import '@ui5/webcomponents-icons/dist/private.js';
import '@ui5/webcomponents-icons/dist/role.js';
import '@ui5/webcomponents-icons/dist/accept.js';
import SideNavigationHeader from './side-navigation-header/SideNavigationHeader';
import { logOut } from '../../../requests/authorization';
import axios_instance from '../../../requests/config_defaults';

const windowIsShown = (window_id) => {
    const window = document.getElementById(window_id);
    return window === null;
}

const NavBar = (props) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [collapsedIcon, setCollapsedIcon] = useState("arrow-left");

    const [openMessageBox, setOpenMessageBox] = useState(false);

    const setNavIcon = (component) => props.open[`${component}`] ? 'show' : '';

    const [isShowToast, setIsShowToast] = useState(false);

    /* 
        change use effect when refactor is_authenticated request for isShowToast & openMessageBox
    */
    useEffect(() => {
        setIsShowToast(true)
    }, [openMessageBox])

    const toast = useRef(null);

    const showToast = () => {
        toast.current.show();
    };

    const [isValidToastMessage, setIsValidToastMessage] = useState(true);

    const handleCloseMessageBox = async (event) => {
        if (event.detail.action === "OK") {
            await axios_instance
                .get('/logout')
                .then((response) => {
                    setIsValidToastMessage(true);
                    showToast();
                })
                .catch((error) => {
                    console.log(error);
                    setIsValidToastMessage(false);
                });
        }
        setOpenMessageBox(false);
    };

    return (
        <ThemeProvider>
            {openMessageBox &&
                <MessageBox
                    open={openMessageBox}
                    onClose={handleCloseMessageBox}
                >
                    Вы действительно хотите выйти?
                </MessageBox>}
            {isShowToast &&
                <Toast
                    ref={toast}
                    style={{
                        color: isValidToastMessage ? 'green' : 'red'
                    }}
                >
                    {isValidToastMessage ? 'Выход выполнен успешно.' : 'При выходе произошла ошибка.'}
                </Toast>
            }

            <SideNavigation
                header={<SideNavigationHeader />}
                fixedItems={
                    <>
                        <SideNavigationItem
                            onClick={() => {
                                props.isAuthorized ? setOpenMessageBox(true) : props.showWindow("authorizationForm")
                            }
                            }
                            icon={props.isAuthorized ? 'role' : 'private'}
                            text={props.isAuthorized ? 'Выйти' : 'Войти'}
                        />
                        <SideNavigationItem
                            onClick={() => {
                                setIsCollapsed(!isCollapsed)
                                if (!isCollapsed) {
                                    setCollapsedIcon('arrow-right')
                                }
                                else {
                                    setCollapsedIcon('arrow-left')
                                }
                            }}
                            icon={collapsedIcon}
                            text="Скрыть"
                        />
                    </>
                }
                collapsed={isCollapsed}
                style={!isCollapsed ? { 'minWidth': 250 } : undefined}
            >
                <SideNavigationItem
                    text="1. АРМ ЭКЗА"
                    icon='cart'
                    wholeItemToggleable
                >
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("myProducts")
                        }}
                        icon={setNavIcon('myProducts')}
                        text="1.1. Управление заказами"
                    />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("offerProducts")
                        }}
                        icon={setNavIcon('offerProducts')}
                        text="1.2. Подбор предложений"
                    />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("ownOrders")
                        }}
                        icon={setNavIcon('ownOrders')}
                        text="1.3. Заказы"
                    />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("sendedOrders")
                        }}
                        icon={setNavIcon('sendedOrders')}
                        text="1.4. Отправки"
                    />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("ownSales")
                        }}
                        icon={setNavIcon('ownSales')}
                        text="1.5. Продажи"
                    />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("calalog")
                        }}
                        icon={setNavIcon('calalog')}
                        text="1.6. Каталог"
                    />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("productCard")
                        }}
                        icon={setNavIcon('productCard')}
                        text="1.6.1 Карточка товара"
                    />

                </SideNavigationItem>
                <SideNavigationItem
                    text="2. Аккаунт"
                    icon='account'
                    wholeItemToggleable
                >
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("profile")
                        }}
                        icon={setNavIcon('profile')}
                        text="2.1. Профиль" />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("ownEntities")
                        }}
                        icon={setNavIcon('ownEntities')}
                        text="2.2. Мои компании" />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("ownWarehouses")
                        }}
                        icon={setNavIcon('ownWarehouses')}
                        text="2.3. Мои склады" />
                </SideNavigationItem>
                <SideNavigationItem
                    text="3. Настройки"
                    icon='action-settings'
                    wholeItemToggleable
                >
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("automationSettings")
                        }}
                        icon={setNavIcon('automationSettings')}
                        text="3.1. Автоматизация" />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("notificationSettings")
                        }}
                        icon={setNavIcon('notificationSettings')}
                        text="3.2. Уведомления" />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("interfaceSettings")
                        }}
                        icon={setNavIcon('interfaceSettings')}
                        text="3.3. Интерфейс" />
                    <SideNavigationSubItem
                        onClick={() => {
                            props.showWindow("forms")
                        }}
                        icon={setNavIcon('forms')}
                        text="3.4. Формы" />
                </SideNavigationItem>
            </SideNavigation>
        </ThemeProvider>
    )
}
export { NavBar, windowIsShown };
