import React, { useEffect, useState, useLayoutEffect } from "react"
import "./PermissionManager.css"
import classNames from "classnames"
import { useAtom } from "jotai"
import { activeCompanyAtom } from "../../../hoc/GlobalAtoms"
import axios_instance from "../../../requests/config_defaults"
import {
  Avatar,
  Button,
  ButtonDesign,
  Label,
  List,
  NotificationListItem,
} from "@ui5/webcomponents-react"

const PermissionCard = (props) => {
  const [sendPermissionRequestData, setSendPermissionRequestData] = useState({
    email: "",
    permission: "",
    entity: "",
  })

  const getMe = async () => {
    await axios_instance
      .get(`/getMe`)
      .then((response) => {
        setSendPermissionRequestData((prev) => ({
          ...prev,
          email: response.data.user.email,
        }))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const sendPermissionRequest = async (newData) => {
    await axios_instance.post("/entity/permission/request/user/send", newData)
  }

  const handleRequestClick = async () => {
    setSendPermissionRequestData((prev) => ({
      ...prev,
      permission: props?.data.permission.id,
      entity: props.activeCompany,
    }))
    const newData = {
      ...sendPermissionRequestData,
      permission: props?.data.permission.id,
      entity: props.activeCompany,
    }

    await sendPermissionRequest(newData)
    props.getData()
  }

  useEffect(() => {
    getMe()
  }, [])

  return (
    <>
      {props?.data?.permission ? (
        <NotificationListItem
          avatar={
            <Avatar size="XS">
              <img src="https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg" />
            </Avatar>
          }
          footnotes={
            props?.data?.manager ? (
              <>
                <Label>
                  {props?.data?.manager.invert ? "Запрещен" : "Выдан"}
                </Label>
                <Label>{props?.data?.manager?.created}</Label>
                {/* {data?.manager?.<Badge colorScheme='8'>Админ</Badge>} */}
              </>
            ) : !props?.data?.request ? (
              <Button
                onClick={() => {
                  handleRequestClick()
                }}
                style={{ color: "#fff" }}
                design={ButtonDesign.Emphasized}>
                Запросить
              </Button>
            ) : (
              <Label>Запрошено</Label>
            )
          }
          onClose={function _a() { }}
          priority={
            props?.data?.manager
              ? props?.data?.manager?.invert
                ? "High"
                : "Low"
              : "Medium"
          }
          titleText={props?.data?.permission?.name}
          children={props?.data?.permission?.description}
          wrappingType="Normal"
        />
      ) : (
        <></>
      )}
    </>
  )
}

const PermissionManager = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeCompany] = useAtom(activeCompanyAtom)
  const [keys] = useState(props.keys)
  const [data, setData] = useState([])

  const getData = async () => {
    await axios_instance
      .post("/permissionsInfo", {
        keys: keys,
      })
      .then(({ data }) => {
        setData(data)
        props.setPermissionData(getPermissions(data))
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getDataByCompanyID = async () => {
    await axios_instance
      .post(`/permissionsInfo/${props.company}`, {
        keys: keys
      })
      .then(({ data }) => {
        setData(data)
        props.setPermissionData(getPermissions(data))
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getPermissions = (data) => {
    let perms = {}
    data?.map((_item) => {
      perms[_item.permission.key] =
        (_item?.manager !== null) | undefined
          ? _item?.manager?.invert === true
            ? false
            : true
          : false
    })
    return perms
  }

  useEffect(() => {
    if (!props.company) {
      getData()
    }
  }, [activeCompany])

  useEffect(() => {
    getDataByCompanyID()
  }, [props.company])

  return (
    <>
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          id="permission-manager-controller">
          Доступные действия на странице
        </button>
      )}

      {isOpen && (
        <div
          onClick={() => setIsOpen(false)}
          id="permission-manager-overlay"
          className="permission-manager-overlay"></div>
      )}

      <div
        id="permission-manager-layout"
        className={classNames(
          "permission-manager-layout",
          isOpen && "permission-manager-layout-open"
        )}>
        <div className="permission-manager-layout-content">
          <div className="permission-manager-layout-header">
            <h3>{`Доступные действия на странице (${props.popUpCompanyName ? props.popUpCompanyName : activeCompany?.name})`}</h3>
            <div
              onClick={() => setIsOpen(false)}
              className="permission-manager-layout-close-sb-btn"></div>
          </div>
          <div className="permission-manager-content">
            <List className="permission-manager-container">
              {data &&
                data.map((inst) => (
                  <PermissionCard
                    getData={getData}
                    data={inst}
                    activeCompany={props.company ? props.company : activeCompany.id}
                  />
                ))}
            </List>
          </div>
        </div>
      </div>
    </>
  )
}

export default PermissionManager
