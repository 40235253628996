import React from "react";
import { AnalyticalTable, Input, Icon } from "@ui5/webcomponents-react";
import { ThemeProvider } from "@ui5/webcomponents-react";
import { useState, useEffect } from "react";
import axios_instance from "../../../requests/config_defaults";
import { checkIsAuthenticated } from "../../../requests/authorization";
import "./CatalogView.css";
import CatalogOfferProductCard from "./catalog-offer-product-card/CatalogOfferProductCard.jsx";
import CatalogProductPropertiesCard from "./catalog-product-properties-card/CatalogProductPropertiesCard.jsx";
import CatalogFilter from "./filter/CatalogFilter.jsx";

const columns = [
  {
    accessor: "article",
    Header: "Артикул",
    headerTooltip: "Артикул",
  },
  {
    accessor: "property_name",
    Header: "Наименование",
    headerTooltip: "Наименование",
  },
  {
    accessor: "property_manufactor",
    Header: "Производитель",
    headerTooltip: "Производитель",
  },
];

const CatalogView = (props) => {
  const [data, setData] = useState([]);
  const [isAuth, setIsAuth] = useState(false);
  async function getCatalog() {
    const catalog = [];
    await axios_instance
      .get("products/list")
      .then((response) => {
        response.data &&
          response.data.results.map((product) => {
            catalog.push({
              id: product.id,
              article: product.code,
              property_name: getName(product),
              property_manufactor: getManufactor(product),
            });
          });
        setData(catalog);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getManufactor = (product) => {
    var name = "";
    product.properties.map((property) => {
      if (property.name === "Основной поставщик") {
        name = property.value.content;
      }
    });
    return name;
  };

  const getName = (product) => {
    var name = "";
    product.properties.map((property) => {
      if (property.name === "Наименование") {
        name = property.value.content;
      }
    });
    return name;
  };

  useEffect(() => {
    setIsAuth(checkIsAuthenticated());
    getCatalog();
  }, []);

  const renderRowSubComponent = (row) => {
    return (
      <div className="sub-component">
        {isAuth && <CatalogOfferProductCard />}
        <CatalogProductPropertiesCard
          id={row.original.id}
          article={row.original.article}
        />
      </div>
    );
  };
  return (
    <div className="catalog-view-content">
      <div className="title">Каталог</div>
      <div className="catalog">
        <CatalogFilter setData={setData} />
        <ThemeProvider style={{height: "100%"}}>
          <AnalyticalTable
            data={data}
            columns={columns}
            renderRowSubComponent={renderRowSubComponent}
            style={{height: "100%"}}
            minRows={20}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

export { CatalogView };
