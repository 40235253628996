import React from 'react'
import { BusyIndicator, TabContainer, Tab } from "@ui5/webcomponents-react";
import { Box } from "@mui/material";
import CatalogOfferProductCard from '../../catalog/catalog-offer-product-card/CatalogOfferProductCard'
import CatalogMyProductsCard from '../../catalog/catalog-my-products-card/CatalogMyProductsCard'
import CatalogProductPropertiesCard from '../../catalog/catalog-product-properties-card/CatalogProductPropertiesCard'

const ProductCardTabContainer = (props) => {

    return (
        <div>
            {props.card_header &&
                <div style={{margin: '1%'}}><b>{props.card_header}</b></div>
            }
            
            {!props.isLoading &&
                <TabContainer onTabSelect={function ka() {
                }}>

                    {props.isAuthorized &&
                        <Tab
                            text="Подбор предложений"
                        >
                            <Box onClick={() => { }}>
                                <CatalogOfferProductCard height={props.height} />
                            </Box>
                        </Tab>
                    }
                    {props.isAuthorized &&
                        <Tab
                            text="Управление заказами"
                        >
                            <Box onClick={() => { }}>
                                <CatalogMyProductsCard height={props.height} />
                            </Box>
                        </Tab>
                    }
                    <Tab
                        text="Свойства"
                        selected={props.properties_selected ?? false}
                    >
                        <CatalogProductPropertiesCard height={props.height} article={props.article} properties={props.properties} />

                    </Tab>
                </TabContainer>
            }

            {props.isLoading &&
                <BusyIndicator active />
            }

        </div>

    )
}

export default ProductCardTabContainer;
