import React, { useEffect, useMemo, useState } from 'react'
import axios_instance from '../../../requests/config_defaults';
import Table from '../../base/table/Table';
import "./EntityPermissionsView.css";
import { Button, TabContainer, Tab, MessageStrip, FlexBox, MessageBox, ThemeProvider } from '@ui5/webcomponents-react';
import { Box, MenuItem } from '@mui/material';
import ModalWindow from '../../base/modal/ModalWindow';
import EntityPermissionCreateForm from './entity-permission-create-form/EntityPermissionCreateForm';
import EntityPermissionUpdateForm from './entity-permission-update-form/EntityPermissionUpdateForm';



const fields = {
    key: {
        key: "key",
        header: "Ключ",
        disabled: false,
    },
    name: {
        key: "name",
        header: "Наименование",
        disabled: false,
    },
    description: {
        key: "description",
        header: "Описание",
        disabled: false,
    },
};


const EntityPermissionsView = (props) => {
    const [loading, setLoading] = useState([]);

    const [entityPermissionsUpdate, setEntityPermissionsUpdate] = useState(false);

    const [entityPermissionsCreateMode, setEntityPermissionsCreateMode] = useState(false);

    const [entityPermissionsUpdateMode, setEntityPermissionsUpdateMode] = useState(false);

    const [updatedEntityPermission, setUpdatedEntityPermission] = useState(null);

    const [dataTable, setDataTable] = useState([]);

    const [entityPermissions, setEntityPermissions] = useState([]);

    useEffect(() => {
        let data_table = [];

        entityPermissions.map((permission) => {
            data_table.push({
                id: permission.id,
                key: permission.key ? permission.key : "Не указан",
                name: permission.name ? permission.name : "Не указано",
                description: permission.description ? permission.description : "Не указано"
            })
        })

        setDataTable(data_table);
    }, [entityPermissions])

    const getLegalEntityPermissionsList = async () => {
        setLoading(true);
        await axios_instance.get(
            "/entity/permissions/list"
        )
            .then((response) => {
                setEntityPermissions(response.data.results);
                console.log(response);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            })
    }

    useEffect(() => {
        getLegalEntityPermissionsList();
    }, [])

    useEffect(() => {
        getLegalEntityPermissionsList();
    }, [entityPermissionsUpdate])

    return (
        <ThemeProvider>
            <Table
                className={"entity-permissions-table"}
                columns={fields}
                data={dataTable}
                enableEditing={false}
                enableColumnResizing
                enableColumnFilters={false}
                enableColumnOrdering={true}
                enableGrouping={false}
                enableColumnPinning={false}
                enableFacetedValues={false}
                enableStickyHeader
                enableRowActions

                isLoading={loading}

                topToolbarCustomActions={
                    <div>
                        <Button
                            design="Emphasized"
                            onClick={() => {
                                setEntityPermissionsCreateMode(true);
                            }}
                            className='add-permission-button'
                        >
                            Добавить право доступа
                        </Button>
                        <Button
                            onClick={() => {
                                setEntityPermissionsUpdate(!entityPermissionsUpdate);
                            }}
                            icon='refresh'
                        >
                            Обновить
                        </Button>
                    </div>

                }
            // pagination={{
            //     page: props.page,
            //     setPage: props.setPage,
            //     pageCount: props.maxPage
            // }}
            rowActionMenuItems={({ closeMenu, row }) => [
                <MenuItem
                    key="edit"
                    onClick={() => {
                        setEntityPermissionsUpdateMode(true);
                        setUpdatedEntityPermission(row.original);
                        closeMenu();
                    }}>
                    Редактировать
                </MenuItem>,
            ]
            }
            />

            <ModalWindow
                state={entityPermissionsCreateMode}
                setState={setEntityPermissionsCreateMode}
                title={"Создать право доступа"}
                children={<EntityPermissionCreateForm
                    setEntityPermissionsCreateMode={setEntityPermissionsCreateMode}
                    entityPermissionsUpdate={entityPermissionsUpdate}
                    setEntityPermissionsUpdate={setEntityPermissionsUpdate}
                />}
            />
            <ModalWindow
                state={entityPermissionsUpdateMode}
                setState={setEntityPermissionsUpdateMode}
                title={"Редактировать право доступа"}
                children={<EntityPermissionUpdateForm
                    permission={updatedEntityPermission}
                    setEntityPermissionsUpdateMode={setEntityPermissionsUpdateMode}
                    entityPermissionsUpdate={entityPermissionsUpdate}
                    setEntityPermissionsUpdate={setEntityPermissionsUpdate}
                />}
            />
        </ThemeProvider>
    )
}

export default EntityPermissionsView