import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import { getIcons } from "../requests/utils";

export const emailAtom = atomWithStorage("email", "");
export const activeCompanyAtom = atomWithStorage("activeCompany", {});
export const activeCompanyAddressAtom = atomWithStorage("activeCompanyAddress", {});
export const fullNameAtom = atomWithStorage("fullName", "");
export const isAdminAtom = atomWithStorage("isAdmin", false);
export const isImpersonatedAtom = atomWithStorage("isImpersonated", false);
export const marketIconsAtom = atomWithStorage("marketIconsAtom", getIcons());
