import React, { useState, useEffect } from 'react'
import { Form, FormGroup, FormItem, Input, Text, Button, FlexBox, Title, Link, ObjectStatus, Icon, Menu, MenuItem, FilterBar, FilterGroupItem, StepInput, Card, CardHeader, StandardListItem, List, CustomListItem, Loader } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';
import "@ui5/webcomponents-icons/dist/form.js";


const Forms = (props) => {
    const [forms, setForms] = useState([]);

    async function getForms() {
        await axios_instance
            .get(`/form/list?page=1`)
            .then(response => {
                setForms(response.data.results);
            })
            .catch(error => {
                console.log(error);
            });
        //setIsLoading(false);
    }

    useEffect(() => {
        getForms();
    }, [])

    return (
        <>
            <FilterBar
                //filterContainerWidth="13.125rem"
                header={<Title>Формы</Title>}
                onAfterFiltersDialogOpen={function Ta() { }}
                onClear={function Ta() { }}
                onFiltersDialogCancel={function Ta() { }}
                onFiltersDialogClose={function Ta() { }}
                onFiltersDialogOpen={function Ta() { }}
                onFiltersDialogSave={function Ta() { }}
                onFiltersDialogSearch={function Ta() { }}
                onFiltersDialogSelectionChange={function Ta() { }}
                onGo={function Ta() { }}
                onRestore={function Ta() { }}
                onToggleFilters={function Ta() { }}
                search={<Input showClearIcon={false} />}
            //showRestoreOnFB={true}
            >
            </FilterBar>
            {forms.length === 0 && (
                <Loader />
            )}
            <List
                mode="Delete"
                onItemClick={function Ta() { }}
                onItemClose={function Ta() { }}
                onItemDelete={function Ta() { }}
                onItemToggle={function Ta() { }}
                onLoadMore={() => console.log('event')}
                onSelectionChange={function Ta() { }}
                separators="Inner"
                header={
                    <Button style={{ width: '100%' }} onClick={() => props.showWindow('createForm')}>
                        Создать
                    </Button>
                }
            //growing="Button"
            >
                {forms.length !== 0 && (forms.map((form) => (
                    <StandardListItem
                        additionalText={`${form.fields.length} поля(ей)`}
                        description={form.name}
                        type="Detail"
                        icon='form'
                    >
                        {form.key}
                    </StandardListItem>
                )))}
            </List>
        </>

    )
}

export { Forms };