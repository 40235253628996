import React, { useState } from "react"
import { Modal } from "@mantine/core"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import dayjs from "dayjs"
import {
  FlexBox,
  Label,
  Button,
  Form,
  FormGroup,
  FormItem,
  Input,
  SuggestionItem,
} from "@ui5/webcomponents-react"

import ModalWindow from "../../../base/modal/ModalWindow"
import axios_instance from "../../../../requests/config_defaults"

// Компонент модального окна для подтверждения действия
const ConfirmationModal = ({ isOpen, onClose, message, onConfirm }) => {
  return (
    <Modal opened={isOpen} onClose={onClose}>
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
        }}>
        <Label style={{ fontSize: "1rem" }}>{message}</Label>
        <FlexBox
          style={{
            gap: "1rem",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Button onClick={onConfirm}>Да</Button>
          <Button onClick={onClose}>Нет</Button>
        </FlexBox>
      </FlexBox>
    </Modal>
  )
}

// Компонент модального окна для выбора периода публикации
const PublicationPeriodModal = ({
  isOpen,
  onClose,
  activeRemnant,
  onSetPeriod,
}) => {
  const [publicationStart, setPublicationStart] = useState(null)
  const [publicationEnd, setPublicationEnd] = useState(null)

  const handleSetPeriod = () => {
    onSetPeriod(activeRemnant, publicationStart, publicationEnd)
    onClose()
  }

  return (
    <ModalWindow state={isOpen} setState={onClose} height={"380px"}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Form
          columnsL={1}
          columnsM={1}
          columnsS={1}
          columnsXL={1}
          labelSpanL={1}
          labelSpanM={4}
          labelSpanS={1}
          labelSpanXL={1}
          style={{
            alignItems: "center",
            textAlign: "center",
          }}
          titleText="Выберите период публикации"
          backgroundDesign="Solid">
          <FormGroup>
            <FormItem label="Начало публикации">
              <div style={{ display: "flex", alignItems: "center" }}>
                <DatePicker
                  value={publicationStart}
                  onChange={setPublicationStart}
                />
                <Button
                  onClick={() => setPublicationStart(dayjs())}
                  style={{ marginLeft: "10px" }}>
                  Сейчас
                </Button>
              </div>
            </FormItem>
          </FormGroup>
          <FormGroup>
            <FormItem label="Конец публикации">
              <div style={{ display: "flex", alignItems: "center" }}>
                <DatePicker
                  value={publicationEnd}
                  onChange={setPublicationEnd}
                />
                <Button
                  onClick={() => setPublicationEnd(null)}
                  style={{ marginLeft: "10px" }}>
                  Без ограничений по времени
                </Button>
              </div>
            </FormItem>
          </FormGroup>
        </Form>
        <FlexBox
          style={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "1rem",
            paddingTop: "5%",
          }}>
          <Button onClick={handleSetPeriod}>Установить период</Button>
        </FlexBox>
      </LocalizationProvider>
    </ModalWindow>
  )
}

// компонент модального окна для обнуления по поставщику
const ResetByEntityModal = ({ isOpen, onClose, onReset }) => {
  const [entityValueState, setEntityValueState] = useState("")
  const [legalEntities, setLegalEntities] = useState([])
  const [activeEntity, setActiveEntity] = useState("")
  const getEntitiesList = async (query) => {
    await axios_instance
      .get(`/getCompanies?query=${query}`)
      .then((response) => {
        setLegalEntities(response.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleReset = () => {
    onReset(activeEntity)
    onClose()
  }

  return (
    <ModalWindow state={isOpen} setState={onClose} height={"300px"}>
      <Form
        columnsL={1}
        columnsM={1}
        columnsS={1.1}
        columnsXL={1}
        labelSpanL={1}
        labelSpanM={2}
        labelSpanS={2}
        labelSpanXL={1}
        style={{
          alignItems: "center",
          textAlign: "center",
        }}
        titleText="Обнулите остатки поставщика"
        backgroundDesign="Solid">
        <FormGroup>
          <FormItem label="Компания">
            <Input
              required
              name="entity"
              valueState={entityValueState}
              onInput={(e) => {
                setEntityValueState(e.target.value)
                getEntitiesList(e.target.value)
              }}
              style={{ width: "95%" }}
              showSuggestions
              onSuggestionItemSelect={(e) => {
                setActiveEntity(e.detail.item.attributes.id.value)
              }}>
              {legalEntities.map((entity) => {
                return (
                  <SuggestionItem
                    key={entity?.id}
                    id={entity?.id}
                    description={entity?.tax_number}
                    icon="customer"
                    text={entity?.name}
                  />
                )
              })}
            </Input>
          </FormItem>
        </FormGroup>
      </Form>
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "5%",
        }}>
        <Button onClick={handleReset}>Обнулить все остатки</Button>
      </FlexBox>
    </ModalWindow>
  )
}

// компонент модального окна для обнуления по товару
const ResetByProductModal = ({ isOpen, onClose, onReset }) => {
  const [productValueState, setProductValueState] = useState("")
  const [products, setProducts] = useState([])
  const [activeProduct, setActiveProduct] = useState("")

  const getProductsList = async (query) => {
    await axios_instance
      .get(`/products/searcher?query=${query}`)
      .then((response) => {
        setProducts(response.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleReset = () => {
    onReset(activeProduct)
    onClose()
  }

  return (
    <ModalWindow state={isOpen} setState={onClose} height={"300px"}>
      <Form
        columnsL={1}
        columnsM={1}
        columnsS={1.1}
        columnsXL={1}
        labelSpanL={1}
        labelSpanM={2}
        labelSpanS={2}
        labelSpanXL={1}
        style={{
          alignItems: "center",
          textAlign: "center",
        }}
        titleText="Обнулите остатки товара"
        backgroundDesign="Solid">
        <FormGroup>
          <FormItem label="Товар">
            <Input
              required
              name="product"
              valueState={productValueState}
              onInput={(e) => {
                setProductValueState(e.target.value)
                getProductsList(e.target.value)
              }}
              style={{ width: "95%" }}
              showSuggestions
              onSuggestionItemSelect={(e) => {
                setActiveProduct(e.detail.item.attributes.id.value)
              }}>
              {products.map((suggestion) => {
                return (
                  <SuggestionItem
                    id={suggestion?.id}
                    icon="product"
                    text={`Артикул: ${suggestion?.code} | Наименование: ${
                      suggestion?.name ? suggestion?.name : "Не указано"
                    } `}
                  />
                )
              })}
            </Input>
          </FormItem>
        </FormGroup>
      </Form>
      <FlexBox
        style={{
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          gap: "1rem",
          paddingTop: "5%",
        }}>
        <Button onClick={handleReset}>Обнулить все остатки</Button>
      </FlexBox>
    </ModalWindow>
  )
}

export {
  ConfirmationModal,
  PublicationPeriodModal,
  ResetByEntityModal,
  ResetByProductModal,
}
