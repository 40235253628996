import React, { useEffect, useState } from "react";
import { MultiComboBox, Input, Icon } from "@ui5/webcomponents-react";
import axios_instance from "../../../../requests/config_defaults";
import "./CatalogFilter.css";
import FilterItem from "./filter-item/FilterItem.jsx";

const CatalogFilter = (props) => {
  const [filterValues, setFilterValues] = useState([]);
  const [selectedFilters, setFilters] = useState([]);
  const [length, setLength] = useState({ name: "Длина", _from: 0, _to: 0 });
  const [width, setWidth] = useState({ name: "Ширина", _from: 0, _to: 0 });
  const [height, setHeight] = useState({ name: "Высота", _from: 0, _to: 0 });
  const [weight, setWeight] = useState({ name: "Вес", _from: 0, _to: 0 });
  var updatedFilters = [];

  async function getFilters() {
    const filters = [];
    await axios_instance
      .get("product/filter/all")
      .then((response) => {
        response.data &&
          response.data.properties.map((value) => {
            filters.push({
              name: Object.keys(value)[0],
              values: Object.values(value),
            });
          });
        setFilterValues(filters);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const getManufactor = (product) => {
    var name = "";
    product.properties.map((property) => {
      if (property.name === "Основной поставщик") {
        name = property.value.content;
      }
    });
    return name;
  };

  const getName = (product) => {
    var name = "";
    product.properties.map((property) => {
      if (property.name === "Наименование") {
        name = property.value.content;
      }
    });
    return name;
  };

  async function getFilteredProducts() {
    console.log(selectedFilters);
    if (length._from != 0 || length._to != 0) selectedFilters.push(length);
    if (width._from != 0 || width._to != 0) selectedFilters.push(width);
    if (height._from != 0 || height._to != 0) selectedFilters.push(height);
    if (weight._from != 0 || weight._to != 0) selectedFilters.push(weight);
    const catalog = [];
    await axios_instance
      .post("product/filter", { properties: selectedFilters })
      .then((response) => {
        response.data.results.map((product) => {
          catalog.push({
            id: product.id,
            article: product.code,
            property_name: getName(product),
            property_manufactor: getManufactor(product),
          });
        });
        filterCatalog(catalog);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const selectHandler = (event) => {
    // console.log(event);
    var newFilter = {};
    event.detail.items.map((value) => {
      let included = false;
      newFilter = { name: event.target.id, value: value.text };
      // console.log(newFilter);
      selectedFilters.map((filter, index) => {
        if (JSON.stringify(filter) === JSON.stringify(newFilter)) {
          included = true;
        }
      });
      if (!included) {
        setFilters((prev) => [...prev, newFilter]);
      }
    });
    // selectedFilters &&
    //   selectedFilters.map((filter, index) => {
    //     const filteredSelectedFilters = event.detail.items.filter(
    //       (value) =>
    //         event.target.id === filter.name && value.text === filter.value
    //     );
    //     console.log(filteredSelectedFilters);
    //     if (filteredSelectedFilters.length === 0) {
    //       setFilters(selectedFilters.splice(index, 1));
    //     }
    //   });
    // setFilters(updatedFilters);
    // updatedFilters = [];
    console.log(selectedFilters);
  };

  const filterCatalog = (filteredCatalog) => {
    props.setData(filteredCatalog);
  };

  useEffect(() => {
    getFilters();
  }, []);

  const setLengthHandler = (id, value) => {
    setLength((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const setWidthHandler = (id, value) => {
    setWidth((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const setHeightHandler = (id, value) => {
    setHeight((prev) => ({
      ...prev,
      [id]: value,
    }));
  };
  const setWeightHandler = (id, value) => {
    setWeight((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <div className="catalog_filter">
      <div className="search">
              <p>Поиск</p>
              <Input icon={<Icon name="search" />} />
            </div>
      {filterValues.map((value) => (
        <>
          <div className="filter_title">
            <p>{value.name}</p>
          </div>
          <MultiComboBox
            id={value.name}
            filter="Contains"
            onSelectionChange={(e) => {
              selectHandler(e);
            }}
          >
            {value.values[0].map((filter_value) => (
              <FilterItem value={filter_value} />
            ))}
          </MultiComboBox>
        </>
      ))}
      <p>Длина</p>
      <div className="vgh">
        <Input
          id="_from"
          className="vgh_input"
          type="Number"
          onInput={(e) => setLengthHandler(e.target.id, e.target.value)}
        />
        <Input
          id="_to"
          className="vgh_input"
          type="Number"
          onInput={(e) => setLengthHandler(e.target.id, e.target.value)}
        />
      </div>
      <p>Ширина</p>
      <div className="vgh">
        <Input
          id="_from"
          className="vgh_input"
          type="Number"
          onInput={(e) => setWidthHandler(e.target.id, e.target.value)}
        />
        <Input
          id="_to"
          className="vgh_input"
          type="Number"
          onInput={(e) => setWidthHandler(e.target.id, e.target.value)}
        />
      </div>
      <p>Высота</p>
      <div className="vgh">
        <Input
          id="_from"
          className="vgh_input"
          type="Number"
          onInput={(e) => setHeightHandler(e.target.id, e.target.value)}
        />
        <Input
          id="_to"
          className="vgh_input"
          type="Number"
          onInput={(e) => setHeightHandler(e.target.id, e.target.value)}
        />
      </div>
      <p>Вес</p>
      <div className="vgh">
        <Input
          id="_from"
          className="vgh_input"
          type="Number"
          onInput={(e) => setWeightHandler(e.target.id, e.target.value)}
        />
        <Input
          id="_to"
          className="vgh_input"
          type="Number"
          onInput={(e) => setWeightHandler(e.target.id, e.target.value)}
        />
      </div>
      <button onClick={getFilteredProducts}>Применить</button>
    </div>
  );
};

export default CatalogFilter;
