import { Button } from "@ui5/webcomponents-react";
import "./UserRoadMapContainerElem.css";
import { useNavigate } from "react-router-dom";

const UserRoadMapContainerElem = (props) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const nav = useNavigate();
  return (
    <div className="UserRoadMapContainerElem-main">
      <img
        alt="EXZA"
        src={props.reference_to_num_logo}
        className="UserRoadMapContainerElem-logo"
      />
      <div className="UserRoadMapContainerElem-body">
        <text className="UserRoadMapContainerElem-body-title">
          {props.title}
        </text>
        <text className="UserRoadMapContainerElem-body-text">
          {props.text}
        </text>
        <div className="UserRoadMapContainerElem-body-button">
          <Button
            onClick={() => {
              props.in_new_tab === "true" ? openInNewTab(props.navigate) : nav(props.navigate)
            }}
            className="UserRoadMapContainerElem-button">
            <h3>
              {props.button_name}
            </h3>
          </Button>
        </div>
      </div>
    </div>
  )
}
export default UserRoadMapContainerElem