import './OfferProductComponent.css'
import {Badge, Button} from "@ui5/webcomponents-react";
import {StepInput, FormItem, Form, ObjectPage, ObjectPageSection, ObjectPageSubSection, Text} from "@ui5/webcomponents-react";

const OfferProductComponent = (props) => {

    return (
        <ObjectPageSection
            aria-label={"props.title"}
            id={props.id}
            titleText={props.title}
            className={"catalog-offer-product-component-page-section"}
        >
            <ObjectPageSubSection
                aria-label="Изменяемые параметры"
                id="stocks-management-editable"
                titleText="Изменяемые параметры"
                style={{
                    width: '100%'
                }}
            >
                <span>Срок доставки: <b>{props.delivery_time}</b> | Цена: <b>{props.cost}</b></span>
                <Button>Заказать</Button>
            </ObjectPageSubSection>
        </ObjectPageSection>
    )
}

export default OfferProductComponent