import "./CatalogProductPropertiesCard.css";
import {
  ObjectPageSubSection,
  ObjectPageSection,
  ObjectPage,
} from "@ui5/webcomponents-react";
import { Box } from "@mui/material";
import { ModelsScene } from "../3d-model-scene/ModelsScene.jsx";
import { VolumeModel } from "../3d-model-scene/3d-model/3DModel.jsx";
import { ExzaLogo } from "../../../base/exza_logo/ExzaLogo.jsx";
import { useState } from "react";
import axios_instance from "../../../../requests/config_defaults";
import { useEffect } from "react";

const CatalogProductPropertiesCard = (props) => {
  const [weightAndDimensionsProperties, setWeightAndDimensionsProperties] =
    useState([]);
  const [articleAndNameProperties, setArticleAndNameProperties] = useState([]);
  const [notPrimaryProperties, setNotPrimaryProperties] = useState([]);

  const fillProperties = (properties) => {
    var weight_and_dimensions_characteristics_properties = [];
    var article_and_name_properties = [];
    var not_primary_properties = [];
    for (var i = 0; i < properties.length; i++) {
      if (
        properties[i].name === "Ширина" ||
        properties[i].name === "Высота" ||
        properties[i].name === "Длина" ||
        properties[i].name === "Вес"
      ) {
        weight_and_dimensions_characteristics_properties.push(properties[i]);
      } else if (properties[i].name === "Наименование") {
        article_and_name_properties.push(properties[i]);
      } else if (properties[i].name === "Код дзч") {
        let article_and_name_property = {
          name: "Артикул",
          value: properties[i].value,
          measure: properties[i].measure,
        };
        article_and_name_properties.push(article_and_name_property);
      } else {
        not_primary_properties.push(properties[i]);
      }
    }
    setWeightAndDimensionsProperties(
      weight_and_dimensions_characteristics_properties
    );
    setArticleAndNameProperties(article_and_name_properties);
    setNotPrimaryProperties(not_primary_properties);
  };

  const getProductById = async () => {
    await axios_instance
      .get(`/product/get/by_id/${props.id}`)
      .then((response) => {
        response.data && fillProperties(response.data.properties);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getProductById();
  }, []);

  return (
    <div className="catalog-product-properties-card-row">
      <ObjectPage
        style={{
          width: "100%",
        }}
      >
        <h3 id="catalog-product-properties-card-section-primary-title">
          {"Основные характеристики"}
        </h3>
        <ObjectPageSection
          aria-label="Основные характеристики"
          id="catalog-product-properties-card-section-primary"
          titleText="Характеристики товара"
          className={"catalog-product-properties-card-section"}
          hideTitleText
        >
          <ObjectPageSubSection
            aria-label="Деталь"
            titleText="Деталь"
            id="article-and-name-properties-subsection"
          >
            {articleAndNameProperties.length === 0 && (
              <Box>
                <div className="catalog-product-properties-card-row">
                  <div className="catalog-product-properties-div-left">
                    Артикул:
                  </div>
                  <div className="catalog-product-properties-div-right">
                    {props.article}
                  </div>
                </div>
              </Box>
            )}
            {articleAndNameProperties.length !== 0 &&
              articleAndNameProperties.map((item) => (
                <Box>
                  <div className="catalog-product-properties-card-row">
                    <div className="catalog-product-properties-div-left">
                      {item.name}:
                    </div>
                    <div className="catalog-product-properties-div-right">
                      {item.value.content}{" "}
                      {item.measure.name !== "Не указан" &&
                      parseFloat(item.value.content)
                        ? `${item.measure.name}`
                        : ""}
                    </div>
                  </div>
                </Box>
              ))}
          </ObjectPageSubSection>

          <ObjectPageSubSection
            aria-label="Весогабаритные характеристики (ВГХ)"
            titleText="Весогабаритные характеристики (ВГХ)"
            id="weight-and-dimensions-characteristics-properties-subsection"
          >
            {weightAndDimensionsProperties.length === 0 && <h4>Не указаны</h4>}
            {weightAndDimensionsProperties.length !== 0 &&
              weightAndDimensionsProperties.map((item) => (
                <Box>
                  <div className="catalog-product-properties-card-row">
                    <div className="catalog-product-properties-div-left">
                      {item.name}:
                    </div>
                    <div className="catalog-product-properties-div-right">
                      {item.value.content}{" "}
                      {item.measure.name !== "Не указан" &&
                      parseFloat(item.value.content)
                        ? `${item.measure.name}`
                        : ""}
                    </div>
                  </div>
                </Box>
              ))}
          </ObjectPageSubSection>
        </ObjectPageSection>

        <h3 id="catalog-product-properties-card-section-not-primary-title">
          {"Дополнительные характеристики"}
        </h3>

        <ObjectPageSection
          aria-label="Дополнительные характеристики"
          id="catalog-product-properties-card-section-not-primary"
          // titleText="Дополнительные характеристики"
          className={"catalog-product-properties-card-section"}
          hideTitleText
        >
          {notPrimaryProperties.length === 0 && <h4>Не указаны</h4>}
          {notPrimaryProperties.length !== 0 &&
            notPrimaryProperties.map((item) => (
              <Box>
                <div className="catalog-product-properties-card-row">
                  <div className="catalog-product-properties-div-left">
                    {item.name}:
                  </div>
                  <div className="catalog-product-properties-div-right">
                    {item.value.content}{" "}
                    {item.measure.name !== "Не указан" &&
                    parseFloat(item.value.content)
                      ? `${item.measure.name}`
                      : ""}
                  </div>
                </div>
              </Box>
            ))}
        </ObjectPageSection>

        <h3 id="catalog-product-properties-card-section-3d-model-title">
          3d модель
        </h3>
        <ObjectPageSection
          id="catalog-product-properties-card-section-3d-model"
          aria-label={"3d модель"}
          // titleText={"3d модель"}
          className={"catalog-product-properties-card-section"}
          hideTitleText
        >
          <ModelsScene style={{ height: 500 }} model={<VolumeModel />} />
        </ObjectPageSection>

        <h3 id="catalog-product-properties-card-section-photo-title">Фото</h3>
        <ObjectPageSection
          id="catalog-product-properties-card-section-photo"
          aria-label={"Фото"}
          // titleText={"Фото"}
          className={"catalog-product-properties-card-section"}
          hideTitleText
        >
          <ExzaLogo />
        </ObjectPageSection>
      </ObjectPage>
    </div>
  );
};

export default CatalogProductPropertiesCard;
