import React from 'react'
import WindowMenuPositionBackground from './window-menu-position-background/WindowMenuPositionBackground'
import './WindowMenu.css'

const WindowMenu = (props) => {
    return (
        <div className="window-menu-wrapper">
            <div className="window-menu">
                <div className='window-menu-group group-rectangle'>
                    <div className="window-menu-component component-rectangle rectangle-left"></div>
                    <div className="window-menu-component component-rectangle rectangle-right"></div>
                </div>
                <div className='window-menu-group group-square'>
                    <div className="window-menu-component component-square square-top-left"></div>
                    <div className="window-menu-component component-square square-top-right"></div>
                    <div className="window-menu-component component-square square-bottom-left"></div>
                    <div className="window-menu-component component-square square-bottom-right"></div>
                </div>
                
            </div>
            <WindowMenuPositionBackground />
        </div>
        
    )
}

export default WindowMenu;
