import React, { useEffect, useState } from 'react'
import './CompanyDetailContactUpdateForm.css'
import { Bar, Button, Card, CheckBox, Form, FormGroup, FormItem, Input, Label, Link, Option, Select, StepInput, Text, SuggestionItem, ObjectStatus, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults'
import "@ui5/webcomponents/dist/features/InputSuggestions.js";
import "@ui5/webcomponents-icons/dist/customer.js";
import "@ui5/webcomponents-icons/dist/accept.js";
import { isValidPhoneNumber } from 'react-phone-number-input';


const ContactUpdateForm = (props) => {
    const [updatedLegalEntityContact, setUpdatedLegalEntityContact] = useState({
        last_name: props.contact.last_name,
        first_name: props.contact.first_name,
        middle_name: props.contact.middle_name,
        phone_number: props.contact.phone_number,
        email: props.contact.email
    });

    useEffect(() => {
        console.log(props.contact);
    }, [])

    useEffect(() => {
        setUpdatedLegalEntityContact({
            last_name: props.contact.last_name,
            first_name: props.contact.first_name,
            middle_name: props.contact.middle_name,
            phone_number: props.contact.phone_number,
            email: props.contact.email
        })
    }, [props.contact])

    const [lastNameValueState, setLastNameValueState] = useState('');

    const [firstNameValueState, setFirstNameValueState] = useState('');

    const [middleNameValueState, setMiddleNameValueState] = useState('');

    const [phoneNumberValueState, setPhoneNumberValueState] = useState('');

    const [emailValueState, setEmailValueState] = useState('');

    const emailValidator = require("email-validator");

    const handleUpdateLegalEntityContact = async (e) => {
        e.preventDefault()
        await axios_instance.patch(
            `/entity/contact/edit/${props.contact.id}`,
            updatedLegalEntityContact
        )
            .then((response) => {
                setEmailValueState("");
                // props.setCreateLegalEntityContactMode(false);
                props.setCompanyContactsUpdate(!props.companyContactsUpdate);
                props.setContactUpdateMode(false);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <div style={{ margin: 20 }}>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanL={1}
                labelSpanM={1}
                labelSpanS={1}
                labelSpanXL={1}
                style={{
                    alignItems: 'left'
                }}
                // className='MyOfferCreateForm-form'
                titleText="Заполните форму"
            >
                <FormGroup>
                    <FormItem label="Фамилия">
                        <Input
                            required
                            name="last_name"
                            valueState={lastNameValueState ? "Error" : props.contact.last_name !== updatedLegalEntityContact.last_name ? "Information" : ""}
                            onInput={
                                (e) => {
                                    setLastNameValueState("");
                                    setUpdatedLegalEntityContact((prev) => ({
                                        ...prev,
                                        last_name: e.target.value.trim()
                                    }))
                                }
                            }
                            placeholder={props.contact.last_name}
                            valueStateMessage={<div>Изменено</div>}
                            value={updatedLegalEntityContact.last_name ?? ""}
                            style={{ width: '100%' }}
                        />
                    </FormItem>
                    {lastNameValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {lastNameValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Имя">
                        <Input
                            required
                            name="first_name"
                            valueState={firstNameValueState ? "Error" : props.contact.first_name !== updatedLegalEntityContact.first_name ? "Information" : ""}
                            onInput={
                                (e) => {
                                    setFirstNameValueState("");
                                    setUpdatedLegalEntityContact((prev) => ({
                                        ...prev,
                                        first_name: e.target.value.trim()
                                    }))
                                }
                            }
                            placeholder={props.contact.first_name}
                            valueStateMessage={<div>Изменено</div>}
                            value={updatedLegalEntityContact.first_name ?? ""}
                            style={{ width: '100%' }}
                        />
                    </FormItem>
                    {firstNameValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {firstNameValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Отчество">
                        <Input
                            required
                            name="middle_name"
                            valueState={middleNameValueState ? "Error" : props.contact.middle_name !== updatedLegalEntityContact.middle_name ? "Information" : ""}
                            onInput={
                                (e) => {
                                    setMiddleNameValueState("");
                                    setUpdatedLegalEntityContact((prev) => ({
                                        ...prev,
                                        middle_name: e.target.value.trim()
                                    }))
                                }
                            }
                            placeholder={props.contact.middle_name}
                            valueStateMessage={<div>Изменено</div>}
                            style={{ width: '100%' }}
                            value={updatedLegalEntityContact.middle_name ?? ""}
                        />
                    </FormItem>
                    {middleNameValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {middleNameValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Телефон">
                        <Input
                            required
                            type='Tel'
                            name="phone_number"
                            valueState={phoneNumberValueState ? "Error" : props.contact.phone_number !== updatedLegalEntityContact.phone_number ? "Information" : ""}
                            onInput={
                                (e) => {
                                    console.log(e);
                                    setPhoneNumberValueState("");
                                    setUpdatedLegalEntityContact((prev) => ({
                                        ...prev,
                                        phone_number: e.target.value.trim()
                                    }))
                                }
                            }
                            placeholder={props.contact.phone_number}
                            valueStateMessage={<div>Изменено</div>}
                            style={{ width: '100%' }}
                            value={updatedLegalEntityContact.phone_number ?? ""}
                        />
                    </FormItem>
                    {phoneNumberValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {phoneNumberValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Почта">
                        <Input
                            required
                            name="email"
                            valueState={emailValueState ? "Error" : props.contact.email !== updatedLegalEntityContact.email ? "Information" : ""}
                            onInput={
                                (e) => {
                                    setEmailValueState("");
                                    setUpdatedLegalEntityContact((prev) => ({
                                        ...prev,
                                        email: e.target.value.trim()
                                    }))
                                }
                            }
                            placeholder={props.contact.email}
                            valueStateMessage={<div>Изменено</div>}
                            style={{ width: '100%' }}
                            value={updatedLegalEntityContact.email ?? ""}
                        />
                    </FormItem>
                    {emailValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {emailValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                </FormGroup>
                <FormItem alignItems={"left"} label="">
                    <Button
                        design='Emphasized'
                        style={{
                            padding: "0.5rem 1rem",
                            width: "100%"
                        }}
                        onClick={(e) => {
                            let validate_status = true;
                            if (updatedLegalEntityContact.last_name === "") {
                                setLastNameValueState("Введите фамилию");
                                validate_status = false;
                            }
                            if (updatedLegalEntityContact.first_name === "") {
                                setFirstNameValueState("Введите имя");
                                validate_status = false;
                            }
                            if (updatedLegalEntityContact.middle_name === "") {
                                setMiddleNameValueState("Введите отчество");
                                validate_status = false;
                            }
                            if (updatedLegalEntityContact.phone_number === "") {
                                setPhoneNumberValueState("Введите номер телефона");
                                validate_status = false;
                            }
                            else if (!isValidPhoneNumber(updatedLegalEntityContact.phone_number)) {
                                setPhoneNumberValueState("Неверный формат телефона");
                                validate_status = false;
                            }
                            if (updatedLegalEntityContact.email === "") {
                                setEmailValueState("Введите почту");
                                validate_status = false;
                            }
                            else if (!emailValidator.validate(updatedLegalEntityContact.email)) {
                                setEmailValueState("Неверный формат почты");
                                validate_status = false;
                            }
                            if (validate_status) {
                                handleUpdateLegalEntityContact(e);
                            }
                        }}
                    >
                        Изменить
                    </Button>
                </FormItem>

            </Form>
        </div>
    )
}

export default ContactUpdateForm