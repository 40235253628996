import React from 'react'
import './MainMenuBlock.css'
import { useNavigate, Link } from 'react-router-dom'
import { Button } from '@ui5/webcomponents-react';

const MainMenuBlock = (props) => {
  return (
    <div className='main-menu-block-container'>
      <div className='main-menu-block-content'>
        <h2 className='main-menu-block-title'>{props.title}</h2>
        <hr />
        <div className="main-menu-block-items">
          {
            props.items && props.items.map((item) =>
            (
              <Link
                to={item.path}
                className='main-menu-block-item'
                onClick={() => props.closeMenu()}
              >
                {props.items.indexOf(item) + 1}. {item.label}
              </Link>
            )
            )
          }
        </div>
      </div>

    </div>
  )
}

export default MainMenuBlock