import {
  Button,
  ButtonDesign,
  DynamicPageHeader,
  FlexBox,
  Label,
  Loader,
  MessageStrip,
  Title,
  Toolbar,
  ToolbarDesign,
  ToolbarSpacer,
} from "@ui5/webcomponents-react";
import { Box, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios_instance from "../../../../requests/config_defaults";
import Table from "../../../base/table/Table";
import AdminCatalogProductsSortingView from "./admin-catalog-products-sorting-view/AdminCatalogProductsSortingView";
import AdminNewProductCreateForm from "./admin-new-product-create-form/AdminNewProductCreateForm";
import AdminBrandCreateForm from "./admin-brand-create-form/AdminBrandCreateForm";
import AdminManufactureCreateForm from "./admin-manufacture-create-form/AdminManufactureCreateForm";
import ModalWindow from "../../../base/modal/ModalWindow"
import "./AdminCatalogView.css";

const fields = {
  name: {
    key: "property_name",
    header: "Наименование",
    disabled: false,
    editable: false,
  },
  code: {
    key: "article",
    header: "Артикул",
    editable: false,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    editable: false,
  },
  manufacture: {
    key: "manufacture",
    header: "Производитель",
    editable: false,
    align: "center",
  },
  is_standard: {
    key: "is_standard",
    header: "Эталонный товар",
    editable: false,
    align: "center",
    Cell: ({ renderedCellValue, cell }) => (
      <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1rem',
              borderRadius: 20,
              padding: '5px',
              color: "#fff",
          }}
      >
          <span
              style={{
                  color: renderedCellValue === true ? "green" : "red"
              }}
          >
              {renderedCellValue === true ? "Да" : "Нет"}
          </span>
      </Box>
    ),
  }
};

const AdminCatalogView = (props) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [allProductsPageCount, setAllProductsPageCount] = useState(1);
  const [catalogProductsPageCount, setCatalogProductsPageCount] = useState(1);
  const [allProductsPage, setAllProductsPage] = useState(1);
  const [catalogProductsPage, setCatalogProductsPage] = useState(1);
  const [allProductsColumnFilters, setAllProductsColumnFilters] = useState([]);
  const [catalogProductsColumnFilters, setCatalogProductsColumnFilters] = useState([]);

  const [catalogProducts, setCatalogProducts] = useState([]);
  const [catalogProductsAdd, setCatalogProductsAdd] = useState(false);
  const [allProducts, setAllProducts] = useState([]);

  const [productsWithoutProvider, setProductsWithoutProvider] = useState([]);
  const [productsWithoutProviderCount, setProductsWithoutProviderCount] = useState(0)
  const [productsWithoutProviderPageCount, setProductsWithoutProviderPageCount] = useState(1);
  const [productsWithoutProviderPage, setProductsWithoutProviderPage] = useState(1);
  const [productsWithoutProviderColumnFilters, setProductsWithoutProviderColumnFilters] = useState([]);

  const [catalogData, setCatalogData] = useState({})

  const [newProductCreateFormOpen, setNewProductCreateFormOpen] = useState(false)
  const [brandCreateFormOpen, setBrandCreateFormOpen] = useState(false);
  const [manufactureCreateFormOpen, setManufactureCreateFormOpen] = useState(false);
  const [allProductsCount, setAllProductsCount] = useState(0)
  const [productsLastUpdateHistory, setProductsLastUpdateHistory] = useState({})
  const [updateInProgress, setUpdateInProgress] = useState(false)
  const [historyLastUpdateUser, setHistoryLastUpdateUser] = useState({})

  const getCatalog = async () => {
    await axios_instance
      .get(`catalog/by_id/` + props.activeCatalogID)
      .then(({ data }) => {
        setCatalogData({
          id: data.id,
          name: data.name,
          count: data.count
        });
      })
      .catch((error) => {});
  };

  const getAllProducts = async () => {
    setLoading(true)
    await axios_instance
      .get(`products?page=${allProductsPage}${`&filters=${JSON.stringify(allProductsColumnFilters ?? [])}`}`)
      .then(({ data }) => {
        const data_columns = [];
        setAllProductsCount(data.count)
        setAllProductsPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        data?.results.map((product) => {
          data_columns.push({
            id: product.id,
            article: product.code,
            property_name: product.name ? product.name: "Не указан",
            brand: product.brand?.name ? product.brand?.name : "Не указан",
            manufacture: product.manufacture?.name ? product.manufacture?.name : "Не указан",
            is_standard: product.is_standard,
            properties: product.properties,
            analogs: product.analogs,
          });
        });
        setAllProducts(data_columns);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getCatalogProducts = async () => {
    setLoading(true)
    await axios_instance
      .get(`catalog/products/` + props.activeCatalogID + `?page=${catalogProductsPage}${`&filters=${JSON.stringify(catalogProductsColumnFilters ?? [])}`}`)
      .then(({ data }) => {
        const data_columns = [];
        setCatalogProductsPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        data?.results.map((product) => {
          data_columns.push({
            id: product.id,
            article: product.code,
            property_name: product.name? product.name: "Не указан",
            brand: product.brand?.name ? product.brand?.name : "Не указан",
            manufacture: product.manufacture?.name ? product.manufacture?.name : "Не указан",
            is_standard: product.is_standard,
            properties: product.properties,
            analogs: product.analogs,
          });
        });
        setCatalogProducts(data_columns);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getProductsWithoutProvider = async () => {
    setLoading(true)
    await axios_instance
      .get(`products/without_provider?page=${productsWithoutProviderPage}${`&filters=${JSON.stringify(productsWithoutProviderColumnFilters ?? [])}`}`)
      .then(({ data }) => {
        const data_columns = [];
        setProductsWithoutProviderCount(data.count)
        setProductsWithoutProviderPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        data?.results.map((product) => (
          data_columns.push({
            id: product.id,
            article: product.code,
            property_name: product.name,
            brand: product.brand?.name ? product.brand?.name : "Не указан",
            manufacture: product.manufacture?.name ? product.manufacture?.name : "Не указан",
            is_standard: product.is_standard,
            properties: product.properties,
            analogs: product.analogs,
          })
      ));
        setProductsWithoutProvider(data_columns);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const nonStandardProductsUpdate = async () => {
    setUpdateInProgress(true)
    await axios_instance
      .post(`non_standard_products/update`)
      .then(({ data }) => {
        getProductsHistory()
      })
      .catch((error) => {
        getProductsHistory();
        getAllProducts();
      });
  };

  const getUserById = async () => {
    await axios_instance
      .get(`/users/get/by_creator_id/${productsLastUpdateHistory?.creator}`)
      .then(({ data }) => {
        setHistoryLastUpdateUser(data);
      })
      .catch((error) => {});
  };

  const getProductsHistory = async () => {
    await axios_instance
      .get(`products/history/get`)
      .then(({ data }) => {
        setProductsLastUpdateHistory(data);
        console.log(data)
        getAllProducts();
        {data.end ? (
          setUpdateInProgress(false)
        ) : (
          setUpdateInProgress(true)
        )}
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (props?.allProductsOpen === true) {
      getAllProducts();
      getProductsHistory();
    }
  }, [props?.allProductsOpen]);

  useEffect(() => {
    if (props.productsWithoutProviderOpen === true) {
      getProductsWithoutProvider();
    }
  }, [props.productsWithoutProviderOpen]);

  useEffect(() => {
    if (props.activeCatalogID !== undefined) {
      getCatalog();
      getCatalogProducts();
    }
  }, [props.activeCatalogID]);

  useEffect(() => {
    getAllProducts();
  }, [allProductsPage]);

  useEffect(() => {
    getCatalogProducts();
  }, [catalogProductsPage]);

  useEffect(() => {
    getProductsWithoutProvider();
  }, [productsWithoutProviderPage]);

  useEffect(() => {
    setAllProductsPage(1);
    getAllProducts();
  }, [allProductsColumnFilters]);

  useEffect(() => {
    setCatalogProductsPage(1);
    getCatalogProducts();
  }, [catalogProductsColumnFilters]);

  useEffect(() => {
    setProductsWithoutProviderPage(1);
    getProductsWithoutProvider();
  }, [productsWithoutProviderColumnFilters]);

  useEffect(() => {
    setAllProductsPage(1);
    setCatalogProductsPage(1);
    setProductsWithoutProviderPage(1);
    setAllProductsColumnFilters([]);
    setProductsWithoutProviderColumnFilters([]);
    setCatalogProductsColumnFilters([]);
  }, [props.activeCatalogID]);

  useEffect(() => {
    getUserById();
  }, [productsLastUpdateHistory]);

  return (
    <div style={{ height: "100%" }}>
      <div style={{ height: "12%" }}>
        <Toolbar design={ToolbarDesign.Solid}>
          {props?.allProductsOpen || props?.productsWithoutProviderOpen ? (
            props?.allProductsOpen ? (
              <Title>Все товары</Title>
            ) : (
              <Title>Товары без поставщиков</Title>
            )
          ) : (
            <Title>{catalogData.name}</Title>
          )}
          <ToolbarSpacer />
          <Button
            icon="decline"
            design={ButtonDesign.Transparent}
            onClick={() => {
              props.setActiveCatalog({});
              props.setAllProductsOpen(false);
              props.setProductsWithoutProvider(false);
            }}
          />
        </Toolbar>
        <DynamicPageHeader>
          <FlexBox direction="Column" style={{ padding: "5px 10px" }}>
            <Label>
              Количество товаров:
              {
                props?.allProductsOpen || props?.productsWithoutProviderOpen
                ? props?.allProductsOpen ? allProductsCount : productsWithoutProviderCount : catalogData.count
              }
            </Label>
            {props?.allProductsOpen ? (
              <Label>
                <MessageStrip hideCloseButton={true}>
                  {!updateInProgress ? "Последнее обновление товаров: " : <text style={{ fontWeight: 700 }}>Идёт обновление: </text>}
                  <>{historyLastUpdateUser?.user?.email} </> 
                  {productsLastUpdateHistory?.start}{productsLastUpdateHistory?.end ? <> - {productsLastUpdateHistory?.end}</> : <Loader progress="60%" />}
                </MessageStrip>
              </Label>
            ) : (
              null
            )}
          </FlexBox>
        </DynamicPageHeader>
      </div>
      <div style={{ height: "87%" }}>
        {catalogProductsAdd || newProductCreateFormOpen ? (
          <>
            {catalogProductsAdd ? (
              <ModalWindow
                className="ProductSortingForm"
                state={catalogProductsAdd}
                setState={setCatalogProductsAdd}
                title={"Добавление/Удаление товара в каталоге"}
                children={
                  <AdminCatalogProductsSortingView
                    catalogProducts={catalogProducts}
                    getCatalogProducts={getCatalogProducts}
                    activeCatalogID={props.activeCatalogID}
                    getCatalog={getCatalog}
                  />
                }
              />
            ) : (
              <>
                {brandCreateFormOpen || manufactureCreateFormOpen ? (
                  <>
                    {brandCreateFormOpen ? (
                      <ModalWindow
                        className="brand-manufacture-create-form-modal"
                        state={brandCreateFormOpen}
                        setState={setBrandCreateFormOpen}
                        title={"Создание бренда"}
                        children={
                          <AdminBrandCreateForm setBrandCreateFormOpen={setBrandCreateFormOpen}/>
                        }
                      />
                    ) : (
                      <ModalWindow
                        className="brand-manufacture-create-form-modal"
                        state={manufactureCreateFormOpen}
                        setState={setManufactureCreateFormOpen}
                        title={"Создание производителя"}
                        children={
                          <AdminManufactureCreateForm setManufactureCreateFormOpen={setManufactureCreateFormOpen}/>
                        }
                      />
                    )}
                  </>
                ) : (
                  <ModalWindow
                    className="NewProductCreateForm"
                    state={newProductCreateFormOpen}
                    setState={setNewProductCreateFormOpen}
                    title={"Создание товара"}
                    children={
                      <AdminNewProductCreateForm
                        getCatalog={getCatalog}
                        allProductsOpen={props?.allProductsOpen}
                        productsWithoutProviderOpen={props?.productsWithoutProviderOpen}
                        getCatalogProducts={getCatalogProducts}
                        getAllProducts={getAllProducts}
                        getProductsWithoutProvider={getProductsWithoutProvider}
                        activeCatalogID={props.activeCatalogID}
                        activeProductID={props.setActiveProductID}
                        brandCreateFormOpen={brandCreateFormOpen}
                        setBrandCreateFormOpen={setBrandCreateFormOpen}
                        manufactureCreateFormOpen={manufactureCreateFormOpen}
                        setManufactureCreateFormOpen={setManufactureCreateFormOpen}
                        setNewProductCreateFormOpen={setNewProductCreateFormOpen}
                      />
                    }
                  />
                )}
              </>
            )}
          </>
        ) : (
          <Table
            className="AdminCatalogProductsTable"
            columns={fields}
            data={
              props?.allProductsOpen || props?.productsWithoutProviderOpen 
                ? props?.allProductsOpen 
                ? allProducts : productsWithoutProvider
              : catalogProducts
            }
            defaultColumn={{
              maxSize: 400,
              minSize: 80,
              size: 150, //default size is usually 180
            }}
            editDisplayMode='cell'
            enableEditing={false}
            enableColumnResizing
            enableColumnFilters
            onIsFullScreenChange={setIsFullScreen}
            enableColumnOrdering={true}
            enableGrouping={false}
            enableColumnPinning={true}
            enableFacetedValues={true}
            enableRowActions={true}
            enableStickyFooter={true}
            enableStickyHeader={true}
            onColumnFiltersChange={
              props?.allProductsOpen || props?.productsWithoutProviderOpen
                ? props?.allProductsOpen
                ? setAllProductsColumnFilters : setProductsWithoutProviderColumnFilters
              : setCatalogProductsColumnFilters
            }
            isLoading={loading}
            isFullScreen={isFullScreen}
            pagination={{
              page: props?.allProductsOpen || props.productsWithoutProviderOpen
                ? props?.allProductsOpen ? allProductsPage : productsWithoutProviderPage
              : catalogProductsPage,
              setPage: props?.allProductsOpen || props.productsWithoutProviderOpen
                ? props?.allProductsOpen ? setAllProductsPage : setProductsWithoutProviderPage
              : setCatalogProductsPage,
              pageCount: props?.allProductsOpen || props.productsWithoutProviderOpen
                ? props?.allProductsOpen ? allProductsPageCount : productsWithoutProviderPageCount
              : catalogProductsPageCount,
            }}
            topToolbarCustomActions={
              <div>
                {props?.allProductsOpen || props?.productsWithoutProviderOpen ? (
                  <FlexBox style={{ gap: "10px" }}>
                    <Button
                      design="Emphasized"
                      onClick={() => {
                        setNewProductCreateFormOpen(true);
                      }}
                    >
                      Создать новый товар
                    </Button>
                    {props?.allProductsOpen ? (
                      <Button
                        design="Emphasized"
                        tooltip={updateInProgress ? "Идёт обновление" : "Обновляет все не эталонные товары, у которых есть эталонный аналог"}
                        disabled={updateInProgress ? true : false}
                        onClick={() => {
                          nonStandardProductsUpdate();
                        }}
                      >
                        Обновить товары
                      </Button>
                    ) : (null)}
                  </FlexBox>
                ) : (
                  <FlexBox style={{ gap: "10px" }}>
                    <Button
                      design="Emphasized"
                      onClick={() => {
                        setNewProductCreateFormOpen(true);
                      }}
                    >
                      Создать новый товар в каталоге
                    </Button>
                    <Button
                      design="Emphasized"
                      onClick={() => {
                        setCatalogProductsAdd(true);
                      }}
                    >
                      Добавить/Удалить товар в каталоге
                    </Button>
                  </FlexBox>
                )}
              </div>
            }
            rowActionMenuItems={({ row, closeMenu }) => [
              <MenuItem
                key="view"
                onClick={() => {
                  setIsFullScreen(false);
                  props.setActiveProduct(row.original);
                  closeMenu();
                }}
              >
                Просмотр
              </MenuItem>,
            ]}
          />
        )}
      </div>
    </div>
  );
};

export default AdminCatalogView;
