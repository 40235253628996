import React, { useEffect, useState } from "react";
import axios_instance from "../../requests/config_defaults";
import "./CatalogSideView.css";
import { Box, MenuItem } from "@mui/material";
import Table from "../base/table/Table";
import "@ui5/webcomponents-icons/dist/full-screen.js";
import "@ui5/webcomponents-icons/dist/exit-full-screen.js";
import "@ui5/webcomponents-icons/dist/navigation-right-arrow.js";
import "@ui5/webcomponents-icons/dist/navigation-left-arrow.js";
import "@ui5/webcomponents-icons/dist/media-pause.js";
import "@ui5/webcomponents-icons/dist/media-play.js";
import Gallery from "../base/gallery/Gallery";
import { useLocation, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
import ProductImageCard from "../admin-panel/products-admin-panel/products-admin-detail-view/ProductImageCard";
import { marketIconsAtom } from "../../hoc/GlobalAtoms";
import { useAtom } from "jotai";
import dayjs from "dayjs";

const fields = {
  article: {
    key: "article",
    header: "Артикул",
    editable: false,
  },
  name: {
    key: "property_name",
    header: "Наименование",
    editable: false,
  },
  manufacture: {
    key: "manufacture",
    header: "Производитель",
    editable: false,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    editable: false,
  },
  common_rest: {
    key: "rest",
    header: "Количество товара в каталоге",
    editable: false,
    filterFn: "between",
  },
  stocks_count: {
    key: "stocks_count",
    header: "Количество в моих предложениях",
    editable: false,
    filterFn: "between",
  },
  min_price: {
    key: "min_price",
    header: "Минимальная цена",
    editable: false,
  },
  stocks_updated_at: {
    key: "stocks_updated_at",
    header: "Обновление остатков",
    editable: false,
    filterVariant: 'datetime-range',
  },
  my_stocks_updated_at: {
    key: "my_stocks_updated_at",
    header: "Моё обновление остатков",
    editable: false,
    filterVariant: 'datetime-range',
  },
};

// const images = [
//   {
//     src: "https://azkamaz.ru/netcat_files/124/127/Korobka_peredach_2_thumb1jpg",
//     // thumbnail: "https://sap.github.io/ui5-webcomponents/assets/images/HT-1000.jpg",
//     sizes: "(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px",
//   },
//   {
//     src: "https://azkamaz.ru/netcat_files/124/127/Bezymyannyy_1_thumb1jpg",
//     // thumbnail: "https://sap.github.io/ui5-webcomponents/assets/images/HT-1010.jpg",
//     sizes: "(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px",
//   },
//   {
//     src: "https://azkamaz.ru/netcat_files/124/127/740.60_1118013___turbokompressor_TKR7S_6M_levyy_thumb1jpg",
//     // thumbnail: "https://sap.github.io/ui5-webcomponents/assets/images/HT-1022.jpg",
//     sizes: "(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px",
//   },
// ];

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerPadding: "60px",
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
};

const CatalogSideView = (props) => {
  const [icons] = useAtom(marketIconsAtom)
  const [productCode, setProductCode] = useState("");
  const [data, setData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [columnFilters, setColumnFilters] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const location = useLocation();
  const [search, setSearch] = useSearchParams();

  const getEarliestUpdateTime = async () => {
    setLoading(true);
    await axios_instance
      .get(
        `/products`
      )
      .then(({ data }) => {
        const date = []
        if (props.stock) {
          const currentDate = new Date(data?.results[0]?.earliest_stock_update);
          const formattedDate = currentDate.toISOString().slice(0, 23) + 'Z'.replace('T', ' ');
          const dateValue = dayjs(formattedDate);
          date.push({ id: "stocks_updated_at", value: [dateValue, null] })
          setColumnFilters(date);
        }
        if (props.myStock) {
          const currentDate = new Date(data?.results[0]?.earliest_entity_stock_update);
          const formattedDate = currentDate.toISOString().slice(0, 23) + 'Z'.replace('T', ' ');
          const dateValue = dayjs(formattedDate);
          date.push({ id: "my_stocks_updated_at", value: [dateValue, null] })
          setColumnFilters(date);
        }
      })
      .catch((error) => {
      });
  };

  const getProducts = async () => {
    setLoading(true);
    await axios_instance
      .get(
        `/products?page=${page}${`&filters=${JSON.stringify(
          columnFilters ?? []
        )}`}`
      )
      .then(({ data }) => {
        const catalog = [];
        setMaxPage(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        data?.results.map((product) => {
          let images = [];
          product?.image.length !== 0 ? (
            product?.image?.map((image) => 
              images.push({
                src: image?.image,
                sizes:
                  "(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px",
              })
            )
          ) : (
            images.push(
              {
                src: icons.default_product_img,
                sizes:
                  "(max-width: 1000px) 400px, (max-width: 2000px) 700px, 1000px",
              }
            )
          )
          catalog.push({
            id: product.id,
            article: product.code,
            property_name: product.name ? product.name : "Не указано",
            manufacture: product?.manufacture?.name || "Не указан",
            brand: product?.brand?.name || "Не указан",
            common_rest: product?.count,
            min_price: product?.min_sale_price?.min_sale_price ? product?.min_sale_price?.min_sale_price : "-",
            stocks_count: product?.self_rest,
            rest: product?.rest,
            images: images,
            stocks_updated_at: product?.last_stock_update,
            my_stocks_updated_at: product?.entity_last_stock_update,
          });
        });
        setData(catalog);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (!props.stock && !props.myStock) {
      getProducts();
    }
  }, []);

  useEffect(() => {
    if (props.stock || props.myStock) {
      getEarliestUpdateTime();
    }
  }, [props.myStock, props.stock]);

  useEffect(() => {
    getProducts();
  }, [page, columnFilters]);

  useEffect(() => {
    setPage(1);
  }, [columnFilters]);

  return (
    // <div className='CatalogSideView-container'>
    //     <div className="CatalogSideView-content">
    <Table
      className={"CatalogSideView-table"}
      columns={fields}
      data={data}
      defaultColumn={{
        maxSize: 400,
        minSize: 80,
        size: 150, //default size is usually 180
      }}
      editDisplayMode="cell"
      enableEditing={false}
      enableColumnResizing
      columnFilters={columnFilters}
      showColumnFilters={props.stock || props.myStock ? true : false}
      enableColumnFilters
      onIsFullScreenChange={setIsFullScreen}
      enableColumnOrdering={true}
      enableGrouping={false}
      enableColumnPinning={true}
      enableFacetedValues={true}
      enableRowActions={true}
      enableStickyFooter={true}
      enableStickyHeader={true}
      renderDetailPanel={(panel) => (
        <Box
          style={{
            height: "600px",
          }}
        >
          <Gallery images={panel.row?.original?.images} height={500} width={800} />
        </Box>
      )}
      onColumnFiltersChange={setColumnFilters}
      isLoading={loading}
      isFullScreen={isFullScreen}
      pagination={{
        page: page,
        setPage: setPage,
        pageCount: maxPage,
      }}
      rowActionMenuItems={({ row, closeMenu }) => [
        <MenuItem
          key="view"
          onClick={() => {
            setIsFullScreen(false);
            props.setActiveProduct(row.original?.id);
            setSearch((prev) => ({ ...prev, product: row.original?.id }));
            closeMenu();
          }}
        >
          Просмотр
        </MenuItem>,
      ]}
    />
    //     </div>
    // </div>
  );
};

export default CatalogSideView;
