import React, { useEffect, useState } from "react";
import "./Header.css";
import { Avatar, Button, Icon } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/menu2.js";
import UserProfileActionSheet from "../../base/user-profile-action-sheet/UserProfileActionSheet";
import { useNavigate, useOutletContext, useLocation } from "react-router-dom";
import axios_instance from "../../../requests/config_defaults";
import UserCompanyController from "../../base/user-company-controller/UserCompanyController";
import { useAtom } from "jotai";
import {
  activeCompanyAtom,
  emailAtom,
  fullNameAtom,
  isAdminAtom,
  isImpersonatedAtom,
  marketIconsAtom,
} from "../../../hoc/GlobalAtoms";
import classNames from "classnames";
import "@ui5/webcomponents-icons/dist/employee-rejections.js";

const Header = (props) => {
  const [fullName, setFullName] = useAtom(fullNameAtom);
  const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
  const [email, setEmail] = useAtom(emailAtom);
  const [activeCompany, setActiveCompany] = useAtom(activeCompanyAtom);
  const [isImpersonated, setIsImpersonated] = useAtom(isImpersonatedAtom);
  const location = useLocation();
  const nav = useNavigate();
  const [icons, setIcons] = useAtom(marketIconsAtom);

  const getMe = async () => {
    await axios_instance
      .get("/getMe")
      .then((response) => {
        console.log(response.data);
        setEmail(response.data.user.email);
        setFullName(
          `${response.data.user.last_name} ${response.data.user.first_name}`
        );
        setIsAdmin(response.data.is_admin || response.data.user.is_superuser);
        console.log(response.data);
      })
      .catch((error) => {});
  };

  const getActiveCompany = async () => {
    await axios_instance
      .get("/myCompany")
      .then((response) => {
        setActiveCompany(response.data);
      })
      .catch((error) => {
        setActiveCompany({});
      });
  };

  const stopImpersonation = async () => {
    await axios_instance
      .post("/users/impersonate/stop")
      .then((response) => {
        setIsImpersonated(false);
        getMe();
        getActiveCompany();
        nav("/admin-users");
      })
      .catch((error) => {
        setIsImpersonated(true);
      });
  };

  return (
    <div className="main-header-container">
      <div className="main-haeder-content">
        <div className="main-haeder-content-block left-side">
          <a href="/" className="main-logo-a">
            <img
              alt="EXZA"
              className="main-logo"
              // src={"/static/EXZA_LOGO_NAME_slogan.svg"}
              src={icons?.icon_full}
            />
          </a>
        </div>
        <div className="main-haeder-content-block right-side">
          {isImpersonated && (
            <Button
              icon="employee-rejections"
              title="Выйти из режима"
              className="impersonate-button"
              onClick={() => {
                stopImpersonation();
                nav("/");
              }}
            ></Button>
          )}
          <button
            onClick={() => {
              console?.log(location);
              nav(`/legal-entities?company=${activeCompany?.id}`, {
                state: { prevPath: location.pathname + location.search },
              });
            }}
            className={classNames(
              "user-company-control-btn",
              !activeCompany?.name && "user-company-control-btn-empty"
            )}
          >
            {activeCompany?.name || "Выбрать компанию"}
          </button>
          {!props.menuIsOpen && (
            <UserProfileActionSheet
              email={props.email}
              onLogout={() => {
                nav("/login");
              }}
            />
          )}
          {!props.menuIsOpen && (
            <Button
              className="main-header-controller"
              icon="menu2"
              onClick={() => props.setMenuIsOpen(true)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
