import React, { useEffect, useMemo, useState } from "react";
import "./ProductDetailView.css";
import {
  Badge,
  Breadcrumbs,
  BreadcrumbsItem,
  Button,
  DynamicPageHeader,
  DynamicPageTitle,
  FlexBox,
  Form,
  FormItem,
  Label,
  Link,
  MessageStrip,
  ObjectPage,
  ObjectPageSection,
  ObjectPageSubSection,
  ObjectStatus,
  Text,
} from "@ui5/webcomponents-react";
import MyOffersView from "../my-offers-view/MyOffersView";
import axios_instance from "../../requests/config_defaults";
import { Loader } from "@mantine/core";
import SupplierOffersView from "../suppliers-offers-view/SupplierOffersView";
import { ExzaLogo } from "../base/exza_logo/ExzaLogo";
import { ModelsScene } from "../dashboard/catalog/3d-model-scene/ModelsScene";
import { VolumeModel } from "../dashboard/catalog/3d-model-scene/3d-model/3DModel";
import MyOfferCreateForm from "../my-offers-view/my-offer-create-form/MyOfferCreateForm";
import { useAtom } from "jotai";
import { activeCompanyAtom } from "../../hoc/GlobalAtoms";
import Slider from "react-slick";
import ProductImageCard from "../admin-panel/products-admin-panel/products-admin-detail-view/ProductImageCard";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  centerPadding: "60px",
  slidesToScroll: 1,
  arrows: false,
  centerMode: true,
};

const ProductDetailView = (props) => {
  const [productData, setProductData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [offerCreateMode, setOfferCreateMode] = useState(false);
  const [loadingMyOffers, setLoadingMyOffers] = useState(false);
  const [myOffersMaxPage, setMyOffersMaxPage] = useState(1);
  const [myOffersData, setMyOffersData] = useState([]);
  const [myOffersPage, setMyOffersPage] = useState(1);
  const [stockCommonInformation, setStockCommonInformation] = useState({});
  const [activeCompany] = useAtom(activeCompanyAtom);
  const [uploadedImage, setUploadedImage] = useState({
    image: null,
  });
  const [displayNone, setDisplayNone] = useState({
    image: true,
  });
  const [bigImageError, setBigImageError] = useState(false);

  const getProductData = async () => {
    setLoading(true);
    await axios_instance
      .get("/product/by_id/" + props.activeProduct)
      .then(({ data }) => {
        setProductData(data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getStockCommonInformation = async () => {
    await axios_instance
      .get("/stock/get/common/information/" + props.activeProduct)
      .then(({ data }) => {
        setStockCommonInformation(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  function formatDateTime(datetimeStr) {
    // Создаем объект Date из строки
    const date = new Date(datetimeStr);
  
    // Преобразуем объект Date обратно в строку в нужном формате
    const formattedDate = date.toISOString().split('.')[0];
  
    return formattedDate;
  }

  const getOffers = async () => {
    setLoadingMyOffers(true);
    await axios_instance
      .get(
        "/myOffersByProduct/" + props.activeProduct + `?page=${myOffersPage}`
      )
      .then(({ data }) => {
        setMyOffersMaxPage(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        const data_items = [];
        data?.results.map((item) => {
          data_items.push({
            id: item.id,
            product_full_name: item.product.name?item.product.name: "Не указано",
            needful_rest: item.needful_rest,
            current_rest: item.current_rest,
            // order_rest: item.to_order_count,
            // total_rest: item.current_rest + item.ordered_count,
            to_order_count: item.to_order_count,

            request_actual_datetime: (
              <input
                type={"datetime-local"}
                style={{
                  border: "2px solid #333",
                  backgroundColor: "#ccc",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
                onChange={(e) => {
                  console.log(e.target.value);
                }}
              />
            ),
            purchase_price: item.purchase_price,
            sale_price: item.clean_sale_price,
            for_sale_count: item.for_sale_count,
            warehouse: item?.warehouse?.name,
            entity: item?.entity?.name,
            updated_at: formatDateTime(item.updated_at),
          });
        });
        setMyOffersData(data_items);
        setLoadingMyOffers(false);
      })
      .catch((error) => {
        setLoadingMyOffers(false);
      });
  };

  useEffect(() => {
    getProductData();
    getStockCommonInformation();
  }, [props.activeProduct]);

  return (
    <>
      {loading && (
        <>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0, 0, 0, 0.5)",
              zIndex: 2,
            }}
            className=""
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 10,

              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            className=""
          >
            <Loader size={50} />
          </div>
        </>
      )}
      <ObjectPage
        headerTitle={
          <DynamicPageTitle
            actions={
              <>
                <Button
                  onClick={() => {
                    setOfferCreateMode(false);
                    props.setActiveProduct(null);
                  }}
                >
                  Закрыть
                </Button>
                <Button
                disabled={!Boolean(productData?.is_standard)}
                  onClick={() => {
                    openInNewTab(`search?pid=${props.activeProduct}`)
                  }}
                >
                  Посмотреть предложения
                </Button>
              </>
            }
            breadcrumbs={
              <Breadcrumbs>
                <BreadcrumbsItem>Каталог</BreadcrumbsItem>
                <BreadcrumbsItem>Бренд</BreadcrumbsItem>
                <BreadcrumbsItem>Производитель</BreadcrumbsItem>
                <BreadcrumbsItem>Артикул</BreadcrumbsItem>
              </Breadcrumbs>
            }
            expandedContent={
              <MessageStrip color="6">
                Информация (дополнительная информация отсутствует)
              </MessageStrip>
            }
            header={<Text colorScheme="10">{productData?.code}</Text>}
            showSubHeaderRight
            snappedContent={
              <MessageStrip>
                Информация (дополнительная информация отсутствует)
              </MessageStrip>
            }
            subHeader={`Бренд: ${productData?.brand?.name || "Не указан"}`}
          >
            <ObjectStatus state="Success">
              Производитель: {productData?.manufacture?.name || "Не указан"}
            </ObjectStatus>
          </DynamicPageTitle>
        }
        headerContent={
          <DynamicPageHeader>
            <FlexBox alignItems="Center" wrap="Wrap">
              <FlexBox direction="Column">
                <Link>+79178864756</Link>
                <Link>invoice@exzamarket.ru</Link>
                <Link href="https://exzamarket.ru">https://exzamarket.ru</Link>
              </FlexBox>
              <FlexBox direction="Column" style={{ padding: "10px" }}>
                <Label>Набережные Челны</Label>
                <Label>Респ. Татарстан, Россия</Label>
              </FlexBox>
            </FlexBox>
          </DynamicPageHeader>
        }
        headerContentPinnable
        image="/static/exza.png"
        imageShapeCircle
        selectedSectionId="goals"
        showHideHeaderButton
        style={{
          height: "100%",
        }}
      >
        <ObjectPageSection
          aria-label="product"
          id="product"
          titleText="Карточка товара"
        >
          <FlexBox
            alignItems="Center"
            justifyContent="FlexStart"
            direction="Row"
            style={{
              gap: "3rem",
            }}
          >
            <FlexBox
              style={{
                width: "50%",
                display: "block",
                fontSize: ".7rem",
              }}
            >
              <FlexBox>
                <Label
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  Артикул:
                </Label>
                <Text
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  {productData?.code}
                </Text>
              </FlexBox>
              <FlexBox>
                <Label
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  Бренд:
                </Label>
                <Text
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  {productData?.brand?.name || "Не указан"}
                </Text>
              </FlexBox>
              <FlexBox>
                <Label
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  Производитель:
                </Label>
                <Text
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  {productData?.manufacture?.name || "Не указан"}
                </Text>
              </FlexBox>
              <FlexBox>
                <Label
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  Наименование:
                </Label>
                <Text
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  {productData?.name ? productData?.name :"Не указано"}
                </Text>
              </FlexBox>
            </FlexBox>

            <FlexBox
              style={{
                width: "50%",
                display: "block",
                fontSize: ".7rem",
              }}
            >
              <FlexBox>
                <Label
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  Всего товаров в каталоге:
                </Label>
                <Text
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  {stockCommonInformation?.catalog_rest}
                </Text>
              </FlexBox>
              <FlexBox>
                <Label
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  Моя потребность в данном товаре:
                </Label>
                <Text
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  {stockCommonInformation?.common_needful_rest}
                </Text>
              </FlexBox>
              <FlexBox>
                <Label
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  Добавлено в мои проекты заказов:
                </Label>
                <Text
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  {stockCommonInformation?.count_in_order_project}
                </Text>
              </FlexBox>
              <FlexBox>
                <Label
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  Нужно добавить в проекты заказа:
                </Label>
                <Text
                  style={{
                    fontSize: "1.1rem",
                  }}
                >
                  {stockCommonInformation?.need_to_add_to_order_project_count}
                </Text>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </ObjectPageSection>
        <ObjectPageSection
          aria-label="offers"
          id="offers"
          titleText="Предложения"
        >
          <SupplierOffersView
            product={props.activeProduct}
            activeCompany={activeCompany}
          />
        </ObjectPageSection>
        <ObjectPageSection
          aria-label="myOffers"
          id="myOffers"
          titleText="Мои предложения"
        >
          <MyOffersView
            loading={loadingMyOffers}
            maxPage={myOffersMaxPage}
            data={myOffersData && myOffersData}
            setData={setMyOffersData}
            page={myOffersPage}
            setPage={setMyOffersPage}
            getOffers={getOffers}
            addressesData={props.addressesData}
            product={props.activeProduct}
            activeCompany={activeCompany}
            setOfferCreateMode={setOfferCreateMode}
          />
        </ObjectPageSection>

        <ObjectPageSection
          aria-label="orderProjects"
          id="orderProjects"
          titleText="Проекты заказа"
        >
          <MessageStrip style={{ margin: "10px 0" }} color="6">
            Скоро управление проектами заказа будет доступно в данном разделе.
          </MessageStrip>
          <Link href="/orders">
            <Button design="Emphasized">Перейти в проекты заказов</Button>
          </Link>
        </ObjectPageSection>

        <ObjectPageSection
          aria-label="properties"
          id="properties"
          titleText="Характеристики"
        >
          <ObjectPageSubSection
            aria-label="baseProperties"
            id="baseProperties"
            titleText="Основные характеристики"
          >
            <FlexBox>
              <Label
                style={{
                  fontSize: "1.1rem",
                }}
              >
                Артикул:
              </Label>
              <Text
                style={{
                  fontSize: "1.1rem",
                }}
              >
                {productData?.code}
              </Text>
            </FlexBox>
            <FlexBox>
              <Label
                style={{
                  fontSize: "1.1rem",
                }}
              >
                Бренд:
              </Label>
              <Text
                style={{
                  fontSize: "1.1rem",
                }}
              >
                {productData?.brand?.name || "Не указан"}
              </Text>
            </FlexBox>
            <FlexBox>
              <Label
                style={{
                  fontSize: "1.1rem",
                }}
              >
                Производитель:
              </Label>
              <Text
                style={{
                  fontSize: "1.1rem",
                }}
              >
                {productData?.manufacture?.name || "Не указан"}
              </Text>
            </FlexBox>
            <FlexBox>
              <Label
                style={{
                  fontSize: "1.1rem",
                }}
              >
                Наименование:
              </Label>
              <Text
                style={{
                  fontSize: "1.1rem",
                }}
              >
                {productData?.name ? productData?.name :"Не указано"}
              </Text>
            </FlexBox>
          </ObjectPageSubSection>
          <ObjectPageSubSection
            aria-label="dimentions"
            id="dimentions"
            titleText="Весогабаритные характеристики (ВГХ)"
          >
            {productData?.properties.map((prop) =>
              prop.is_primary ? (
                <FlexBox
                  style={{
                    gap: "10px",
                  }}
                >
                  <Label
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {prop.name}:
                  </Label>
                  <Text
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {prop?.value?.content} {prop?.measure?.name}
                  </Text>
                </FlexBox>
              ) : null
            )}
          </ObjectPageSubSection>
          <ObjectPageSubSection
            aria-label="additionalProperties"
            id="additionalProperties"
            titleText="Дополнительные характеристики"
          >
            {productData?.properties.map((prop) =>
              !prop.is_primary ? (
                <FlexBox
                  style={{
                    gap: "10px",
                  }}
                >
                  <Label
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {prop.name}:
                  </Label>
                  <Text
                    style={{
                      fontSize: "1.1rem",
                    }}
                  >
                    {prop?.value?.content}
                  </Text>
                </FlexBox>
              ) : null
            )}
          </ObjectPageSubSection>

          <ObjectPageSubSection
            id="catalog-product-properties-card-section-3d-model"
            aria-label={"3d модель"}
            titleText={"3D модель"}
          >
            <ModelsScene style={{ height: 500 }} model={<VolumeModel />} />
          </ObjectPageSubSection>

          <ObjectPageSubSection
            id="catalog-product-properties-card-section-photo"
            aria-label={"Фото"}
            titleText={"Фото"}
          >
            {uploadedImage.image ? (
              <>
                <img
                  style={{ height: "100%", width: "100%", margin: 20 }}
                  src={uploadedImage.image.image_interface}
                  alt="EXZA"
                />
              </>
            ) : (
              <>
                {productData?.image?.length !== 1 ? (
                  <Slider {...settings}>
                    {productData?.image?.map((image) => (
                      <ProductImageCard
                        image={image}
                        key={productData.id}
                        productData={productData}
                        getProduct={getProductData}
                        setImageDeleteOpen={props.setImageDeleteOpen}
                        setActiveImageID={props.setActiveImageID}
                        imageDeleted={props.imageDeleted}
                        setImageDeleted={props.setImageDeleted}
                        catalogView={true}
                      />
                    ))}
                  </Slider>
                ) : (
                  <ProductImageCard
                    image={productData?.image[0]}
                    key={productData?.id}
                    productData={productData}
                    getProduct={getProductData}
                    setImageDeleteOpen={props.setImageDeleteOpen}
                    setActiveImageID={props.setActiveImageID}
                    imageDeleted={props.imageDeleted}
                    setImageDeleted={props.setImageDeleted}
                    catalogView={true}
                  />
                )}
              </>
            )}
          </ObjectPageSubSection>
        </ObjectPageSection>
      </ObjectPage>

      {offerCreateMode && (
        <MyOfferCreateForm
          setOfferCreateMode={setOfferCreateMode}
          productData={productData}
          addressesData={props.addressesData}
          getOffers={getOffers}
        />
      )}
    </>
  );
};

export default ProductDetailView;
