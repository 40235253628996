import React from 'react'
import {
    ObjectPage,
    ObjectPageSection,
    DynamicPageHeader,
    FlexBox,
    Link,
    Label,
    Text,
    DynamicPageTitle,
    Button,
    Breadcrumbs,
    BreadcrumbsItem,
    MessageStrip,
} from "@ui5/webcomponents-react"


const UserProfileView = (props) => {
    return (
        <>
            <ObjectPage
                headerContent={
                    <DynamicPageHeader>
                        <FlexBox alignItems="Center" wrap="Wrap">
                            <FlexBox direction="Column">
                                <Link>+7 927 047 11 99</Link>
                                <Link href="">boboshko.m@it-psg.com</Link>
                            </FlexBox>
                            <FlexBox direction="Column" style={{ padding: '10px' }}>
                                <Label>Казань</Label>
                                <Label>Республика Татарстан, Россия</Label>
                            </FlexBox>
                        </FlexBox>
                    </DynamicPageHeader>
                }
                headerContentPinnable
                headerTitle={
                    <DynamicPageTitle
                        actions={<><Button design="Emphasized">Написать</Button><Button>Редактировать</Button></>}
                        breadcrumbs={<Breadcrumbs><BreadcrumbsItem>ELS</BreadcrumbsItem><BreadcrumbsItem>Персонал</BreadcrumbsItem></Breadcrumbs>}
                        expandedContent={<MessageStrip>Информация</MessageStrip>}
                        header="Михаил Бобошко"
                        showSubHeaderRight
                        snappedContent={<MessageStrip>Информация</MessageStrip>}
                        subHeader="СТО, Технический директор"
                    >
                    </DynamicPageTitle>}
                image="https://cdn-ru.bitrix24.ru/b7104523/main/1b5/1b56c219ec9e0ebf0cd95880e3a081cb/Mjg4MTkxOQ _3_.png"
                imageShapeCircle
                selectedSectionId="info"
                showHideHeaderButton
                style={{
                    height: '700px'
                }}
            >
                <ObjectPageSection
                    aria-label="Info"
                    id="info"
                    titleText="Информация"
                >
                    <Text>В разработке</Text>
                </ObjectPageSection>
            </ObjectPage>
        </>
    )
}

export { UserProfileView }