import React, { useEffect, useState } from 'react'
import './CompanyDetailAddressUpdateForm.css'
import { Bar, Button, Card, CheckBox, Form, FormGroup, FormItem, Input, Switch, Link, Option, Select, StepInput, Text, SuggestionItem, ObjectStatus, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults'
import "@ui5/webcomponents/dist/features/InputSuggestions.js";
import "@ui5/webcomponents-icons/dist/customer.js";
import "@ui5/webcomponents-icons/dist/accept.js";
import { isValidPhoneNumber } from 'react-phone-number-input';


const CompanyDetailAddressUpdateForm = (props) => {
    const [updatedLegalEntityAddress, setUpdatedLegalEntityAddress] = useState({
        city: props.address.geo_object?.full_name,
        exact_address: props.address.exact_address,
        name: props.address.name,
        is_pvz: props.address.is_pvz,
        is_default: props.address.is_default
    });

    useEffect(() => {
        setUpdatedLegalEntityAddress({
            city: props.address.geo_object?.full_name,
            exact_address: props.address.exact_address,
            name: props.address.name,
            is_pvz: props.address.is_pvz,
            is_default: props.address.is_default
        })
        setNameValueState();
    }, [props.address])

    const [exactAddressValueState, setExactAddressValueState] = useState('');

    const [nameValueState, setNameValueState] = useState('');

    const [isPvzValueState, setIsPvzValueState] = useState('');

    const handleUpdateLegalEntityAddress = async (e) => {
        e.preventDefault()
        await axios_instance.patch(
            `/address/edit/${props.address.id}`,
            updatedLegalEntityAddress
        )
            .then((response) => {
                // props.setCreateLegalEntityContactMode(false);
                props.setCompanyAddressesUpdate(!props.companyAddressesUpdate);
                props.setAddressUpdateMode(false);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getByName = async (name) => {
        await axios_instance
            .get(`/address/${props.activeCompany}/get?name=${name}`)
            .then(({ data }) => {
                if (name !== props.address.name) {
                    setNameValueState("Адрес с таким именем уже существует.");
                }
            })
            .catch((error) => {
                setNameValueState("");
            })
    }

    return (
        <div style={{ margin: 20 }}>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanL={1}
                labelSpanM={1}
                labelSpanS={1}
                labelSpanXL={1}
                style={{
                    alignItems: 'left'
                }}
                // className='MyOfferCreateForm-form'
                titleText="Заполните форму"
            >
                <FormGroup>
                    <FormItem label="Населенный пункт">
                        <Input
                            disabled
                            name="city"
                            value={updatedLegalEntityAddress.city ?? ""}
                            style={{ width: '100%' }}
                        />
                    </FormItem>
                    <FormItem label="Точный адрес">
                        <Input
                            required
                            name="exact_address"
                            valueState={exactAddressValueState ? "Error" : props.address.exact_address !== updatedLegalEntityAddress.exact_address ? "Information" : ""}
                            onInput={
                                (e) => {
                                    setExactAddressValueState("");
                                    setUpdatedLegalEntityAddress((prev) => ({
                                        ...prev,
                                        exact_address: e.target.value.trim()
                                    }))
                                }
                            }
                            valueStateMessage={<div>Изменено</div>}
                            value={updatedLegalEntityAddress.exact_address ?? ""}
                            style={{ width: '100%' }}
                        />
                    </FormItem>
                    {exactAddressValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {exactAddressValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Наименование">
                        <Input
                            required
                            name="name"
                            valueState={nameValueState ? "Error" : props.address.name !== updatedLegalEntityAddress.name ? "Information" : ""}
                            onInput={
                                (e) => {
                                    setNameValueState("");
                                    getByName(e.target.value.trim());
                                    setUpdatedLegalEntityAddress((prev) => ({
                                        ...prev,
                                        name: e.target.value.trim()
                                    }))
                                }
                            }
                            valueStateMessage={<div>Изменено</div>}
                            value={updatedLegalEntityAddress.name ?? ""}
                            style={{ width: '100%' }}
                        />
                    </FormItem>
                    {nameValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {nameValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Режим доставки ПВЗ">
                        <Switch
                            onChange={(e) => {
                                setUpdatedLegalEntityAddress((prev) => ({
                                    ...prev,
                                    is_pvz: e.target.checked
                                }))
                            }}
                            textOff={"Нет"}
                            textOn={"Да"}
                            checked={updatedLegalEntityAddress.is_pvz}
                            name="is_pvz"
                            valueState={props.address.is_pvz !== updatedLegalEntityAddress.is_pvz ? "Information" : ""}
                            valueStateMessage={<div>Изменено</div>}
                        />
                    </FormItem>
                    <FormItem label="По умолчанию">
                        <Switch
                            onChange={(e) => {
                                setUpdatedLegalEntityAddress((prev) => ({
                                    ...prev,
                                    is_default: e.target.checked
                                }))
                            }}
                            textOff={"Нет"}
                            textOn={"Да"}
                            checked={updatedLegalEntityAddress.is_default}
                            name="is_pvz"
                            valueState={props.address.is_default !== updatedLegalEntityAddress.is_default ? "Information" : ""}
                            valueStateMessage={<div>Изменено</div>}
                        />
                    </FormItem>
                </FormGroup>
                <FormItem alignItems={"left"} label="">
                    <Button
                        design='Emphasized'
                        style={{
                            padding: "0.5rem 1rem",
                            width: "100%"
                        }}
                        onClick={(e) => {
                            let validate_status = true;
                            if (updatedLegalEntityAddress.exact_address === "") {
                                setExactAddressValueState("Введите точный адрес");
                                validate_status = false;
                            }
                            if (updatedLegalEntityAddress.name === "") {
                                setNameValueState("Введите наименование");
                                validate_status = false;
                            }
                            else if (nameValueState) {
                                validate_status = false;
                            }
                            if (validate_status) {
                                handleUpdateLegalEntityAddress(e);
                            }
                        }}
                    >
                        Изменить
                    </Button>
                </FormItem>

            </Form>
        </div>
    )
}

export default CompanyDetailAddressUpdateForm