import { useMemo, useState, useEffect } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';
import { Box, Typography } from '@mui/material';
import axios_instance from '../../../requests/config_defaults';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import Table from '../../base/table/Table';
import { Button, FlexBox, SplitterLayout, SplitterElement } from '@ui5/webcomponents-react';
import "./ManagerPermissionsManagementTable.css";
import "@ui5/webcomponents-icons/dist/arrow-right.js";
import "@ui5/webcomponents-icons/dist/arrow-left.js";

// import { data } from './makeData';


const fields = {
    key: {
        key: "key",
        header: "Ключ",
        disabled: false,
        size: 100
    },
    name: {
        key: "name",
        header: "Имя",
        disabled: true,
        size: 100
    },
    description: {
        key: "description",
        header: "Описание",
        disabled: false,
        size: 100
    },
};



const ManagerPermissionsManagementTable = (props) => {
    const [dataTable, setDataTable] = useState([]);

    const [data1, setData1] = useState([]);
    const [data2, setData2] = useState([]);

    const [draggingRow, setDraggingRow] = useState(null);
    const [hoveredTable, setHoveredTable] = useState(null);

    useEffect(() => {
        let data_table_1 = [];
        let data_table_2 = [];

        props.manager.actual_permissions.map((manager_permission) => {
            data_table_2.push({
                id: manager_permission.permission.id,
                key: manager_permission.permission.key ? manager_permission.permission.key : "Не указан",
                name: manager_permission.permission.name ? manager_permission.permission.name : "Не указано",
                description: manager_permission.permission.description ? manager_permission.permission.description : "Не указано",
            })
        })

        dataTable.map((permission) => {
            if (!data_table_2.find(standard_permission => standard_permission.key === permission.key)) {
                data_table_1.push({
                    id: permission.id,
                    key: permission.key ? permission.key : "Не указан",
                    name: permission.name ? permission.name : "Не указано",
                    description: permission.description ? permission.description : "Не указано",
                })
            }
        })

        setData1(data_table_1);
        setData2(data_table_2);
    }, [dataTable])

    const managerPermissionCreate = async (row) => {
        await axios_instance.post(
            "/entity/manager/permission/create",
            {
                entity: props.activeCompany,
                email: props.manager.email,
                permission: row ? row.original.id : draggingRow.original.id
            }
        )
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const managerPermissionBlock = async (row) => {
        await axios_instance.post(
            "/entity/manager/permission/block",
            {
                entity: props.activeCompany,
                email: props.manager.email,
                permission: row ? row.original.id : draggingRow.original.id
            }
        )
            .then((response) => {
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getLegalEntityPermissionsList = async () => {
        await axios_instance.get(
            "/entity/permissions/list"
        )
            .then((response) => {
                setDataTable(response.data.results);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const companyManagerDelete = async () => {
        // props.setLoading(true);
        await axios_instance.put(
            `/entity/manager/block`,
            {
                entity: props.activeCompany,
                email: props.manager.email
            }
        )
            .then((response) => {
                console.log(response);
                setData1([...data1, ...data2]);
                setData2([]);
                // props.setLoading(false);
            })
            .catch((error) => {
                // props.setLoading(false);
            })
    }

    const managerPermissionsAllowAll = async () => {
        // props.setLoading(true);
        await axios_instance.put(
            `/entity/manager/permission/allow/all`,
            {
                entity: props.activeCompany,
                email: props.manager.email
            }
        )
            .then((response) => {
                console.log(response);
                setData2([...data1, ...data2]);
                setData1([]);
                // props.setLoading(false);
            })
            .catch((error) => {
                // props.setLoading(false);
            })
    }

    useEffect(() => {
        getLegalEntityPermissionsList();
    }, [])

    return (
        <SplitterLayout style={{ width: "100%", height: 400 }}>
            <SplitterElement>
                <Table
                    className="ManagerPermissionsManagement-table"
                    data={data1}
                    columns={fields}
                    enableRowDragging={Boolean(props.permissionData?.admin)}
                    draggingRow={draggingRow}
                    topToolbarCustomActions={
                        <FlexBox direction='Column'>
                            <Typography color="success.main" component="span" variant="h6">
                                Доступные права доступа
                            </Typography>
                            <Button design='Positive'
                                disabled={(data1?.length === 0 ? true : false) || !Boolean(props.permissionData?.admin) }
                                onClick={e => {
                                    managerPermissionsAllowAll();
                                }}
                            >
                                Назначить все
                            </Button>
                        </FlexBox>
                    }
                    muiRowDragHandleProps={{
                        onDragEnd: () => {
                            if (hoveredTable === 'table-2') {
                                setData2((data2) => [...data2, draggingRow.original]);
                                setData1((data1) => data1.filter((d) => d !== draggingRow.original));
                                managerPermissionCreate();
                            }
                            setHoveredTable(null);
                        },
                    }}
                    muiTablePaperProps={{
                        onDragEnter: () => setHoveredTable('table-1'),
                        sx: {
                            outline: hoveredTable === 'table-1' ? '2px dashed pink' : undefined,
                        },
                    }}
                    defaultColumn={{
                        size: 50,
                    }}
                    onDraggingRowChange={setDraggingRow}
                    enableRowActions={true}
                    renderRowActions={({ row }) => (
                      <Box>
                        <Button design='Positive'
                            icon='arrow-right'
                            iconEnd
                            disabled={!Boolean(props.permissionData?.admin)}
                            onClick={(e) => {
                                setData2((data2) => [...data2, row?.original]);
                                setData1((data1) => data1.filter((d) => d !== row?.original));
                                managerPermissionCreate(row);
                            }}
                        >
                            Назначить
                        </Button>
                      </Box>
                    )}
                />
            </SplitterElement>

            <SplitterElement>
                <Table
                    className={"ManagerPermissionsManagement-table"}
                    data={data2}
                    columns={fields}
                    enableRowDragging={Boolean(props.permissionData?.admin)}
                    draggingRow={draggingRow}
                    topToolbarCustomActions={
                        <FlexBox direction='Column'>
                            <Typography color="error.main" component="span" variant="h6">
                                Назначенные права доступа
                            </Typography>
                            <Button design='Negative'
                                disabled={(data2?.length === 0 ? true : false) || !Boolean(props.permissionData?.admin)}
                                onClick={e => {
                                    companyManagerDelete();
                                }}
                            >
                                Отозвать все (заблокировать)
                            </Button>
                        </FlexBox>
                    }
                    muiRowDragHandleProps={{
                        onDragEnd: () => {
                            if (hoveredTable === 'table-1') {
                                setData1((data1) => [...data1, draggingRow.original]);
                                setData2((data2) => data2.filter((d) => d !== draggingRow.original));
                                managerPermissionBlock();
                            }
                            setHoveredTable(null);
                        },
                    }}
                    muiTablePaperProps={{
                        onDragEnter: () => setHoveredTable('table-2'),
                        sx: {
                            outline: hoveredTable === 'table-2' ? '2px dashed pink' : undefined,
                        },
                    }}
                    onDraggingRowChange={setDraggingRow}
                    enableRowActions={true}
                    renderRowActions={({ row }) => (
                      <Box>
                        <Button design='Negative'
                            icon='arrow-left'
                            disabled={!Boolean(props.permissionData?.admin)}
                            onClick={(e) => {
                                setData1((data1) => [...data1, row.original]);
                                setData2((data2) => data2.filter((d) => d !== row.original));
                                managerPermissionBlock(row);
                            }}
                        >
                            Отозвать
                        </Button>
                      </Box>
                    )}
                />
            </SplitterElement>
        </SplitterLayout>
    );
}

export default ManagerPermissionsManagementTable