import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Stage, OrbitControls } from "@react-three/drei";

const ModelsScene = (props) => {
  return (
    <Canvas dpr={[1, 2]} camera={{ position: [3, 100, 3], fov: 50 }}>
      <Suspense fallback={null}>
        <Stage>{props.model}</Stage>
      </Suspense>
      <OrbitControls makeDefault dampingFactor={0.3} />
    </Canvas>
  );
};

export { ModelsScene };
