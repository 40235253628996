import { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import axios_instance from "../../../../../requests/config_defaults";
import Table from "../../../../base/table/Table";
import { FlexBox, SplitterLayout, SplitterElement } from "@ui5/webcomponents-react";
import "./AdminCatalogProductsSortingView.css";

const fields = {
  article: {
    key: "article",
    header: "Артикул",
    editable: false,
    size: 180
  },
  name: {
    key: "property_name",
    header: "Наименование",
    editable: false,
    size: 180
  },
  brand: {
    key: "brand",
    header: "Бренд",
    editable: false,
    size: 180
  },
  manufacture: {
    key: "manufacture",
    header: "Производитель",
    editable: false,
    size: 180
  },
  is_standard: {
    key: "is_standard",
    header: "Эталонный товар",
    editable: false,
    size: 180,
    Cell: ({ renderedCellValue, cell }) => (
      <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1rem',
              borderRadius: 20,
              padding: '5px',
              color: "#fff",
          }}
      >
          <span
              style={{
                  color: renderedCellValue === true ? "green" : "red"
              }}
          >
              {renderedCellValue === true ? "Да" : "Нет"}
          </span>
      </Box>
    ),
  }
};

const AdminCatalogProductsSortingView = (props) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [columnFilters, setColumnFilters] = useState([]);

  const [catalogProductsPage, setCatalogProductsPage] = useState(1);
  const [pageCatalogProductsPageCount, setCatalogProductsPageCount] = useState(1);
  const [catalogProductsColumnFilters, setCatalogProductsColumnFilters] = useState([]);

  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);

  const [draggingRow, setDraggingRow] = useState(null);
  const [hoveredTable, setHoveredTable] = useState(null);

  const getAllProductsExcludeCatalogProducts = async () => {
    await axios_instance
      .get(
        `/all_products/exclude/catalog_products/` +
          props?.activeCatalogID +
          `?page=${page}${`&filters=${JSON.stringify(columnFilters ?? [])}`}`
      )
      .then(({ data }) => {
        const data_columns = [];
        setPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        data?.results.map((product) => {
          data_columns.push({
            id: product.id,
            article: product.code,
            property_name: product.name?product.name:"Не указан",
            brand: product.brand?.name ? product.brand.name : "Не указан",
            manufacture: product.manufacture?.name ? product.manufacture.name : "Не указан",
            is_standard: product.is_standard,
          });
        });
        setData1(data_columns);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getCatalogProducts = async () => {
    await axios_instance
      .get(`catalog/products/` + props.activeCatalogID + `?page=${catalogProductsPage}${`&filters=${JSON.stringify(catalogProductsColumnFilters ?? [])}`}`)
      .then(({ data }) => {
        const data_columns = [];
        setCatalogProductsPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        data?.results.map((product) => {
          data_columns.push({
            id: product.id,
            article: product.code,
            property_name: product.name ? product.name:"Не указан",
            brand: product.brand?.name ? product.brand?.name : "Не указан",
            manufacture: product.manufacture?.name ? product.manufacture?.name : "Не указан",
            is_standard: product.is_standard,
            properties: product.properties,
            analogs: product.analogs,
          });
        });
        setData2(data_columns);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const catalogProductAdd = async (id) => {
    await axios_instance
      .put("/catalog/add/product/" + id, {
        product: draggingRow.original.id,
      })
      .then((response) => {
        props.getCatalog();
        props.getCatalogProducts()
      })
      .catch((error) => {});
  };

  const catalogProductDelete = async (id) => {
    await axios_instance
      .put("/catalog/delete/product/" + id, {
        product: draggingRow.original.id,
      })
      .then((response) => {
        props.getCatalog();
        props.getCatalogProducts()
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getCatalogProducts()
  }, [catalogProductsPage]);

  useEffect(() => {
    setCatalogProductsPage(1);
    getCatalogProducts();
  }, [catalogProductsColumnFilters])

  useEffect(() => {
    getAllProductsExcludeCatalogProducts();
  }, [page]);

  useEffect(() => {
    setPage(1);
    getAllProductsExcludeCatalogProducts();
  }, [columnFilters]);

  return (
    <>
      <SplitterLayout style={{ height: "100%" }}>
        <SplitterElement>
          <Table
            className={"AdminProductsSortingTable"}
            data={data1}
            columns={fields}
            enableRowDragging={true}
            enableColumnFilters
            enableColumnResizing
            onColumnFiltersChange={setColumnFilters}
            draggingRow={draggingRow}
            pagination={{
              page: page,
              setPage: setPage,
              pageCount: pageCount,
            }}
            topToolbarCustomActions={
              <FlexBox direction="Column">
                <Typography color="success.main" component="span" variant="h6">
                  Все товары
                </Typography>
              </FlexBox>
            }
            muiRowDragHandleProps={{
              onDragEnd: () => {
                if (hoveredTable === "table-2") {
                  setData2((data2) => [...data2, draggingRow.original]);
                  setData1((data1) =>
                    data1.filter((d) => d !== draggingRow.original)
                  );
                  catalogProductAdd(props?.activeCatalogID);
                }
                setHoveredTable(null);
              },
            }}
            muiTablePaperProps={{
              onDragEnter: () => setHoveredTable("table-1"),
              sx: {
                outline:
                  hoveredTable === "table-1" ? "2px dashed pink" : undefined,
              },
            }}
            onDraggingRowChange={setDraggingRow}
          />
        </SplitterElement>

        <SplitterElement>
          <Table
            className={"AdminProductsSortingTable"}
            data={data2}
            columns={fields}
            enableRowDragging={true}
            enableColumnFilters
            enableColumnResizing
            onColumnFiltersChange={setCatalogProductsColumnFilters}
            draggingRow={draggingRow}
            pagination={{
              page: catalogProductsPage,
              setPage: setCatalogProductsPage,
              pageCount: pageCatalogProductsPageCount,
            }}
            topToolbarCustomActions={
              <FlexBox direction="Column">
                <Typography color="error.main" component="span" variant="h6">
                  Товары в каталоге
                </Typography>
              </FlexBox>
            }
            muiRowDragHandleProps={{
              onDragEnd: () => {
                if (hoveredTable === "table-1") {
                  setData1((data1) => [...data1, draggingRow.original]);
                  setData2((data2) =>
                    data2.filter((d) => d !== draggingRow.original)
                  );
                  catalogProductDelete(props?.activeCatalogID);
                }
                setHoveredTable(null);
              },
            }}
            muiTablePaperProps={{
              onDragEnter: () => setHoveredTable("table-2"),
              sx: {
                outline:
                  hoveredTable === "table-2" ? "2px dashed pink" : undefined,
              },
            }}
            onDraggingRowChange={setDraggingRow}
          />
        </SplitterElement>
      </SplitterLayout>
    </>
  );
};

export default AdminCatalogProductsSortingView;
