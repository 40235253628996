import React, { useReducer, useState } from 'react'
import './../user-profile-main/UserProfileMain.css'
import './UserProfileMe.css'
import axios_instance from '../../../requests/config_defaults';
import { Avatar, Button, ButtonDesign, Card, CardHeader, Form, FormGroup, FormItem, Input, InputType, Link, Text, ThemeProvider, ObjectStatus, Icon, Label, FlexBox } from '@ui5/webcomponents-react';
import { isValidPhoneNumber } from 'react-phone-number-input';

const StandardField = ({ editMode, value, inputType = InputType.None, onInput, ...rest }) => {
    if (editMode) {
        return <Input value={value} style={{ width: '100%' }} type={inputType} onInput={onInput} {...rest} />;
    }
    if (inputType === InputType.URL || inputType === InputType.Email) {
        return (
            <Link href={inputType === InputType.Email ? `mailto:${value}` : value} target="_blank" {...rest}>
                {value}
            </Link>
        );
    }
    return <Text {...rest}>{value}</Text>;
};

const reducer = (state, { field, value }) => {
    return { ...state, [field]: value };
};

const UserProfileMe = (props) => {

    const [lastNameValueState, setLastNameValueState] = useState('');

    const [firstNameValueState, setFirstNameValueState] = useState('');

    const [middleNameValueState, setMiddleNameValueState] = useState('');

    const [phoneNumberValueState, setPhoneNumberValueState] = useState('');

    const [teleramNameValueState, setTeleramNameValueState] = useState('');

    const [prevUserData, setPrevUserData] = useState({
        last_name: props.user.last_name,
        first_name: props.user.first_name,
        middle_name: props.user.middle_name,
        phone_number: props.user.phone_number,
        telegram_name: props.user.telegram_name,
    });

    const [updatedUserData, setUpdatedUserData] = useState({
        last_name: props.user.last_name,
        first_name: props.user.first_name,
        middle_name: props?.user?.middle_name,
        phone_number: props.user.phone_number,
        telegram_name: props.user.telegram_name,
    });

    const [editMode, setEditMode] = useState(false);

    const updateMe = async () => {
        await axios_instance.patch(
            "/updateMe",
            updatedUserData
        )
            .then((response) => {
                props.getMe();
                setEditMode(false);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useState(() => {
        setPrevUserData({
            last_name: props.user.last_name,
            first_name: props.user.first_name,
            middle_name: props.user.middle_name,
            phone_number: props.user.phone_number,
            telegram_name: props.user.telegram_name,
        });
        setUpdatedUserData({
            last_name: props.user.last_name,
            first_name: props.user.first_name,
            middle_name: props.user.middle_name,
            phone_number: props.user.phone_number,
            telegram_name: props.user.telegram_name,
        })
    })

    const openInNewTab = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <>
            <ThemeProvider>
                <Card
                    header={
                        <CardHeader
                            avatar={
                                <Avatar
                                    colorScheme="Accent6"
                                    icon="employee"
                                    shape="Circle"
                                    size="S"
                                >
                                    <img
                                        src="https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg"
                                        alt=""
                                        style={{
                                            backgroundSize: "zoom"
                                        }}
                                    // className="profile-view-user-info-img"
                                    />
                                </Avatar>
                            }
                            status=""
                            subtitleText={props.user.email}
                            titleText={`${props.user.last_name} ${props.user.first_name}`} />
                    }
                    style={{
                        width: '100%',
                        height: '100%'
                    }}
                    className='profile-view-user-me'
                >
                    <Form
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={1}
                        labelSpanL={1}
                        labelSpanM={1}
                        labelSpanS={1}
                        labelSpanXL={1}
                        style={{
                            alignItems: 'left',
                            margin: 20
                        }}
                        // className='MyOfferCreateForm-form'
                        titleText="Личная информация"
                    >
                        <FormGroup>
                            <FormItem label="Фамилия">
                                {editMode ?
                                    <Input
                                        required
                                        name="last_name"
                                        valueState={lastNameValueState ? "Error" : props.user.last_name !== updatedUserData.last_name ? "Information" : ""}
                                        onInput={
                                            (e) => {
                                                setLastNameValueState("");
                                                setUpdatedUserData((prev) => ({
                                                    ...prev,
                                                    last_name: e.target.value.trim()
                                                }))
                                            }
                                        }
                                        valueStateMessage={<div>Изменено</div>}
                                        placeholder={prevUserData.last_name}
                                        value={updatedUserData.last_name ?? ""}
                                        style={{ width: '100%' }}
                                    /> :
                                    <Label>{props.user.last_name}</Label>}

                            </FormItem>
                            {lastNameValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {lastNameValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="Имя">
                                {editMode ?
                                    <Input
                                        required
                                        name="first_name"
                                        valueState={firstNameValueState ? "Error" : props.user.first_name !== updatedUserData.first_name ? "Information" : ""}
                                        onInput={
                                            (e) => {
                                                setFirstNameValueState("");
                                                setUpdatedUserData((prev) => ({
                                                    ...prev,
                                                    first_name: e.target.value.trim()
                                                }))
                                            }
                                        }
                                        valueStateMessage={<div>Изменено</div>}
                                        value={updatedUserData.first_name ?? ""}
                                        placeholder={prevUserData.first_name}
                                        style={{ width: '100%' }}
                                    /> :
                                    <Label>{props.user.first_name}</Label>}
                            </FormItem>
                            {firstNameValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {firstNameValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="Отчество">
                                {editMode ?
                                    <Input
                                        required
                                        name="middle_name"
                                        valueState={middleNameValueState ? "Error" : props.user.middle_name !== updatedUserData.middle_name && updatedUserData.middle_name !== "" ? "Information" : ""}
                                        onInput={
                                            (e) => {
                                                setMiddleNameValueState("");
                                                setUpdatedUserData((prev) => ({
                                                    ...prev,
                                                    middle_name: e.target.value.trim()
                                                }))
                                                console.log(updatedUserData.middle_name)
                                            }
                                        }
                                        valueStateMessage={<div>Изменено</div>}
                                        style={{ width: '100%' }}
                                        value={updatedUserData.middle_name ?? ""}
                                        placeholder={prevUserData.middle_name}
                                    /> :
                                    <Label>{props.user.middle_name}</Label>}
                            </FormItem>
                            {middleNameValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {middleNameValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="Телефон">
                                {editMode ?
                                    <Input
                                        required
                                        type='Tel'
                                        name="phone_number"
                                        valueState={phoneNumberValueState ? "Error" : props.user.phone_number !== updatedUserData.phone_number ? "Information" : ""}
                                        onInput={
                                            (e) => {
                                                console.log(e);
                                                setPhoneNumberValueState("");
                                                setUpdatedUserData((prev) => ({
                                                    ...prev,
                                                    phone_number: e.target.value.trim()
                                                }))
                                            }
                                        }
                                        valueStateMessage={<div>Изменено</div>}
                                        style={{ width: '100%' }}
                                        value={updatedUserData.phone_number ?? ""}
                                        placeholder={prevUserData.phone_number}
                                    /> :
                                    <Label>{props.user.phone_number}</Label>}
                            </FormItem>
                            {phoneNumberValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {phoneNumberValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="Telegram">
                                {editMode ?
                                    <Input
                                        required
                                        name="telegram_name"
                                        valueState={teleramNameValueState ? "Error" : props.user.telegram_name !== updatedUserData.telegram_name ? "Information" : ""}
                                        onInput={
                                            (e) => {
                                                console.log(e);
                                                setTeleramNameValueState("");
                                                setUpdatedUserData((prev) => ({
                                                    ...prev,
                                                    telegram_name: e.target.value.trim()
                                                }))
                                            }
                                        }
                                        valueStateMessage={<div>Изменено</div>}
                                        style={{ width: '100%' }}
                                        value={updatedUserData.telegram_name ?? ""}
                                        placeholder={prevUserData.telegram_name}
                                    /> :
                                    <FlexBox alignItems='Center' style={{ width: 'auto' }}>
                                        <Label>{props.user.telegram_name}</Label>
                                        {props.user.telegram_name ?
                                            <Button
                                                icon='paper-plane'
                                                design='Transparent'
                                                onClick={() => openInNewTab(`https://t.me/${props.user.telegram_name}`)}
                                            /> :
                                            <></>
                                        }
                                    </FlexBox>
                                }
                            </FormItem>
                            {teleramNameValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {teleramNameValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                        </FormGroup>
                        <FormItem alignItems={"left"} label="">
                            <Button
                                design='Emphasized'
                                style={{
                                    padding: "0.5rem 1rem",
                                    width: editMode ? "70%" : "100%"
                                }}
                                onClick={editMode ? (e) => {
                                    let validate_status = true;
                                    if (updatedUserData.last_name === "") {
                                        setLastNameValueState("Введите фамилию");
                                        validate_status = false;
                                    }
                                    if (updatedUserData.first_name === "") {
                                        setFirstNameValueState("Введите имя");
                                        validate_status = false;
                                    }
                                    if (updatedUserData.phone_number && !isValidPhoneNumber(updatedUserData.phone_number)) {
                                        setPhoneNumberValueState("Неверный формат телефона");
                                        validate_status = false;
                                    }
                                    if (validate_status) {
                                        updateMe();
                                    }
                                } : () => setEditMode(true)}
                            >
                                {editMode ? "Сохранить" : "Изменить"}
                            </Button>
                            {editMode &&
                                <Button
                                    style={{ width: "25%", marginLeft: "5%" }}
                                    onClick={() => {
                                        setEditMode(false);
                                        setFirstNameValueState("");
                                        setLastNameValueState("");
                                        setMiddleNameValueState("");
                                        setPhoneNumberValueState("");
                                        setTeleramNameValueState("");
                                    }}
                                >
                                    Отмена
                                </Button>
                            }
                        </FormItem>

                    </Form>
                    {/* <div className="user-profile-card-container">
                        <Form
                            {...props}
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <FormGroup titleText="Личная информация">
                                <FormItem label="Фамилия">
                                    <StandardField editMode={editMode} value={last_name} onInput={handleInput} data-last_name valueState={lastNameValueState ? "Error" : ""} />
                                </FormItem>
                                {lastNameValueState &&
                                    <FormItem>
                                        <ObjectStatus
                                            onClick={function Ta() { }}
                                            state="Error"
                                            icon={<Icon name="alert" />}
                                        >
                                            {lastNameValueState}
                                        </ObjectStatus>
                                    </FormItem>
                                }
                                <FormItem label="Имя">
                                    <StandardField editMode={editMode} value={first_name} onInput={handleInput} data-first_name valueState={firstNameValueState ? "Error" : ""} />
                                </FormItem>
                                {firstNameValueState &&
                                    <FormItem>
                                        <ObjectStatus
                                            onClick={function Ta() { }}
                                            state="Error"
                                            icon={<Icon name="alert" />}
                                        >
                                            {firstNameValueState}
                                        </ObjectStatus>
                                    </FormItem>
                                }
                                <FormItem label="Отчество">
                                    <StandardField editMode={editMode} value={middle_name} onInput={handleInput} data-middle_name />
                                </FormItem>
                                <FormItem label="Телефон">
                                    <StandardField editMode={editMode} value={phone_number} onInput={handleInput} data-phone_number valueState={phoneNumberValueState ? "Error" : ""} />
                                </FormItem>
                                {phoneNumberValueState &&
                                    <FormItem>
                                        <ObjectStatus
                                            onClick={function Ta() { }}
                                            state="Error"
                                            icon={<Icon name="alert" />}
                                        >
                                            {phoneNumberValueState}
                                        </ObjectStatus>
                                    </FormItem>
                                }
                                <FormItem label="Telegram">
                                    <StandardField editMode={editMode} value={telegram_name} onInput={handleInput} data-telegram_name />
                                </FormItem>
                            </FormGroup>
                        </Form>
                        {editMode ?
                            (<div className="profile-view-user-me-info-block-controllers">
                                <Button
                                    design={ButtonDesign.Emphasized}
                                    onClick={async () => {
                                        let validate_status = true;
                                        if (last_name === "") {
                                            setLastNameValueState("Введите фамилию");
                                            validate_status = false;
                                        }
                                        if (first_name === "") {
                                            setFirstNameValueState("Введите имя");
                                            validate_status = false;
                                        }
                                        if (phone_number !== "" && !isValidPhoneNumber(phone_number)) {
                                            setPhoneNumberValueState("Неверный формат телефона");
                                            validate_status = false;
                                        }
                                        if (validate_status) {
                                            setLastNameValueState();
                                            setFirstNameValueState();
                                            setPhoneNumberValueState();
                                            await updateMe();
                                            toggleEditMode(false);
                                        }
                                    }}>
                                    Сохранить
                                </Button>
                                <Button
                                    design={ButtonDesign.Transparent}
                                    onClick={async () => {
                                        handleCancel()
                                        toggleEditMode(false)
                                    }}>
                                    Отмена
                                </Button>
                            </div>) :
                            (<Button
                                design={ButtonDesign.Emphasized}
                                onClick={() => toggleEditMode(true)}>
                                Редактировать
                            </Button>)
                        }
                    </div> */}
                </Card>

                {/* <div className="profile-view-user-me">
                <div className="profile-view-user-me-content">
                    <div className="profile-view-user-me-info-block">
                        <span>E-mail: </span>
                        {user.email}
                    </div>

                    <div className="profile-view-user-me-info-block">
                        <span>Фамилия: </span>
                        {!editMode ? (user.last_name) : (
                            <input
                                type="text" className="profile-view-user-me-info-block-input"
                                value={data.last_name}
                                onChange={(e) => setData((prev) => ({ ...prev, last_name: e.target.value }))}
                            />
                        )}
                    </div>

                    <div className="profile-view-user-me-info-block">
                        <span>Имя: </span>
                        {!editMode ? (user.first_name) : (
                            <input
                                type="text" className="profile-view-user-me-info-block-input"
                                value={data.first_name}
                                onChange={(e) => setData((prev) => ({ ...prev, first_name: e.target.value }))}
                            />
                        )}
                    </div>

                    <div className="profile-view-user-me-info-block">
                        <span>Отчество: </span>
                        {!editMode ? (user.middle_name) : (
                            <input
                                type="text" className="profile-view-user-me-info-block-input"
                                value={data.middle_name}
                                onChange={(e) => setData((prev) => ({ ...prev, middle_name: e.target.value }))}
                            />
                        )}
                    </div>

                    {editMode ? (
                        <div className="profile-view-user-me-info-block-controllers">
                            <button
                                onClick={async () => {
                                    await updateMe();
                                    setEditMode(false)
                                }}
                                className='__save'>Сохранить</button>
                            <button
                                onClick={() => setEditMode(false)}
                                className='__cancel'
                            >Отмена</button>
                        </div>

                    ) : (
                        <div className="profile-view-user-me-info-block-controllers">
                            <button
                                onClick={() => setEditMode(true)}
                                className='__save'>Редактировать</button>
                        </div>
                    )}

                    <div className="profile-view-user-me-info-block-controllers">

                    </div>
                </div>
            </div> */}
            </ThemeProvider>
        </>
    )
}

export default UserProfileMe