import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { isAdminAtom } from './GlobalAtoms'
import axios_instance from '../requests/config_defaults'

const AdminOnly = ({ children }) => {
    const navigate = useNavigate()
    const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);

    const getMe = async () => {
        await axios_instance
            .get("/getMe")
            .then(({data}) => {
                setIsAdmin(data.is_admin || data.user.is_superuser);
                console.log(data.is_admin || data.user.is_superuser)
                if (!(data.is_admin || data.user.is_superuser)) {
                    navigate("/")
                }
            })
            .catch((error) => {
                navigate("/login")
            });
    };

    useEffect(() => {
        getMe()
    }, [])

    return children
}

export default AdminOnly