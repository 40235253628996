import { useState } from "react";
import axios_instance from "../../../../../requests/config_defaults";
import { Bar, Card, Form, FormGroup, FormItem, Input, Button } from "@ui5/webcomponents-react";
import "./AdminBrandCreateForm.css";

const AdminBrandCreateForm = (props) => {
  const [brandName, setBrandName] = useState("")
  const [brandNameError, setBrandNameError] = useState(false)

  const brandCreate = async () => {
    await axios_instance
      .post(`brand/create`, { name: brandName })
      .then(({ data }) => {
        setBrandName("")
        if (props.getBrands) {
          props.getBrands()
        }
        props.setBrandCreateFormOpen(false)
      })
      .catch((error) => {});
  };

  const brandCreateHandler = () => {
    if (brandName !== "") {
      brandCreate();
    } else {
      setBrandNameError(true);
    }
  };

  return (
    <div className="AdminBrandCreateForm-modal-container">
      <Form
        columnsL={1}
        columnsM={1}
        columnsS={1}
        columnsXL={1}
        labelSpanL={1}
        labelSpanM={1}
        labelSpanS={1}
        labelSpanXL={1}
        style={{
          alignItems: "left",
        }}
        titleText="Заполните форму"
      >
        <FormGroup>
          <FormItem>
            <Input
              style={{ width: "100%" }}
              placeholder="Наименование бренда"
              value={brandName}
              valueState={
                brandNameError ? "Error" : "None"
              }
              onChange={(e) =>
                setBrandName(e.target.value)
              }
              onInput={(e) => {
                setBrandName(e.target.value);
                setBrandNameError(false);
              }}
              valueStateMessage={<div>Обязательное поле!</div>}
            />
          </FormItem>
          <FormItem alignItems={"left"} label="">
            <Button
              design="Emphasized"
              style={{
                padding: "0.5rem 1rem",
                width: "100%",
              }}
              onClick={() => {
                brandCreateHandler();
              }}
            >
              Создать
            </Button>
          </FormItem>
        </FormGroup>
      </Form>
    </div>
  );
};

export default AdminBrandCreateForm;
