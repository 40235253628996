import React, { useEffect, useState } from 'react'
import { useSearchParams } from "react-router-dom";
import { FlexBox, ThemeProvider, Loader, MultiComboBox, MultiComboBoxItem, Form, FormGroup, FormItem, Input, Title, ObjectStatus, Icon, Button, Text, DatePicker, Option, Select } from '@ui5/webcomponents-react';
import { RegistrationFooter } from './registration-footer/RegistrationFooter';
import axios_instance from '../../requests/config_defaults';
import RegStep1 from './reg-step-1/RegStep1';
import RegStep2 from './reg-step-2/RegStep2';
import RegStep3 from './reg-step-3/RegStep3';
import RegStep4 from './reg-step-4/RegStep4';
import RegStep5 from './reg-step-5/RegStep5';
import RegStepFinal from './reg-step-final/RegStepFinal';
import RegStep6 from './reg-step-6/RegStep6';
import RegStepSendCode from './reg-step-send-code/RegStepSendCode';


const Registration = (props) => {
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [step, setStep] = useState(1);

    const [isLoading, setIsLoading] = useState(false);

    const [registrationData, setRegistrationData] = useState({
        last_name: "",
        first_name: "",
        middle_name: "",
        email: searchParams.get("email") ?? "",
        password: ""
    });

    const [entityData, setEntityData] = useState({
        tax_number: "",
        name: ""
    });

    const [permissionRequest, setPermissionRequest] = useState({
        permission: searchParams.get("permission"),
        entity: searchParams.get("entity")
    });


    return (
        <ThemeProvider>
            <FlexBox
                alignItems="Center"
                direction="Row"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <FlexBox
                    direction="Column"
                    justifyContent="Center"
                    wrap="NoWrap"
                    style={{ height: '100%' }}
                >
                    {isLoading && <Loader />}
                    <FlexBox
                        direction="Column"
                        justifyContent="Center"
                        wrap="NoWrap"
                        style={{
                            border: '1px solid #bbb',
                            borderRadius: 5,
                            padding: '5% 10% 10% 10%',
                            width: 450,
                            opacity: isLoading ? 0.6 : 1
                        }}
                    >
                        {step === 1 &&
                            <RegStep1 onCancel={props.onCancel} setStep={setStep} registrationData={registrationData} setRegistrationData={setRegistrationData} setIsLoading={setIsLoading} />
                        }
                        {step === 2 &&
                            <RegStep2 setStep={setStep} registrationData={registrationData} setRegistrationData={setRegistrationData} />
                        }
                        {step === 3 &&
                            <RegStep3 setStep={setStep} registrationData={registrationData} setRegistrationData={setRegistrationData} setIsLoading={setIsLoading} />
                        }
                        {step === 4 &&
                            <RegStep4 setStep={setStep} registrationData={registrationData} setRegistrationData={setRegistrationData} isLoading={isLoading} setIsLoading={setIsLoading} permissionRequest={permissionRequest} />
                        }
                        {step === 5 &&
                            <RegStep5 setStep={setStep} entityData={entityData} setEntityData={setEntityData} setIsLoading={setIsLoading} registrationData={registrationData} />
                        }
                        {step === 6 &&
                            <RegStep6 setStep={setStep} entityData={entityData} setEntityData={setEntityData} setIsLoading={setIsLoading} registrationData={registrationData} />
                        }
                        {step === 7 &&
                            <RegStepFinal titleDescription="Уведомление о потдверждении введенных данных придет вам на почтe" />
                        }
                        {step === 8 &&
                            <RegStepFinal titleDescription="Уведомление о потдверждении аккаунта придет вам на почту" />
                        }
                        {step === 9 &&
                            <RegStepSendCode setStep={setStep} registrationData={registrationData} setIsLoading={setIsLoading} />
                        }
                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </ThemeProvider>
    )
}

export default Registration;
