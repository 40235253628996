import {
  Bar,
  Button,
  CustomListItem,
  FlexBox,
  GroupHeaderListItem,
  Input,
  List,
  IllustratedMessage,
  MessageBox,
  Card,
  Form,
  FormGroup,
  FormItem,
  SplitterLayout,
  SplitterElement,
  StandardListItem,
  ThemeProvider,
  Toolbar,
  ToolbarDesign,
  Toast,
} from "@ui5/webcomponents-react";
import React, { useEffect, useState, useRef } from "react";
import axios_instance from "../../../requests/config_defaults";
import ProductsAdminDetailView from "./products-admin-detail-view/ProductsAdminDetailView";
import ProductsAdminPropertyAddForm from "./products-admin-detail-view/products-admin-update-view/ProductsAdminPropertyAddForm";
import AdminBrandCreateForm from "./admin-catalog-view/admin-brand-create-form/AdminBrandCreateForm";
import AdminManufactureCreateForm from "./admin-catalog-view/admin-manufacture-create-form/AdminManufactureCreateForm";
import "./ProductsAdminView.css";
import AdminCatalogView from "./admin-catalog-view/AdminCatalogView";
import ModalWindow from "../../base/modal/ModalWindow";

const ProductsAdminView = () => {
  const [openAddPropertiesForm, setOpenAddPropertiesForm] = useState(false);
  const [brandCreateFormOpen, setBrandCreateFormOpen] = useState(false);
  const [manufactureCreateFormOpen, setManufactureCreateFormOpen] = useState(false);
  
  const [query, setQuery] = useState("");

  const [catalogs, setCatalogs] = useState([]);
  const [activeCatalog, setActiveCatalog] = useState({});
  const [deleteCatalog, setDeleteCatalog] = useState("");

  const [activeProduct, setActiveProduct] = useState({});

  const [catalogCreateOpen, setCatalogCreateOpen] = useState(false);
  const [catalogUpdateOpen, setCatalogUpdateOpen] = useState(false);
  const [catalogDeleteOpen, setCatalogDeleteOpen] = useState(false);
  const [catalogCreateName, setCatalogCreateName] = useState("");
  const [catalogCreateNameError, setCatalogCreateNameError] = useState(false);
  const [duplicateNameError, setDuplicateNameError] = useState(false)

  const [productsWithoutProviderOpen, setProductsWithoutProviderOpen] = useState(false);

  const [allProductsOpen, setAllProductsOpen] = useState(false);
  const toastSuccess = useRef(null);
  const toastError = useRef(null);
  const showToastSuccess = () => {
    toastSuccess.current.show();
  };
  const showToastError = () => {
    toastError.current.show();
  };
  const [imageDeleteOpen, setImageDeleteOpen] = useState(false);
  const [activeImageID, setActiveImageID] = useState({});
  const [imageDeleted, setImageDeleted] = useState(false)

  const imageDelete = async () => {
    await axios_instance
      .delete(`image/unbind/` + activeProduct.id + `/` + activeImageID)
      .then(({ data }) => {
        setImageDeleted(true)
      })
      .catch((error) => {
        setImageDeleted(true)
      });
  };

  const getCatalogs = async () => {
    await axios_instance
      .get(`catalogs?query=${query}`)
      .then(({ data }) => {
        setCatalogs(data);
      })
      .catch((error) => {});
  };

  const catalogCreate = async () => {
    await axios_instance
      .post(`catalog/create`, { name: catalogCreateName })
      .then(({ data }) => {
        setCatalogCreateName("");
        setCatalogCreateOpen(false);
        setQuery("");
        getCatalogs();
      })
      .catch((error) => setDuplicateNameError(true));
  };

  const catalogUpdate = async (id) => {
    await axios_instance
      .put(`catalog/update/` + id, { name: catalogCreateName })
      .then(({ data }) => {
        setCatalogCreateName("");
        setCatalogUpdateOpen(false);
        setQuery("");
        getCatalogs();
      })
      .catch((error) => {
        setDuplicateNameError(true)
      });
  };

  const catalogUpdateHandler = () => {
    if (catalogCreateName !== "") {
      catalogUpdate(activeCatalog?.id);
    } else {
      setCatalogCreateNameError(true);
    }
  };

  const catalogCreateHandler = () => {
    if (catalogCreateName !== "") {
      catalogCreate();
    } else {
      setCatalogCreateNameError(true);
    }
  };

  const catalogDelete = async (id) => {
    await axios_instance
      .delete(`catalog/delete/` + id)
      .then(({ data }) => {
        setQuery("");
        getCatalogs();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getCatalogs();
  }, [query]);

  return (
    <ThemeProvider>
      <SplitterLayout 
        style={{
          height: "100%",
          width: "100%"
        }}
      >
        <SplitterElement>
          <div style={{ 
            justifyContent: "start",
            width: "100%",
            overflow: "scroll"
            }}
          >
            <Toolbar design={ToolbarDesign.Solid} toolbarStyle="Standard">
              <Input
                placeholder="Поиск..."
                onChange={(e) => setQuery(e.target.value)}
                onInput={(e) => setQuery(e.target.value)}
                value={query}
              />
            </Toolbar>
            <List
              growing="None"
              headerText="Каталоги"
              mode="None"
              separators="All"
            >
              <GroupHeaderListItem>Системные</GroupHeaderListItem>
              <StandardListItem
                onClick={() => {
                  setActiveCatalog({});
                  setProductsWithoutProviderOpen(false);
                  setAllProductsOpen(true);
                }}
              >
                Все товары
              </StandardListItem>
              <StandardListItem
                onClick={() => {
                  setProductsWithoutProviderOpen(true);
                  setAllProductsOpen(false);
                  setActiveCatalog({});
                }}
              >
                Товары без поставщиков
              </StandardListItem>
              {catalogs.filter(catalog => catalog.name === "Карусель-товаров").map(catalog => (
                <StandardListItem
                  onClick={() => {
                    setProductsWithoutProviderOpen(false);
                    setAllProductsOpen(false);
                    setActiveCatalog({
                      id: catalog.id,
                      name: catalog.name,
                      count: catalog.count,
                    });
                  }}
                  data-id={catalog?.id}
                >
                  <FlexBox
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {catalog.name}
                  </FlexBox>
                </StandardListItem>
              ))}
              <GroupHeaderListItem>
                Настроенные {query ? `(Поиск: ${query})` : ""}
              </GroupHeaderListItem>
              {catalogs.filter(catalog => catalog.name !== "Карусель-товаров").map((catalog) => (
                <StandardListItem
                  onClick={() => {
                    setProductsWithoutProviderOpen(false);
                    setAllProductsOpen(false);
                    setActiveCatalog({
                      id: catalog.id,
                      name: catalog.name,
                      count: catalog.count,
                    });
                  }}
                  data-id={catalog?.id}
                >
                  <FlexBox
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    {catalog.name}
                    <div>
                      <Button
                        onClick={() => setCatalogUpdateOpen(true)}
                        icon="edit"
                      ></Button>
                      <Button
                        onClick={() => {
                          setCatalogDeleteOpen(true);
                          setDeleteCatalog(catalog.id)
                        }}
                        icon="delete"
                      ></Button>
                    </div>
                  </FlexBox>
                </StandardListItem>
              ))}
              {catalogUpdateOpen ? (
                <>
                  <div
                    className="AdminCatalogUpadate-overlay"
                    id="AdminCatalogUpadate-overlay"
                  ></div>
                  <Card
                    header={
                      <Bar
                        endContent={
                          <div
                            onClick={() => {
                              setCatalogUpdateOpen(false);
                              setCatalogCreateNameError(false);
                              setCatalogCreateName("");
                            }}
                            className="AdminCatalogUpdate-modal-close-btn"
                          ></div>
                        }
                        startContent={<span>Редактирование каталога</span>}
                        style={{
                          height: "50px",
                        }}
                      ></Bar>
                    }
                    className="AdminCatalogUpadate-form-layout AdminCatalogUpadate-form-layout-open"
                  >
                    <div className="AdminCatalogUpadate-modal-container">
                      <Form
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={1}
                        labelSpanL={1}
                        labelSpanM={1}
                        labelSpanS={1}
                        labelSpanXL={1}
                        style={{
                          alignItems: "left",
                        }}
                        titleText="Заполните форму"
                      >
                        <FormGroup>
                          <FormItem>
                            <Input
                              style={{ width: "100%" }}
                              placeholder={activeCatalog.name}
                              value={activeCatalog.name}
                              valueState={
                                catalogCreateNameError || duplicateNameError ? "Error" : "None"
                              }
                              onChange={(e) =>
                                setCatalogCreateName(e.target.value)
                              }
                              onInput={(e) => {
                                setCatalogCreateName(e.target.value);
                                setCatalogCreateNameError(false);
                                setDuplicateNameError(false)
                              }}
                              valueStateMessage={
                                duplicateNameError ? <div>Каталог с таким наименованием уже существует!</div> : <div>Обязательное поле!</div>
                              }
                            />
                          </FormItem>
                        </FormGroup>
                        <FormItem alignItems={"left"} label="">
                          <Button
                            design="Emphasized"
                            style={{
                              padding: "0.5rem 1rem",
                              width: "100%",
                            }}
                            onClick={() => {
                              catalogUpdateHandler();
                            }}
                          >
                            Изменить
                          </Button>
                        </FormItem>
                      </Form>
                    </div>
                  </Card>
                </>
              ) : null}
              <MessageBox
                open={catalogDeleteOpen}
                type="Confirm"
                onClose={() => {
                  setCatalogDeleteOpen(false);
                }}
                actions={[
                  <Button
                    design="Emphasized"
                    onClick={() => {
                      catalogDelete(deleteCatalog);
                      setCatalogDeleteOpen(false);
                      setActiveCatalog({})
                    }}
                  >
                    Удалить
                  </Button>,
                  <Button
                    onClick={() => {
                      setCatalogDeleteOpen(false);
                    }}
                  >
                    Отмена
                  </Button>,
                ]}
              >
                Нажмите "Удалить", чтобы подтвердить удаление.
              </MessageBox>
              <GroupHeaderListItem>Добавление</GroupHeaderListItem>
              {!catalogCreateOpen ? (
                <StandardListItem
                  onClick={() => setCatalogCreateOpen(true)}
                  icon="add"
                >
                  Создать каталог
                </StandardListItem>
              ) : (
                <CustomListItem>
                  <FlexBox
                    alignItems="Center"
                    style={{
                      gap: "10px",
                    }}
                  >
                    <Input
                      placeholder="Наименование"
                      value={catalogCreateName}
                      valueState={catalogCreateNameError || duplicateNameError ? "Error" : "None"}
                      valueStateMessage={
                        duplicateNameError ? <div>Каталог с таким наименованием уже существует!</div> : <div>Обязательное поле!</div>
                      }
                      onChange={(e) => setCatalogCreateName(e.target.value)}
                      onInput={(e) => {
                        setCatalogCreateName(e.target.value);
                        setDuplicateNameError(false);
                        setCatalogCreateNameError(false)
                      }}
                    />
                    <Button onClick={() => catalogCreateHandler()}>
                      Создать
                    </Button>
                    <Button
                      design="Negative"
                      onClick={() => {
                        setCatalogCreateName("");
                        setCatalogCreateNameError(false);
                        setCatalogCreateOpen(false);
                      }}
                    >
                      Отмена
                    </Button>
                  </FlexBox>
                </CustomListItem>
              )}
            </List>
          </div>
        </SplitterElement>

        <SplitterElement>
          {allProductsOpen || productsWithoutProviderOpen || activeCatalog.id ? (
            <div style={{ width: "100%", height: "100%"}}>
              <AdminCatalogView
                activeCatalogID={activeCatalog?.id}
                setActiveCatalog={setActiveCatalog}
                activeProductID={activeProduct?.id}
                setActiveProduct={setActiveProduct}
                allProductsOpen={allProductsOpen}
                setAllProductsOpen={setAllProductsOpen}
                productsWithoutProviderOpen={productsWithoutProviderOpen}
                setProductsWithoutProviderOpen={setProductsWithoutProviderOpen}
                catalogs={catalogs}
              />
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              <IllustratedMessage
                titleText='Выберите каталог'
                subtitleText=' '
              />
            </div>
          )}
        </SplitterElement>

        <SplitterElement style={{ justifyContent: "end" }}>
          {brandCreateFormOpen ? (
            <ModalWindow
              className="brand-manufacture-create-form-modal"
              state={brandCreateFormOpen}
              setState={setBrandCreateFormOpen}
              title={"Создание бренда"}
              children={
                <AdminBrandCreateForm setBrandCreateFormOpen={setBrandCreateFormOpen}/>
              }
            />
          ) : (
            null
          )}

          {manufactureCreateFormOpen ? (
            <ModalWindow
              className="brand-manufacture-create-form-modal"
              state={manufactureCreateFormOpen}
              setState={setManufactureCreateFormOpen}
              title={"Создание производителя"}
              children={
                <AdminManufactureCreateForm setManufactureCreateFormOpen={setManufactureCreateFormOpen}/>
              }
            />
          ) : (
            null
          )}

          {openAddPropertiesForm ? (
            <ModalWindow
              className="openAddPropertiesFormModal"
              state={openAddPropertiesForm}
              setState={setOpenAddPropertiesForm}
              title={"Добавление характеристики"}
              children={
                <ProductsAdminPropertyAddForm
                  activeProductID={activeProduct?.id}
                  setOpenAddPropertiesForm={setOpenAddPropertiesForm}
                />
              }
            />
          ) : (null)}
          
          {activeProduct.id ? (
            <div style={{ height: "100%", width: "100%" }}>
              <ProductsAdminDetailView
                openAddPropertiesForm={openAddPropertiesForm}
                setOpenAddPropertiesForm={setOpenAddPropertiesForm}

                brandCreateFormOpen={brandCreateFormOpen}
                setBrandCreateFormOpen={setBrandCreateFormOpen}

                manufactureCreateFormOpen={manufactureCreateFormOpen}
                setManufactureCreateFormOpen={setManufactureCreateFormOpen}
                
                activeProductID={activeProduct?.id}
                activeProduct={activeProduct}
                setActiveProduct={setActiveProduct}
                activeCatalog={activeCatalog}
                getCatalogs={getCatalogs}
                showToastSuccess={showToastSuccess}
                showToastError={showToastError}
                setImageDeleteOpen={setImageDeleteOpen}
                setImageDeleted={setImageDeleted}
                setActiveImageID={setActiveImageID}
                imageDeleted={imageDeleted}
              />
              <Toast 
                style={{ color: "green" }}
                duration={2000}
                ref={toastSuccess}
              >
                Успешно сохранено
              </Toast>
              <Toast
                style={{ color: "red" }}
                duration={2000}
                ref={toastError}
              >
                Ошибка сохранения
              </Toast>
              {imageDeleteOpen ? (
                <>
                  <div
                    className="AdminCatalogUpadate-overlay"
                    id="AdminCatalogUpadate-overlay"
                  ></div>
                  <MessageBox
                    open={true}
                    type="Confirm"
                    onClose={() => {
                      setImageDeleteOpen(false);
                      setActiveImageID("")
                    }}
                    actions={[
                      <Button
                        design="Emphasized"
                        onClick={() => {
                          imageDelete();
                          setImageDeleteOpen(false);
                          setActiveImageID("")
                        }}
                      >
                        Удалить
                      </Button>,
                      <Button
                        onClick={() => {
                          setImageDeleteOpen(false);
                          setActiveImageID("")
                        }}
                      >
                        Отмена
                      </Button>,
                    ]}
                  >
                    Нажмите "Удалить", чтобы подтвердить удаление.
                  </MessageBox>
                </>
              ) : null}
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              <IllustratedMessage
                titleText='Выберите товар'
                subtitleText=' '
              />
            </div>
          )}
        </SplitterElement>

      </SplitterLayout>
    </ThemeProvider>
  );
};

export default ProductsAdminView;
