import React from 'react'
import './UserProfileSideMenu.css'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

const UserProfileSideMenuItem = (props) => {
  const nav = useNavigate()
  return (
    <div
      onClick={() => props.setActiveChapter(props.chapter)}
      className={classNames(
        "profile-menu-item-content",
        props.chapter === props.activeChapter ? "profile-menu-item-content-active" : ""
      )}
    >
      <div className="profile-menu-item-container">
        <img src={`/static/${props.icon}`} alt="" />
        {props.label}
      </div>
    </div>
  )
}

const UserProfileSideMenu = (props) => {
  const nav = useNavigate()

  return (
    <div className='profile-view-menu'>
      {/*<UserProfileSideMenuItem*/}
      {/*  icon={"house_icon.svg"}*/}
      {/*  label={"Главная"}*/}
      {/*  chapter={"main"}*/}
      {/*  activeChapter={props.activeChapter}*/}
      {/*  setActiveChapter={props.setActiveChapter}*/}
      {/*/>*/}
      <UserProfileSideMenuItem
        icon={"person_icon.svg"}
        label={"Профиль"}
        chapter={"main"}
        activeChapter={props.activeChapter}
        setActiveChapter={props.setActiveChapter}
      />
      {/* <UserProfileSideMenuItem
        icon={"settings_icon.svg"}
        label={"Настройки"}
        chapter={"settings"}
        activeChapter={props.activeChapter}
        setActiveChapter={props.setActiveChapter}
      /> */}
      {/* <UserProfileSideMenuItem
        icon={"alarm_icon.svg"}
        label={"Уведомления"}
        chapter={"notofications"}
        activeChapter={props.activeChapter}
        setActiveChapter={props.setActiveChapter}
      /> */}
      {/* <UserProfileSideMenuItem
        icon={"magnetic_card_icon.svg"}
        label={"Платежи"}
        chapter={"billing"}
        activeChapter={props.activeChapter}
        setActiveChapter={props.setActiveChapter}
      /> */}
      <div onClick={(e) => {
        nav("/legal-entities");
      }}>
        <UserProfileSideMenuItem
          icon={"companies_icon.svg"}
          label={"Моя компания"}
          chapter={"company"}
          activeChapter={props.activeChapter}
          setActiveChapter={props.setActiveChapter}
        />
      </div>
    </div>
  )
}

export default UserProfileSideMenu