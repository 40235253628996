import React, { useEffect, useMemo, useState } from "react";
import {
  Paper,
  TableContainer,
  Box,
  Typography,
  MenuItem,
  Tooltip,
  IconButton,
  ListItemIcon,
} from "@mui/material";
import { lime, purple, cyan, grey } from "@mui/material/colors";
import axios_instance from "../../../requests/config_defaults";
import Table from "../../base/table/Table.jsx";
import "./IncommingRequestsAllView.css";

import { CheckCircleRounded, ClearRounded } from "@mui/icons-material";
import { useAtom } from "jotai";
import { activeCompanyAtom } from "../../../hoc/GlobalAtoms";
import {
  Badge,
  Button,
  FlexBox,
  Input,
  Label,
  StepInput,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/sys-cancel.js";
import IncomingRequestPvzChooseModal from "./IncomingRequestPvzChooseModal.jsx";
import OrderManagerStepInput from "../../orders-view/order-project-info-side/order-manager-view/OrderManagerStepInput.jsx";

const fields = {
  count_to_confirm: {
    key: "count_to_confirm",
    header: "Продать",
    editable: false,
  },
  pvz_name: {
    key: "pvz_name",
    header: "ПВЗ",
    editable: false,
  },
  product_code: {
    key: "product_code",
    header: "Артикул",
    editable: false,
  },
  product_full_name: {
    key: "product_full_name",
    header: "Полное наименование товара в каталоге",
    editable: false,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    editable: false,
  },
  manufactor: {
    key: "manufactor",
    header: "Прозводитель",
    editable: false,
  },
  current_rest: {
    key: "current_rest",
    header: "Моё количество на складe",
    editable: false,
  },
  needful_rest: {
    key: "needful_rest",
    header: "Моя потребность",
    editable: false,
  },
  for_sale_count: {
    key: "for_sale_count",
    header: "На продажу",
    editable: false,
  },
  requested_count: {
    key: "requested_count",
    header: "Запрошено",
    editable: false,
  },
  sale_price: {
    key: "sale_price",
    header: "Цена продажи",
    editable: false,
  },
  warehouse: {
    key: "warehouse",
    header: "Склад",
    editable: false,
  },
};

const fields_admin = {
  supplier: {
    key: "supplier",
    header: "Поставщик",
    editable: false,
  },
  contractor: {
    key: "contractor",
    header: "Покупатель",
    editable: false,
  },
  order_project_id: {
    key: "order_project_id",
    header: "Идентификатор проекта заказа",
    editable: false,
  },
  order_project_name: {
    key: "order_project_name",
    header: "Наименование проекта заказа",
    editable: false,
  },
  count_to_confirm: {
    key: "count_to_confirm",
    header: "Продать",
    editable: false,
  },
  pvz_name: {
    key: "pvz_name",
    header: "ПВЗ",
    editable: false,
  },
  product_code: {
    key: "product_code",
    header: "Артикул",
    editable: false,
  },
  product_full_name: {
    key: "product_full_name",
    header: "Полное наименование товара в каталоге",
    editable: false,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    editable: false,
  },
  manufactor: {
    key: "manufactor",
    header: "Прозводитель",
    editable: false,
  },
  current_rest: {
    key: "current_rest",
    header: "Моё количество на складe",
    editable: false,
  },
  needful_rest: {
    key: "needful_rest",
    header: "Моя потребность",
    editable: false,
  },
  for_sale_count: {
    key: "for_sale_count",
    header: "На продажу",
    editable: false,
  },
  requested_count: {
    key: "requested_count",
    header: "Запрошено",
    editable: false,
  },
  sale_price: {
    key: "sale_price",
    header: "Цена продажи",
    editable: false,
  },
  warehouse: {
    key: "warehouse",
    header: "Склад",
    editable: false,
  },
};

const premoderation_fields_admin = {
  supplier: {
    key: "supplier",
    header: "Поставщик",
    editable: false,
  },
  contractor: {
    key: "contractor",
    header: "Покупатель",
    editable: false,
  },
  order_project_id: {
    key: "order_project_id",
    header: "Идентификатор проекта заказа",
    editable: false,
  },
  order_project_name: {
    key: "order_project_name",
    header: "Наименование проекта заказа",
    editable: false,
  },
  sale_price: {
    key: "sale_price",
    header: "Цена продажи",
    editable: false,
  },
  delivery_cost: {
    key: "delivery_cost",
    header: "Стоимость доставки",
    editable: false,
  },
  price_with_delivery_price: {
    key: "price_with_delivery_price",
    header: "Цена с доставкой",
    editable: false,
  },
  end_price_with_delivery_price: {
    key: "end_price_with_delivery_price",
    header: "Итоговая цена с доставкой",
    editable: false,
  },
  delivery_period: {
    key: "delivery_period",
    header: "Сроки доставки",
    editable: false,
  },
  product_code: {
    key: "product_code",
    header: "Артикул",
    editable: false,
  },
  product_full_name: {
    key: "product_full_name",
    header: "Полное наименование товара в каталоге",
    editable: false,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    editable: false,
  },
  manufactor: {
    key: "manufactor",
    header: "Прозводитель",
    editable: false,
  },
  current_rest: {
    key: "current_rest",
    header: "Моё количество на складe",
    editable: false,
  },
  needful_rest: {
    key: "needful_rest",
    header: "Моя потребность",
    editable: false,
  },
  for_sale_count: {
    key: "for_sale_count",
    header: "На продажу",
    editable: false,
  },
  requested_count: {
    key: "requested_count",
    header: "Запрошено",
    editable: false,
  },
  warehouse: {
    key: "warehouse",
    header: "Склад",
    editable: false,
  },
};

const IncommingRequestsAllView = (props) => {
  const [salesData, setSalseData] = useState([]);
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pvzGeoObject, setPvzGeoObject] = useState(null);
  const [pvzAggegator, setPvzAggregator] = useState(null);
  const [pvzRow, setPvzRow] = useState(null);
  const [activeComapny] = useAtom(activeCompanyAtom);
  const pvz_name = ({ renderedCellValue, row }) => (
    <Button
      onClick={() => {
        if (!renderedCellValue) {
          setPvzGeoObject(row.original.warehouse_geo_object);
          setPvzAggregator(row.original.aggregator);
          setPvzRow(row);
          setPvzModalOpen(true);
        } else {
          salesData[row.index] = {
            ...salesData[row.index],
            pvz: null,
            pvz_name: null,
          };
          setSalseData([...salesData]);
        }
      }}
      disabled={row.original?.warehouse_pvz_mode}
      icon={renderedCellValue ? "sys-cancel" : ""}
      design={!renderedCellValue ? "None" : "Information"}
      style={{
        wordBreak: "break-word",
        whiteSpace: "wrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "inline-block",
      }}
    >
      {renderedCellValue || "Выбрать"}
    </Button>
  );

  const count_to_confirm = ({ cell, renderedCellValue }) => (
    <>
      <FlexBox
        direction="row"
        justifyContent="Center"
        alignItems="Center"
        style={{ width: "100%", gap: "10px" }}
      >
        <Input
          value={cell.getValue()}
          style={{ width: "100%" }}
          onChange={(e) => {
            let value = parseFloat(e.target.value);
            if (value > cell.row.original.requested_count) {
              value = cell.row.original.requested_count;
            } else if (value < 0 || !value || value === NaN) {
              value = 0;
            }
            salesData[cell.row.index].count_to_confirm = value;
            e.target.value = value;
            setSalseData([...salesData]);
          }}
          type="Number"
        />
        <Label> из {cell.row.original.requested_count}</Label>
      </FlexBox>
    </>
  );

  fields.pvz_name.Cell = pvz_name;
  fields.count_to_confirm.Cell = count_to_confirm;
  fields_admin.pvz_name.Cell = pvz_name;
  fields_admin.count_to_confirm.Cell = count_to_confirm;

  const filteredData = useMemo(() => salesData, [salesData]);

  const [pvzModalOpen, setPvzModalOpen] = useState(false);

  const getManufactor = (product) => {
    var name = "";
    product?.properties.map((property) => {
      if (property.name === "Основной поставщик") {
        name = property.value.content;
      }
    });
    return name;
  };

  const getName = (product) => {
    var name = "";
    product?.properties.map((property) => {
      if (property.name === "Наименование") {
        name = property.value.content;
      }
    });
    return name;
  };

  const setPvz = (row, pvz, pvz_name) => {
    salesData[row.index] = {
      ...salesData[row.index],
      pvz: pvz,
      pvz_name: pvz_name,
    };
    setSalseData([...salesData]);
  };

  const getData = async () => {
    setLoading(true);
    await axios_instance
      .post(`/sales/filter?page=${page}`, {})
      .then(({ data }) => {
        console.log(data);
        const catalog = [];
        setMaxPage(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        data?.results.map((req) => {
          catalog.push({
            id: req.id,
            count_to_confirm: req?.count,
            pvz: null,
            pvz_name: null,
            product_code: req?.stock?.product?.code,
            brand: req?.stock?.product?.brand?.name || "Не указан",
            manufactor: req?.stock?.product?.manufacture?.name || "Не указан",
            product_full_name: req?.stock?.product.name,
            property_manufactor: getManufactor(req?.stock?.product),
            current_rest: req?.stock?.current_rest,
            needful_rest: req?.stock?.needful_rest,
            for_sale_count: req?.stock?.for_sale_count,
            requested_count: req?.count,
            sale_price: req?.stock?.sale_price,
            delivery_cost: req?.delivery_cost,
            price_with_delivery_price: req?.price_with_delivery_price,
            end_price_with_delivery_price: (
              <OrderManagerStepInput
                is_selected={true}
                order_item_id={req?.related_position.id}
                delivery_cost={req?.delivery_cost}
                price_with_delivery_price={
                  req?.related_position.total_cost
                  ? req?.related_position.total_cost
                  : req?.price_with_delivery_price
                }
              />
            ),
            delivery_period: req?.delivery_period,
            warehouse: req?.stock?.warehouse?.name,
            warehouse_geo_object: req?.stock?.warehouse?.geo_object,
            warehouse_pvz_mode: req?.stock?.warehouse?.is_pvz,
            aggregator: req?.aggregator,
            supplier: req?.supplier?.entity?.name,
            contractor: req?.contractor?.entity?.name,
            order_project_id: req?.order_project?.id,
            order_project_name: req?.order_project?.name,
          });
        });
        setSalseData(catalog);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const confirmSale = async (row) => {
    let data = {
      count: row.original.count_to_confirm,
      pvz: row.original.pvz,
    };
    await axios_instance
      .post(`/sales/confirm/${row?.original?.id}`, data)
      .then(({ data }) => {
        getData();
      })
      .catch((error) => {});
  };

  const refuseSale = async (row) => {
    let data = {
      count: 0,
    };
    await axios_instance
      .post(`/sales/confirm/${row?.original?.id}`, data)
      .then(({ data }) => {
        getData();
      })
      .catch((error) => {});
  };

  const handleSaveCell = (cell, value) => {
    salesData[cell.row.index][cell.column.id] = value;
    setSalseData([...salesData]);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [page, activeComapny, props.trigger]);

  return (
    <>
      <Table
        className={"IncommingRequestsAllView-table"}
        columns={
          props.isAdmin || props.premoderation
            ? props.premoderation
              ? premoderation_fields_admin
              : fields_admin
            : fields
        }
        data={filteredData && filteredData}
        editDisplayMode="cell"
        enableEditing={true}
        enableColumnResizing
        enableColumnFilters={false}
        enableColumnOrdering={true}
        enableGrouping={false}
        enableFacetedValues={false}
        enableGlobalFilter={false}
        enableColumnPinning
        enableRowActions
        enableStickyHeader
        isLoading={loading}
        rowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            disabled={
              !Boolean(
                props.permissionData?.admin ||
                  props.permissionData?.sales_manager ||
                  props.permissionData?.manager ||
                  props.isAdmin
              )
            }
            key={0}
            onClick={async () => {
              await confirmSale(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <CheckCircleRounded />
            </ListItemIcon>
            Подтвердить продажу
          </MenuItem>,
          <MenuItem
            disabled={
              !Boolean(
                props.permissionData?.admin ||
                  props.permissionData?.sales_manager ||
                  props.permissionData?.manager ||
                  props.isAdmin
              )
            }
            key={1}
            onClick={async () => {
              await refuseSale(row);
              closeMenu();
            }}
            sx={{ m: 0 }}
          >
            <ListItemIcon>
              <ClearRounded />
            </ListItemIcon>
            Отказать
          </MenuItem>,
        ]}
        pagination={{
          page: page,
          setPage: setPage,
          pageCount: maxPage,
        }}
      />
      {pvzModalOpen && (
        <IncomingRequestPvzChooseModal
          isOpen={pvzModalOpen}
          closeModal={setPvzModalOpen}
          setPvz={setPvz}
          row={pvzRow}
          aggregator={pvzAggegator && pvzAggegator}
          geo_object={pvzGeoObject && pvzGeoObject}
        />
      )}
    </>
  );
};

export default IncommingRequestsAllView;
