import "./UserRoadMapContainer.css"
import UserRoadMapContainerElem from "./UserRoadMapContainerElem/UserRoadMapContainerElem"

const UserRoadMapContainer = () => {
  return (
    <>
      <div className="main-page-body-sixth-container-body">
        <UserRoadMapContainerElem
          reference_to_num_logo="/static/num_one_logo.svg"
          title="РЕГИСТРАЦИЯ"
          text="Зарегистрируйтесь на платформе. Получите права администратора. Управляйте пользователями своей компании.
            Работайте в качестве Покупателя, либо Поставщика"
          button_name="Зарегистрироваться"
          navigate="/registration"
          in_new_tab="false"
        />
        <UserRoadMapContainerElem
          reference_to_num_logo="/static/num_two_logo.svg"
          title="ОТСЛЕЖИВАНИЕ И УПРАВЛЕНИЕ"
          text="Следите за статусом заказа. Планируйте закупки на нашей платформе. 
            Редактируйте ваши прайсы в личном кабинете в качестве Поставщика."
          button_name="Войти в личный кабинет"
          navigate="/login"
          in_new_tab="false"
        />
        <UserRoadMapContainerElem
          reference_to_num_logo="/static/num_three_logo.svg"
          title="ПОДКЛЮЧЕНИЕ ДАННЫХ"
          text="Подключите выгрузку вашего склада через https-сервер, создайте 
            автоматическую рассылку прайсов, настройте конфигурацию учётной системы для обмена данными"
          button_name="Подключить данные"
          navigate="https://gitlab.it-psg.com/"
          in_new_tab="true"
        />
        <UserRoadMapContainerElem
          reference_to_num_logo="/static/num_four_logo.svg"
          title="ПОИСК"
          text="Удобный поиск по артикулу и названию запчасти. Поиск списком – загрузите перечень 
            артикулов и система подберёт лучшие варианты по цене и сроку доставки"
          button_name="Поиск"
          navigate="/search"
          in_new_tab="false"
        />
        <UserRoadMapContainerElem
          reference_to_num_logo="/static/num_five_logo.svg"
          title="ОФОРМЛЕНИЕ ЗАКАЗА"
          text="Оформите заказ, как обычно. Создайте несколько проектов заказа, 
            чтобы оформить заказ, когда система предложит лучшее предложение."
          button_name="Оформить заказ"
          navigate="/orders"
          in_new_tab="false"
        />
      </div>
    </>
  )
}
export default UserRoadMapContainer