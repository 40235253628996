import React, { useEffect, useMemo, useState } from 'react'
import './MyOffersView.css'
import axios_instance from '../../requests/config_defaults';
import {
    MaterialReactTable, MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton,
    MRT_EditActionButtons, MRT_ShowHideColumnsButton,
    MRT_ToolbarDropZone
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { Paper, TableContainer, Box, Typography, MenuItem, Tooltip, IconButton } from "@mui/material";
import { createTheme, useTheme, lighten } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { lime, purple, cyan, grey } from "@mui/material/colors";
import Paginator from '../base/paginator/Paginator';

import { Button } from '@ui5/webcomponents-react';
import { ToastContainer, toast } from 'react-toastify';
import { isAdminAtom } from '../../hoc/GlobalAtoms';
import { useAtom } from 'jotai';

const fields = {
    product_full_name: {
        key: "product_full_name",
        header: "Полное наименование товара в каталоге",
        editable: false,
    },
    current_rest: {
        key: "current_rest",
        header: "Моё количество на складe",
        editable: true,
    },
    needful_rest: {
        key: "needful_rest",
        header: "Моя потребность",
        editable: true,
    },
    requested_aprooved: {
        key: "requested_aprooved",
        header: "Запрошено/подтверждено",
        editable: false,
    },
    max_delivery_period: {
        key: "max_delivery_period",
        header: "Максимальный срок доставки",
        editable: false,
    },
    max_price_for_my_needs: {
        key: "max_price_for_my_needs",
        header: "Максимальная цена для моих потребностей",
        editable: false,
    },
    order_rest: {
        key: "order_rest",
        header: "Заказано",
        editable: false,
    },
    market_rest: {
        key: "market_rest",
        header: "Доступно на площадке",
        editable: false,
    },
    to_order_count: {
        key: "to_order_count",
        header: "Необходимо заказать",
        editable: false,
    },
    purchase_price: {
        key: "purchase_price",
        header: "Цена покупки",
        editable: true,
    },
    for_sale_count: {
        key: "for_sale_count",
        header: "На продажу",
        editable: false,
    },
    sale_price: {
        key: "sale_price",
        header: "Цена продажи",
        editable: true,
    },
};

const adminFields = {
    ...fields,
    warehouse: {
        key: "warehouse",
        header: "Склад",
        editable: false,
    },
    entity: {
        key: "entity",
        header: "Поставщик",
        editable: false,
    },
    updated_at: {
        key: "updated_at",
        header: "Дата обновления",
        editable: false,
    },
};

const MyOffersView = (props) => {

    const globalTheme = useTheme({
        palette: {
            primary: lime,
            secondary: purple,
        },
    });
    const [isAdmin] = useAtom(isAdminAtom)
    const data_columns = [];
    const fieldsToUse = isAdmin ? adminFields : fields;
    for (let main_key in fieldsToUse) {
        data_columns.push({
            accessorKey: fieldsToUse[main_key].key, //simple recommended way to define a column
            header: fieldsToUse[main_key].header,
            muiTableHeadCellProps: {
                sx: {
                    color: "black",
                },
            },
            muiTableBodyRowProps: {
                onHover(e, value) {
                    e.target.style.background = "#e1f4ff";
                },
            },
            muiTopToolbarProps: {
                background: "#333",
            },
            enableEditing: fieldsToUse[main_key].editable,
            muiEditTextFieldProps: ({ cell, row }) => ({
                type: 'number',
            }),
        })
    }

    const updateStock = async (id, data) => {
        await axios_instance.post(
            "/patchStock/" + id,
            { ...data },
        )
            .then(({ data }) => {
                toast.success("Success!");
            })
            .catch((error) => {
                toast.error("Error");
            })
    };

    const handleSaveCell = async (cell, value) => {
        const patch_data = {}
        patch_data[cell.column.id] = parseFloat(value);
        await updateStock(props.data[cell.row.index].id, { ...patch_data });
        props.data[cell.row.index][cell.column.id] = value;
        props.setData([...props.data]);
    };


    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: grey, //swap in the secondary color as the primary for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "#fff" //random light yellow color for the background in light mode
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1.2rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "1.1rem", //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: "gray", //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme]
    );

    


    useEffect(() => {
        props.getOffers();
    }, [])

    useEffect(() => {
        props.getOffers();
    }, [props.product, props.page, props.activeCompany])

    return (
        <>
            <TableContainer component={Paper} className=''>
                <MuiThemeProvider theme={tableTheme}>
                    <MaterialReactTable
                        columns={data_columns}
                        data={props.data && props.data}
                        localization={MRT_Localization_RU}
                        defaultColumn={{
                        }}
                        sx={{
                            fontSize: 36,
                        }}
                        editDisplayMode='cell'
                        enableEditing={true}
                        enableColumnResizing
                        enableColumnFilters={false}
                        enableColumnOrdering={true}
                        enableGrouping={false}
                        enableColumnPinning={false}
                        enableFacetedValues={false}
                        enableFullScreenToggle={false}

                        initialState={
                            {
                                pagination: { pageSize: 20, pageIndex: 0 }
                            }
                        }

                        state={{
                            isLoading: props.loading,
                        }}
                        renderTopToolbarCustomActions={({ table }) => (
                            <Button
                            style={{
                                zIndex: 0
                            }}
                                className='MyOffersView-create-stock-btn'
                                variant="contained"
                                onClick={() => {
                                    toast.success("Success!");
                                    props.setOfferCreateMode(true);
                                }}
                            >
                                Создать товар на складe
                            </Button>
                        )}
                        renderBottomToolbar={({ table }) => {
                            return (
                                <Paginator
                                    page={props.page}
                                    setPage={props.setPage}
                                    pages_count={props.maxPage}
                                    disabled={props.loading}
                                />
                            )
                        }}
                        muiTableBodyCellProps={({ cell }) => ({
                            onBlur: (event) => {
                                handleSaveCell(cell, event.target.value)
                            },
                        })}

                    />
                </MuiThemeProvider>
            </TableContainer>
        </>
    )
}

export default MyOffersView