import React, { useEffect, useState } from 'react'
import './CompanyDetailView.css'
import { Breadcrumbs, BreadcrumbsItem, Button, DynamicPageHeader, DynamicPageTitle, FlexBox, Label, Link, ObjectPage, ObjectPageSection, Text } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults';
import { Loader } from '@mantine/core';
import CompanyDetailEmployeeView from '../company-detail-employee-view/CompanyDetailEmploeeView';
import CompanyDetailContactsView from '../company-detail-contacts-view/CompanyDetailContactsView';
import CompanyDetailAddressesView from '../company-detail-addresses-view/CompanyDetailAddressesView';
import ModalWindow from '../../base/modal/ModalWindow';
import { atom } from 'jotai';
import CompanyAddressCreateForm from '../company-address-create-form/CompanyAddressCreateForm';
import CompanyEditForm from '../company-edit-form/CompanyEditForm';
import ContactUpdateForm from '../company-detail-contact-update-form/CompanyDetailContactUpdateForm';
import CompanyDetailAddressUpdateForm from '../company-detail-address-update-form/CompanyDetailAddressUpdateForm';

export const addressesRefresh = atom(false);

const CompanyDetailView = (props) => {
    const [companyData, setCompanyData] = useState(null);
    const [loading, setLoading] = useState(false);

    const [contactsLoading, setContactsLoading] = useState([]);

    const [employeesLoading, setEmployeesLoading] = useState([]);

    const [companyContacts, setCompanyContacts] = useState([]);

    const [companyAddresses, setCompanyAddresses] = useState([]);

    const [maxPageContacts, setMaxPageContacts] = useState(1);
    const [pageContacts, setPageContacts] = useState(1);

    const [maxPageEmployees, setMaxPageEmployees] = useState(1);
    const [pageEmployees, setPageEmployees] = useState(1);

    const [addressCreateModalIsOpen, setAddressCreateModalIsOpen] = useState(false);

    const [contactUpdateMode, setContactUpdateMode] = useState(false);

    const [addressUpdateMode, setAddressUpdateMode] = useState(false);

    const [updatedContact, setUpdatedContact] = useState({});

    const [updatedAddress, setUpdatedAddress] = useState({});

    const getCompany = async () => {
        setLoading(true);
        await axios_instance.get(
            `/entity/detail/${props.activeCompany}`
        )
            .then((response) => {
                setCompanyData(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    const getCompanyContacts = async () => {
        setContactsLoading(true);
        await axios_instance.get(
            `/entity/contact/list/${props.activeCompany}?page=${pageContacts}`
        )
            .then((response) => {
                setCompanyContacts(response.data.results)
                console.log(response);
                setContactsLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setContactsLoading(false);
            })
    }

    const getCompanyEmployees = async () => {
        setEmployeesLoading(true);
        await axios_instance.get(
            `/myCompany/managers/${props.activeCompany}?page=${pageEmployees}`
        )
            .then((response) => {
                props.setCompanyManagers(response.data);
                console.log(response.data)
                setEmployeesLoading(false);
            })
            .catch((error) => {
                setEmployeesLoading(false);
            })
    }

    const getCompanyAddresses = async () => {
        setLoading(true);
        await axios_instance.get(
            `/myCompany/addresses/${props.activeCompany}`
        )
            .then((response) => {
                setCompanyAddresses(response.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    useEffect(() => {
        getCompany();
        getCompanyContacts();
        getCompanyEmployees();
        getCompanyAddresses();
    }, [props.activeCompany, props.companyUpdate])

    useEffect(() => {
        getCompanyContacts();
    }, [props.companyContactsUpdate])

    useEffect(() => {
        getCompanyEmployees();
    }, [props.companyEmployeesUpdate])

    useEffect(() => {
        getCompanyAddresses();
    }, [props.companyAddressesUpdate])

    return (
        <>
            {loading && (
                <>
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "rgba(0, 0, 0, 0.5)",
                            zIndex: 2
                        }}
                        className=""></div>
                    <div
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            zIndex: 10,

                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                        className="">
                        <Loader size={50} />
                    </div>
                </>
            )}
            <ObjectPage
                headerTitle={
                    <DynamicPageTitle
                        actions={<>
                            <Button
                                disabled={!Boolean(props.permissionData?.admin)}
                                onClick={() => props.setEditLegalEntityMode(true)}
                                icon='edit'
                            />
                            <Button
                                onClick={() => props.setCompanyUpdate(!props.companyUpdate)}
                                icon='refresh'
                            />
                            <Button
                                onClick={() => props.setActiveCompany(null)}
                                icon='decline'
                            />
                        </>}
                        breadcrumbs={
                            <Breadcrumbs>
                                <BreadcrumbsItem>
                                    Профиль
                                </BreadcrumbsItem>
                                <BreadcrumbsItem>
                                    Компании
                                </BreadcrumbsItem>
                            </Breadcrumbs>}
                        header={<Text colorScheme='10'>{companyData?.name}</Text>}
                        showSubHeaderRight
                        subHeader={`ИНН: ${companyData?.tax_number || "Не указан"}`}>
                    </DynamicPageTitle>}
                headerContent={
                    <DynamicPageHeader
                        style={{
                            backgroundColor: "#fff"
                        }}>
                        <FlexBox
                            alignItems="Center"
                            wrap="Wrap">
                            <FlexBox direction="Column">
                                {companyData?.default_contact?.phone_number &&
                                    <Link>{companyData?.default_contact?.phone_number}</Link>
                                }
                                {companyData?.default_contact?.email &&
                                    <Link>{companyData?.default_contact?.email}</Link>
                                }
                            </FlexBox>
                            <FlexBox
                                direction="Column"
                                style={{ padding: '10px' }}>
                                {companyAddresses && (
                                    <>
                                        <Label>{companyAddresses.find((address) => address.is_default === true)?.geo_object?.name ?? ""}</Label>
                                        <Label>
                                            {
                                                `${companyAddresses.find((address) => address.is_default === true)?.geo_object?.parent?.name ?? ""} ` +
                                                `${companyAddresses.find((address) => address.is_default === true)?.geo_object?.parent?.type?.abbreviation ?? ""}` +
                                                `${companyAddresses.find((address) => address.is_default === true)?.geo_object?.parent?.type?.abbreviation ? "." : ""}` +
                                                `${companyAddresses.find((address) => address.is_default === true)?.geo_object?.parent?.type?.abbreviation ? ", " : ""}` +
                                                `${companyAddresses.find((address) => address.is_default === true)?.geo_object?.parent?.parent?.name ?? ""}`
                                            }
                                        </Label>
                                    </>
                                )}
                            </FlexBox>
                        </FlexBox>
                    </DynamicPageHeader>}
                headerContentPinnable
                image="https://w7.pngwing.com/pngs/291/853/png-transparent-juridical-person-business-natural-person-business-people-logo-general-contractor.png"
                imageShapeCircle
                selectedSectionId="goals"
                showHideHeaderButton
                style={{
                    height: '100%'
                }}
            >
                <ObjectPageSection
                    aria-label="info"
                    id="info"
                    titleText="Информация"
                >
                    <FlexBox
                        alignItems='Center'
                        justifyContent='FlexStart'
                        direction='Row'
                        style={{
                            gap: "3rem"
                        }}
                    >
                        <FlexBox
                            style={{
                                width: "50%",
                                display: "block",
                                fontSize: ".7rem"
                            }}

                        >
                            <FlexBox>
                                <Label
                                    style={{
                                        fontSize: "1.1rem"
                                    }}>
                                    Наименование:
                                </Label>
                                <Text
                                    style={{
                                        fontSize: "1.1rem"
                                    }}
                                >
                                    {companyData?.name}
                                </Text>
                            </FlexBox>
                            <FlexBox>
                                <Label
                                    style={{
                                        fontSize: "1.1rem"
                                    }}>
                                    ИНН:
                                </Label>
                                <Text
                                    style={{
                                        fontSize: "1.1rem"
                                    }}>
                                    {companyData?.tax_number || "Не указан"}
                                </Text>
                            </FlexBox>
                        </FlexBox>
                    </FlexBox>
                </ObjectPageSection>
                <ObjectPageSection
                    aria-label="contacts"
                    id="contacts"
                    titleText="Контакты"
                >
                    <CompanyDetailContactsView
                        permissionData={props.permissionData}
                        // companyContacts={companyData?.contacts}
                        companyContacts={companyContacts}
                        loading={contactsLoading}
                        setCreateLegalEntityContactMode={props.setCreateLegalEntityContactMode}
                        page={pageContacts}
                        setPage={setPageContacts}
                        maxPage={maxPageContacts}
                        companyContactsUpdate={props.companyContactsUpdate}
                        setCompanyContactsUpdate={props.setCompanyContactsUpdate}
                        setUpdatedContact={setUpdatedContact}
                        setContactUpdateMode={setContactUpdateMode}
                    />
                </ObjectPageSection>
                <ObjectPageSection
                    aria-label="employees"
                    id="employees"
                    titleText="Сотрудники"
                >
                    <CompanyDetailEmployeeView
                        permissionData={props.permissionData}
                        companyManagers={props.companyManagers}
                        loading={employeesLoading}
                        setLoading={setEmployeesLoading}
                        page={pageEmployees}
                        setPage={setPageEmployees}
                        maxPage={maxPageEmployees}
                        setAddLegalEntityManagerMode={props.setAddLegalEntityManagerMode}
                        activeCompany={props.activeCompany}
                        setCompanyEmployeesUpdate={props.setCompanyEmployeesUpdate}
                        companyEmployeesUpdate={props.companyEmployeesUpdate}
                    />
                </ObjectPageSection>
                <ObjectPageSection
                    aria-label="addresses"
                    id="addresses"
                    titleText="Адреса"
                >
                    <CompanyDetailAddressesView
                        permissionData={props.permissionData}
                        activeCompany={props.activeCompany}
                        setModalIsOpen={setAddressCreateModalIsOpen}
                        setUpdatedAddress={setUpdatedAddress}
                        setAddressUpdateMode={setAddressUpdateMode}
                        companyAddresses={companyAddresses}
                    />
                </ObjectPageSection>
            </ObjectPage >

            <ModalWindow
                state={addressCreateModalIsOpen}
                setState={setAddressCreateModalIsOpen}
                title={"Добавить склад"}
                children={<CompanyAddressCreateForm activeCompany={props.activeCompany} setState={setAddressCreateModalIsOpen} />}
            />

            <ModalWindow
                state={props.editLegalEntityMode}
                setState={props.setEditLegalEntityMode}
                title={"Редактирование юр. лица"}
                children={<CompanyEditForm
                    editLegalEntityMode={props.editLegalEntityMode}
                    setEditLegalEntityMode={props.setEditLegalEntityMode}
                    activeCompany={props.activeCompany}
                    legalEntitiesUpdate={props.legalEntitiesUpdate}
                    setLegalEntitiesUpdate={props.setLegalEntitiesUpdate}
                    companyUpdate={props.companyUpdate}
                    setCompanyUpdate={props.setCompanyUpdate}
                    entity_name={companyData?.name}
                    entity_tax_number={companyData?.tax_number}
                />}
            />

            <ModalWindow
                state={contactUpdateMode}
                setState={setContactUpdateMode}
                title={"Редактирование контакта"}
                children={<ContactUpdateForm
                    contact={updatedContact}
                    companyContactsUpdate={props.companyContactsUpdate}
                    setCompanyContactsUpdate={props.setCompanyContactsUpdate}
                    setContactUpdateMode={setContactUpdateMode}
                />}
            />

            <ModalWindow
                state={addressUpdateMode}
                setState={setAddressUpdateMode}
                title={"Редактирование адреса"}
                children={<CompanyDetailAddressUpdateForm
                    address={updatedAddress}
                    companyAddressesUpdate={props.companyAddressesUpdate}
                    setCompanyAddressesUpdate={props.setCompanyAddressesUpdate}
                    setAddressUpdateMode={setAddressUpdateMode}
                    activeCompany={props.activeCompany}
                />}
            />
        </>
    )
}

export default CompanyDetailView
