import React, {useEffect, useState, useRef} from "react";
import axios_instance from "../../../requests/config_defaults";
import {
    Badge,
    Button,
    DynamicPageTitle,
    FlexBox,
    Icon,
    IllustratedMessage,
    Label,
    ObjectPage,
    ObjectPageSection,
    Option,
    Select,
} from "@ui5/webcomponents-react";
import UploadFile from "../UploadFile/UploadFile";
import "@ui5/webcomponents-icons/dist/chain-link.js";
import "@ui5/webcomponents-icons/dist/provision.js";
import "./ExchangePage.css";
import {Box, MenuItem} from "@mui/material";

import Table from "../../base/table/Table";
import {Loader} from "@mantine/core";

const error_fields = {
    key: {
        key: "key",
        header: "Название поля",
        editable: false,
      },
    value: {
        key: "value",
        header: "Значение поля",
        editable: false,
    },
    line_number: {
        key: "line_number",
        header: "Номер строки",
        editable: false,
    },
    description: {
        key: "description",
        header: "Описание ошибки",
        editable: false,
    }
}

const ExchangePage = (props) => {

    const [exchange, setExchange] = useState(null);
    const [configFields, setConfigFields] = useState([]);
    const [uploadedFields, setUploadedFields] = useState([]);
    const [createConfigError, setCreateConfigError] = useState(false);
    const [unfilledBrandConfigError, setUnfilledBrandConfigError] =
        useState(false);
    const [unfilledCodeConfigError, setUnfilledCodeConfigError] = useState(false);
    const [unfilledManufactureConfigError, setUnfilledManufactureConfigError] =
        useState(false);
    const [unfilledWarehouseConfigError, setUnfilledWarehouseConfigError] =
        useState(false);
    const [errorsList, setErrorsList] = useState([]);
    const [errorsOpen, setErrorsOpen] = useState(false);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [data, setData] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [existedConfig, setExistedConfig] = useState({});

    const [maxPageErrors, setMaxPageErrors] = useState(1);
    const [pageErrors, setPageErrors] = useState(1);
    const [columnFiltersErrors, setColumnFiltersErrors] = useState([]);
    const [loadingErrors, setLoadingErrors] = useState(false);
    const errorsTableRef = useRef(null);
    const [heightErrors, setHeightErrors] = useState(null);

    const fields = {
        user_field: {
            key: "user_field",
            header: "Пользовательское поле",
            editable: false,
        },
        icon: {
            key: "icon",
            editable: false,
            size: 80
        },
        system_field: {
            key: "system_field",
            header: "Cистемное поле",
            editable: false,
        }

    }

    useEffect(() => {
        setData([]);
        setExistedConfig({});
        getAllExchangeData();
    }, [props.exchangeID]);

    const getAllExchangeData = async () => {
        setLoading(true)
        let resp = await getExchangeByID(props.exchangeID);
        await getFieldsExchangeByID(props.exchangeID, resp);
        await getExchangeErrors();
        setLoading(false)
    }

    // useEffect(() => {
    //     setPage(1);
    // }, [columnFilters])
    //

    useEffect(()=> {
        props.setUpdater((prev) => !prev)
        setUploading(false)
    },[exchange?.processed, exchange?.start_process_dt])


    useEffect(() => {
       const interval = setInterval(async ()=>{
           if(props.exchangeID !== null) {
                await getExchangeByID(props.exchangeID)
           }
       }, 5000);
      return () => clearInterval(interval);
    }, [props.exchangeID]);


    const getExchangeByID = async (id) => {
        let _data = {}
        await axios_instance
            .get("/exchange/get/by_id/" + id)
            .then(({data}) => {
                setExchange(data);
                _data = {...data}
            })
            .catch((error) => {
                console.log(error);
            });
        return _data
    };

    const searchExistingConfiguration = async (fields) => {
        setLoading(true)
        let config = []
        let foundExchange = {}
        await axios_instance
            .get("exchange/filter/by_entity")
            .then((response) => {
                response.data.map((exchange) => {
                    let counter = 0;
                    exchange.config.map((obj) => {
                        fields.forEach((field) => {
                            if (obj.user_variant === field) {
                                counter++
                            }
                        })
                    })
                    if (counter === fields.length) {
                        config = exchange.config
                        foundExchange = exchange
                        setExistedConfig(foundExchange)
                    }
                })
            })
        setLoading(false)
        return config
    }

    const bindExistedConfigToExchange = async (id) => {
        await axios_instance
            .patch("exchange/update/" + id, {"exchange_config": existedConfig.config_id})
            .then((_res)=> {
                getExchangeByID(id)
            })
            .catch((error) => {
                console.log(error);
            });
    }


    const getFieldsExchangeByID = async (id, ex_resp) => {
        let fields = [];
        await axios_instance
            .get("/exchange/fields/get/by_id/" + id)
            .then(async (response) => {
                const row_items = [];
                setMaxPage(response.data.fields?.length % 20 === 0 ? response.data.fields?.length / 20 : Math.floor(response.data.fields?.length / 20) + 1);
                setUploadedFields(response.data.fields);
                const config = await searchExistingConfiguration(response.data.fields)
                if (config.length > 0) {
                    config.map((_obj) => {
                        fields.push({
                            name: _obj.field.name,
                            key: _obj.field.key,
                            user_variant: _obj.user_variant,
                        });
                        row_items.push({
                            user_field: _obj.user_variant,
                            icon: <Icon name="chain-link"/>,
                            system_field:
                                <div className="config-our-field" id={_obj.user_variant}>
                                    <Select onChange={(e) => onSelected(e)} >
                                        <Option>Выберите поле</Option>
                                        {props.serverFields &&
                                            props.serverFields.map((field) => (
                                                <Option id={field.key} selected={field.key === _obj.field.key}>
                                                    {field.required ? `${field.name}*` : field.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                        })
                    })
                    setConfigFields(fields)
                } else {
                    response.data.fields.map((field) => {
                        fields.push({
                            name: "",
                            key: "",
                            user_variant: field,
                        });
                        row_items.push({
                            user_field: field,
                            icon: <Icon name="chain-link"/>,
                            system_field:
                                <div className="config-our-field" id={field}>
                                    <Select onChange={(e) => onSelected(e)}>
                                        <Option>Выберите поле</Option>
                                        {props.serverFields &&
                                            props.serverFields.map((field) => (
                                                <Option id={field.key}>
                                                    {field.required ? `${field.name}*` : field.name}
                                                </Option>
                                            ))}
                                    </Select>
                                </div>
                        })


                    });
                    setConfigFields(fields);
                }
                    if (ex_resp?.config?.length === 0) {
                        setData(row_items);
                    } else {
                        fillRowsWithExchangeConfig(ex_resp)
                    }


            })
            .catch((error) => {
                console.log(error);
            });
    };


    const createExchangeConfig = async (id) => {
        setUnfilledCodeConfigError(false);
        let check_config_article = configFields.filter(
            (config_field) => config_field.key === "code"
        );

        if (check_config_article.length === 0) {
            setUnfilledCodeConfigError(true);
        } else {
            setUnfilledCodeConfigError(false);
            await axios_instance
                .post("/exchange/config/create/" + id, {fields: configFields})
                .then((response) => {
                    getExchangeByID(id);
                    fillRowsWithExchangeConfig(response.data)
                    props.setUpdater((prev) => !prev)
                })
                .catch((error) => {
                    console.log(error);
                    setCreateConfigError(true);
                });
        }
    }


    const fillRowsWithExchangeConfig = (data) => {
        const row_items = [];
        if (data.config?.length > 0) {
            data.config.map((configuration) => {
                row_items.push({
                    user_field: configuration.user_variant,
                    icon: <Icon name="chain-link"/>,
                    system_field: configuration.field.name
                })
            })
        } else {
            data.fields.map((field) => {
                row_items.push({
                    user_field: field.user_variant,
                    icon: <Icon name="chain-link"/>,
                    system_field: field.name
                })
            });
        }
        setData(row_items)
    }

    const getExchangeErrors = async () => {
        setLoadingErrors(true)
        let errorFields = [];
        await axios_instance
            .get(`/exchange/errors/get/${
                props.exchangeID}?page=${pageErrors
                }${`&filters=${JSON.stringify(columnFiltersErrors ?? [])}`}`)
            .then(({data}) => {
                setMaxPageErrors(data.fields?.length % 20 === 0
                    ? data.fields?.length / 20
                    : Math.floor(data.fields?.length / 20) + 1);
                data.results.map((error) => {
                    let errorField = {
                        key: error?.key,
                        value: error?.value ? error?.value : "-",
                        line_number: error?.line_number,
                        description: error?.error?.description,
                    };
                    errorFields.push(errorField);
                });
                setErrorsList(errorFields);
                setLoadingErrors(false);
                if (!heightErrors) {
                    setHeightErrors(errorsTableRef?.current?.offsetHeight);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const selectOnChangeHandler = (e) => configFields => {
        let updatedConfigFields = [...configFields];
        let index = updatedConfigFields.findIndex(
            (field) => field.user_variant === e.target.parentElement.id
        );
        if (index !== -1) {
            updatedConfigFields[index] = {
                ...updatedConfigFields[index],
                name: e.target.value,
                key: e.target.selectedOption.id,
            };
        }
        return updatedConfigFields
    };


    const onSelected = e => {
        const updatedConfigFields = selectOnChangeHandler(e);
        setConfigFields(updatedConfigFields);
    }

    const executeExchange = async (id) => {
        setUploading(true)
        await axios_instance
            .post("/exchange/execute/" + id)
            .then((response) => {
                getExchangeByID(id);
                props.setUpdater((prev) => !prev);
            })
            .catch((error) => {
                console.log(error);
                setUploading(false)
            });
    };

    const formatTimeToLocal = (time) => {
        // Пример времени из базы данных в формате UTC
        // Разбиение строки на составляющие
        const [datePart, timePart] = time.split(' ');
        // Разбиение даты на год, месяц и день
        const [day, month, year] = datePart.split('.');
        // Разбиение времени на часы, минуты и секунды
        const [hours, minutes, seconds] = timePart.split(':');
        // Создание объекта Date в формате UTC
        const processedTimeUTC = new Date(Date.UTC(year, month - 1, day, hours, minutes, seconds));
        // Преобразование времени из UTC в локальное время пользователя
        return processedTimeUTC.toLocaleString()
    }

    useEffect(() => {
        getExchangeErrors();
    },[pageErrors])

    useEffect(() => {
        if (pageErrors === 1) {
            getExchangeErrors();
        }
        setPageErrors(1)
    },[columnFiltersErrors]);

    useEffect(() => {
        setErrorsOpen(false)
    },[props.exchangeID]);

    return (
        <>
            {props.exchangeID !== null ?
                loading ? (
                        <div
                            style={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Loader size={50}/>
                        </div>) :
                    (<ObjectPage
                        headerTitle={
                            <DynamicPageTitle
                                header={
                                    <h1>{exchange && exchange.file.split("/").pop()}</h1>
                                }
                                actions={
                                    <>
                                        <Button
                                            onClick={() => {
                                                props.setExchangeVisible(false)
                                            }}
                                        >
                                            Закрыть
                                        </Button>
                                    </>
                                }
                            >
                            </DynamicPageTitle>
                        }
                        headerContent={
                            <>
                                {exchange && !exchange.file && (
                                    <UploadFile
                                        permissionData={props.permissionData}
                                        getExchangeByID={getExchangeByID}
                                        getFieldsExchangeByID={getFieldsExchangeByID}
                                        exchangeID={props.exchangeID}
                                        setUpdater={props.setUpdater}
                                    />
                                )}
                                <div className="exchange-parameters">
                                    <div className="exchange-status">
                                        <p>Статус:</p>
                                        {exchange && exchange.start_process_dt && !exchange.processed && (
                                            <Badge colorScheme="6">В обработке</Badge>
                                        )}
                                        {exchange &&
                                            !exchange.start_process_dt &&
                                            !exchange.processed &&
                                            exchange.file && (
                                                <Badge colorScheme="10">
                                                    Файл с предложениями товара принят
                                                </Badge>
                                            )}
                                        {exchange &&
                                            !exchange.start_process_dt &&
                                            !exchange.processed &&
                                            !exchange.file && <Badge colorScheme="1">Загрузите файл</Badge>}
                                        {exchange && exchange.start_process_dt && exchange.processed && (
                                            <Badge colorScheme="8">Завершена</Badge>
                                        )}
                                    </div>
                                </div>
                            </>
                        }
                    >
                        <ObjectPageSection
                            aria-label="exchange_info"
                            id="exchange_info"
                            titleText="Информация о выгрузке"
                        >
                            <FlexBox style={{gap: "1rem", alignItems: "center"}}>
                                <Label>Дата начала выгрузки:</Label>
                                <Label> {exchange &&
                                    (exchange.start_process_dt ? formatTimeToLocal(exchange.start_process_dt) : "-")}</Label>
                            </FlexBox>
                            <FlexBox style={{gap: "1rem", alignItems: "center"}}>
                                <Label>Дата окончания выгрузки:</Label>
                                <Label>{exchange && (exchange.processed ? formatTimeToLocal(exchange.processed) : "-")}</Label>
                            </FlexBox>
                            {exchange && !exchange.start_process_dt && (
                                <Button
                                    onClick={() => executeExchange(props.exchangeID)}
                                    disabled={exchange?.config?.length === 0 || uploading}
                                    icon={uploading ? "" : "provision"}
                                >
                                    {
                                        uploading ? (
                                            <Loader size={15}/>
                                        ) : (
                                            "Выполнить загрузку"
                                        )
                                    }

                                </Button>
                            )}
                            {exchange && exchange.processed && (
                                <p>
                                    Количество выгруженных товарных предложений:{" "}
                                    {exchange.row_count - exchange.code_errors_count}
                                </p>
                            )}
                            <Button
                                onClick={() => {
                                    getExchangeErrors();
                                    setErrorsOpen(!errorsOpen)
                                }}
                                disabled={exchange && !exchange.errors ? true : false}
                            >
                                Посмотреть ошибки
                            </Button>
                        </ObjectPageSection>
                        <ObjectPageSection
                            aria-label="config"
                            id="config"
                            titleText="Конфигурация"
                        >
                            <Table
                                className={"exchange-table"}
                                columns={fields}
                                data={data}
                                defaultColumn={{
                                    maxSize: 400,
                                    minSize: 80,
                                    size: 150, //default size is usually 180
                                }}
                                editDisplayMode='cell'
                                enableEditing={false}
                                enableColumnResizing
                                enableColumnFilters
                                onColumnFiltersChange={setColumnFiltersErrors}
                                enableColumnOrdering={true}
                                enableGrouping={false}
                                enableColumnPinning={true}
                                enableFacetedValues={true}
                                enableRowActions={true}
                                enableStickyFooter={true}
                                enableStickyHeader={true}
                                isFullScreen={isFullScreen}
                                pagination={{
                                    page: pageErrors,
                                    setPage: setPageErrors,
                                    pageCount: maxPageErrors,
                                }}

                                rowActionMenuItems={({row, closeMenu}) => [
                                    <MenuItem
                                        key="view"
                                        onClick={() => {
                                            setIsFullScreen(false)
                                        }}>
                                        Просмотр
                                    </MenuItem>
                                ]
                                }
                            />
                            {exchange && exchange.config.length === 0 && (
                                <div className="exchange-config-tool">
                                    <Button onClick={() => createExchangeConfig(props.exchangeID)}>
                                        {existedConfig?.config ? "Создать новую конфигурацию" : "Создать конфигурацю"}
                                    </Button>
                                    {
                                        existedConfig?.config ? (
                                            <>
                                            <Button onClick={ async ()=> {
                                            await fillRowsWithExchangeConfig(existedConfig)
                                            await bindExistedConfigToExchange(props.exchangeID)
                                            props.setUpdater((prev) => !prev)
                                        }}>
                                            Применить конфигурацию
                                        </Button>
                                            </>
                                        ) :
                                            (<></>)
                                    }

                                    {unfilledCodeConfigError && (
                                        <Badge colorScheme="2">
                                            Не заполнено обязательное поле "Артикул"
                                        </Badge>
                                    )}
                                    {unfilledBrandConfigError && (
                                        <Badge colorScheme="2">
                                            Не заполнено обязательное поле "Бренд"
                                        </Badge>
                                    )}
                                    {unfilledWarehouseConfigError && (
                                        <Badge colorScheme="2">
                                            Не заполнено обязательное поле "Склад"
                                        </Badge>
                                    )}
                                    {unfilledManufactureConfigError && (
                                        <Badge colorScheme="2">
                                            Не заполнено обязательное поле "Производитель"
                                        </Badge>
                                    )}
                                </div>
                            )}
                            {createConfigError && (
                                <Badge colorScheme="2">Ошибка при создании конфигурации!</Badge>
                            )}
                        </ObjectPageSection>
                        {errorsOpen &&
                            <ObjectPageSection
                                aria-label="exchange_errors"
                                id="exchange_errors"
                                titleText="Ошибки выгрузки"
                            >
                                <div ref={errorsTableRef} style={{ minHeight: heightErrors }}>
                                    <Table
                                        className={"exchange-table"}
                                        columns={error_fields}
                                        data={errorsList}
                                        isLoading={loadingErrors}
                                        enableEditing={false}
                                        enableColumnResizing
                                        enableColumnFilters
                                        onIsFullScreenChange={setIsFullScreen}
                                        onColumnFiltersChange={setColumnFiltersErrors}
                                        enableGrouping={false}
                                        isFullScreen={isFullScreen}
                                        pagination={{
                                            page: page,
                                            setPage: setPage,
                                            pageCount: maxPage,
                                        }}
                                    />
                                </div>
                            </ObjectPageSection>
                        }
                    </ObjectPage>)
                : (
                    <div
                        style={{
                            width: "100%",
                            height: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <IllustratedMessage
                            style={{margin: "0 auto"}}
                            titleText="Загрузите Прайс-лист или выберите загруженный ранее"
                            subtitleText=" "
                        />
                    </div>
                )
            }
        </>
    )
        ;
};

export default ExchangePage;
