import React, { useEffect, useState } from 'react'
import './UserProfile.css'
import { ProfileCard } from '../container/side-bar/side-bar-profile/SideBarProfile'
import axios_instance from '../../requests/config_defaults';
import UserProfileSideMenu from './user-profile-side-menu/UserProfileSideMenu';
import UserProfileActionSheet from '../base/user-profile-action-sheet/UserProfileActionSheet';
import ExtendedMenu from '../container/side-bar/extended-menu/ExtendedMenu';
import { useNavigate } from 'react-router-dom';
import UserProfileMain from './user-profile-main/UserProfileMain';
import UserProfileMe from './user-profile-me/UserProfileMe';
import UserProfileCompanies from './user-profile-companies/UserProfileCompanies';



const UserProfile = (props) => {
    const [fullName, setFullName] = useState(null);
    const [email, setEmail] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false)

    const [user, setUser] = useState({})

    const [activeChapter, setActiveChapter] = useState("main")

    const getMe = async () => {
        await axios_instance.get(
            "/getMe"
        )
            .then((response) => {
                setFullName(`${response.data.user.last_name} ${response.data.user.first_name}`);
                setEmail(response.data.user.email);
                setIsAdmin(response.data.is_admin || response.data.user.is_superuser);
                setUser(response.data.user);
            })
            .catch((error) => {

            })

    }

    useEffect(() => {
        getMe();
    }, [])

    return (
        <div className="profile-view-container">
            <div className="profile-view left">
                <UserProfileSideMenu activeChapter={activeChapter} setActiveChapter={setActiveChapter} />
            </div>
            <div className="profile-view center">
                {/*{activeChapter === "main" && (*/}
                {/*    <UserProfileMain*/}
                {/*        email={email}*/}
                {/*        fullName={fullName}*/}
                {/*    />*/}
                {/*)}*/}
                {activeChapter === "profile" || activeChapter === "main" && user.id && (
                    <UserProfileMe
                        user={user}
                        getMe={getMe}
                    />
                )}
                {activeChapter === "company" && (
                    <UserProfileCompanies />
                )}
            </div>
            {/* <div className="profile-view right">
            </div> */}
        </div>
    )
}

export default UserProfile