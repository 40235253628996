import { AnalyticalCardHeader, Card, MessageStrip, NumericSideIndicator, ThemeProvider } from '@ui5/webcomponents-react'
import { LineChart } from '@ui5/webcomponents-react-charts'
import React from 'react'

const SalesStatisticView = () => {
    return (
        <ThemeProvider>
            <Card
                header={
                    <AnalyticalCardHeader
                        description="Q1, 2024"
                        scale="K"
                        state="Good"
                        subtitleText="Доход"
                        titleText="Аналитика по продажам"
                        trend="Up"
                        unitOfMeasurement="RUB"
                        value="650.34"
                    >
                        <NumericSideIndicator number="100" titleText="Цель" unit="k" />
                        <NumericSideIndicator number="550.34" state="Good" titleText="Отклонение" unit="%" />
                    </AnalyticalCardHeader>
                }
                style={{
                    width: '100%',
                }}
            >
                <LineChart
                    style={{
                    }}
                    dataset={[
                        {
                            name: 'Январь',
                            sessions: 300,
                            count: 100,
                            volume: 756
                        },
                        {
                            name: 'Февраль',
                            sessions: 330,
                            count: 230,
                            volume: 880
                        },
                        {
                            name: 'Март',
                            sessions: 404,
                            count: 240,
                            volume: 700
                        },
                        {
                            name: 'Апрель',
                            sessions: 80,
                            count: 280,
                            volume: 604
                        },
                        {
                            name: 'Май',
                            sessions: 300,
                            count: 100,
                            volume: 756
                        },
                        {
                            name: 'Июнь',
                            sessions: 330,
                            count: 230,
                            volume: 880
                        },
                        {
                            name: 'Июль',
                            sessions: 470,
                            count: 20,
                            volume: 450
                        },
                        {
                            name: 'Август',
                            sessions: 180,
                            count: 220,
                            volume: 104
                        },
                        {
                            name: 'Сентябрь',
                            sessions: 360,
                            count: 200,
                            volume: 879
                        },
                        {
                            name: 'Октябрь',
                            sessions: 500,
                            count: 250,
                            volume: 200
                        },
                        {
                            name: 'Ноябрь',
                            sessions: 404,
                            count: 240,
                            volume: 700
                        },
                        {
                            name: 'Декабрь',
                            sessions: 80,
                            count: 280,
                            volume: 604
                        }
                    ]}
                    dimensions={[
                        {
                            accessor: 'name',
                            interval: 0
                        }
                    ]}
                    measures={[
                        {
                            accessor: 'count',
                            label: 'Мои продажи',
                        },
                        {
                            accessor: 'sessions',
                            hideDataLabel: true,
                            label: 'Продажи по текущему ЮЛ'
                        },
                        {
                            accessor: 'volume',
                            label: 'Продажи по всем ЮЛ'
                        }
                    ]}
                />
                <MessageStrip
                    style={{
                        margin: "10px 0"
                    }}
                    color='6'>
                    В разработке
                </MessageStrip>
            </Card>
        </ThemeProvider>
    )
}

export default SalesStatisticView