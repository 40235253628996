import React from 'react'
import './MainMenu.css'
import MainMenuBlock from './main-menu-block/MainMenuBlock.jsx'
import { useAtom } from 'jotai'
import { isAdminAtom } from '../../../../hoc/GlobalAtoms.jsx'

const MainMenu = (props) => {
    const [isAdmin] = useAtom(isAdminAtom)
    return (
        <div className='main-menu-container'
        style={props.sidebarFullscreen ? {overflowY: "auto"}: {}}
        >
            <div className="main-menu-content"
                style={!props.sidebarFullscreen ?
                    {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center"
                    } : {
                        display: "grid",
                        gridTemplateColumns: "1fr",
                        gap: "1rem"
                    }}
            >
                <MainMenuBlock
                    title={"Покупатель"}
                    number={1}
                    closeMenu={props.closeMenu}
                    items={[
                        {
                            label: "Каталог EXZA",
                            path: "/goods"
                        },
                        {
                            label: "Мои заказы",
                            path: "/orders"
                        },
                        // {
                        //     label: "Отправки",
                        //     path: "/sales"
                        // },
                    ]}
                />
                <MainMenuBlock
                    title={"Поставщик"}
                    number={1}
                    closeMenu={props.closeMenu}
                    items={[
                        {
                            label: "Мои продажи",
                            path: "/sales"
                        },
                        {
                            label: "Мой каталог",
                            path: "/my-goods"
                        },
                        {
                            label: "Загрузить прайс-лист вручную",
                            path: "/exchanges"
                        },
                        {
                            label: "Настроить загрузку прайс-листа по почте",
                            path: "/auto-exchanges"
                        },
                    ]}
                />
                <MainMenuBlock
                    title={"Профиль и моя компания"}
                    number={1}
                    closeMenu={props.closeMenu}
                    items={[
                        {
                            label: "Мой профиль",
                            path: "/account"
                        },
                        {
                            label: "Моя компания",
                            path: "/legal-entities"
                        },
                    ]}
                />
                {isAdmin && (<MainMenuBlock
                    title={"Администрирование"}
                    number={1}
                    closeMenu={props.closeMenu}
                    items={[
                        // {
                        //     label: "Настройки маркетплейса",
                        //     path: "/admin/settings"
                        // },
                        // {
                        //     label: "Настройки интерфейса",
                        //     path: "/admin/interface"
                        // },
                        {
                            label: "Пользователи",
                            path: "/admin-users"
                        },
                        // {
                        //     label: "Управление доступом",
                        //     path: "/admin-manage-access"
                        // },
                        {
                            label: "Управление товарами",
                            path: "/admin-manage-products"
                        },
                        {
                            label: "Права доступа",
                            path: "/admin-entity-permissions"
                        },
                        {
                            label: "Настройки маркетплейса",
                            path: "/market-settings"
                        },
                        // {
                        //     label: "Анкеты",
                        //     path: "/admin/questionnaire"
                        // },
                        {
                            label: "Журнал мониторинга почты",
                            path: "/admin-exchange-email-review"
                        },
                        {
                            label: "Управление остатками",
                            path: "/admin-entity-remnants"
                        },
                    ]}
                />)}
            </div>
        </div>
    )
}

export default MainMenu