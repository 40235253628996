import { Badge, Bar, Breadcrumbs, BreadcrumbsItem, BusyIndicator, Button, DynamicPageHeader, DynamicPageTitle, FlexBox, Form, FormItem, Label, Link, MessageStrip, ObjectPage, ObjectPageSection, ObjectPageSubSection, ObjectStatus, Text, ThemeProvider } from '@ui5/webcomponents-react'
import React, { useEffect, useState } from 'react'
import axios_instance from '../../../../requests/config_defaults';
import { useAtom } from 'jotai';
import { emailAtom } from '../../../../hoc/GlobalAtoms';

const UserAdminProfileView = (props) => {
    const [email] = useAtom(emailAtom);
    const [userData, setUserData] = useState({});
    const [loading, setLoading] = useState(false);
    const [messageStrip, setMessageStrip] = useState(<></>)
    const [primaryAction, setPrimaryAction] = useState(<></>)
    const [havePrimaryAction, setHavePrimaryAction] = useState(false)
    const [actions, setActions] = useState([])

    const getMessageStrip = (data) => {
        let message = ""
        if (data?.user?.is_superuser) {
            message = "Пользователь является администратором системы"
        } else if (data?.blocked !== null || undefined) {
            message = "Пользователь заблокирован"
        } else if (!data?.blocked && data?.approved === null || undefined) {
            message = "Пользователь ожидает подтверждения"
        }
        message !== "" ? setMessageStrip(<MessageStrip hideCloseButton>{message}</MessageStrip>) : setMessageStrip(<></>)
    }

    const getPrimaryAction = (data) => {
        setHavePrimaryAction(false)
        if (data?.user?.is_superuser) {

        } else if (data?.blocked !== null || undefined) {
            setPrimaryAction(<Button onClick={async () => {
                props.unblockUser(props.activeUser)
                getUserData();
            }} design="Emphasized">Разблокировать</Button>)
            setHavePrimaryAction(true)
        } else if (!data?.blocked && data?.approved === null || undefined) {
            setPrimaryAction(<Button onClick={async () => {
                props.approveUser(props.activeUser)
                getUserData();
            }} design="Emphasized">Подтвердить</Button>)
            setHavePrimaryAction(true)
        } else {
            setPrimaryAction(<></>)
        }
    }

    const getActions = (data) => {
        setActions(...[])
        if (!havePrimaryAction) {
            !data?.is_admin ?
                setActions(
                    [...actions, <Button>
                        Назначить администратором
                    </Button>]

                ) : !data?.user?.is_superuser ?
                    setActions(
                        [...actions, <Button>
                            Лишить прав администратора
                        </Button>]
                    ) : <></>

            (data?.user?.blocked === null || undefined) && !data?.user?.is_superuser ?
                setActions(
                    [...actions, <Button>
                        Заблокировать
                    </Button>]
                ) : <></>
        }
        console.log(actions)
    }

    const isCurrentUser = () => userData?.user?.email === email

    const getUserData = async () => {
        setLoading(true)
        await axios_instance.get(
            `/users/get/by_id/${props.activeUser}`
        )
            .then(({ data }) => {
                setUserData(data)
                getMessageStrip(data)
                getPrimaryAction(data)
                getActions(data)
                setLoading(false)
            })
            .catch((error) => {
                setLoading(false)
            })
    }



    useEffect(() => {
        getUserData();
    }, [props.activeUser, props.refreshTrigger])

    return (
        <>
            {loading ? (<>
                <FlexBox
                    alignItems="Center"
                    direction="Column"
                    justifyContent="SpaceAround"
                    wrap="NoWrap"
                    style={{
                        width: "100%",
                        height: "100%"
                    }}
                >
                    <BusyIndicator
                        active
                        delay={1000}
                        size="Medium"
                    />
                </FlexBox>
            </>) : (<ThemeProvider>
                <ObjectPage
                    style={{
                        height: "100%",
                        width: "100%"
                    }}

                    showHideHeaderButton
                    headerContent={
                        <DynamicPageHeader>
                            <FlexBox
                                alignItems="Center"
                                wrap="Wrap">
                                <FlexBox
                                    direction="Column">
                                    <Link>+33 6 4512 5158</Link>
                                    <Link href={`mailto:${userData?.user?.email}`}>{userData?.user?.email}</Link>
                                </FlexBox>
                            </FlexBox>
                        </DynamicPageHeader>
                    }
                    headerContentPinnable
                    headerTitle={
                        <DynamicPageTitle
                            actions={
                                <>
                                    {primaryAction}
                                    {!havePrimaryAction && !isCurrentUser() &&
                                        (
                                            <>
                                                {!userData?.is_admin ?
                                                    (<Button onClick={() => props.setUserAsAdmin(props.activeUser)}>
                                                        Назначить администратором
                                                    </Button>)
                                                    : !userData?.user?.is_superuser ?
                                                        (<Button onClick={() => props.unsetUserAsAdmin(props.activeUser)}>
                                                            Лишить прав администратора
                                                        </Button>
                                                        ) : <></>}

                                                {
                                                    (!userData?.user?.blocked) && !userData?.user?.is_superuser ?
                                                        (<Button onClick={() => props.blockUser(props.activeUser)}>
                                                            Заблокировать
                                                        </Button>
                                                        ) : <></>
                                                }
                                            </>
                                        )
                                    }
                                </>
                            }
                            expandedContent={messageStrip}
                            header={`${userData?.user?.last_name} ${userData?.user?.first_name}`}
                            showSubHeaderRight
                            snappedContent={messageStrip}>
                            {userData?.is_admin && <Badge colorScheme='6'>Администратор</Badge>}
                            {isCurrentUser() && <Badge colorScheme='8'>Вы</Badge>}
                        </DynamicPageTitle>
                    }
                    image={<img
                        alt="avatar"
                        height={150}
                        width={150}
                        src={"https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg"}
                        loading="lazy"
                        style={{ borderRadius: '50%' }}
                    />}
                >
                    <ObjectPageSection
                        aria-label="Информация"
                        id="employment"
                        titleText="Информация"
                    >
                        <ObjectPageSubSection
                            aria-label="Общая информация"
                            id="employment-job-information"
                            titleText="Общая информация"
                        >
                            <Form
                                columnsL={4}
                                columnsXL={4}
                                style={{
                                    alignItems: 'baseline'
                                }}
                            >
                                <FormItem label="Фамилия">
                                    <Text>
                                        {userData?.user?.last_name}
                                    </Text>
                                </FormItem>
                                <FormItem label="Имя">
                                    <Text>
                                        {userData?.user?.first_name}
                                    </Text>
                                </FormItem>
                                <FormItem label="Отчество">
                                    <Text>
                                        {userData?.user?.middle_name || "-"}
                                    </Text>
                                </FormItem>
                                <FormItem label="Дата регистрации">
                                    <Text>
                                        {userData?.create_date_time_stamp}
                                    </Text>
                                </FormItem>
                            </Form>
                        </ObjectPageSubSection>
                    </ObjectPageSection>
                </ObjectPage>
            </ThemeProvider>)}
        </>
    )
}

export default UserAdminProfileView