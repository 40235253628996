import React, { useEffect, useState } from 'react'
import './EntityPermissionCreateForm.css'
import { Bar, Button, Card, CheckBox, Form, FormGroup, FormItem, Input, Label, Link, Option, Select, StepInput, Text, SuggestionItem, ObjectStatus, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../../requests/config_defaults'


const EntityPermissionCreateForm = (props) => {
    const [newLegalEntityPermission, setNewLegalEntityPermission] = useState({
        key: "",
        name: "",
        description: ""
    });

    const [keyValueState, setKeyValueState] = useState('');

    const [nameValueState, setNameValueState] = useState('');

    const [descriptionValueState, setDescriptionValueState] = useState('');

    const handleCreateLegalEntityPermission = async (e) => {
        e.preventDefault()
        await axios_instance.post(
            `/entity/permission/create`,
            newLegalEntityPermission
        )
            .then((response) => {
                props.setEntityPermissionsCreateMode(false);
                props.setEntityPermissionsUpdate(!props.entityPermissionsUpdate);
                console.log(response);
            })
            .catch((error) => {
                if (error.response.status === 400) {
                    setKeyValueState("Право доступа с таким ключом уже существует.");
                }
                console.log(error);
            })
    }

    return (
        <div style={{ margin: 20 }}>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanL={1}
                labelSpanM={1}
                labelSpanS={1}
                labelSpanXL={1}
                style={{
                    alignItems: 'left'
                }}
                // className='MyOfferCreateForm-form'
                titleText="Заполните форму"
            >
                <FormGroup>
                    <FormItem label="Ключ">
                        <Input
                            required
                            name="key"
                            valueState={keyValueState ? "Error" : ""}
                            onInput={
                                (e) => {
                                    setKeyValueState("");
                                    setNewLegalEntityPermission((prev) => ({
                                        ...prev,
                                        key: e.target.value
                                    }))
                                }
                            }
                            style={{ width: '100%' }}
                        />
                    </FormItem>
                    {keyValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {keyValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Наименование">
                        <Input
                            required
                            name="name"
                            valueState={nameValueState ? "Error" : ""}
                            onInput={
                                (e) => {
                                    setNameValueState("");
                                    setNewLegalEntityPermission((prev) => ({
                                        ...prev,
                                        name: e.target.value
                                    }))
                                }
                            }
                            style={{ width: '100%' }}
                        />
                    </FormItem>
                    {nameValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {nameValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Описание">
                        <Input
                            required
                            name="description"
                            valueState={descriptionValueState ? "Error" : ""}
                            onInput={
                                (e) => {
                                    setDescriptionValueState("");
                                    setNewLegalEntityPermission((prev) => ({
                                        ...prev,
                                        description: e.target.value
                                    }))
                                }
                            }
                            style={{ width: '100%' }}
                        />
                    </FormItem>
                    {descriptionValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {descriptionValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    
                </FormGroup>
                <FormItem alignItems={"left"} label="">
                    <Button
                        design='Emphasized'
                        style={{
                            padding: "0.5rem 1rem",
                            width: "100%"
                        }}
                        onClick={(e) => {
                            let validate_status = true;
                            if (newLegalEntityPermission.key === "") {
                                setKeyValueState("Введите ключ");
                                validate_status = false;
                            }
                            if (newLegalEntityPermission.name === "") {
                                setNameValueState("Введите наименование");
                                validate_status = false;
                            }
                            if (newLegalEntityPermission.description === "") {
                                setDescriptionValueState("Введите описание");
                                validate_status = false;
                            }
                            if (validate_status) {
                                handleCreateLegalEntityPermission(e);
                            }
                        }}
                    >
                        Создать
                    </Button>
                </FormItem>

            </Form>
        </div>
    )
}

export default EntityPermissionCreateForm