import { Button } from "@ui5/webcomponents-react";
import "./SearcherProductImageCard.css";
import "@ui5/webcomponents-icons/dist/zoom-in.js";

const SearcherProductImageCard = (props) => {
  return (

    <div className="SearcherProductImageCard-card">
      <div className="SearcherProductImageCard-photo">
        <img 
          className="SearcherProductImageCard-photo-img"
          src={
            props?.image?.image
          }
          alt="EXZA"
        />
        <Button
          onClick={() => {
            props.setImageToFull(props?.image?.image);
          }}
          className="SearcherProductImageCard-photo-zoom-in"
          icon="zoom-in"
        />
      </div>
    </div>
  );
};
export default SearcherProductImageCard;
