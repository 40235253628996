import "./MainPageMainContainer.css"

const MainPageMainContainer = (props) => {
  return (
    <div className="MainPageMainContainer-main">
      {props.children}
    </div>
  )
}

export default MainPageMainContainer