import { React, useEffect, useState } from "react"
import UploadFile from "./UploadFile/UploadFile"
import ExchangeCreatePage from "./ExchangeCreatePage/ExchangeCreatePage"
import { Outlet, useNavigate } from "react-router-dom"
import {
  ThemeProvider,
  SplitterLayout,
  SplitterElement,
  IllustratedMessage,
} from "@ui5/webcomponents-react"
import ExchangePage from "./ExchangePage/ExchangePage"
import axios_instance from "../../requests/config_defaults"
import CompanyAddressCreateForm from "../../components/companies-view/company-address-create-form/CompanyAddressCreateForm"
import ModalWindow from "../base/modal/ModalWindow"
import { useAtom } from "jotai"
import { activeCompanyAtom } from "../../hoc/GlobalAtoms"
import PermissionManager from "../base/permission_manager/PermissionManager"

const ExchangeView = () => {
  const [permissionData, setPermissionData] = useState({})
  const [activeCompany] = useAtom(activeCompanyAtom)
  const [addressCreateModalIsOpen, setAddressCreateModalIsOpen] =
    useState(false)
  const [exchange, setExchange] = useState(null)
  const [updater, setUpdater] = useState(false)
  const [serverFields, setServerFields] = useState([])
  const [exchangeVisible, setExchangeVisible] = useState(false)

  const getServerFields = async () => {
    await axios_instance
      .get("/exchange/fields/get/list")
      .then((response) => {
        setServerFields(response.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getServerFields()
  }, [])

  useEffect(() => {
    setExchange(null)
  }, [activeCompany])

  return (
    <ThemeProvider>
      <SplitterLayout
        style={{
          height: "100%",
          width: "100%",
        }}>
        <SplitterElement>
          <ExchangeCreatePage
            permissionData={permissionData}
            setExchangeVisible={setExchangeVisible}
            activeCompany={activeCompany}
            setAddressCreateModalIsOpen={setAddressCreateModalIsOpen}
            updater={updater}
            setExchange={setExchange}
          />
        </SplitterElement>
        <SplitterElement>
          {exchangeVisible ? (
            <ExchangePage
              permissionData={permissionData}
              setExchangeVisible={setExchangeVisible}
              serverFields={serverFields}
              setUpdater={setUpdater}
              exchangeID={exchange}
            />
          ) : (
            <div className="ProductsStocksView-nodata-content">
              <IllustratedMessage />
            </div>
          )}
        </SplitterElement>
      </SplitterLayout>
      <ModalWindow
        state={addressCreateModalIsOpen}
        setState={setAddressCreateModalIsOpen}
        title={"Добавить склад"}
        children={
          <CompanyAddressCreateForm
            activeCompany={activeCompany && activeCompany?.id}
            setState={setAddressCreateModalIsOpen}
          />
        }
      />
      <PermissionManager
        keys={["admin", "manager", "residuals_control_manager"]}
        setPermissionData={setPermissionData}
      />
    </ThemeProvider>
  )
}

export default ExchangeView
