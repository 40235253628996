import React from 'react'
import {Box} from "@mui/material";
import { marketIconsAtom } from '../../../hoc/GlobalAtoms';
import { useAtom } from 'jotai';


const ExzaLogo = (props) => {
    const [icons, setIcons] = useAtom(marketIconsAtom);
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                justifyContent: 'space-around'

            }}
        >
            <img onMouseEnter={(e) => {
                e.target.src = e.target.getAttribute("data-hover-src")
                e.target.style.transform = 'scale(2)'
                e.target.style.transition = 'all .3s linear'
            }}
                 onMouseLeave={(e) => {
                     e.target.src = e.target.getAttribute("data-unhover-src")
                     e.target.style.transform = 'scale(1)'
                     e.target.style.transition = 'all .3s linear'
                 }}
                 data-unhover-src="static/Preview_parts_no_select.svg"
                 data-hover-src={icons?.icon}
                 src="static/Preview_parts_no_select.svg"/>
        </Box>
    )
}

export {ExzaLogo}