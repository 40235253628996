import React, { useEffect, useState } from 'react'
import './CompanyDetailContactCreateForm.css'
import { Bar, Button, Card, CheckBox, Form, FormGroup, FormItem, Input, Label, Link, Option, Select, StepInput, Text, SuggestionItem, ObjectStatus, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults'
import "@ui5/webcomponents/dist/features/InputSuggestions.js";
import "@ui5/webcomponents-icons/dist/customer.js";
import "@ui5/webcomponents-icons/dist/accept.js";
import { isValidPhoneNumber } from 'react-phone-number-input';


const CompanyDetailContactCreateForm = (props) => {
    const [newLegalEntityContact, setNewLegalEntityContact] = useState({
        last_name: "",
        first_name: "",
        middle_name: "",
        phone_number: "",
        email: ""
    });

    const [lastNameValueState, setLastNameValueState] = useState('');

    const [firstNameValueState, setFirstNameValueState] = useState('');

    const [middleNameValueState, setMiddleNameValueState] = useState('');

    const [phoneNumberValueState, setPhoneNumberValueState] = useState('');

    const [emailValueState, setEmailValueState] = useState('');

    const emailValidator = require("email-validator");

    const handleCreateLegalEntityContact = async (e) => {
        e.preventDefault()
        await axios_instance.post(
            `/entity/${props.activeCompany}/contact/create`,
            newLegalEntityContact
        )
            .then((response) => {
                setEmailValueState("");
                props.setCreateLegalEntityContactMode(false);
                props.setCompanyContactsUpdate(!props.companyContactsUpdate);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
            <div className='MyOfferCreateForm-overlay' id='MyOfferCreateForm-overlay'></div>

            <Card
                header={
                    <Bar
                        endContent={
                            <div
                                onClick={() => props.setCreateLegalEntityContactMode(false)}
                                className="MyOfferCreateForm-modal-close-btn"></div>
                        }
                        startContent={<span>Добавление контакта</span>}
                        style={{
                            height: "50px"
                        }}
                    ></Bar>
                }
                style={{
                    height: "65vh"
                }}
                className='MyOfferCreateForm-form-layout MyOfferCreateForm-form-layout-open'
            >
                <div className="MyOfferCreateForm-modal-container">
                    <Form
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={1}
                        labelSpanL={1}
                        labelSpanM={1}
                        labelSpanS={1}
                        labelSpanXL={1}
                        style={{
                            alignItems: 'left'
                        }}
                        // className='MyOfferCreateForm-form'
                        titleText="Заполните форму"
                    >
                        <FormGroup>
                            <FormItem label="Фамилия">
                                <Input
                                    required
                                    name="last_name"
                                    valueState={lastNameValueState ? "Error" : ""}
                                    onInput={
                                        (e) => {
                                            setLastNameValueState("");
                                            setNewLegalEntityContact((prev) => ({
                                                ...prev,
                                                last_name: e.target.value
                                            }))
                                        }
                                    }
                                    style={{ width: '100%' }}
                                />
                            </FormItem>
                            {lastNameValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {lastNameValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="Имя">
                                <Input
                                    required
                                    name="first_name"
                                    valueState={firstNameValueState ? "Error" : ""}
                                    onInput={
                                        (e) => {
                                            setFirstNameValueState("");
                                            setNewLegalEntityContact((prev) => ({
                                                ...prev,
                                                first_name: e.target.value
                                            }))
                                        }
                                    }
                                    style={{ width: '100%' }}
                                />
                            </FormItem>
                            {firstNameValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {firstNameValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="Отчество">
                                <Input
                                    required
                                    name="middle_name"
                                    valueState={middleNameValueState ? "Error" : ""}
                                    onInput={
                                        (e) => {
                                            setMiddleNameValueState("");
                                            setNewLegalEntityContact((prev) => ({
                                                ...prev,
                                                middle_name: e.target.value
                                            }))
                                        }
                                    }
                                    style={{ width: '100%' }}
                                />
                            </FormItem>
                            {middleNameValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {middleNameValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="Телефон">
                                <Input
                                    required
                                    type='Tel'
                                    name="phone_number"
                                    valueState={phoneNumberValueState ? "Error" : ""}
                                    onInput={
                                        (e) => {
                                            console.log(e);
                                            setPhoneNumberValueState("");
                                            setNewLegalEntityContact((prev) => ({
                                                ...prev,
                                                phone_number: e.target.value
                                            }))
                                        }
                                    }
                                    style={{ width: '100%' }}
                                />
                            </FormItem>
                            {phoneNumberValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {phoneNumberValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="Почта">
                                <Input
                                    required
                                    name="email"
                                    valueState={emailValueState ? "Error" : ""}
                                    onInput={
                                        (e) => {
                                            setEmailValueState("");
                                            setNewLegalEntityContact((prev) => ({
                                                ...prev,
                                                email: e.target.value
                                            }))
                                        }
                                    }
                                    style={{ width: '100%' }}
                                />
                            </FormItem>
                            {emailValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {emailValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                        </FormGroup>
                        <FormItem alignItems={"left"} label="">
                            <Button
                                design='Emphasized'
                                style={{
                                    padding: "0.5rem 1rem",
                                    width: "100%"
                                }}
                                onClick={(e) => {
                                    let validate_status = true;
                                    if (newLegalEntityContact.last_name === ""){
                                        setLastNameValueState("Введите фамилию");
                                        validate_status = false;
                                    }
                                    if (newLegalEntityContact.first_name === ""){
                                        setFirstNameValueState("Введите имя");
                                        validate_status = false;
                                    }
                                    if (newLegalEntityContact.middle_name === ""){
                                        setMiddleNameValueState("Введите отчество");
                                        validate_status = false;
                                    }
                                    if (newLegalEntityContact.phone_number === ""){
                                        setPhoneNumberValueState("Введите номер телефона");
                                        validate_status = false;
                                    }
                                    else if (!isValidPhoneNumber(newLegalEntityContact.phone_number)) {
                                        setPhoneNumberValueState("Неверный формат телефона");
                                        validate_status = false;
                                    }
                                    if (newLegalEntityContact.email === "") {
                                        setEmailValueState("Введите почту");
                                        validate_status = false;
                                    }
                                    else if (!emailValidator.validate(newLegalEntityContact.email)) {
                                        setEmailValueState("Неверный формат почты");
                                        validate_status = false;
                                    }
                                    if(validate_status) {
                                        handleCreateLegalEntityContact(e);
                                    }
                                }}
                            >
                                Создать
                            </Button>
                        </FormItem>

                    </Form>
                </div>
            </Card>

        </>
    )
}

export default CompanyDetailContactCreateForm