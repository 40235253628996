import { Icon } from "@ui5/webcomponents-react";
import "./PlusElemMainPage.css";
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';
import "@ui5/webcomponents-icons/dist/arrow-down.js";
import "@ui5/webcomponents-icons/dist/fob-watch.js";
import "@ui5/webcomponents-icons/dist/select-appointments.js";
import "@ui5/webcomponents-icons/dist/crm-service-manager.js";
import classNames from "classnames";

const PlusElemMainPage = (props) => {
const classname = classNames(
  "plus-elem",
  props.className
)
    return (
      <div className={classname}>
        <div className="plus-elem-logo">
          {props.logo_name === "WarehouseIcon" ? 
            <WarehouseOutlinedIcon
              sx={{ fontSize: 60, color: "red" }}
              design="Negative"
            /> : 
            <Icon
              style={{ alignSelf: "center", width: 60, height: 60 }}
              design="Negative"
              name={props.logo_name}
            />
          }
        </div>
        <div className={props.center ==="true" ? "plus-elem-text-header" : "plus-elem-text-header-2"}>
          {props.text_header}
        </div>
        <div className={props.center ==="true" ? "plus-elem-text-body" : "plus-elem-text-body-2"}>
          {props.text_body}
        </div>
      </div>
    )
  }

export default PlusElemMainPage