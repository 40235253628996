import React, { useEffect, useState } from "react";
import "./Paginator.css";
import { FlexBox, Button, Badge, StepInput } from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/slim-arrow-left.js";
import "@ui5/webcomponents-icons/dist/close-command-field.js";
import "@ui5/webcomponents-icons/dist/open-command-field.js";
import classNames from "classnames";

const Paginator = (props) => {
  const classnames = "paginator " + props.className;
  const [buttons, setButtons] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const getPages = () => {
    let buttons = [];
    for (let page = 0; page < props.pages_count; page++) {
      buttons.push(
        <Button
          icon=""
          onClick={() => props.setPage(page + 1)}
          disabled={props.disabled}
          className={page + 1 === props.page ? "active" : ""}
        >
          {page + 1}
        </Button>
      );
    }
    setButtons(buttons);
  };
  useEffect(() => {
    getPages();
  }, [props.page]);
  return (
    <div className={classnames}>
      <FlexBox
        alignItems="Center"
        direction="Row"
        justifyContent="SpaceBetween"
        wrap="NoWrap"
        gap="2px"
        className="paginator-box"
      >
        <FlexBox
          alignItems="Center"
          direction="Row"
          justifyContent="Start"
          wrap="NoWrap"
          gap="2px"
          className=""
        >
          <Button
            icon="slim-arrow-left"
            onClick={() => props.setPage(props.page - 1)}
            disabled={props.disabled || props.page === 1}
            className="paginator-elem"
          ></Button>
          <Button
            onClick={() => props.setPage(1)}
            disabled={props.disabled || props.page === 1}
            className={classNames(
              "paginator-elem",
              props.page - 1 <= 1 ? "none" : ""
            )}
          >
            1
          </Button>
          <Badge
            colorScheme="6"
            className={classNames(
              "paginator-elem",
              props.page - 1 <= 1 ? "none" : ""
            )}
          >
            ...
          </Badge>
          <Button
            onClick={() => props.setPage(props.page - 1)}
            disabled={props.disabled || props.page === 1}
            className={classNames(
              "prev",
              "paginator-elem",
              props.page - 1 <= 0 ? "none" : ""
            )}
          >
            {props.page - 1}
          </Button>
          <Button
            onClick={() => {}}
            disabled={props.disabled}
            className={classNames("paginator-elem", "active")}
          >
            {props.page}
          </Button>
          <Button
            onClick={() => props.setPage(props.page + 1)}
            disabled={props.disabled || props.page + 1 > props.pages_count}
            className={classNames(
              "next",
              "paginator-elem",
              props.pages_count - props.page <= 0 ? "none" : ""
            )}
          >
            {props.page + 1}
          </Button>
          <Badge
            colorScheme="6"
            className={classNames(
              "paginator-elem",
              props.pages_count - props.page <= 1 ? "none" : ""
            )}
          >
            ...
          </Badge>
          <Button
            onClick={() => props.setPage(props.pages_count)}
            disabled={props.disabled || props.page === props.pages_count}
            className={classNames(
              "paginator-elem",
              props.pages_count - props.page <= 1 ? "none" : ""
            )}
          >
            {props.pages_count}
          </Button>
          <Button
            icon="slim-arrow-right"
            onClick={() => {
              props.setPage(props.page + 1);
              console.log(props.page);
            }}
            disabled={props.disabled || props.page === props.pages_count}
            className={classNames("paginator-elem")}
          ></Button>
        </FlexBox>
        <FlexBox
          alignItems="Center"
          direction="Row"
          justifyContent="End"
          wrap="NoWrap"
          gap="2px"
          className="paginator-box"
        >
          <StepInput
            onChange={(e) => {
              if (e.target.value <= props.pages_count && e.target.value >= 1) {
                setPageNum(e.target.value);
              } else {
                setPageNum(props.pages_count);
              }
              e.target.value = pageNum;
            }}
            min={1}
            max={props.pages_count}
            value={pageNum}
            className="paginator-elem page-input"
            disabled={props.disabled}
          />
          <Button
            icon="accept"
            disabled={props.disabled}
            className="paginator-elem"
            onClick={() => props.setPage(pageNum)}
          ></Button>
        </FlexBox>
      </FlexBox>
    </div>
  );
};

export default Paginator;
