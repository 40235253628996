import { Button, Input, Label, StepInput, Switch } from "@ui5/webcomponents-react";
import React, { useEffect, useState } from "react";
import "./MarketSettings.css";
import { useAtom } from "jotai";
import { marketIconsAtom } from "../../hoc/GlobalAtoms.jsx";
import axios_instance from "../../requests/config_defaults.js";
import { green, red } from "@mui/material/colors";

const FormData = require("form-data");

const MarketSettings = () => {
  const [icons] = useAtom(marketIconsAtom);
  const [market, setMarket] = useState(null);
  const [updateError, setUpdateError] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [markUp, setMarkUp] = useState(icons?.mark_up);
  const [rootLink, setRootLink] = useState(icons?.root_link);
  const [name, setName] = useState(icons?.name);
  const [shortName, setShortName] = useState(icons?.short_name);
  const [premoderation, setPremoderation] = useState(null);
  const [displayNone, setDisplayNone] = useState({
    icon: true,
    icon_full: true,
    icon_mini: true,
    default_product_img: true,
  });
  const [uploadedIcon, setUploadedIcon] = useState({
    icon: null,
    icon_full: null,
    icon_mini: null,
    default_product_img: null,
  });

  const marketGet = async () => {
    await axios_instance
      .get(`/market/get/by_id`)
      .then(({ data }) => {
        console.log(data);
        setMarket(data);
        setPremoderation(data.premoderation)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    marketGet();
  }, []);

  const marketUpdate = async () => {
    const formData = new FormData();
    uploadedIcon.icon && formData.append("icon", uploadedIcon.icon?.icon);
    uploadedIcon.icon_mini &&
      formData.append("icon_mini", uploadedIcon.icon_mini?.icon_mini);
    uploadedIcon.icon_full &&
      formData.append("icon_full", uploadedIcon.icon_full?.icon_full);
    uploadedIcon.default_product_img &&
      formData.append("default_product_img", uploadedIcon.default_product_img?.default_product_img);
    markUp && formData.append("mark_up", markUp);
    name && formData.append("name", name);
    shortName && formData.append("short_name", shortName);
    rootLink && formData.append("root_link", rootLink);
    premoderation && formData.append("premoderation", premoderation);
    await axios_instance
      .post(`/market/update`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        marketGet();
        setUpdateSuccess(true);
      })
      .catch((error) => {
        console.log(error);
        setUpdateError(true);
      });
  };

  return (
    <div className="market-settings-content">
      <Label className="market-settings-title" style={{ fontSize: "1.1rem" }}>
        Настройка маркетплейса
      </Label>
      <div className="settings-fields-content">
        <div className="text-inputs">
          <div className="setting-field">
            <Label>Название</Label>
            <Input
              placeholder="Введите название"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div>
          <div className="setting-field">
            <Label>Короткое название</Label>
            <Input
              placeholder="Введите короткое название"
              value={shortName}
              onChange={(e) => {
                setShortName(e.target.value);
              }}
            />
          </div>
          <div className="setting-field">
            <Label>Корневая ссылка на сайт</Label>
            <Input
              placeholder="Введите корневую ссылку на сайт"
              value={rootLink}
              onChange={(e) => {
                setRootLink(e.target.value);
              }}
            />
          </div>
          <div className="setting-field">
            <Label>Наценка</Label>
            <StepInput
              placeholder="Введите наценку"
              value={markUp}
              min={0}
              valuePrecision={2}
              onChange={(e) => {
                setMarkUp(e.target.value);
              }}
            />
          </div>
          <div className="setting-field">
            <Label>Премодерация Юр.Лиц</Label>
            <Switch
              design="Textual"
              checked={premoderation}
              textOff="Нет"
              textOn="Да"
              onChange={(e) => {
                setPremoderation(e.target.checked)
              }}
            />
          </div>
        </div>
        <div className="setting-field-icons">
          <div className="setting-field-icon-card">
            <Label>Средняя иконка</Label>
            <div
              className="setting-field"
              onMouseEnter={() =>
                setDisplayNone((prev) => ({ ...prev, icon: false }))
              }
              onMouseLeave={() =>
                setDisplayNone((prev) => ({ ...prev, icon: true }))
              }
            >
              <img
                src={
                  !uploadedIcon.icon?.icon_interface
                    ? icons?.icon
                    : uploadedIcon.icon.icon_interface
                }
                alt="icon"
              />
              <div
                className={
                  displayNone.icon ? "icon-uploader-none" : "icon-uploader"
                }
              >
                {!uploadedIcon.icon ? (
                  <>
                    <Label style={{ color: "#fff" }}>Нажмите для загрузки</Label>
                    <input
                      type="file"
                      onChange={(e) => {
                        setUploadedIcon((prev) => ({
                          ...prev,
                          icon: {
                            icon_interface: URL.createObjectURL(e.target.files[0]),
                            icon: e.target.files[0],
                          },
                        }));
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() =>
                        setUploadedIcon((prev) => ({ ...prev, icon: null }))
                      }
                      className="cancel-upload"
                    >
                      <Label style={{ color: "#fff" }}>Нажмите для отмены</Label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="setting-field-icon-card">
            <Label>Полная иконка</Label>
            <div
              className="setting-field"
              onMouseEnter={() =>
                setDisplayNone((prev) => ({ ...prev, icon_full: false }))
              }
              onMouseLeave={() =>
                setDisplayNone((prev) => ({ ...prev, icon_full: true }))
              }
            >
              <img
                src={
                  !uploadedIcon.icon_full?.icon_full_interface
                    ? icons?.icon_full
                    : uploadedIcon.icon_full.icon_full_interface
                }
                alt="full-icon"
              />
              <div
                className={
                  displayNone.icon_full ? "icon-uploader-none" : "icon-uploader"
                }
              >
                {!uploadedIcon.icon_full ? (
                  <>
                    <Label style={{ color: "#fff" }}>Нажмите для загрузки</Label>
                    <input
                      type="file"
                      onChange={(e) => {
                        setUploadedIcon((prev) => ({
                          ...prev,
                          icon_full: {
                            icon_full_interface: URL.createObjectURL(
                              e.target.files[0]
                            ),
                            icon_full: e.target.files[0],
                          },
                        }));
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() =>
                        setUploadedIcon((prev) => ({ ...prev, icon_full: null }))
                      }
                      className="cancel-upload"
                    >
                      <Label style={{ color: "#fff" }}>Нажмите для отмены</Label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="setting-field-icon-card">
            <Label>Маленькая иконка</Label>
            <div
              className="setting-field"
              onMouseEnter={() =>
                setDisplayNone((prev) => ({ ...prev, icon_mini: false }))
              }
              onMouseLeave={() =>
                setDisplayNone((prev) => ({ ...prev, icon_mini: true }))
              }
            >
              <img
                src={
                  !uploadedIcon.icon_mini?.icon_mini_interface
                    ? icons?.icon_mini
                    : uploadedIcon.icon_mini.icon_mini_interface
                }
                alt="small-icon"
              />
              <div
                className={
                  displayNone.icon_mini ? "icon-uploader-none" : "icon-uploader"
                }
              >
                {!uploadedIcon.icon_mini ? (
                  <>
                    <Label style={{ color: "#fff" }}>Нажмите для загрузки</Label>
                    <input
                      type="file"
                      onChange={(e) => {
                        setUploadedIcon((prev) => ({
                          ...prev,
                          icon_mini: {
                            icon_mini_interface: URL.createObjectURL(
                              e.target.files[0]
                            ),
                            icon_mini: e.target.files[0],
                          },
                        }));
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() =>
                        setUploadedIcon((prev) => ({ ...prev, icon_mini: null }))
                      }
                      className="cancel-upload"
                    >
                      <Label style={{ color: "#fff" }}>Нажмите для отмены</Label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="setting-field-default-product-img">
          <div className="setting-field-icon-card">
            <Label>Фото товара по умолчанию</Label>
            <div
              className="setting-field"
              onMouseEnter={() =>
                setDisplayNone((prev) => ({ ...prev, default_product_img: false }))
              }
              onMouseLeave={() =>
                setDisplayNone((prev) => ({ ...prev, default_product_img: true }))
              }
            >
              <img
                src={
                  !uploadedIcon.default_product_img?.default_product_img_interface
                    ? icons?.default_product_img
                    : uploadedIcon.default_product_img.default_product_img_interface
                }
                alt="default_product_img"
              />
              <div
                className={
                  displayNone.default_product_img ? "icon-uploader-none" : "icon-uploader"
                }
              >
                {!uploadedIcon.default_product_img ? (
                  <>
                    <Label style={{ color: "#fff" }}>Нажмите для загрузки</Label>
                    <input
                      type="file"
                      onChange={(e) => {
                        setUploadedIcon((prev) => ({
                          ...prev,
                          default_product_img: {
                            default_product_img_interface: URL.createObjectURL(
                              e.target.files[0]
                            ),
                            default_product_img: e.target.files[0],
                          },
                        }));
                      }}
                    />
                  </>
                ) : (
                  <>
                    <div
                      onClick={() =>
                        setUploadedIcon((prev) => ({ ...prev, default_product_img: null }))
                      }
                      className="cancel-upload"
                    >
                      <Label style={{ color: "#fff" }}>Нажмите для отмены</Label>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <Button onClick={() => marketUpdate()}>Сохранить</Button>
        {updateError && (
          <Label className="update-error" style={{ color: "red" }}>
            Ошибка обновления
          </Label>
        )}
        {updateSuccess && (
          <Label className="update-error" style={{ color: "green" }}>
            Успешно сохранено
          </Label>
        )}
      </div>
    </div>
  );
};

export default MarketSettings;
