import React, { useEffect, useMemo, useState } from 'react'
import axios_instance from '../../../requests/config_defaults';
import "./CompanyDetailEmployeeView.css";

import { Button, TabContainer, Tab } from '@ui5/webcomponents-react';
import Table from '../../base/table/Table';
import { Box } from '@mui/material';
import ManagerPermissionsManagementTable from '../manager-permission-management-table/ManagerPermissionsManagementTable';
import ManagerInviteManagementTable from '../manager-invite-management-table/ManagerInviteManagementTable';

const fields = {
    last_name: {
        key: "last_name",
        header: "Фамилия",
        disabled: false,
    },
    first_name: {
        key: "first_name",
        header: "Имя",
        disabled: true,
    },
    middle_name: {
        key: "middle_name",
        header: "Отчество",
        disabled: false,
    },
    email: {
        key: "email",
        header: "Почта",
        disabled: false,
    },
    phone_number: {
        key: "phone_number",
        header: "Телефон",
        disabled: false,
    },
    actual_permissions: {
        key: "actual_permissions",
        header: "Права доступа",
        disabled: false,
        size: 200,
        //custom conditional format and styling
        Cell: ({ renderedCellValue, cell }) => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                    borderRadius: 20,
                    padding: '5px',
                    color: "#fff",
                }}
            >
                <span
                    style={{
                        color: renderedCellValue.length === 0 ? "red" : renderedCellValue.find(manager_permission => manager_permission.permission.key === "admin") ? "#189CFF" : "green"
                    }}
                >
                    {renderedCellValue.length === 0 ? "Заблокирован" : renderedCellValue.find(manager_permission => manager_permission.permission.key === "admin") ? "Администратор" : "Установлены"}
                </span>
            </Box>
        ),
    },
};


const CompanyDetailEmployeeView = (props) => {
    const [dataTable, setDataTable] = useState([]);

    useEffect(() => {
        let data_table = [];

        props.companyManagers.map((employee) => {
            data_table.push({
                last_name: employee.last_name ? employee.last_name : "Не указана",
                first_name: employee.first_name ? employee.first_name : "Не указано",
                middle_name: employee.middle_name ? employee.middle_name : "Не указано",
                email: employee.email ? employee.email : "Не указана",
                phone_number: employee.phone_number ? employee.phone_number : "Не указан",
                actual_permissions: employee.actual_permissions
            })
        })

        setDataTable(data_table);
    }, [props.companyManagers])

    const [activePermissionRequestsCount, setActivePermissionRequestsCount] = useState(0);


    return (
        <>
            <Table
                className={"emloyee-table"}
                columns={fields}
                data={dataTable}
                enableEditing={false}
                enableColumnResizing
                enableColumnFilters={false}
                enableColumnOrdering={true}
                enableGrouping={false}
                enableColumnPinning={false}
                enableFacetedValues={false}
                enableStickyHeader
                renderDetailPanel={(row, table) => (
                    <>
                        <TabContainer
                            contentBackgroundDesign="Solid"
                            headerBackgroundDesign="Solid"
                            onTabSelect={function _a() { }}
                            tabLayout="Inline"
                            style={{ width: "2000px" }}
                        >
                            <Tab
                                selected
                                text="Общая информация"
                            >
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        left: '30px',
                                        maxWidth: '1000px',
                                        position: 'sticky',
                                        width: '100%',
                                    }}
                                >
                                    <img
                                        alt="avatar"
                                        height={150}
                                        width={150}
                                        src={"https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg"}
                                        loading="lazy"
                                        style={{ borderRadius: '50%' }}
                                    />
                                    <Box sx={{ textAlign: 'start', marginLeft: '50px' }}>
                                        <p><b>Фамилия:</b> {row.row.original.last_name}</p>
                                        <p><b>Имя:</b> {row.row.original.first_name}</p>
                                        <p><b>Отчество:</b> {row.row.original.middle_name}</p>
                                        <p><b>Почта:</b> {row.row.original.email}</p>
                                        <p><b>Телефон:</b> {row.row.original.phone_number}</p>
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab
                                text="Права доступа"
                            >
                                <>
                                    <ManagerPermissionsManagementTable
                                        permissionData={props.permissionData}
                                        manager={row.row.original}
                                        activeCompany={props.activeCompany}
                                        setLoading={props.setLoading}
                                    />
                                </>

                            </Tab>
                            <Tab
                                text="Заявки"
                                design={activePermissionRequestsCount !== 0 ? 'Critical' : 'Default'}
                                additionalText={activePermissionRequestsCount !== 0 ? activePermissionRequestsCount : ""}
                            >
                                <>
                                    <ManagerInviteManagementTable
                                        manager={row.row.original}
                                        activeCompany={props.activeCompany}
                                        setActivePermissionRequestsCount={setActivePermissionRequestsCount}
                                    />
                                </>
                            </Tab>
                        </TabContainer>
                    </>

                )}

                isLoading={props.loading}

                topToolbarCustomActions={
                    <div>
                        <Button
                            disabled={!Boolean(props.permissionData?.admin)}
                            design="Emphasized"
                            onClick={() => {
                                props.setAddLegalEntityManagerMode(true);
                            }}
                            className='add-manager-button'
                        >
                            Добавить сотрудника
                        </Button>
                        <Button
                            onClick={() => {
                                props.setCompanyEmployeesUpdate(!props.companyEmployeesUpdate);
                            }}
                            icon='refresh'
                        >
                            Обновить
                        </Button>
                    </div>

                }
            />
        </>
    )
}

export default CompanyDetailEmployeeView