import { React } from "react";
import OfferProductComponent from "./offer-product-component/OfferProductComponent.jsx";
import {
  ObjectPage,
  ObjectPageSection,
  Button,
} from "@ui5/webcomponents-react";
import "./CatalogOfferProductCard.css";

const CatalogOfferProductCard = (props) => {
  return (
    <div className="catalog-offer-product-card">
      <ObjectPage
        style={{
          width: "100%",
        }}
      >
        <h3>Быстрая</h3>
        <ObjectPageSection
          aria-label={"Быстрая"}
          id={"fastetst-catalog-offer-product-component-page-section"}
          titleText={'Доставка'}
          className={"catalog-offer-product-component-page-section"}
          hideTitleText
        >
          <span>
            Срок доставки: <b>{"2 - 2 дн."}</b> | Цена: <b>{"760 руб."}</b>
          </span>
          <Button style={{ marginLeft: "5%" }}>Заказать</Button>
        </ObjectPageSection>
        <h3>Выгодная</h3>
        <ObjectPageSection
          aria-label={"Выгодная"}
          id={"cheapest-catalog-offer-product-component-page-section"}
          // titleText={'Выгодная'}
          className={"catalog-offer-product-component-page-section"}
          hideTitleText
        >
          <span>
            Срок доставки: <b>{"4 - 6 дн."}</b> | Цена: <b>{"400 руб."}</b>
          </span>
          <Button style={{ marginLeft: "5%" }}>Заказать</Button>
        </ObjectPageSection>
        <h3>Другие</h3>
        <ObjectPageSection
          aria-label={"Другие"}
          id={"another-catalog-offer-product-component-page-section"}
          // titleText={'Другие'}
          className={"catalog-offer-product-component-page-section"}
          hideTitleText
        >
          <div style={{ marginBottom: 20 }}>
            <span>
              Срок доставки: <b>{"Не указано"}</b> | Цена: <b>{"Не указано"}</b>
            </span>
            <Button disabled style={{ marginLeft: "5%" }}>
              Заказать
            </Button>
          </div>
          <div>
            <span>
              Срок доставки: <b>{"Не указано"}</b> | Цена: <b>{"Не указано"}</b>
            </span>
            <Button disabled style={{ marginLeft: "5%" }}>
              Заказать
            </Button>
          </div>
        </ObjectPageSection>
        {/*<OfferProductComponent id={'fastetst-catalog-offer-product-component-page-section'} 
                title={'Быстрая'} 
                delivery_time={'2 - 2 дн.'}
                cost={'760 руб.'}/>
                <OfferProductComponent id={'cheapest-catalog-offer-product-component-page-section'} 
                title={'Выгодная'}
                delivery_time={'4 - 6 дн.'}
                cost={'400 руб.'}/>    
                <OfferProductComponent id={'another-catalog-offer-product-component-page-section'} 
                title={'Другие'}
                delivery_time={'Не указано'}
                cost={'Не указано'}/>*/}
      </ObjectPage>
    </div>
  );
};

export default CatalogOfferProductCard;
