import React, { useEffect, useState } from 'react'
import { Badge, Bar, Button, Card, CardHeader, List, StandardListItem, Panel, Form, FormItem, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults';
import "./UserProfileCompanies.css";
import CompaniesView from '../../companies-view/CompaniesView';


// const UserCompanyItem = (props) => {
//     const entity = props.company_id;
//     const [data, setData] = useState({});
//     const [activeCompany, setActiveCompany] = useState({});

//     const getCompany = async () => {
//         await axios_instance.get(
//             `/entity/detail/${entity}`
//         )
//             .then((response) => {
//                 setData(response.data);
//                 console.log(response.data)
//             })
//             .catch((error) => {
//                 setData({});
//             })
//     }

//     const setCompany = async () => {
//         await axios_instance.post(
//             `/setMyCompany/${data?.id}`
//         )
//             .then((response) => {

//             })
//             .catch((error) => {

//             })

//     }

//     const getActiveCompany = async () => {
//         await axios_instance.get(
//             "/myCompany"
//         )
//             .then((response) => {
//                 setActiveCompany(response.data);

//             })
//             .catch((error) => {

//             })
//     }

//     useEffect(() => {
//         getCompany();
//     }, []);

//     return (
//         <Panel
//             headerText={data?.name}
//             onToggle={function _a() { }}
//             className='company-item-panel'
//         >
//             <div className='company-form'>
//                 <div className='company-form-header'>
//                     <div>
//                         {activeCompany}
//                         <Badge colorScheme='8'>
//                             Текущее
//                         </Badge>
//                     </div>
//                     <div>
//                         <Icon name='action-settings' interactive>

//                         </Icon>
//                     </div>
//                 </div>
//                 <Form
//                     backgroundDesign="Transparent"
//                     columnsL={1}
//                     columnsM={1}
//                     columnsS={1}
//                     columnsXL={1}
//                     labelSpanL={1}
//                     labelSpanM={1}
//                     labelSpanS={1}
//                     labelSpanXL={1}
//                     style={{
//                         alignItems: 'center'
//                     }}
//                 >
//                     <FormItem label="Название">
//                         <div>{data?.name}</div>
//                     </FormItem>
//                     <FormItem label="ИНН">
//                         <div>{data?.tax_number}</div>
//                     </FormItem>

//                 </Form>
//                 <h3>Контакты</h3>

//                 <div className='company-contacts'>
//                     {data?.contacts?.map((contact) => {
//                         return (
//                             <div className='company-contact'>
//                                 <Form
//                                     backgroundDesign="Transparent"
//                                     columnsL={1}
//                                     columnsM={1}
//                                     columnsS={1}
//                                     columnsXL={1}
//                                     labelSpanL={1}
//                                     labelSpanM={1}
//                                     labelSpanS={1}
//                                     labelSpanXL={1}
//                                     style={{
//                                         alignItems: 'center'
//                                     }}
//                                 >
//                                     <FormItem label="Фамилия">
//                                         <div>{contact?.last_name}</div>
//                                     </FormItem>
//                                     <FormItem label="Имя">
//                                         <div>{contact?.first_name}</div>
//                                     </FormItem>
//                                     <FormItem label="Отчество">
//                                         <div>{contact?.middle_name}</div>
//                                     </FormItem>
//                                     <FormItem label="Почта">
//                                         <div>{contact?.email}</div>
//                                     </FormItem>
//                                     <FormItem label="Телефон">
//                                         <div>{contact?.phone_number}</div>
//                                     </FormItem>
//                                 </Form>
//                             </div>
//                         )
//                     })}
//                 </div>
//             </div>
//             {/* <Card
//                 header={
//                     <Bar
//                         endContent={
//                             props.active ? (<Badge colorScheme='8'>Текущее</Badge>) : (<></>)
//                         }
//                         startContent={<h3>{data?.name}</h3>}
//                     ></Bar>
//                 }
//             >
//                 <div className="user-company-item-card-container">
//                     <p>ИНН: {data?.tax_number || "Не указан"}</p>
//                     {!props.active && (
//                         <Button
//                             onClick={() => {
//                                 setCompany();
//                                 props.setActiveCompany(data)
//                             }}
//                         >
//                             Выбрать
//                         </Button>
//                     )}
//                 </div>
//             </Card> */}
//         </Panel>

//     )
// }

// const UserProfileCompanies = (props) => {
//     const [companiesData, setComapniesData] = useState([]);

//     const getMyCompanies = async () => {
//         await axios_instance.get(
//             "/myCompanies"
//         )
//             .then((response) => {
//                 setComapniesData(response.data);
//                 console.log(response.data)
//             })
//             .catch((error) => {
//                 setComapniesData([]);
//             })
//     }
//     useEffect(() => {
//         getMyCompanies();
//     }, [])

//     const handleCompanyCreateButtonClick = (e) => {

//     }

//     return (
//         <>
//             <h1>Мои юридические лица</h1>
//             <Button
//                 design="Emphasized"
//                 className='company-create-button'
//                 onClick={handleCompanyCreateButtonClick}
//             >
//                 Создать
//             </Button>
//             <div className="my-companies">
//                 {companiesData && companiesData.map((company) => (
//                     <UserCompanyItem
//                         company_id={company?.entity}
//                     // active={props.activeCompany === company?.entity}
//                     // setActiveCompany={props.setActiveCompany}
//                     />

//                 ))}
//             </div>
//         </>
//     )
// }

// export default UserProfileCompanies

const UserProfileCompanies = (props) => {

    return (
        <CompaniesView />
    )
}

export default UserProfileCompanies