import axios_instance from "./config_defaults.js";

async function logOut() {
    let result;
    await axios_instance
        .get('/logout')
        .then((response) => {
            result = response.data.status;
        })
        .catch((error) => {
            result = false;
        });
    return result;
}

async function checkIsAuthenticated() {
    let result;
    await axios_instance
        .get('/is_authenticated')
        .then((response) => {
            result = response.data.status;
            // console.log(result)
        })
        .catch((error) => {
            result = false;
        });
    return result;
}

export { logOut, checkIsAuthenticated }