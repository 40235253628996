import axios_instance from "../../../../requests/config_defaults"
import React, { useState, useEffect } from "react"
import { Button, StepInput } from "@ui5/webcomponents-react"

const CustomInput = (props) => {
  const [needCount, setNeedCount] = useState(props.label)
  const [previousNeedCount, setPreviousNeedCount] = useState(0)
  const [isEditing, setIsEditing] = useState(false)
  const handleSubmit = async (needCount) => {
    try {
      const requestData = Object.fromEntries(
        Object.entries({
          [props.fieldName]: needCount,
        }).filter(([, value]) => value != undefined)
      )

      await axios_instance.put(`/updateStock/${props.activeRemnant}`, requestData)
      setPreviousNeedCount(needCount)
      props.getEntityRemnantsList()
    } catch (error) {
      console.error("Ошибка запроса:", error)
    }
  }
  useEffect(() => {
    setNeedCount(props.label)
    setPreviousNeedCount(props.label)
  }, [props.label, props.activeRemnant])
  const handleInputChange = (e) => {
    setNeedCount(e.detail.value)
    if (!isEditing) {
      setPreviousNeedCount(needCount)
    }
    setIsEditing(true)
  }
  const handleCancelEdit = () => {
    setNeedCount(previousNeedCount)
    setIsEditing(false)
  }
  useEffect(() => {}, [needCount])

  return (
    <>
      <div
        style={{
          display: "flex",
          gap: "5px",
          justifyContent: "center",
          width: "250px",
        }}>
        <StepInput
          onChange={handleInputChange}
          style={{ width: 180 }}
          min={0}
          value={needCount}
        />
      </div>
      {isEditing && (
        <>
          <Button
            onClick={() => {
              setIsEditing(false)
              handleSubmit(needCount)
            }}
            tooltip="сохранить изменения"
            style={{ color: "green" }}
            icon="accept"
          />
          <Button
            onClick={handleCancelEdit}
            tooltip="Отменить изменения"
            style={{ color: "red" }}
            icon="decline"
          />
        </>
      )}
    </>
  )
}

export default CustomInput
