import React, { useState } from 'react'
import { Form, FormGroup, FormItem, Input, Text, Button, FlexBox, Title, Link, ObjectStatus, Icon, Menu, MenuItem } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai';
import { emailAtom } from '../../../hoc/GlobalAtoms';


const IdentificationForm = (props) => {

    /* props area */

    const [email, setEmail] = useAtom(emailAtom);

    const setIsLoading = (isLoading) => {
        props.setIsLoading(isLoading);
    };

    const setValidIdentification = (isLoading) => {
        props.setValidIdentification(isLoading);
    };

    const openRegistrationWindow = () => {
        props.showWindow("registrationForm");
    };

    const onRegClick = props.onRegClick || (() => {})

    /* end props area */

    const [createAccountMenuIsOpen, setCreateAccountMenuIsOpen] = useState(false);

    const [emailValueState, setEmailValueState] = useState('');

    const [focusEmailInput, setFocusEmailInput] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const handleEmailInput = (e) => {
        setEmailValueState("");
        setEmail(e.target.value);
    };

    async function performIdentification() {
        await axios_instance
            .get(`/identification/${email}`)
            .then(({ data, status }) => {
                console.log(data)
                switch (data?.error_code) {
                    case "NOT_EXISTS":
                        setEmailValueState('Error');
                        setValidIdentification(false);
                        setErrorMessage("Не удалось найти аккаунт");
                        break
                    case "NO_MARKET_USER":
                        setEmailValueState('Error');
                        setValidIdentification(false);
                        setErrorMessage("Не удалось найти аккаунт");
                        break
                    case "NOT_APPROVED":
                        setEmailValueState('Error');
                        setValidIdentification(false);
                        setErrorMessage("Ваша учетная запись не подтверждена");
                        break
                    case "USER_BLOCKED":
                        setEmailValueState('Error');
                        setValidIdentification(false);
                        setErrorMessage("Ваша учетная запись заблокирована");
                        break
                    case null || undefined:
                        setEmail(data.email);
                        setEmailValueState('');
                        setValidIdentification(true);
                        break
                }
            })
            .catch(error => {
                console.log(error);
            });
        setIsLoading(false);
    }

    const nav = useNavigate()

    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
                style={{ marginBottom: 20 }}
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Вход
                </Title>
                <Text>
                    Используйте аккаунт EXZA
                </Text>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
                style={{
                    '--_ui5wcr-FormGroupTitleHeight': 10
                }}
            >
                <FormGroup>
                    <FormItem>
                        <Input
                            name="email"
                            valueState={emailValueState}
                            required
                            placeholder='Адрес эл. почты'
                            value={email}
                            onInput={handleEmailInput}
                            style={{
                                width: '100%',
                                height: 50,
                                //border: emailValueState ? '' : focusEmailInput ? '2px solid #0a6ed1' : '1px solid #F8F8F8',
                                //borderRadius: 5,
                                fontSize: 16,
                                padding: 0,
                                //backgroundColor: '#F8F8F8'
                            }} />
                    </FormItem>
                    {emailValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {email ? errorMessage : 'Введите почту'}
                            </ObjectStatus>
                        </FormItem>
                    }
                    {/* <FormItem>
                        <Link
                            href=""
                            design='Emphasized'
                            style={{color: '#0070f2', marginBottom: 20}}
                        >
                            Забыли адрес электронной почты?
                        </Link>
                    </FormItem> */}
                </FormGroup>
                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="SpaceBetween"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                id={'createAccountMenuBtn'}
                                design="Transparent"
                                style={{
                                    color: '#0070f2',
                                    fontWeight: 'bold'
                                }}
                                onClick={() => {
                                    //setCreateAccountMenuIsOpen(true);
                                    onRegClick()
                                }}>
                                Регистрация
                            </Button>
                            <Button
                                onClick={() => { setIsLoading(true); performIdentification() }}
                                design="Emphasized" style={{ fontWeight: 'normal', width: 100 }}
                                disabled={email === "" ? true : false}>
                                Далее
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export { IdentificationForm };