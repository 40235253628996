import React, { useEffect, useState } from 'react'
import { FlexBox, ThemeProvider, Loader, MultiComboBox, MultiComboBoxItem, Form, FormGroup, FormItem, Input, Title, ObjectStatus, Icon, Button, Text, DatePicker, Option, Select } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';


const RegStep5 = (props) => {

    const [taxNumberValueState, setTaxNumberValueState] = useState('');

    function isNaturalNumber(n) {
        var n1 = Math.abs(n),
            n2 = parseInt(n, 10);
        return !isNaN(n1) && n2 === n1 && n1.toString() === n;
    }

    async function checkLegalEntityByTaxNumber() {
        props.setIsLoading(true);
        await axios_instance
            .get(
                `/entity/check/by_tax_number/${props.entityData.tax_number}`
            )
            .then(response => {
                console.log(response);
                props.setIsLoading(false);
                sendLegalEntityPermissionRequest();
            })
            .catch(error => {
                console.log(error);
                props.setIsLoading(false);
                props.setStep(6);
            });
    }

    async function sendLegalEntityPermissionRequest() {
        props.setIsLoading(true);
        await axios_instance
            .post(
                `/entity/permission/request`,
                {
                    tax_number: props.entityData.tax_number,
                    email: props.registrationData.email
                }
            )
            .then(response => {
                console.log(response);
                props.setIsLoading(false);
                props.setStep(8);
            })
            .catch(error => {
                console.log(error);
                props.setIsLoading(false);
            });
    }


    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Введите ИНН компании
                </Title>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >
                <FormGroup>
                    <FormItem>
                        <Input
                            name="tax_number"
                            valueState={taxNumberValueState ? "Error" : ""}
                            required
                            placeholder='ИНН'
                            maxlength={10}
                            //value={login}
                            //onInput={handleLoginInput}
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0,
                                marginTop: 50,
                                marginBottom: taxNumberValueState ? 0 : 50
                            }}
                            onInput={e => {
                                setTaxNumberValueState("");
                                props.setEntityData({
                                    ...props.entityData,
                                    tax_number: e.target.typedInValue
                                });
                            }}
                        />
                    </FormItem>
                    {taxNumberValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                                style={{marginBottom: taxNumberValueState ? 50 : 0}}
                            >
                                {taxNumberValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                </FormGroup>
                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="SpaceBetween"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                design="Transparent"
                                style={{
                                    color: '#0070f2',
                                    fontWeight: 'bold'
                                }}
                                onClick={() => {
                                    props.setStep(8);
                                }}
                            >
                                Пропустить
                            </Button>
                            <Button
                                onClick={() => {
                                    if (props.entityData.tax_number.length < 10){
                                        setTaxNumberValueState("ИНН должен содержать не менее 10 цифр");
                                    }
                                    else if (!isNaturalNumber(props.entityData.tax_number)){
                                        setTaxNumberValueState("Неверный формат ИНН");
                                    }
                                    else {
                                        checkLegalEntityByTaxNumber();
                                    }
                                }}
                                design="Emphasized" style={{ fontWeight: 'normal', width: 100 }}>
                                Далее
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export default RegStep5