import { Button, Toast } from '@ui5/webcomponents-react'
import './footer.css'
import { useNavigate } from "react-router-dom";
import { useRef } from 'react';
import MainPageMainContainer from "../MainPageMainContainer/MainPageMainContainer"

const Footer = () => {
  const nav = useNavigate();
  const toast = useRef(null);

  const showToast = () => {
    toast.current.show();
  };

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  var currentYear = new Date().getFullYear()

  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Текст скопирован в буфер обмена:', text);
      })
      .catch((error) => {
        console.error('Ошибка при копировании текста:', error);
      });
  }

  return (
    <>
      <hr/>
      <MainPageMainContainer>
        <div className='main-page-footer'>
          <div className='main-page-footer-content'>
            <div className='main-page-footer-logo'>
              <a href="/" className="main-logo-a">
                <img
                  alt="EXZA"
                  className="main-logo-footer"
                  src={"/static/EXZA_LOGO_NAME_slogan.svg"}
                />
              </a>
            </div>
            <div className='main-page-footer-main'>
              <div className='main-page-footer-main-first'>
                <text className='main-page-footer-main-first-text'>
                  КОНТАКТЫ
                </text>
                <text
                  onClick={() => {
                    copyTextToClipboard("+7 (910) 453-42-96");
                    showToast()
                  }}
                  className='main-page-footer-main-first-dashboard-buttons'
                >
                  +7 (910) 453-42-96
                </text>
                <Toast duration={1000} ref={toast}>Скопировано</Toast>
                <text
                  onClick={() => {
                    copyTextToClipboard("list@exzamarket.ru");
                    showToast()
                  }}
                  className='main-page-footer-main-first-dashboard-buttons'
                >
                  list@exzamarket.ru
                </text>
                {/* <div className='main-page-footer-main-first-messengers'>
                  <Button
                    className='main-page-footer-messenger-logo'
                    design='Transparent'
                    onClick={() => nav("/search")}
                    // onClick={() => openInNewTab(`https://t.me/${???}`)}
                  >
                    <img
                      alt="EXZA"
                      src={"/static/tg_logo.svg"}
                      className='main-page-footer-messenger-logo-img'
                    />
                  </Button>
                  <Button
                    className='main-page-footer-messenger-logo'
                    design='Transparent'
                    onClick={() => nav("/search")}
                    // onClick={() => openInNewTab(`https://vk.com/${???}`)}
                  >
                    <img
                      alt="EXZA"
                      src={"/static/vk_logo.svg"}
                      className='main-page-footer-messenger-logo-img'
                    />
                  </Button>
                </div> */}
              </div>
              <div className='main-page-footer-main-second'>
                <Button
                  onClick={() => nav("/search")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  ПОИСК ЗАПЧАСТЕЙ
                </Button>
                <Button 
                  onClick={() => nav("/search")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  КАТАЛОГ
                </Button>
                <Button 
                  onClick={() => nav("/registration")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  РЕГИСТРАЦИЯ
                </Button>
                <Button
                  onClick={() => nav("/login")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  ВОЙТИ В АККАУНТ
                </Button>
                <Button 
                  onClick={() => nav("/search")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  ОФОРМЛЕНИЕ ЗАКАЗА
                </Button>
              </div>
              <div className='main-page-footer-main-third'>
                <Button
                  onClick={() => nav("/search")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  ДОСТАВКА
                </Button>
                <Button
                  onClick={() => nav("/documents")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  ДОКУМЕНТЫ
                </Button>
                <Button
                  onClick={() => nav("/search")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  ПРЕДЛОЖЕНИЯ
                </Button>
                <Button
                  onClick={() => openInNewTab("https://gitlab.it-psg.com/")}
                  design='Transparent'
                  className='main-page-footer-main-first-dashboard-buttons'>
                  ДЛЯ РАЗРАБОТЧИКОВ
                </Button>
              </div>
            </div>
          </div>
        </div>
      </MainPageMainContainer>
      <hr/>
      <MainPageMainContainer>
        <div className='main-page-footer'>
          <div className='main-page-footer-year-content'>
            <text className='main-page-footer-main-first-text'>
              {currentYear}
            </text>
          </div>
        </div>
      </MainPageMainContainer>
    </>
  )
}

export default Footer