import { useMemo, useState, useEffect } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable
} from 'material-react-table';
import { Box, Typography, MenuItem } from '@mui/material';
import axios_instance from '../../../../requests/config_defaults';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import Table from '../../../base/table/Table';
import { Button, FlexBox, TabContainer, Tab } from '@ui5/webcomponents-react';
import './PermissionRequestsActiveTable.css'

// import { data } from './makeData';


const fields = {
    permission: {
        key: "permission",
        header: "Право доступа",
        disabled: false
    },
    create_date_time_stamp: {
        key: "create_date_time_stamp",
        header: "Время заявки",
        disabled: true
    }
};



const PermissionRequestsActiveTable = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const [permissionRequestsNotProcessed, setPermissionRequestsNotProcessed] = useState([]);

    const [dataTable, setDataTable] = useState([]);

    const [page, setPage] = useState(1);

    useEffect(() => {
        let data_table = [];

        permissionRequestsNotProcessed.map((permission_request) => {
            data_table.push({
                id: permission_request.id,
                permission: permission_request.permission ? permission_request.permission.key : "Не указано",
                create_date_time_stamp: permission_request.create_date_time_stamp ? permission_request.create_date_time_stamp : "Не указано"
            })
        })

        setDataTable(data_table);

        props.setActivePermissionRequestsCount(permissionRequestsNotProcessed.length);
    }, [permissionRequestsNotProcessed])

    const getPermissionRequestNotProcessed = async () => {
        setIsLoading(true);
        await axios_instance.post(
            `/entity/permission/request/not/processed/list/by_manager?page=${page}`,
            {
                entity: props.activeCompany,
                email: props.manager.email
            }
        )
            .then((response) => {
                console.log(response)
                setIsLoading(false);
                setPermissionRequestsNotProcessed(response.data.results);
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false);
            })
    }

    const permissionRequestProcess = async (permission_request_id, status) => {
        await axios_instance.put(
            `/entity/permission/request/process/${permission_request_id}`,
            {
                entity: props.activeCompany,
                status: status,
                email: props.manager.email
            }
        )
            .then((response) => {
                console.log(response)
                props.setPermissionRequestsUpdate(!props.permissionRequestsUpdate);
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        getPermissionRequestNotProcessed();
    }, [])

    useEffect(() => {
        getPermissionRequestNotProcessed();
    }, [props.permissionRequestsUpdate])

    return (
        <>
            <Table
                columns={fields}
                data={dataTable}
                enableEditing={false}
                enableColumnResizing
                enableColumnFilters={false}
                enableColumnOrdering={true}
                enableGrouping={false}
                enableColumnPinning={false}
                enableFacetedValues={false}
                enableRowActions
                enableStickyHeader
                className={"permissions-table-container"}

                isLoading={isLoading}

                pagination={{
                    page: page,
                    setPage: setPage,
                    pageCount: props.maxPage
                }}

                rowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                        key="access"
                        onClick={() => {
                            permissionRequestProcess(row.original?.id, true);
                            closeMenu();
                        }}>
                        Подтвердить
                    </MenuItem>,
                    <MenuItem
                        key="deny"
                        onClick={() => {
                            permissionRequestProcess(row.original?.id, false);
                            closeMenu();
                        }}>
                        Отклонить
                    </MenuItem>,
                ]
                }

                topToolbarCustomActions={
                    <FlexBox direction='Column'>
                        <Typography color="error.main" component="span" variant="h6">
                            Активные заявки
                        </Typography>
                    </FlexBox>
                }

            />
        </>
    )
}

export default PermissionRequestsActiveTable