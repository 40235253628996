import React, { useEffect, useRef, useState } from "react";
import "./ProductSearcher.css";
import { useSearchParams } from "react-router-dom";
import { Input } from "@ui5/webcomponents-react";
import ProductSearcherResultCard from "./product-searcher-result-card/ProductSearcherResultCard";
import axios_instance from "../../../requests/config_defaults";
import { ProductSearchHeader } from "../product-searcher-header/ProductSearchHeader";


const ProductSearcher = ({
  searcher,
  geo_object,
  activeProduct,
  activeAddress,
  searchParams,
  setSearchParams,
  isAuth,
  setIsOpen,
}) => {
  const [product, setProduct] = useState(null);
  const [analogs, setAnalogs] = useState([]);
  const [openOrderProject, setOpenOrderProject] = useState(false);


  const getProductWithAnalogs = async () => {
    await axios_instance
      .get(`/product/with/analogs/${activeProduct}`)
      .then(({ data }) => {
        setProduct(data);
        setAnalogs(data.analogs);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getProductWithAnalogs();
  }, [activeProduct]);



  return (
    <div className="searcher__results__container">
      {product && (
        <ProductSearcherResultCard
          searcher={searcher}
          geo_object={geo_object}
          product={product}
          isAuth={isAuth}
          address={activeAddress}
          setIsOpen={setIsOpen}
        />
      )}
      {product ? (
        analogs ? (
          <>
            <span className="searcher__results__empty">Другие предложения</span>
            {analogs.map((analog) => {
              return (
                <ProductSearcherResultCard
                  searcher={searcher}
                  geo_object={geo_object}
                  product={analog}
                  isAuth={isAuth}
                  address={activeAddress}
                  setIsOpen={setIsOpen}
                />
              );
            })}
          </>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductSearcher;
