import axios_instance from "./config_defaults";

export const getLog = async (data) => {
  let response_data = {};
  await axios_instance
    .post(`/log/filter`, data)
    .then(({ data }) => {
      response_data = data?.results;
    })
    .catch((error) => {
      console.log(error);
    });
  return response_data;
};

export const getIcons = async (data) => {
  let response_data = {};
  await axios_instance
    .get(`/market/get/by_id`)
    .then(({ data }) => {
      response_data = {
        name: data?.name,
        short_name: data?.short_name,
        root_link: data?.root_link,
        mark_up: data?.mark_up,
        icon: data?.icon,
        icon_mini: data?.icon_mini,
        icon_full: data?.icon_full,
        default_product_img: data?.default_product_img,
      };
    })
    .catch((error) => {
      console.log(error);
      response_data = {
        name: "",
        short_name: "",
        root_link: "",
        mark_up: 0,
        icon: "https://b24.psg.services/docs/pub/d90c2b63282d209d5dc922b4ac7ea150/showFile/?&token=ve1zwvxy8pie",
        icon_mini:
          "https://b24.psg.services/docs/pub/d90c2b63282d209d5dc922b4ac7ea150/showFile/?&token=ve1zwvxy8pie",
        icon_full:
          "https://b24.psg.services/docs/pub/d90c2b63282d209d5dc922b4ac7ea150/showFile/?&token=ve1zwvxy8pie",
        default_product_img:
          "https://b24.psg.services/docs/pub/d90c2b63282d209d5dc922b4ac7ea150/showFile/?&token=ve1zwvxy8pie",
      };
    });
  return response_data;
};
