import React, { useState } from 'react'
import { Button, FlexBox, Select, Option } from '@ui5/webcomponents-react';


const RegistrationFooter = (props) => {

    const [selectOpen, setSelectOpen] = useState(false);

    return (
        <>
            <Select
                onOpen={() => setSelectOpen(true)}
                onClose={() => setSelectOpen(false)}
                style={{
                    border: selectOpen ? '1px solid #0854a0' : 'none',
                    borderRadius: 5
                }}
            >
                <Option>
                    Русский
                </Option>
                <Option>
                    Английский
                </Option>
            </Select>
            <FlexBox
                alignItems="Center"
                direction="Row"
                justifyContent="SpaceAround"
                wrap="NoWrap"
            >
                <Button design="Transparent" className='auth-button'>Справка</Button>
                <Button design="Transparent" className='auth-button'>Конфиденциальность</Button>
                <Button design="Transparent" className='auth-button'>Условия</Button>
            </FlexBox>
        </>
    )
}

export { RegistrationFooter };