import React, { useEffect, useState } from 'react'

import Carousel from 'react-gallery-carousel';
import 'react-gallery-carousel/dist/index.css';

import "@ui5/webcomponents-icons/dist/full-screen.js";
import "@ui5/webcomponents-icons/dist/exit-full-screen.js";
import "@ui5/webcomponents-icons/dist/navigation-right-arrow.js";
import "@ui5/webcomponents-icons/dist/navigation-left-arrow.js";
import "@ui5/webcomponents-icons/dist/media-pause.js";
import "@ui5/webcomponents-icons/dist/media-play.js";
import { Icon } from '@ui5/webcomponents-react';

const GalleryController = ({ design, name }) => {
    return (
        <Icon design={design} name={name} style={{ width: "1rem" }} />
    )
}

const Gallery = ({
    images,

    height,
    width
}) => {
    const [isFullScreen, setIsFullScreen] = useState(false)
    return (
        <Carousel
            objectFit="contain"
            leftIcon={<GalleryController design={"Information"} name='navigation-left-arrow' />}
            rightIcon={<GalleryController design={"Information"} name='navigation-right-arrow' />}
            playIcon={<GalleryController design={"Information"} name='media-play' />}
            pauseIcon={<GalleryController design={"Information"} name='media-pause' />}
            minIcon={<GalleryController design={"Information"} name='exit-full-screen' />}
            maxIcon={<GalleryController design={"Information"} name='full-screen' />}
            isMaximized={isFullScreen}
            maximize={() => setIsFullScreen(true)}
            minimize={() => setIsFullScreen(false)}
            style={{ height: height, width: width, backgroundColor: "#fff" }}
            images={images} />
    )
}

export default Gallery