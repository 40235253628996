import React, { useEffect, useMemo, useState } from 'react'
import axios_instance from '../../../requests/config_defaults';
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { Paper, TableContainer, MenuItem } from "@mui/material";
import './CompanyDetailAddressesView.css'
import { Badge, Button, ButtonDesign, CheckBox } from '@ui5/webcomponents-react';
import Table from '../../base/table/Table';
import ModalWindow from '../../base/modal/ModalWindow';
import { useAtom } from 'jotai';
import { addressesRefresh } from '../company-detail-view/CompanyDetailView';



const fields = {
    name: {
        key: "name",
        header: "Наименование",
        disabled: false,
    },
    city: {
        key: "city",
        header: "Населенный пункт",
        disabled: true,
        editable: false
    },
    exact_address: {
        key: "exact_address",
        header: "Адрес",
        disabled: true,
    },
    type: {
        key: "type",
        header: "Тип",
        disabled: false,
    },
    is_default: {
        key: "is_default",
        header: "Адрес по умолчанию",
        disabled: false,
        size: 300,
        Cell: (({ renderedCellValue, row }) =>
        (
            <Badge
                colorScheme={renderedCellValue == true ? "8" : "10"}

            >{renderedCellValue === true ? "Да" : "Нет"}</Badge>
        ))
    },
    is_pvz: {
        key: "is_pvz",
        header: "Режим доставки 'ПВЗ'",
        disabled: false,
        size: 300,
        Cell: (({ renderedCellValue, row }) =>
        (
            <Badge
                colorScheme={renderedCellValue == true ? "8" : "10"}

            >{renderedCellValue === true ? "Да" : "Нет"}</Badge>
        ))
    }
};


const CompanyDetailAddressesView = ({ activeCompany, setModalIsOpen, setAddressUpdateMode, setUpdatedAddress, companyAddresses, permissionData }) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false)
    const [refresher] = useAtom(addressesRefresh)

    const getCompanyAddresses = async () => {
        setLoading(true);
        await axios_instance.get(
            `/myCompany/addresses/${activeCompany}`
        )
            .then(({ data }) => {
                let data_table = []
                console.log(data)
                data?.map((address) => {
                    data_table.push({
                        id: address.id,
                        city: address?.geo_object ? address?.geo_object?.full_name : "Не указан",
                        exact_address: address.exact_address ? address.exact_address : "Не указан",
                        name: address.name ? address.name : "Не указано",
                        type: address?.type?.name ? address?.type?.name : "Не указан",
                        is_default: address?.is_default,
                        is_pvz: address?.pvz
                    })
                })
                setData(data_table)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    useEffect(() => {
        getCompanyAddresses()
    }, [activeCompany, refresher, companyAddresses])

    return (
        <>
            <Table
                className={"addresses-table"}
                columns={fields}
                data={data && data}
                editDisplayMode='cell'
                enableEditing={Boolean(permissionData?.admin)}
                enableColumnResizing
                enableColumnFilters={false}
                enableColumnOrdering={true}
                enableGrouping={false}
                enableColumnPinning={false}
                enableFacetedValues={false}
                enableStickyHeader
                enableStickyFooter
                enableRowActions
                isLoading={loading}
                topToolbarCustomActions={
                    <>
                        <Button
                            disabled={!Boolean(permissionData?.admin)}
                            onClick={() => setModalIsOpen(true)}
                            design={ButtonDesign.Emphasized}>
                            Добавить адрес
                        </Button>
                    </>
                }
                rowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                        disabled={!Boolean(permissionData?.admin)}
                        key="edit"
                        onClick={() => {
                            setAddressUpdateMode(true);
                            setUpdatedAddress(row.original);
                            closeMenu();
                        }}>
                        Редактировать
                    </MenuItem>,
                ]
                }
            />
        </>
    )
}

export default CompanyDetailAddressesView