import { useState, useEffect } from "react";
import axios_instance from "../../../../../requests/config_defaults";
import {
  Button,
  FlexBox,
  Form,
  FormGroup,
  FormItem,
  Label,
  Input,
  CheckBox,
  Tab,
  TabContainer,
  Text,
  Select,
  Option,
} from "@ui5/webcomponents-react";
import { Box, MenuItem } from "@mui/material";
import Table from "../../../../base/table/Table";
import "./AdminNewProductCreateForm.css";

const fields = {
  name: {
    key: "property_name",
    header: "Наименование",
    disabled: false,
    editable: false,
    size: 200,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    editable: false,
    size: 200,
  },
  manufacture: {
    key: "manufacture",
    header: "Производитель",
    editable: false,
    align: "center",
    size: 200,
  },
  is_standard: {
    key: "is_standard",
    header: "Эталонный товар",
    editable: false,
    align: "center",
    size: 200,
    Cell: ({ renderedCellValue, cell }) => (
      <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1rem',
              borderRadius: 20,
              padding: '5px',
              color: "#fff",
          }}
      >
          <span
              style={{
                  color: renderedCellValue === true ? "green" : "red"
              }}
          >
              {renderedCellValue === true ? "Да" : "Нет"}
          </span>
      </Box>
    ),
  }
};

const AdminNewProductCreateForm = (props) => {
  const [properties, setProperties] = useState([]);

  const [brands, setBrands] = useState([]);
  const [activeBrand, setActiveBrand] = useState("Не указан");

  const [manufactures, setManufactures] = useState([]);
  const [activeManufacture, setActiveManufacture] = useState("Не указан");

  const [codeCreate, setCodeCreate] = useState("");
  const [codeCreateError, setCodeCreateError] = useState(false);

  const [nameCreate, setNameCreate] = useState("");
  const [nameCreateError, setNameCreateError] = useState(false); 

  const [standardParametr, setStandardParametr] = useState(false);

  const [codeBrandManufactureError, setCodeBrandManufactureError] = useState(false);
  const [addPropertyError, setAddPropertyError] = useState(false);
  const [standardParametrError, setStandardParametrError] = useState(false);

  const [data, setData] = useState([]);

  const [analogs, setAnalogs] = useState([]);
  const [inputCode, setInputCode] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1)

  const getAnalogsByCode = async () => {
    await axios_instance
      .get(`products?page=${page}${`&filters=[{"id": "article", "value": "${inputCode}"}]`}`)
      .then(({ data }) => {
        const data_columns = [];
        setPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        data?.results.filter(analog => analog.code === inputCode).map((analog) => {
          data_columns.push({
            id: analog.id,
            article: analog.code,
            property_name: analog.name?analog.name: "Не указано",
            brand: analog.brand?.name ? analog.brand?.name : "Не указан",
            manufacture: analog.manufacture?.name ? analog.manufacture?.name : "Не указан",
            is_standard: analog.is_standard,
            properties: analog.properties,
          });
        });
        setAnalogs(data_columns);
      })
      .catch((error) => {
      });
  };

  const newProductCreate = async () => {
    await axios_instance
    .post(`/product/create`, {
      code: codeCreate,
      brand: activeBrand,
      manufacture: activeManufacture,
      is_standard: standardParametr,
      properties: properties,
      name: nameCreate,
    })
      .then(({ data }) => {
        props.getAllProducts();
        props.getProductsWithoutProvider();
        props.setNewProductCreateFormOpen(false);
        setStandardParametr(false);
        setAddPropertyError(false);
        setCodeCreate("");
        setActiveManufacture("Не указан");
        setActiveBrand("Не указан");
        setProperties([]);
        setData([]);
      })
      .catch((error) => {
        if (error.response.data.message === "Товар с такой связкой парметров: Артикул-Бренд-Производитель уже существует!") {
          setCodeBrandManufactureError(true)
        }
      });
  };

  const newProductCreateInCatalog = async () => {
    await axios_instance
    .post(`/product/create/in_catalog/` + props.activeCatalogID, {
      code: codeCreate,
      brand: activeBrand,
      manufacture: activeManufacture,
      is_standard: standardParametr,
      properties: properties,
      name: nameCreate,
    })
      .then(({ data }) => {
        props.getCatalog();
        props.getCatalogProducts();
        props.setNewProductCreateFormOpen(false);
        setStandardParametr(false);
        setAddPropertyError(false);
        setCodeCreate("");
        setActiveManufacture("Не указан");
        setActiveBrand("Не указан");
        setProperties([]);
        setData([]);
      })
      .catch((error) => {
        if (error.response.data.message === "Товар с такой связкой парметров: Артикул-Бренд-Производитель уже существует!") {
          setCodeBrandManufactureError(true)
        }
      });
  };

  const getBrands = async () => {
    await axios_instance
      .get(`brand_list`)
      .then(({ data }) => {
        setBrands(data);
      })
      .catch((error) => {});
  };

  const getManufactures = async () => {
    await axios_instance
      .get(`manufacture_list`)
      .then(({ data }) => {
        setManufactures(data);
      })
      .catch((error) => {});
  };

  const propertyAddHandler = (e, index) => {
    let property = [...data];
    property[index][e.target.name] = e.target.value.trim();
    setData(property);
  }

  const addFields = () => {
    let object = {
      name: '',
      value: '',
      measure: '',
    }
    setData([...data, object])
  }

  const removeFields = (index) => {
    let property = [...data];
    property.splice(index, 1)
    setData(property)
  }

  const productUpdateHandler = () => {
    let addProductCreateErrorCount = 0
    const primaryPropertyList = []
    if (codeCreate === "") {
      setCodeCreateError(true);
      addProductCreateErrorCount = addProductCreateErrorCount + 1
    }
    if (nameCreate === "") { 
      setNameCreateError(true); 
      addProductCreateErrorCount = addProductCreateErrorCount + 1
    }
    data.map((property) => {
      if (property.measure === "") {
        property.measure = "-"
      };
      if (property.name === "" || property.value === "") {
        setAddPropertyError(true);
        addProductCreateErrorCount = addProductCreateErrorCount + 1
      };
      properties.push(property);
      if (
        property.name === "Высота" ||
        property.name === "Длина" ||
        property.name === "Ширина" ||
        property.name === "Вес") {
        primaryPropertyList.push(property.name)
      }
    })
    if (standardParametr === true && new Set(primaryPropertyList).size !== 4) {
        setStandardParametrError(true);
        addProductCreateErrorCount = addProductCreateErrorCount + 1
      }
    if (addProductCreateErrorCount === 0) {
      if (props.allProductsOpen || props.productsWithoutProviderOpen) {
        newProductCreate();
      } else newProductCreateInCatalog();
    } else setProperties([])
  }

  const propertiesPrimaryCreateFromAnalogHandler = (analog) => {
    const data_properties = []
    analog.properties?.map((prop) => {
      if (prop.is_primary && !data?.find(property => property.name === prop.name)) {
        data_properties.push({
          name: prop.name,
          value: prop.value.content,
          measure: prop.measure.name,
        })
      };
      let newData = data.concat(data_properties);
      setData(newData);
    })
  }

  const propertiesNotPrimaryCreateFromAnalogHandler = (analog) => {
    const data_properties = []
    console.log(analog.properties)
    analog.properties?.map((prop) => {
      if (!prop.is_primary && !data?.find(property => property.name === prop.name)) {
        data_properties.push({
          name: prop.name,
          value: prop.value.content,
          measure: prop.measure ? prop.measure.name : "",
        })
      };
      let newData = data.concat(data_properties);
      setData(newData);
    })
  }

  useEffect(() => {
    if (inputCode !== "") {
      getAnalogsByCode();
    }
  }, [inputCode]);

  useEffect(() => {
    getBrands();
  }, [props.brandCreateFormOpen]);

  useEffect(() => {
    getManufactures();
  }, [props.manufactureCreateFormOpen]);

  return (
    <div style={{ margin: 20 }}>
      <Form
        columnsL={1}
        columnsM={1}
        columnsS={1}
        columnsXL={1}
        labelSpanL={1}
        labelSpanM={1}
        labelSpanS={1}
        labelSpanXL={1}
        style={{
          alignItems: "left",
        }}
        titleText="Заполните форму"
      >
        <FormGroup>
          <FormItem>
            <FlexBox
              style={{
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Label
                style={{
                  fontSize: "1.1rem",
                  alignItems: "center",
                }}
              >
                Артикул:
              </Label>
              <Input
                placeholder="Введите артикул"
                valueState={codeCreateError || codeBrandManufactureError ? "Error" : "None"}
                valueStateMessage={!codeBrandManufactureError ? <div>Обязательное поле</div> : <div>Артикул-Бренд-Производитель должны быть уникальной связкой!</div>}
                onInput={(e) => {
                  setCodeCreateError(false);
                  setCodeBrandManufactureError(false);
                  setInputCode(e.target.value);
                }}
                onChange={(e) => {
                  setCodeCreate(e.target.value);
                }}
              />
            </FlexBox>
          </FormItem>
          <FormItem>
            <FlexBox
              style={{
                alignItems: "center",
                gap: "10px",
              }}
            >
              <>
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Бренд:
                </Label>
                <Select
                  value={activeBrand}
                  valueState={codeBrandManufactureError ? "Error" : "None"}
                  valueStateMessage={<div>Артикул-Бренд-Производитель должны быть уникальной связкой!</div>}
                  onChange={(e) => {
                    setActiveBrand((e.target.value !== "Не указан" ? e.target.value : "Не указан"));
                    setCodeBrandManufactureError(false);
                  }}
                >
                  <Option>Не указан</Option>
                  {brands?.map((brand) => (
                    <Option value={brand?.id}>{brand.name}</Option>
                  ))}
                </Select>
                <Button
                  icon="add"
                  onClick={() => props.setBrandCreateFormOpen(true)}
                />
              </>
            </FlexBox>
          </FormItem>
          <FormItem>
            <FlexBox
              style={{
                alignItems: "center",
                gap: "10px",
              }}
            >
              <>
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Производитель:
                </Label>
                <Select
                  value={activeManufacture}
                  valueState={codeBrandManufactureError ? "Error" : "None"}
                  valueStateMessage={<div>Артикул-Бренд-Производитель должны быть уникальной связкой!</div>}
                  onChange={(e) => {
                    setActiveManufacture((e.target.value !== "Не указан" ? e.target.value : "Не указан"));
                    setCodeBrandManufactureError(false);
                  }}
                >
                  <Option>Не указан</Option>
                  {manufactures?.map((manufacture) => (
                    <Option value={manufacture?.id}>{manufacture.name}</Option>
                  ))}
                </Select>
                <Button
                  icon="add"
                  onClick={() => props.setManufactureCreateFormOpen(true)}
                />
              </>
            </FlexBox>
          </FormItem>
          <FormItem>
            <FlexBox
              style={{
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Label
                style={{
                  fontSize: "1.1rem",
                  alignItems: "center",
                }}
              >
                Наименование:
              </Label>
              <Input
                placeholder="Введите наименование"
                valueState={nameCreateError ? "Error" : "None"} 
                valueStateMessage={<div>Обязательное поле</div>}
                onInput={(e) => {
                  setNameCreateError(false); 
                  setNameCreate(e.target.value); 
                }}
              />
            </FlexBox>
          </FormItem>
          {codeBrandManufactureError ? (
            <FormItem>
              <FlexBox>
                <Text
                  style={{
                    fontSize: "1.1rem",
                    color: "red",
                    width: "70%"
                  }}
                >
                  Товар с такой связкой парметров: Артикул-Бренд-Производитель уже существует!
                </Text>
              </FlexBox>
            </FormItem>
          ) : (
            null
          )}
          <FormItem>
            <FlexBox
              style={{
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Label
                style={{
                  fontSize: "1.1rem",
                  alignItems: "center",
                }}
              >
                Эталонный товар:
              </Label>
              <CheckBox
                onChange={(e) => {
                  setStandardParametr(e.target.checked);
                  setStandardParametrError(false);
                }}
                valueState={standardParametrError ? "Error" : "None"}
                checked={false}
              />
            </FlexBox>
          </FormItem>
          <FormItem>
            {standardParametrError ? (
              <Text
                style={{
                  fontSize: "1.1rem",
                  color: "red",
                  marginLeft: '20px'
                }}
              >
                У эталонного товара должны быть заполнены 4 ВГХ характеристики: Вес, Длина, Ширина, Высота!
              </Text>
            ) : (null)}
          </FormItem>
        </FormGroup>
        <FormGroup titleText="Характеристики">
        <FormItem
          style={{
            alignItems: "center",
          }}
        >
          {addPropertyError ? (
            <Text
              style={{
                fontSize: "1.1rem",
                color: "red",
                marginLeft: "80px",
              }}
            >
              Заполните или удалите все обязательные поля!
            </Text>
          ) : (
            null
          )}
        </FormItem>
        {data.map((form, index) => {
          return (
            <FormItem key={index}>
              <FlexBox
                style={{
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Input
                  className="AdminNewProductCreateFormInputItem"
                  placeholder="Наименование"
                  valueState={addPropertyError && form.name === "" ? "Error" : "None"}
                  valueStateMessage={<div>Обязательное поле</div>}
                  onInput={() => (setAddPropertyError(false))}
                  name="name"
                  value={form.name}
                  onChange={(e) => {
                    propertyAddHandler(e, index);
                    setProperties([])
                  }}
                />
                <Input
                  className="AdminNewProductCreateFormInputItem"
                  placeholder="Значение"
                  valueState={addPropertyError && form.value === "" ? "Error" : "None"}
                  valueStateMessage={<div>Обязательное поле</div>}
                  onInput={() => (setAddPropertyError(false))}
                  name="value"
                  value={form.value}
                  onChange={(e) => {
                    propertyAddHandler(e, index);
                    setProperties([])
                  }}
                />
                <Input
                  className="AdminNewProductCreateFormInputItem"
                  placeholder="Единицы измерения"
                  name="measure"
                  value={form.measure}
                  onChange={(e) => {
                    propertyAddHandler(e, index);
                    setProperties([])
                  }}
                />
                <Button
                  onClick={() => {
                    removeFields(index);
                    setAddPropertyError(false);
                    setProperties([])
                  }}
                  icon="delete"
                />
              </FlexBox>
            </FormItem>
          )
        })}
        </FormGroup>
        <FormItem alignItems={"left"} label="">
          <Button
            icon="add"
            style={{ width: "100%" }}
            onClick={() => {
              addFields();
              setStandardParametrError(false)
            }}
          >
            Добавить характеристику...
          </Button>
        </FormItem>
        <FormItem alignItems={"left"} label="">
          <Button
            design="Emphasized"
            style={{
              padding: "0.5rem 1rem",
              width: "100%",
            }}
            onClick={() => {
              productUpdateHandler();
            }}
          >
            Создать товар
          </Button>
        </FormItem>
        <FormGroup titleText="Аналоги">
          <FormItem>
            <Table
              className="AdminCatalogProductAnalogsTable"
              columns={fields}
              data={analogs}
              enableEditing={false}
              enableColumnResizing
              enableColumnOrdering={true}
              enableGrouping={false}
              enableColumnPinning={true}
              enableFacetedValues={true}
              enableRowActions={true}
              enableStickyFooter={true}
              enableStickyHeader={true}
              pagination={{
                page: page,
                setPage: setPage,
                pageCount: pageCount,
              }}
              rowActionMenuItems={({ row, closeMenu }) => [
                <>
                  <MenuItem
                    key="view"
                    disabled={row.original.is_standard ? false : true}
                    onClick={() => {
                      propertiesPrimaryCreateFromAnalogHandler(row.original);
                      closeMenu();
                    }}
                  >
                    Перенести ВГХ характеристики к новому товару
                  </MenuItem>
                  <MenuItem
                    key="view"
                    disabled={row.original.is_standard ? false : true}
                    onClick={() => {
                      propertiesNotPrimaryCreateFromAnalogHandler(row.original);
                      closeMenu();
                    }}
                  >
                    Перенести дополнительные характеристики к новому товару
                  </MenuItem>
                </>
              ]}
              renderDetailPanel={(row, table) => (
                <>
                  <TabContainer
                    contentBackgroundDesign="Solid"
                    headerBackgroundDesign="Solid"
                    onTabSelect={function _a() { }}
                    tabLayout="Standard"
                    style={{ width: "2000px" }}
                  >
                    <Tab
                      selected
                      text="ВГХ характеристики"
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          left: '30px',
                          maxWidth: '1000px',
                          position: 'sticky',
                          width: '100%',
                        }}
                      >
                        <Box sx={{ textAlign: 'start', marginLeft: '50px' }}>
                          {row?.row?.original?.properties?.map((prop) =>
                            <FlexBox>
                              {prop.is_primary ? (
                                <Text
                                  style={{
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  {prop?.name}: {prop?.value?.content} {prop?.measure?.name}
                                </Text>
                              ) : (null)}
                            </FlexBox>
                          )}
                        </Box>
                      </Box>
                    </Tab>

                    <Tab
                      text="Дополнительные характеристики"
                    >
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'flex-start',
                          left: '30px',
                          maxWidth: '1000px',
                          position: 'sticky',
                          width: '100%',
                        }}
                      >
                        <Box sx={{ textAlign: 'start', marginLeft: '50px' }}>
                          {row?.row?.original?.properties?.map((prop) =>
                            <FlexBox>
                              {!prop.is_primary ? (
                                <Text
                                  style={{
                                    fontSize: "1.1rem",
                                  }}
                                >
                                  {prop?.name}: {prop?.value?.content}
                                </Text>
                              ) : (null)}
                            </FlexBox>
                          )}
                        </Box>
                      </Box>
                    </Tab>

                  </TabContainer>
                </>
              )}
            />
          </FormItem>
        </FormGroup>
      </Form>
    </div>
  );
};

export default AdminNewProductCreateForm;
