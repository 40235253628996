import React, { useEffect, useState } from 'react'
import { Form, FormGroup, FormItem, Input, Text, Button, FlexBox, Title, ObjectStatus, Icon, CheckBox } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAtom } from 'jotai';
import { activeCompanyAtom, emailAtom, fullNameAtom, isAdminAtom, isImpersonatedAtom, activeCompanyAddressAtom } from '../../../hoc/GlobalAtoms';


const AuthenticationForm = (props) => {
    const nav = useNavigate()
    const location = useLocation();

    const onSuccess = props.onSuccess || (() => {});

    const fromPage = location.state?.from?.pathname || '/';

    const [email] = useAtom(emailAtom);
    const [fullName, setFullName] = useAtom(fullNameAtom);
    const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
    const [activeCompany, setActiveCompany] = useAtom(activeCompanyAtom);
    const [activeCompanyAddress, setActiveCompanyAddress] = useAtom(activeCompanyAddressAtom);
    const [isImpersonated, setIsImpersonated] = useAtom(isImpersonatedAtom);

    const setIsLoading = (isLoading) => {
        props.setIsLoading(isLoading);
    };

    const closeAuthorizationWindow = () => {
        onSuccess()
    };

    const [passwordValueState, setPasswordValueState] = useState('');

    const [focusPasswordInput, setFocusPasswordInput] = useState(false);

    const [passwordIsShown, setPasswordIsShown] = useState(false);

    const [password, setPassword] = useState('');

    const handlePasswordInput = (e) => {
        setPassword(e.target.value);
    };

    const getMe = async () => {
        await axios_instance
            .get("/getMe")
            .then((response) => {
                setFullName(
                    `${response.data.user.last_name} ${response.data.user.first_name}`
                );
                setIsAdmin(response.data.is_admin || response.data.user.is_superuser);
                console.log(`Is Admin ${response.data.is_admin, response.data.user.is_superuser}`)
            })
            .catch((error) => { });
    };

    const getActiveCompany = async () => {
        await axios_instance
            .get("/myCompany")
            .then((response) => {
                setActiveCompany(response.data);
                getCompanyAddresses(response.data.id);
            })
            .catch((error) => { });
    };

    const getCompanyAddresses = async (_id) => {
        await axios_instance
          .get(`/myCompany/addresses/${_id}`)
          .then(({ data }) => {
            const default_address = data.find(address => address.is_default === true) || null;
            if (default_address) {
              setActiveCompanyAddress({
                id: default_address.id,
                entity: _id,
                exact_address: default_address.exact_address,
                geo_object: default_address.geo_object,
                geo_object_full: default_address.geo_object_full,
                name: default_address.name,
                pvz: default_address?.pvz,
                type: default_address?.type,
                is_default: default_address?.is_default,
              });
            } else setActiveCompanyAddress({})
          })
          .catch((error) => {});
      };

    async function performAuthentication() {
        await axios_instance
            .post('/login',
                {
                    email: email,
                    password: password,
                })
            .then(response => {
                getMe();
                getActiveCompany();
                setIsImpersonated(false)
                response.data.status ? closeAuthorizationWindow() : setPasswordValueState('Error');
            })
            .catch(error => {
                console.log(error);
                setPasswordValueState('Error');
            });
        setIsLoading(false);
    }

    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Добро пожаловать!
                </Title>
                <div style={{ border: '1px solid gray', borderRadius: 10, padding: 5 }}>
                    <Text>
                        {email}
                    </Text>
                </div>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
                style={{
                    '--_ui5wcr-FormGroupTitleHeight': 10
                }}
            >
                <FormGroup>
                    <FormItem>
                        <Input
                            type={passwordIsShown ? 'Text' : 'Password'}
                            name="password"
                            valueState={passwordValueState}
                            required
                            placeholder='Пароль'
                            value={password}
                            onInput={handlePasswordInput}
                            onFocus={() => setFocusPasswordInput(true)}
                            onBlur={() => setFocusPasswordInput(false)}
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0,
                            }} />
                    </FormItem>
                    {passwordValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {password ? 'Неверный пароль' : 'Введите пароль'}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem>
                        <CheckBox
                            onChange={() => { setPasswordIsShown(!passwordIsShown) }}
                            text="Показать пароль"
                        />
                    </FormItem>
                </FormGroup>
                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="SpaceBetween"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                design="Transparent"
                                style={{
                                    color: '#0070f2',
                                    fontWeight: 'bold'
                                }}
                                onClick={() => { props.setStepResetPassword(1); }}
                            >
                                Забыли пароль?
                            </Button>
                            <Button
                                onClick={() => { setIsLoading(true); performAuthentication() }}
                                design="Emphasized" style={{ fontWeight: 'normal', width: 100 }}>
                                Войти
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export { AuthenticationForm };
