import React, { useEffect, useState } from 'react'
import './SendInviteForm.css'
import { Bar, Button, SuggestionItem, Select, Option, Form, FormGroup, FormItem, Input, ObjectStatus, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults'


const SendInviteForm = (props) => {
    const [sendPermissionRequestData, setSendPermissionRequestData] = useState({
        email: "",
        permission: "",
        entity: ""
    });

    const [legalEntities, setLegalEntities] = useState([]);

    const [legalEntityPermissions, setLegalEntityPermissions] = useState([]);

    const [permissionValueState, setPermissionValueState] = useState('');

    const [entityValueState, setEntityValueState] = useState('');

    const getMe = async () => {
        await axios_instance.get(
            `/getMe`
        )
            .then((response) => {
                setSendPermissionRequestData((prev) => ({
                    ...prev,
                    email: response.data.user.email
                }))
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getEntitiesList = async (query) => {
        await axios_instance.get(
            `/getCompanies?query=${query}`
        )
            .then((response) => {
                setLegalEntities(response.data.results);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getLegalEntityPermissionsList = async () => {
        await axios_instance.get(
            "/entity/permissions/list"
        )
            .then((response) => {
                setLegalEntityPermissions(response.data.results);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const sendPermissionRequest = async () => {
        await axios_instance.post(
            "/entity/permission/request/send",
            sendPermissionRequestData
        )
            .then((response) => {
                props.setSendInviteMode(false);
                setPermissionValueState("");
                props.setLegalEntitiesUpdate(!props.legalEntitiesUpdate);
                console.log(response);
            })
            .catch((error) => {
                if (error.response.status === 400){
                    setEntityValueState(error.response.data.status);
                }
                console.log(error);
            })
    }

    useEffect(() => {
        getMe();
        getLegalEntityPermissionsList();
    }, [])

    return (
        <div style={{ margin: 20 }}>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanL={1}
                labelSpanM={1}
                labelSpanS={1}
                labelSpanXL={1}
                style={{
                    alignItems: 'left',

                }}
                // className='MyOfferCreateForm-form'
                titleText="Заполните форму"
            >
                <FormGroup>
                    <FormItem label="Компания">
                        <Input
                            required
                            name="entity"
                            valueState={entityValueState ? "Error" : ""}
                            onInput={
                                (e) => {
                                    setEntityValueState("");
                                    // setSendPermissionRequestData((prev) => ({
                                    //     ...prev,
                                    //     entity: e.target.value
                                    // }));
                                    getEntitiesList(e.target.value);
                                }
                            }
                            // icon={emailSuggestionItemSelectValueState ? <Icon design='Positive' name="accept" /> : ""}

                            // placeholder='Почта'
                            style={{ width: '100%' }}
                            showSuggestions
                            onSuggestionItemSelect={e => {
                                setSendPermissionRequestData((prev) => ({
                                    ...prev,
                                    entity: e.detail.item.attributes.id.value
                                }));
                            }}
                        >
                            {legalEntities.map((entity) => {
                                return (
                                    <SuggestionItem
                                        id={entity?.id}
                                        description={entity?.tax_number}
                                        icon="customer"
                                        text={entity?.name}
                                    />
                                )
                            })}
                        </Input>
                    </FormItem>
                    {entityValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {entityValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="Право доступа">
                        <Select
                            onChange={e => {
                                setPermissionValueState("");
                                setSendPermissionRequestData((prev) => ({
                                    ...prev,
                                    permission: e.detail.selectedOption.id
                                }))
                            }}
                            onClose={function _a() { }}
                            onLiveChange={function _a() { }}
                            onOpen={function _a() { }}
                            valueState={permissionValueState ? "Error": ""}
                            style={{ width: '100%' }}
                            valueStateMessage={<div>Выберите право доступа</div>}
                        >
                            <Option id="default">

                            </Option>
                            {legalEntityPermissions.map((permission) => {
                                return (
                                    <Option id={permission.id}>
                                        {permission.name}
                                    </Option>
                                )
                            })}

                        </Select>
                    </FormItem>
                </FormGroup>
                <FormItem alignItems={"left"} label="">
                    <Button
                        design='Emphasized'
                        style={{
                            padding: "0.5rem 1rem",
                            width: "100%"
                        }}
                        onClick={() => {
                            let validate_status = true;
                            if (sendPermissionRequestData.entity === "") {
                                setEntityValueState("Обязательное поле");
                                validate_status = false;
                            }
                            if (sendPermissionRequestData.permission === "default" || sendPermissionRequestData.permission === "") {
                                setPermissionValueState("Обязательное поле");
                                validate_status = false;
                            }
                            if (validate_status) {
                                sendPermissionRequest();
                            }
                        }}
                    >
                        Отправить
                    </Button>
                </FormItem>
            </Form>
        </div>
    )
}

export default SendInviteForm