import React, { useEffect, useState } from 'react'
import { FlexBox, Form, FormGroup, FormItem, Title, Button, Text } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';


const RegStepSendCode = (props) => {

    async function unfinishedRegistrationDataSend() {
        props.setIsLoading(true);
        await axios_instance
            .post(
                "/registration/unfinished",
                {
                    email: props.registrationData.email
                }
            )
            .then((response) => {
                console.log(response.data);
                props.setIsLoading(false);
                props.setStep(4);
            })
            .catch((error) => {
                console.log(error);
                props.setIsLoading(false);
            })
    }

    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Завершение регистрации
                </Title>
                <div style={{ border: '1px solid gray', borderRadius: 10, padding: 5, marginTop: 10, marginBottom: 30 }}>
                    <Text>
                        {props.registrationData.email}
                    </Text>
                </div>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >

                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="Center"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                onClick={() => {
                                    unfinishedRegistrationDataSend();
                                }}
                                design="Emphasized" style={{ width: "100%", fontWeight: 'normal' }}>
                                Выслать код
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export default RegStepSendCode