import React, { useState, useEffect } from "react"
import "./CompaniesView.css"
import {
  SplitterElement,
  SplitterLayout,
  SplitterElementContent,
  ThemeProvider,
  IllustratedMessage,
} from "@ui5/webcomponents-react"
import CompaniesSideView from "./companies-side-view/CompaniesSideView"
import CompanyDetailView from "./company-detail-view/CompanyDetailView"
import CompanyCreateForm from "./company-create-form/CompanyCreateForm"
import axios_instance from "../../requests/config_defaults"
import CompanyManagerInviteForm from "./company-manager-invite-form/CompanyManagerInviteForm"
import CompanyDetailContactCreateForm from "./company-detail-contact-create-form/CompanyDetailContactCreateForm"
import CompanyEditForm from "./company-edit-form/CompanyEditForm"
import { useSearchParams } from "react-router-dom"
import PermissionManager from "../base/permission_manager/PermissionManager"
import { useAtom } from "jotai"
import { activeCompanyAddressAtom } from "../../hoc/GlobalAtoms"

const CompaniesView = (props) => {
  const [search, setSearch] = useSearchParams()
  const [activeCompany, setActiveCompany] = useState(
    search.get("company") || null
  )

  const [createLegalEntityMode, setCreateLegalEntityMode] = useState(false)
  const [addLegalEntityManagerMode, setAddLegalEntityManagerMode] =
    useState(false)
  const [createLegalEntityContactMode, setCreateLegalEntityContactMode] =
    useState(false)
  const [editLegalEntityMode, setEditLegalEntityMode] = useState(false)

  const [companyUpdate, setCompanyUpdate] = useState(false)
  const [legalEntitiesUpdate, setLegalEntitiesUpdate] = useState(false)
  const [companyEmployeesUpdate, setCompanyEmployeesUpdate] = useState(false)
  const [companyContactsUpdate, setCompanyContactsUpdate] = useState(false)
  const [companyAddressesUpdate, setCompanyAddressesUpdate] = useState(false)
  const [activeCompanyAddress, setActiveCompanyAddress] = useAtom(activeCompanyAddressAtom)

  const [loading, setLoading] = useState(false)

  const [companyManagers, setCompanyManagers] = useState([])

  const [permissionData, setPermissionData] = useState({})
  const [popUpCompanyName, setPopUpCompanyName] = useState('')

  useEffect(() => {
    setActiveCompany(search.get("company") || null)
  },[search]);

  return (
    <ThemeProvider>
      {createLegalEntityMode && (
        <CompanyCreateForm
          setCreateLegalEntityMode={setCreateLegalEntityMode}
          legalEntitiesUpdate={legalEntitiesUpdate}
          setLegalEntitiesUpdate={setLegalEntitiesUpdate}
        />
      )}
      {addLegalEntityManagerMode && (
        <CompanyManagerInviteForm
          setAddLegalEntityManagerMode={setAddLegalEntityManagerMode}
          activeCompany={activeCompany}
          companyManagers={companyManagers}
          companyEmpoyeesUpdate={companyEmployeesUpdate}
          setCompanyEmployeesUpdate={setCompanyEmployeesUpdate}
        />
      )}
      {createLegalEntityContactMode && (
        <CompanyDetailContactCreateForm
          setCreateLegalEntityContactMode={setCreateLegalEntityContactMode}
          activeCompany={activeCompany}
          companyContactsUpdate={companyContactsUpdate}
          setCompanyContactsUpdate={setCompanyContactsUpdate}
        />
      )}
      {/* {editLegalEntityMode && <CompanyEditForm setEditLegalEntityMode={setEditLegalEntityMode} activeCompany={activeCompany} legalEntitiesUpdate={legalEntitiesUpdate} setLegalEntitiesUpdate={setLegalEntitiesUpdate} companyUpdate={companyUpdate} setCompanyUpdate={setCompanyUpdate} />} */}
      <SplitterLayout
        style={{
          height: "100%",
          width: "100%",
        }}>
        <SplitterElement>
          <CompaniesSideView
            setActiveCompanyAddress={setActiveCompanyAddress}
            permissionData={permissionData}
            activeCompany={activeCompany}
            setActiveCompany={setActiveCompany}
            setCreateLegalEntityMode={setCreateLegalEntityMode}
            loading={loading}
            setLoading={setLoading}
            legalEntitiesUpdate={legalEntitiesUpdate}
            setLegalEntitiesUpdate={setLegalEntitiesUpdate}
            setPopUpCompanyName={setPopUpCompanyName}
          />
        </SplitterElement>
        <SplitterElement style={{ position: "relative" }}>
          {activeCompany ? (
            <CompanyDetailView
              permissionData={permissionData}
              activeCompany={activeCompany}
              setActiveCompany={setActiveCompany}
              setAddLegalEntityManagerMode={setAddLegalEntityManagerMode}
              setCreateLegalEntityContactMode={setCreateLegalEntityContactMode}
              editLegalEntityMode={editLegalEntityMode}
              setEditLegalEntityMode={setEditLegalEntityMode}
              companyManagers={companyManagers}
              setCompanyManagers={setCompanyManagers}
              companyEmployeesUpdate={companyEmployeesUpdate}
              setCompanyEmployeesUpdate={setCompanyEmployeesUpdate}
              companyContactsUpdate={companyContactsUpdate}
              setCompanyContactsUpdate={setCompanyContactsUpdate}
              companyUpdate={companyUpdate}
              setCompanyUpdate={setCompanyUpdate}
              legalEntitiesUpdate={legalEntitiesUpdate}
              setLegalEntitiesUpdate={setLegalEntitiesUpdate}
              companyAddressesUpdate={companyAddressesUpdate}
              setCompanyAddressesUpdate={setCompanyAddressesUpdate}
            />
          ) : (
            <div
              className="ProductsStocksView-nodata-content"
              style={{ width: "100%" }}>
              <IllustratedMessage
                titleText="Выберите юр. лицо"
                subtitleText=" "
              />
            </div>
          )}
        </SplitterElement>
      </SplitterLayout>
      {activeCompany &&
        <PermissionManager
          keys={["admin", "manager", "purchasing_manager", "residuals_control_manager", "sales_manager"]}
          setPermissionData={setPermissionData}
          company={activeCompany}
          popUpCompanyName={popUpCompanyName}
        />
      }
    </ThemeProvider>
  )
}

export default CompaniesView
