import './SalesView.css'
import React, { useEffect, useMemo, useState } from 'react'

import '@ui5/webcomponents-icons/dist/multiselect-none.js';
import '@ui5/webcomponents-icons/dist/message-success.js';
import '@ui5/webcomponents-icons/dist/sys-cancel.js';
import IncommingRequestsAllView from './incomming-requests-all-view/IncommingRequestsAllView';
import IncommingRequestsConfirmedView from './incomming-requests-confirmed-view/IncommingRequestsConfirmedView';
import IncommingRequestsPartConfirmedView from './incomming-requests-part-confirmed-view/IncommingRequestsPartConfirmedView';
import IncommingRequestsRefusedView from './incomming-requests-refused-view/IncommingRequestsRefusedView';
import IncommingRequestsIsDeliveredView from './incomming-requests-is-delivered-view/IncommingRequestsIsDeliveredView';
import IncommingRequestsHasDeliveredView from './incomming-requests-has-delivered-view/IncommingRequestsHasDeliveredView';
import { useAtom } from 'jotai';
import { activeCompanyAtom, isAdminAtom } from '../../hoc/GlobalAtoms';
import SalesStatisticView from './sales-statistic-view/SalesStatisticView';
import SalesSideNavigation from './sales-side-navigation/SalesSideNavigation';
import { useSearchParams } from 'react-router-dom';
import SalesMainChapter from './sales-main-chapter/SalesMainChapter';
import PermissionManager from '../base/permission_manager/PermissionManager';

const SalesView = (props) => {
  const [activeCompany] = useAtom(activeCompanyAtom);
  const [searchParams, setSearchParams] = useSearchParams();
  const [chapter, setChapter] = useState(searchParams.get("chapter") || "main");
  const [permissionData, setPermissionData] = useState({})
  const [isAdmin] = useAtom(isAdminAtom)

  const chapters = {
    "main": <SalesMainChapter setChapter={setChapter}/>,
    "all": <IncommingRequestsAllView permissionData={permissionData} isAdmin={isAdmin} />,
    "confirmed": <IncommingRequestsConfirmedView isAdmin={isAdmin} />,
    "part_confirmed": <IncommingRequestsPartConfirmedView isAdmin={isAdmin} />,
    "is_delivered": <IncommingRequestsIsDeliveredView isAdmin={isAdmin} />,
    "has_delivered": <IncommingRequestsHasDeliveredView isAdmin={isAdmin} />,
    "refused": <IncommingRequestsRefusedView isAdmin={isAdmin} />,
    "statistic": <SalesStatisticView />,
  }

  const admin_chapters = {
    "main": <SalesMainChapter setChapter={setChapter}/>,
    "all": <IncommingRequestsAllView permissionData={permissionData} isAdmin={isAdmin} />,
    "premoderation": <IncommingRequestsAllView setChapter={setChapter} premoderation={true}/>,
    "confirmed": <IncommingRequestsConfirmedView isAdmin={isAdmin} />,
    "part_confirmed": <IncommingRequestsPartConfirmedView isAdmin={isAdmin} />,
    "is_delivered": <IncommingRequestsIsDeliveredView isAdmin={isAdmin} />,
    "has_delivered": <IncommingRequestsHasDeliveredView isAdmin={isAdmin} />,
    "refused": <IncommingRequestsRefusedView isAdmin={isAdmin} />,
    "statistic": <SalesStatisticView />,
  }

  return (
    <div className='sales-container'>
      <SalesSideNavigation chapter={chapter} setChapter={setChapter} isAdmin={isAdmin}/>
      <div className="sale-content-chapter">
        {chapter && isAdmin ? admin_chapters[chapter] : chapters[chapter]}
      </div>
      <PermissionManager
        keys={["admin", "manager", "sales_manager"]}
        setPermissionData={setPermissionData}
      />
    </div>
    
  )
}

export default SalesView