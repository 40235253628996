import React, { useEffect, useState } from "react";
import "./Calculation.css";
import classNames from "classnames";
import { ThemeProvider, AnalyticalTable } from "@ui5/webcomponents-react";
import axios_instance from "../../../requests/config_defaults.js";

const columns = [
  {
    accessor: "tariff",
    Header: "Тариф",
    headerTooltip: "Тариф",
  },
  {
    accessor: "delivery_cost",
    Header: "Стоимость доставки",
    headerTooltip: "Стоимость доставки",
  },
  {
    accessor: "min_period",
    Header: "Минимальный срок доставки",
    headerTooltip: "Минимальный срок доставки",
  },
  {
    accessor: "max_period",
    Header: "Максимальный срок доставки",
    headerTooltip: "Максимальный срок доставки",
  },
];

const CalculationView = () => {
  const [dropdownState, setDropdownState] = useState({ open: false });
  const [textFromInputed, setTextFromInputed] = useState(false);
  const [textToInputed, setTextToInputed] = useState(false);
  const [geoguides, setGeoguides] = useState([]);
  const [fromInputValue, setFromInputValue] = useState({
    id: "",
    value: "",
  });
  const [toInputValue, setToInputValue] = useState({
    id: "",
    value: "",
  });
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [weight, setWeight] = useState("");
  const [count, setCount] = useState("");
  // const [isCSEResults, setIsCSEResults] = useState(true);
  // const [isCDEKResults, setIsCDEKResults] = useState(false);
  const [aggregator, setAggregator] = useState("cse");
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);

  const resultsGet = async (id) => {
    setLoading(true);
    const data = {
      start_point: fromInputValue.id,
      end_point: toInputValue.id,
      positions: [
        {
          weight: parseFloat(weight),
          length: parseFloat(length),
          width: parseFloat(width),
          height: parseFloat(height),
          count: parseInt(count),
        },
      ],
    };
    const delivery_results = [];
    console.log(data);
    console.log(aggregator);
    await axios_instance
      .post(`/${id}/calculating`, data)
      .then((response) => {
        console.log(response.data);
        aggregator === "cdek"
          ? response.data &&
            response.data.results.map((result) => {
              delivery_results.push({
                tariff: result.urgency_name,
                delivery_cost: result.delivery_cost,
                min_period: result.min_period,
                max_period: result.max_period,
              });
            })
          : response.data &&
            response.data.results.map((result) => {
              result.results.map((delivery_type_result) => {
                delivery_results.push({
                  tariff: `${delivery_type_result.urgency_name} [${result.delivery_type}]`,
                  delivery_cost: delivery_type_result.delivery_cost,
                  min_period: delivery_type_result.min_period,
                  max_period: delivery_type_result.max_period,
                });
              });
            });
        setLoading(false);
        setResults(delivery_results);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const localtyDropdownClose = () => {
    textToInputed && setTextToInputed(!textToInputed);
    textFromInputed && setTextFromInputed(!textFromInputed);
  };

  const clickFromGeoguidElemHandler = (id, locality) => {
    setFromInputValue((prev) => ({ id: id, value: locality }));
    setTextFromInputed(false);
  };

  const clickToGeoguidElemHandler = (id, locality) => {
    setToInputValue((prev) => ({ id: id, value: locality }));
    console.log(toInputValue);
    setTextToInputed(false);
  };

  const changeFromInputHandler = (e) => {
    setFromInputValue((prev) => ({ ...prev, value: e.target.value }));
    geoguidSearch(e.target.id, e.target.value);
  };

  const changeToInputHandler = (e) => {
    setToInputValue((prev) => ({ ...prev, value: e.target.value }));
    geoguidSearch(e.target.id, e.target.value);
  };

  const geoguidSearch = async (input_id, q) => {
    await axios_instance
      .get(`/geo_object/list?contentType=city&q=${q}`)
      .then((response) => {
        if (!response.data.results) {
          setTextFromInputed(false);
        } else {
          setGeoguides(response.data.results);
        }
        if (!q) {
          console.log(q);
          if (input_id === "_from") {
            setTextFromInputed(false);
          }
          if (input_id === "_to") {
            setTextToInputed(false);
          }
        }
        if (input_id === "_from") {
          setTextFromInputed(true);
        }
        if (input_id === "_to") {
          setTextToInputed(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const cseBtnClickHandler = () => {
    // setIsCSEResults(true);
    // setIsCDEKResults(false);
    setAggregator("cse")
    resultsGet("cse");
  };

  const cdekBtnClickHandler = () => {
    // setIsCSEResults(false);
    // setIsCDEKResults(true);
    setAggregator("cdek")
    resultsGet("cdek");
  };

  useEffect(() => {
    resultsGet(aggregator)
  }, [aggregator])

  const handleDropdownClick = () =>
    setDropdownState({ open: !dropdownState.open });

  return (
    <div className="calculation-content" onClick={localtyDropdownClose}>
      <div className="title">Расчёт доставки</div>
      <div className="parameters-input-content">
        <div className="locality-input-container">
          <input
            id="_from"
            className="locality-input"
            type="text"
            placeholder="Город отправки"
            onChange={(e) => changeFromInputHandler(e)}
            value={fromInputValue.value}
            autocomplete="off"
          />
          <div
            className={
              textFromInputed ? "locality-dropdown-open" : "locality-dropdown"
            }
          >
            {geoguides &&
              geoguides.map((geoguid) => (
                <p
                  id={geoguid.id}
                  className="dropdown-geoguid-element"
                  onClick={(e) =>
                    clickFromGeoguidElemHandler(
                      e.target.id,
                      e.target.textContent
                    )
                  }
                >{`${
                  geoguid.parent.parent ? geoguid.parent.parent.name + ", " : ""
                } ${geoguid.parent.name}, ${geoguid.name}`}</p>
              ))}
          </div>
        </div>
        <div className="locality-input-container">
          <input
            id="_to"
            className="locality-input"
            type="text"
            placeholder="Город назначения"
            onChange={(e) => changeToInputHandler(e)}
            value={toInputValue.value}
            autocomplete="off"
          />
          <div
            className={
              textToInputed ? "locality-dropdown-open" : "locality-dropdown"
            }
          >
            {geoguides &&
              geoguides.map((geoguid) => (
                <p
                  id={geoguid.id}
                  className="dropdown-geoguid-element"
                  onClick={(e) =>
                    clickToGeoguidElemHandler(e.target.id, e.target.textContent)
                  }
                >{`${
                  geoguid.parent.parent ? geoguid.parent.parent.name + ", " : ""
                } ${geoguid.parent.name}, ${geoguid.name}`}</p>
              ))}
          </div>
        </div>

        <div className="dropdown-container">
          <button
            className={classNames(
              "dropdown-btn",
              dropdownState.open ? "open" : ""
            )}
            type="button"
            onClick={handleDropdownClick}
          >
            ВГХ
          </button>
          {dropdownState.open && (
            <div
              className={classNames(
                "dropdown-content",
                dropdownState.open ? "dropdown-content-open" : ""
              )}
            >
              <input
                id="length"
                className="dropdown-input"
                type="number"
                placeholder="Длина, см"
                onChange={(e) => setLength(e.target.value)}
                value={length}
              />
              <input
                id="width"
                className="dropdown-input"
                type="number"
                placeholder="Ширина, см"
                onChange={(e) => setWidth(e.target.value)}
                value={width}
              />
              <input
                id="height"
                className="dropdown-input"
                type="number"
                placeholder="Высота, см"
                onChange={(e) => setHeight(e.target.value)}
                value={height}
              />
              <input
                id="weight"
                className="dropdown-input"
                type="number"
                placeholder="Вес, кг"
                onChange={(e) => setWeight(e.target.value)}
                value={weight}
              />
              <input
                id="count"
                className="dropdown-input"
                type="number"
                placeholder="Количество, шт"
                onChange={(e) => setCount(e.target.value)}
                value={count}
              />
            </div>
          )}
        </div>
      </div>
      <button
        id="calculate"
        className="calculate-btn"
        onClick={() => {
          // isCSEResults && resultsGet("cse");
          // isCDEKResults && resultsGet("cdek");
          aggregator === "cse" && resultsGet("cse");
          aggregator === "cdek" && resultsGet("cdek");
        }}
      >
        Расчитать
      </button>
      <div className="aggregator-switch">
        <button
          id="cse-aggregator-btn"
          // className={!isCSEResults ? "aggregator-btn" : "choosed-aggr-btn"}
          className={aggregator !== "cse" ? "aggregator-btn" : "choosed-aggr-btn"}
          onClick={() => {
            setAggregator("cse");
          }}
        >
          КСЭ
        </button>
        <button
          id="cdek-aggregator-btn"
          // className={!isCDEKResults ? "aggregator-btn" : "choosed-aggr-btn"}
          className={aggregator !== "cdek" ? "aggregator-btn" : "choosed-aggr-btn"}
          onClick={() => {
            setAggregator("cdek");
          }}
        >
          СДЭК
        </button>
      </div>
      <div className="calculation-results-content">
        <ThemeProvider style={{ height: "100%" }}>
          <AnalyticalTable
            data={results}
            columns={columns}
            style={{ height: "100%" }}
            loading={loading}
          />
        </ThemeProvider>
      </div>
    </div>
  );
};

export default CalculationView;
