import { Box, MenuItem } from "@mui/material";
import { TabContainer, Tab, Text, FlexBox } from "@ui5/webcomponents-react";
import { useState, useEffect } from "react";
import axios_instance from "../../../../requests/config_defaults";
import Table from "../../../base/table/Table";
import "./ProductAnalogsView.css";

const fields = {
  name: {
    key: "property_name",
    header: "Наименование",
    disabled: false,
    editable: false,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    editable: false,
  },
  manufacture: {
    key: "manufacture",
    header: "Производитель",
    editable: false,
    align: "center",
  },
  is_standard: {
    key: "is_standard",
    header: "Эталонный товар",
    editable: false,
    align: "center",
    Cell: ({ renderedCellValue, cell }) => (
      <Box
          sx={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              gap: '1rem',
              borderRadius: 20,
              padding: '5px',
              color: "#fff",
          }}
      >
          <span
              style={{
                  color: renderedCellValue === true ? "green" : "red"
              }}
          >
              {renderedCellValue === true ? "Да" : "Нет"}
          </span>
      </Box>
    ),
  }
};

const ProductAnalogsView = (props) => {
  const [analogs, setAnalogs] = useState([]);
  const [properties, setProperties] = useState([]);

  const CreateUpdateProperties = async () => {
    await axios_instance
      .post(`product/properties/create/by_id`, {
        product_id: props?.activeProductID,
        properties: properties,
      })
      .then(({ data }) => {
        setProperties([]);
        props.getProduct()
      })
      .catch((error) => {});
  };

  const propertiesPrimaryCreateHandler = (analog) => {
    const data_properties = []
    analog.properties?.map((prop) => {
      if (prop.is_primary && !props?.activeProduct.properties.find(property => property.name === prop.name)) {
        data_properties.push({
          name: prop.name,
          value: prop.value.content,
          measure: prop.measure.name
        })
      };
    })
    setProperties(data_properties);
  }

  const propertiesNotPrimaryCreateHandler = (analog) => {
    const data_properties = []
    analog.properties?.map((prop) => {
      if (!prop.is_primary && !props?.activeProduct.properties.find(property => property.name === prop.name)) {
        data_properties.push({
          name: prop.name,
          value: prop.value.content,
          measure: prop.measure ? prop.measure.name : "-"
        })
      };
    })
    setProperties(data_properties);
  }

  const getProductWithAnalogs = async () => {
    await axios_instance
      .get(`/product/with/analogs/${props.activeProductID}`)
      .then(({ data }) => {
        const data_columns = [];
        data?.analogs.map((analog) => {
          data_columns.push({
            id: analog.id,
            property_name: analog.name?analog.name:"Не указано",
            brand: analog.brand?.name ? analog.brand?.name : "Не указан",
            manufacture: analog.manufacture?.name ? analog.manufacture?.name : "Не указан",
            is_standard: analog.is_standard,
            properties: analog.properties,
          });
        });
        setAnalogs(data_columns);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (properties.length > 0) {
      CreateUpdateProperties();
    }
}, [properties.length])

  useEffect(() => {
    getProductWithAnalogs();
}, [props.activeProductID])

  return (
    <Table
      className="AdminCatalogProductAnalogsTable"
      columns={fields}
      data={analogs}
      enableEditing={false}
      enableColumnResizing
      enableColumnOrdering={true}
      enableGrouping={false}
      enableColumnPinning={true}
      enableFacetedValues={true}
      enableRowActions={true}
      enableStickyFooter={true}
      enableStickyHeader={true}
      rowActionMenuItems={({ row, closeMenu }) => [
        <MenuItem
          key="view"
          onClick={() => {
            props.setActiveProduct(row.original);
            closeMenu();
          }}
        >
          Просмотр
        </MenuItem>,
        <MenuItem
          key="view"
          disabled={row.original.is_standard ? false : true}
          onClick={() => {
            propertiesPrimaryCreateHandler(row.original);
            closeMenu();
          }}
        >
          Перенести ВГХ характеристики к текущему товару
        </MenuItem>,
        <MenuItem
          key="view"
          disabled={row.original.is_standard ? false : true}
          onClick={() => {
            propertiesNotPrimaryCreateHandler(row.original);
            closeMenu();
          }}
        >
          Перенести дополнительные характеристики к текущему товару
        </MenuItem>
      ]}
      renderDetailPanel={(row, table) => (
        <>
          <TabContainer
            contentBackgroundDesign="Solid"
            headerBackgroundDesign="Solid"
            onTabSelect={function _a() { }}
            tabLayout="Standard"
            style={{ width: "2000px" }}
          >
            <Tab
              selected
              text="ВГХ характеристики"
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  left: '30px',
                  maxWidth: '1000px',
                  position: 'sticky',
                  width: '100%',
                }}
              >
                <Box sx={{ textAlign: 'start', marginLeft: '50px' }}>
                  {row?.row?.original?.properties?.map((prop) =>
                    <FlexBox>
                      {prop.is_primary ? (
                        <Text
                          style={{
                            fontSize: "1.1rem",
                          }}
                        >
                          {prop?.name}: {prop?.value?.content} {prop?.measure?.name}
                        </Text>
                      ) : (null)}
                    </FlexBox>
                  )}
                </Box>
              </Box>
            </Tab>

            <Tab
              text="Дополнительные характеристики"
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  left: '30px',
                  maxWidth: '1000px',
                  position: 'sticky',
                  width: '100%',
                }}
              >
                <Box sx={{ textAlign: 'start', marginLeft: '50px' }}>
                  {row?.row?.original?.properties?.map((prop) =>
                    <FlexBox>
                      {!prop.is_primary ? (
                        <Text
                          style={{
                            fontSize: "1.1rem",
                          }}
                        >
                          {prop?.name}: {prop?.value?.content}
                        </Text>
                      ) : (null)}
                    </FlexBox>
                  )}
                </Box>
              </Box>
            </Tab>

          </TabContainer>
        </>
      )}
    />
  )
}

export default ProductAnalogsView