import React, { useEffect, useMemo, useState } from 'react'
import "./IncommingRequestsPartConfirmedView.css"
import {
    MaterialReactTable, MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton,
    MRT_EditActionButtons, MRT_ShowHideColumnsButton,
    MRT_ToolbarDropZone
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { Paper, TableContainer, Box, Typography, MenuItem, Tooltip, IconButton, ListItemIcon } from "@mui/material";
import { createTheme, useTheme, lighten } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { lime, purple, cyan, grey } from "@mui/material/colors";
import axios_instance from '../../../requests/config_defaults';
import Paginator from '../../base/paginator/Paginator';
import { useAtom } from 'jotai';
import { activeCompanyAtom } from '../../../hoc/GlobalAtoms';
import Table from '../../base/table/Table';

const fields = {
    product_code: {
        key: "product_code",
        header: "Артикул",
        editable: false,
    },
    product_full_name: {
        key: "product_full_name",
        header: "Полное наименование товара в каталоге",
        editable: false,
    },
    brand: {
        key: "brand",
        header: "Бренд",
        editable: false,
    },
    manufactor: {
        key: "manufactor",
        header: "Прозводитель",
        editable: false,
    },
    requested_count: {
        key: "requested_count",
        header: "Запрошено",
        editable: false,
    },
    confirmed_count: {
        key: "confirmed_count",
        header: "Подтверджено",
        editable: false,
    },
    warehouse: {
        key: "warehouse",
        header: "Склад",
        editable: false,
    },
    confirm_date_time_stamp: {
        key: "confirm_date_time_stamp",
        header: "Дата/Время продтверждения",
        editable: false,
    }
};

const fields_admin = {
    supplier: {
        key: "supplier",
        header: "Поставщик",
        editable: false,
    },
    contractor: {
        key: "contractor",
        header: "Покупатель",
        editable: false,
    },
    order_project_id: {
        key: "order_project_id",
        header: "Идентификатор проекта заказа",
        editable: false,
    },
    order_project_name: {
        key: "order_project_name",
        header: "Наименование проекта заказа",
        editable: false,
    },
    product_code: {
        key: "product_code",
        header: "Артикул",
        editable: false,
    },
    product_full_name: {
        key: "product_full_name",
        header: "Полное наименование товара в каталоге",
        editable: false,
    },
    brand: {
        key: "brand",
        header: "Бренд",
        editable: false,
    },
    manufactor: {
        key: "manufactor",
        header: "Прозводитель",
        editable: false,
    },
    requested_count: {
        key: "requested_count",
        header: "Запрошено",
        editable: false,
    },
    confirmed_count: {
        key: "confirmed_count",
        header: "Подтверджено",
        editable: false,
    },
    warehouse: {
        key: "warehouse",
        header: "Склад",
        editable: false,
    },
    confirm_date_time_stamp: {
        key: "confirm_date_time_stamp",
        header: "Дата/Время продтверждения",
        editable: false,
    }
};

const IncommingRequestsPartConfirmedView = (props) => {
    const [salesData, setSalseData] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [activeComapny] = useAtom(activeCompanyAtom)

    const globalTheme = useTheme({
        palette: {
            primary: lime,
            secondary: purple,
        },
    });

    const data_columns = [];

    for (let main_key in fields) {
        data_columns.push({
            accessorKey: fields[main_key].key, //simple recommended way to define a column
            header: fields[main_key].header,
            muiTableHeadCellProps: {
                sx: {
                    color: "black",
                },
            },
            muiTableBodyRowProps: {
                onHover(e, value) {
                    e.target.style.background = "#e1f4ff";
                },
            },
            muiTopToolbarProps: {
                background: "#333",
            },
            enableEditing: false,
            muiEditTextFieldProps: ({ cell, row }) => ({
                type: 'number',
            }),
        })
    }

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: grey, //swap in the secondary color as the primary for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "#fff" //random light yellow color for the background in light mode
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1.2rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "1.1rem", //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: "gray", //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme]
    );

    const getManufactor = (product) => {
        var name = "";
        product?.properties.map((property) => {
            if (property.name === "Основной поставщик") {
                name = property.value.content;
            }
        });
        return name;
    };

    const getName = (product) => {
        var name = "";
        product?.properties.map((property) => {
            if (property.name === "Наименование") {
                name = property.value.content;
            }
        });
        return name;
    };

    const getData = async () => {
        setLoading(true)
        await axios_instance.post(
            `/sales/filter?page=${page}`,
            {
                part: true
            }
        )
            .then(({ data }) => {

                const catalog = [];
                setMaxPage(data?.count % 20 === 0 ? data?.count / 20 : Math.floor(data?.count / 20) + 1);
                data?.results.map((req) => {
                    catalog.push({
                        id: req.id,
                        product_code: req?.stock?.product?.code,
                        brand: req?.stock?.product?.brand?.name || "Не указан",
                        manufactor: getManufactor(req?.stock?.product) || "Не указан",
                        product_full_name: getName(req?.stock?.product),
                        property_manufactor: getManufactor(req?.stock?.product),
                        current_rest: req?.stock?.current_rest,
                        needful_rest: req?.stock?.needful_rest,
                        for_sale_count: req?.stock?.for_sale_count,
                        requested_count: req?.count,
                        confirmed_count: req?.confirmed_count,
                        sale_price: req?.stock?.sale_price,
                        warehouse: req?.stock?.warehouse?.name,
                        confirm_date_time_stamp: req?.confirm?.create_date_time_stamp,
                        supplier: req?.supplier?.entity?.name,
                        contractor: req?.contractor?.entity?.name,
                        order_project_id: req?.order_project?.id,
                        order_project_name: req?.order_project?.name,
                    });
                });
                setSalseData(catalog)
                setLoading(false);
                console.log(salesData);
            })
            .catch((error) => {
                setLoading(false);
                console.log(error);
            })
    }

    const confirmSale = async (row) => {
        await axios_instance.post(
            `/sales/confirm/${row?.original?.id}`
        )
            .then(({ data }) => {

                getData();
            })
            .catch((error) => {

            })
    }

    const refuseSale = async (row) => {
        await axios_instance.post(
            `/sales/refuse/${row?.original?.id}`
        )
            .then(({ data }) => {

                getData();
            })
            .catch((error) => {

            })
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        getData();
    }, [page, activeComapny, props.trigger])


    return (
        <Table
            className={"IncommingRequestsConfirmedView-table"}
            columns={props.isAdmin ? fields_admin : fields}
            data={salesData && salesData}
            editDisplayMode='cell'
            enableEditing={true}
            enableColumnResizing
            enableColumnFilters={false}
            enableColumnOrdering={true}
            enableGrouping={false}
            enableColumnPinning={false}
            enableFacetedValues={false}
            enableGlobalFilter={false}
            isLoading={loading}
            enableStickyHeader
            pagination={{
                page: page,
                setPage: setPage,
                pageCount: maxPage,
            }}
        />
    )
}

export default IncommingRequestsPartConfirmedView