import React, { useEffect, useState } from 'react'
import { FlexBox, Form, FormGroup, FormItem, Title, Button } from '@ui5/webcomponents-react';


const FinalResetPasswordForm = (props) => {

    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Пароль успешно изменён!
                </Title>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >

                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="Center"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                onClick={() => {
                                    props.setValidIdentification(false); 
                                    props.setStepResetPassword(0); 
                                }}
                                design="Emphasized" style={{ width: 100, fontWeight: 'normal' }}>
                                Войти
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export default FinalResetPasswordForm