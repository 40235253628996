import axios from "axios";
import { BASE_URL } from "./constants.js";

const axios_instance = axios.create({
  headers: { "Content-Type": "application/json" },
  baseURL: BASE_URL,
  withCredentials: true,
});

export default axios_instance;

