import React, {useEffect, useState, useMemo, useRef} from "react";
import "./OrdersViewSide.css";
import axios_instance from "../../../requests/config_defaults";
import Paginator from "../../base/paginator/Paginator";
import {MaterialReactTable} from "material-react-table";
import {MRT_Localization_RU} from "material-react-table/locales/ru";
import {Paper, TableContainer, MenuItem, Box} from "@mui/material";
import {createTheme, useTheme, lighten} from "@mui/material";
import {ThemeProvider as MuiThemeProvider} from "@mui/material";
import {lime, purple, cyan, grey} from "@mui/material/colors";
import {
  Button,
  ButtonDesign,
  FlexBox,
  Input,
  Label,
  MessageBox,
} from "@ui5/webcomponents-react";
import {Modal} from "@mantine/core";
import {useAtom} from "jotai";
import {activeCompanyAtom, isAdminAtom} from "../../../hoc/GlobalAtoms";
import Table from "../../base/table/Table";
import {useSearchParams} from "react-router-dom";

const fields = {
    number: {
        key: "number",
        header: "Номер",
        editable: false,
        size: 170,
        minSize: 170,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_custome_name: {
        key: "order_custome_name",
        header: "Название проекта заказа",
        editable: false,
        size: 300,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    status: {
        key: "status",
        header: "Статус",
        editable: false,
        size: 160,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_product_count: {
        key: "order_product_count",
        header: "Кол-во товаров",
        Header: ({column}) => (
            <Box title={"Количество артикулов товаров в проекте заказа"}
            >
                {column.columnDef.header}
            </Box>
        ),
        editable: false,
        size: 240,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_item_count: {
        key: "order_item_count",
        header: "Кол-во шт.",
        Header: ({column}) => (
            <Box title={"Количество позиций в проекте заказа"}
            >
                {column.columnDef.header}
            </Box>
        ),
        editable: false,
        size: 190,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_create_datetime_stamp: {
        key: "order_create_datetime_stamp",
        header: "Дата",
        editable: false,
        size: 150,
        enableClickToCopy: true,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
};

const adminFields = {
    number: {
        key: "number",
        header: "Номер",
        editable: false,
        Header: ({column}) => (
            <Box title={"Номер проекта заказа"}
            >
                {column.columnDef.header}
            </Box>
        ),
        size: 170,
        minSize: 170,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_custome_name: {
        key: "order_custome_name",
        header: "Название проекта заказа",
        editable: false,
        size: 300,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    status: {
        key: "status",
        header: "Статус",
        editable: false,
        size: 160,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_product_count: {
        key: "order_product_count",
        header: "Кол-во товаров",
        editable: false,
        Header: ({column}) => (
            <Box title={"Количество артикулов товаров в проекте заказа"}
            >
                {column.columnDef.header}
            </Box>
        ),
        size: 240,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_item_count: {
        key: "order_item_count",
        header: "Кол-во шт.",
        Header: ({column}) => (
            <Box title={"Количество позиций в проекте заказа"}
            >
                {column.columnDef.header}
            </Box>
        ),
        editable: false,
        size: 190,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_create_datetime_stamp: {
        key: "order_create_datetime_stamp",
        header: "Дата",
        editable: false,
        size: 150,
        enableClickToCopy: true,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    user: {
        key: "user",
        header: "Создатель",
        editable: false,
        size: 200,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    contractor: {
        key: "contractor",
        header: "Покупатель",
        editable: false,
        size: 200,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
    order_uid: {
        key: "order_uid",
        header: "Идентификатор заказа",
        editable: false,
        size: 330,
        enableClickToCopy: true,
        Cell: ({cell, renderedCellValue}) => (
            <Box title={renderedCellValue}
                 sx={{
                     display: "block",
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                 }}
            >
                {renderedCellValue}
            </Box>
        ),
    },
};

const OrdersViewSide = (props) => {
    const [orderProjects, setOrderProjects] = useState([]);
    const [page, setPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const [columnFilters, setColumnFilters] = useState([]);
    const [orderProjectData, setOrderProjectData] = useState("");
    const [openModal, setOpenModal] = useState(false);
    const [openDeleteModal, setDeleteOpenModal] = useState(false);
    const [deleteProject, setDeleteProject] = useState("");
    const [search, setSearch] = useSearchParams();

    const [isAdmin] = useAtom(isAdminAtom);
    const [activeCompany] = useAtom(activeCompanyAtom);

  const filterOrderProjects = async () => {
    setLoading(true);
    await axios_instance
      .post(
        `/orders/order_project/filter?page=${page}${`&filters=${JSON.stringify(
          columnFilters ?? []
        )}`}`,
        columnFilters
      )
      .then(({ data }) => {
        setPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        const order_projects = [];
        data?.results.map((project) => {
          order_projects.push({
            id: project?.id,
            order_uid: project?.id,
            order_custome_name: project?.name,
            user: project?.user,
            contractor: project.contractor.name,
            order_create_datetime_stamp: project?.create_date_time_stamp,
            order_product_count: project?.product_count,
            order_item_count: project?.order_item_count,
            formed_status: project?.formed,
            completed: project?.completed,
            payment: project?.payment,
            delivered: project?.delivered,
            number: project?.number,
            status: 
              project?.formed || project?.confirmed_order_items_count !== 0 || project?.completed || project?.payment || project?.delivered
                ? project?.completed ? "Доставлен"
                : project?.delivered ? "Доставляется"
                : project?.payment ? "Ожидает оформления доставки"
                : project?.confirmed_order_items_count !== 0 ? `Ожидает оплату ${project?.confirmed_order_items_count}/${project?.order_item_count}`
                : "Ожидает подтверждение поставки"
              : "Формируется",
          });
        });
        setOrderProjects(order_projects);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

    useEffect(() => {
        filterOrderProjects();
    }, []);

    useEffect(() => {
        filterOrderProjects();
    }, [page, columnFilters, activeCompany]);

    useEffect(() => {
        setPage(1);
    }, [columnFilters]);

    const createOrderProject = async () => {
        setOpenModal(false);
        await axios_instance
            .post(`/orders/order_project/create`, {name: orderProjectData})
            .then(({data}) => {
                filterOrderProjects();
            })
            .catch((error) => {
                console.log(error);
                filterOrderProjects();
            });
    };

    const createOrderProjectButtonHandler = () => {
        setOpenModal(true);
    };

    const closeModalHandler = () => {
        setOpenModal(false);
    };

    const removeFromProject = async (id) => {
        await axios_instance
            .delete("/orders/order_project/remove/" + id)
            .then(({data}) => {
                filterOrderProjects();
            })
            .catch((error) => {
                console.log(error);
            });
    };

  return (
    <>
      <div className="orders-view-side-container">
      <MessageBox
          open={openDeleteModal}
          type="Confirm"
          onClose={() => {
            setDeleteOpenModal(false);
          }}
          actions={[
            <Button
              design="Emphasized"
              onClick={() => {
                removeFromProject(deleteProject);
                props.setChoosedProject("");
                props.setOpenProject(false);
                setDeleteOpenModal(false);
              }}
            >
              Удалить
            </Button>,
            <Button
              onClick={() => {
                setDeleteOpenModal(false);
              }}
            >
              Отмена
            </Button>,
          ]}
        >
          Нажмите "Удалить", чтобы подтвердить удаление.
        </MessageBox>
        <div className="orders-view-side-content">
          <Modal opened={openModal} onClose={() => closeModalHandler()}>
            <h1>Создание проекта заказа</h1>
            <FlexBox style={{ gap: "1rem", alignItems: "center" }}>
              <Input
                onChange={(e) => setOrderProjectData(e.target.value)}
                placeholder="Введите название проекта заказа"
              >
                Название проекта
              </Input>
              <Button onClick={() => createOrderProject()}>Создать</Button>
            </FlexBox>
          </Modal>
          {orderProjects && (
            <Table
              className={"order-side-view-table"}
              columns={isAdmin ? adminFields : fields}
              data={orderProjects}
              editDisplayMode="cell"
              enableEditing={false}
              enablePagination={false}
              enableColumnFilters={true}
              enableColumnOrdering={true}
              enableGrouping={false}
              enableColumnPinning={true}
              enableFacetedValues={false}
              enableColumnResizing
              enableRowActions={true}
              onColumnFiltersChange={setColumnFilters}
              enableStickyHeader={true}
              isLoading={loading}
              columnPinHeaderIcon={true}
              layoutMode={"grid-no-grow"}
              initialState={
                {
                  columnPinning: {
                    left: ['mrt-row-actions', 'mrt-row-pin', 'number'],
                    right: ['order_custome_name', 'order_uid']
                  }
                }
              }
              topToolbarCustomActions={
                <>
                  <Button 
                  disabled={!Boolean(props.permissionData?.purchasing_manager || props.permissionData?.admin || props.permissionData?.manager)}
                  onClick={() => createOrderProjectButtonHandler()}>
                    Создать проект заказа
                  </Button>
                </>
              }
              pagination={{
                page: page,
                setPage: setPage,
                pageCount: pageCount,
              }}
              rowActionMenuItems={({ row, closeMenu }) => [
                <MenuItem
                  key="view"
                  onClick={() => {
                    props.setChoosedProject(row.original?.id);
                    props.setOpenProject(true);
                    setSearch((prev) => ({
                      ...prev,
                      project: row.original?.id,
                    }));
                    closeMenu();
                  }}
                >
                  Просмотр
                </MenuItem>,
                <MenuItem
                  key="delete"
                  onClick={() => {
                    setDeleteProject(row.original?.id);
                    setDeleteOpenModal(true);
                    closeMenu();
                  }}
                  disabled={
                    row.original?.formed_status && !row.original?.completed
                  }
                >
                  Удалить проект заказа
                </MenuItem>,
              ]}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OrdersViewSide;
