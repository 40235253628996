import React, { useEffect } from "react";
import "./ModalWindow.css";
import classNames from "classnames";
import { Bar, Card } from "@ui5/webcomponents-react";

const ModalWindow = ({
  className,
  children,
  title,
  state,
  setState,
  width, // Новый пропс для ширины
  height, // Новый пропс для высоты
}) => {
  // Стили для Card
  const cardStyles = {}; 

  // Проверяем наличие пропсов перед добавлением в стили
  if (width) { 
    cardStyles.width = width;
  }
  if (height) {
    cardStyles.height = height;
  }
  return (
    <>
      {state && (
        <div
          style={{
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 3,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
          className="modal-window-overlay"
        ></div>
      )}
      <Card
       style={cardStyles}
        header={
          <Bar
            endContent={
              <div
                onClick={() => setState(false)}
                className="modal-window-close-btn"
              ></div>
            }
            startContent={<span>{title || ""}</span>}
          ></Bar>
        }
        className={classNames(
          "modal-window",
          state && "modal-window-open",
          className
        )}
      >
        <div className="modal-window-container">
          <div className="modal-window-content">{children}</div>
        </div>
      </Card>
    </>
  );
};

export default ModalWindow;