import "./body.css";
import { Button, Input, SuggestionItem } from "@ui5/webcomponents-react";
import CatalogMainPage from "../CatalogMainPage/CatalogMainPage";
import PlusMainPage from "../PlusMainPage/PlusMainPage";
import UserRoadMapContainer from "../UserRoadMapContainer/UserRoadMapContainer";
import MainPageMainContainer from "../MainPageMainContainer/MainPageMainContainer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import "@ui5/webcomponents-icons/dist/arrow-right.js";
import { HOST_IP_ADDRESS } from "../../../requests/constants";
import { useAtom } from "jotai";
import { activeCompanyAtom } from "../../../hoc/GlobalAtoms";

const Body = () => {
  const nav = useNavigate();
  const [inputCode, setInputCode] = useState("");
  const [inputError, setInputError] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [activeProduct, setActiveProduct] = useState("")

  const [activeCompany] = useAtom(activeCompanyAtom);
  const [searchError, setSearchError] = useState(false)
  const [searchErrorMessage, setSearchErrorMessage] = useState("")

  const [buttonWidth, setButtonWidth] = useState('70%');
  const [showClearIcon, setShowClearIcon] = useState(false);
  const [buttonDisplay, setButtonDisplay] = useState('block');
  const [inputFocus, setInputFocus] = useState(false)

  const websocket = useRef(null);
  const queryRef = useRef(inputCode);
  const inputRef = useRef(null)

  useEffect(() => {
    websocket.current = new WebSocket(HOST_IP_ADDRESS);

    websocket.current.onopen = () => {
      console.log('WebSocket is open now.');
    };

    websocket.current.onclose = () => {
      console.log('WebSocket is closed now.');
    };

    websocket.current.onmessage = (event) => {
      console.log('WebSocket message received:', event);
      let data = JSON.parse(event.data);
      if (data?.request_id === queryRef.current) {
        if (data.data.detail) {
          setSearchError(true);
          setSearchErrorMessage(data.data.detail);
        } else {
          setSuggestions(data?.data);
        }
      }
    };

    websocket.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      websocket.current.close();
    };
  }, []);

  const sendMessage = () => {
    if (websocket.current && websocket.current.readyState === WebSocket.OPEN){
      if (inputCode){
        let request_id = new Date().getTime();
        queryRef.current = request_id;
        const message = {
          request_id: request_id,  // Ensure this ID is unique per message
          action: 'search',         // Specify the action to be performed
          code: inputCode,
          entity: activeCompany.id,
        };
        websocket.current.send(JSON.stringify(message));
      }
    }
  };

  const onSuggestionItemSelect = (e) => {
    const selectedItemId = e.detail.item.attributes.id.value
    setActiveProduct(selectedItemId);
    nav(`/search?pid=${encodeURIComponent(selectedItemId)}`)
  }

  useEffect(() => {
    setSuggestions([]);
    sendMessage();
  }, [inputCode]);

  useEffect(() => {
    if (inputCode !== '') {
      setButtonWidth('0%');
    } else {
      setButtonWidth('70%');
      setShowClearIcon(false);
      setButtonDisplay('block');
    }
  }, [inputCode]);

  useEffect(() => {
    if (buttonWidth === '0%') {
      const timer = setTimeout(() => {
        setShowClearIcon(true);
        setButtonDisplay('none');
      }, 200);

      return () => clearTimeout(timer);
    }
  }, [buttonWidth]);

  return (
    <div className='main-page-body'>
      <div className="main-page-body-first-container">
        <MainPageMainContainer>
          <div className="main-page-body-container-main">
            <div className="main-page-body-container-content">
              <div className="main-page-body-first-container-buttons">
                <Input
                  ref={inputRef}
                  className="main-page-body-first-elem-code-input"
                  style={{
                    transition: '.7s ease',
                    width: inputCode === '' ? '70%' : '100%',
                  }}
                  placeholder={inputFocus ? '' : 'Введите артикул...'}
                  onInput={(e) => {
                    setInputCode(e.target.value);
                    setInputError(false);
                    setSearchError(false);
                    setSearchErrorMessage("");
                  }}
                  onFocus={() => setInputFocus(true)} // Устанавливаем фокус
                  onBlur={() => setInputFocus(false)} // Снимаем фокус
                  showSuggestions
                  valueState={searchError ? "Error" : "None"}
                  valueStateMessage={searchError ? <div>{searchErrorMessage}</div> : null}
                  showClearIcon={showClearIcon}
                  onSuggestionItemSelect={onSuggestionItemSelect}

                >
                  {suggestions &&
                    suggestions.map((suggestion) => {
                      return (
                        <SuggestionItem
                          id={suggestion?.id}
                          icon="product"
                          text={`Артикул: ${suggestion?.code} | Наименование: ${suggestion?.name ? suggestion?.name : "Не указано"
                          } | Бренд: ${suggestion?.brand
                              ? suggestion?.brand
                              : "Не указан"
                          } | Производитель: ${
                            suggestion?.manufacture
                              ? suggestion?.manufacture
                              : "Не указан"
                          }`}
                        />
                      );
                    })}
                </Input>
                <Button
                    style={{
                      transition: '.7s ease',
                      width: buttonWidth,
                      overflow: 'hidden',
                      opacity: buttonWidth === '0%' ? 0 : 1,
                      display: buttonDisplay
                    }}
                  className="main-page-body-first-elem-input-btn"
                  onClick={() => {
                    inputCode !== "" && activeProduct !== ""
                      ? nav(`/search?pid=${activeProduct}`)
                      : setInputError(true);
                    inputRef.current.focus();
                    setInputError(false);
                    setInputFocus(true);
                  }}
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                >
                  <div className="main-page-body-first-elem-input-btn-btn">
                    {inputCode === "" ? <></> : null}
                  </div>
                </Button>
              </div>
              <text className="main-page-body-first-elem-text-h1">
                ЭКСПРЕСС-ЗАПЧАСТЬ
              </text>
              <text className="main-page-body-first-elem-text-h2">
                БЫСТРО И ВЫГОДНО
              </text>
            </div>
          </div>
        </MainPageMainContainer>
      </div>
      <MainPageMainContainer>
        <div className="main-page-body-second-container">
          <div className="main-page-body-second-container-content">
            <div className="main-page-body-second-container-block">
              <h1 className="main-page-body-second-container-block-text-1">
                ЭКЗА – ПОСТАВКА ЗАПЧАСТЕЙ ОТ НАДЁЖНЫХ ПАРТНЁРОВ
              </h1>
            </div>
            <div className="main-page-body-second-container-block">
              <span className="main-page-body-second-container-block-text-2">
                Поставщиками ЭКЗА являются дилеры ПАО КАМАЗ. 
                Вы можете быть уверены в профессионализме партнёров и качестве запасных частей. 
                Цены на сайте включают доставку выбранным вами способом. 
                При заказе нескольких деталей, система подберёт самое выгодное 
                предложение на основе цены поставщика и стоимости доставки.
              </span>
            </div>
          </div>
        </div>
        <div className="main-page-body-third-container">
          <div className="main-page-body-third-container-content">
            <CatalogMainPage/>
          </div>
        </div>
        <div className="main-page-body-fourth-container">
          <div className="main-page-body-fourth-container-content">
            <text className="main-page-body-third-elem-text-1">
              exzamarket.ru – БОЛЬШЕ, чем B2B-МАРКЕТПЛЕЙС
            </text>
            <text className="main-page-body-third-elem-text-2">
              Компания ЭКЗА предоставляет своим партнёрам больше возможностей для развития бизнеса
            </text>
            <PlusMainPage/>
          </div>
        </div>
      </MainPageMainContainer>
      <div className="main-page-body-fifth-container">
        <MainPageMainContainer>
          <div className="main-page-body-fifth-container-main">
            <div className="main-page-body-fifth-container-content">
              <div className="main-page-body-fifth-container-left-side">
                <text className="main-page-body-fifth-container-left-side-first">
                  <text style={{ color: "red" }}>ЛОГИСТИКА </text>- ОНЛАЙН
                </text>
                <text className="main-page-body-fifth-container-left-side-second">
                  Расчёт доставки от разных логистических компаний интегрирован в площадку. 
                  Доверьтесь алгоритму или собирайте ваши заказы на основе 
                  рекомендаций системы для получения низкой цены, включающей доставку.
                </text>
                <div className="main-page-body-fifth-container-left-side-third">
                  <div className="main-page-body-fifth-container-left-side-third-elem">
                    <text className="main-page-body-fifth-container-left-side-third-elem1">
                      Найдите запчасть
                    </text>
                    <text className="main-page-body-fifth-container-left-side-third-elem2">
                      <text>Выберите запчасть по артикулу или названию.</text>
                      <text>Определитесь с брендом и производителем.</text>
                      <text>Сформируйте проект заказа.</text>
                      <text>Сохраните проект до момента покупки или на будущее.</text>
                    </text>
                    <Button 
                      className="main-page-body-first-elem-button"
                      design="Transparent"
                      onClick={() => nav("/search")}
                    >
                      Поиск
                    </Button>
                  </div>
                  <div className="main-page-body-fifth-container-left-side-third-elem">
                    <text className="main-page-body-fifth-container-left-side-third-elem1">
                      Выберите доставку
                    </text>
                    <text className="main-page-body-fifth-container-left-side-third-elem2">
                      <text>Доставка до двери или до пункта выдачи заказов любой транспортной компании.</text>
                      <text>Оформите заказ, включая удобный вид доставки.</text>
                      <text>Цена зачасти включает доставку.</text>
                    </text>
                    <Button
                      className="main-page-body-first-elem-button"
                      design="Transparent"
                      onClick={() => nav("/search")}
                    >
                      Оформить заказ
                    </Button>
                  </div>
                </div>
              </div>
              <div className="main-page-body-fifth-container-right-side">
                <img 
                  alt="EXZA"
                  src="/static/fifth-container-img.svg"
                  className="fifth-container-img"
                />
              </div>
            </div>
          </div>
        </MainPageMainContainer>
      </div>
      <MainPageMainContainer>
        <div className="main-page-body-sixth-container">
          <div className="main-page-body-sixth-container-content">
            <text className="main-page-body-sixth-container-head">
              МАКСИМАЛЬНАЯ <span style={{ color: "red" }}>АВТОМАТИЗАЦИЯ</span> БИЗНЕС-<span style={{ color: "red" }}>
              ПРОЦЕССОВ </span>НА ПЛОЩАДКЕ
            </text>
            <UserRoadMapContainer/>
          </div>
        </div>
        <div className="main-page-body-seventh-container">
          <div className="main-page-body-seventh-container-content">
            <div className="main-page-body-seventh-container-body">
              <div className="main-page-body-seventh-container-body-head">
                ОСТАЛИСЬ ВОПРОСЫ?
              </div>
              <text className="main-page-body-seventh-container-body-head-text">
                Напишите нам, и мы с вами свяжемся
              </text>
              <div className="main-page-body-seventh-container-input-container">
                <textarea
                  className="main-page-body-seventh-container-input"
                  style={{ height: 150 }}
                  placeholder="Ваш вопрос / контакты"
                />
              </div>
              <Button
                design="Transparent"
                className="main-page-body-seventh-container-button"
                onClick={() => nav("/search")}
              >
                <div className="main-page-body-first-elem-input-btn-btn">
                  Отправить
                  <img
                    className="arrow-icon"
                    src="/static/Arrow.svg"
                    alt="EXZA"
                  />
                </div>
              </Button>
            </div>
          </div>
        </div>
      </MainPageMainContainer>
    </div>
  );
};

export default Body;
