
import React, { useEffect, useState, useRef } from 'react'
import axios_instance from '../requests/config_defaults';
import { Input, Button, FlexBox, SuggestionItem } from '@ui5/webcomponents-react';

const useGeoSearcher = (
    style,
    className
) => {
    const [q, setQ] = useState("")
    const [suggestions, setSuggestions] = useState([])
    const [value, setValue] = useState(null);

    const geoguidSearch = async () => {
        await axios_instance
            .get(`/geo_object/list?contentType=city&q=${q}`)
            .then(({ data }) => {
                setSuggestions(data?.results)
            })
            .catch((error) => {
                console.log(error);
            });
    };

    // console.log(input)
    useEffect(() => {
        geoguidSearch()
    }, [q]);

    return {
        searcher:
            <FlexBox
                alignItems='Center'
                className={className || ""}
                style={style || {}}
            >
                <Input
                    icon={null}
                    type="Text"
                    showSuggestions
                    placeholder='Укажите геолокацию'
                    style={{ width: "100%" }}
                    onChange={(e) => {
                        setQ(e.target.value)
                    }}
                    onInput={(e) => {
                        setQ(e.target.value)
                    }}
                    value={q}
                    readonly={value !== null || undefined}
                    onSuggestionItemSelect={
                        (e) => {
                            setValue(suggestions[e.target.Suggestions.selectedItemIndex])
                            e.target.value = suggestions[e.target.Suggestions.selectedItemIndex]?.full_name
                        }
                    }
                >
                    {suggestions.map((sugg) => (
                        <SuggestionItem
                            id={sugg.id}
                            text={sugg.name}
                            description={sugg.full_name}
                        />
                    ))}
                </Input>
                <Button
                    icon='decline'
                    onClick={() => {
                        setValue(null)
                        setSuggestions([])
                        setQ("")
                    }}
                    design='Negative' />
            </FlexBox>,
        geo_object: value,
        setValue: setValue
    };
}

export default useGeoSearcher