import { FlexBox } from "@ui5/webcomponents-react";
import React, { useEffect, useMemo, useState } from "react";
import axios_instance from "../../../requests/config_defaults";
import "./CompaniesSideView.css";
import { MenuItem, Box } from "@mui/material";
import { Button } from "@ui5/webcomponents-react";
import Table from "../../base/table/Table";
import SendInviteForm from "../send-invite-form/SendInviteForm";
import ModalWindow from "../../base/modal/ModalWindow";
import { useSearchParams } from "react-router-dom";
import { activeCompanyAtom, isAdminAtom } from "../../../hoc/GlobalAtoms";
import { useAtom } from "jotai";

const fields = {
  tax_number: {
    key: "tax_number",
    header: "ИНН",
    editable: false,
    onColumnFilterFnsChange: (e) => console.log(e),
    Cell: ({ renderedCellValue, cell }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          borderRadius: 20,
          padding: "5px",
          color: "#fff",
        }}
      >
        <span
          style={{
            color: cell.row.original.activeCompany ? "blue" : "black",
          }}
        >
          {cell.row.original.tax_number}
        </span>
      </Box>
    ),
  },
  name: {
    key: "name",
    header: "Наименование",
    editable: false,
    edit_type: "number",
    Cell: ({ renderedCellValue, cell }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          borderRadius: 20,
          padding: "5px",
          color: "#fff",
        }}
      >
        <span
          style={{
            color: cell.row.original.activeCompany ? "blue" : "black",
          }}
        >
          {cell.row.original.name}
        </span>
      </Box>
    ),
  },
  creator_email: {
    key: "creator_email",
    header: "Создатель",
    editable: false,
    Cell: ({ renderedCellValue, cell }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          borderRadius: 20,
          padding: "5px",
          color: "#fff",
        }}
      >
        <span
          style={{
            color: cell.row.original.activeCompany ? "blue" : "black",
          }}
        >
          {cell.row.original.creator_email}
        </span>
      </Box>
    ),
  },
  create_date_time_stamp: {
    key: "create_date_time_stamp",
    header: "Время создания",
    editable: false,
    Cell: ({ renderedCellValue, cell }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          borderRadius: 20,
          padding: "5px",
          color: "#fff",
        }}
      >
        <span
          style={{
            color: cell.row.original.activeCompany ? "blue" : "black",
          }}
        >
          {cell.row.original.create_date_time_stamp}
        </span>
      </Box>
    ),
  },
};

const fields_admin = {
  ...fields,
  is_moderated: {
    key: "is_moderated",
    header: "Премодерация включена",
    editable: false,
    Cell: ({ renderedCellValue, cell }) => (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          borderRadius: 20,
          padding: "5px",
          color: "#fff",
        }}
      >
        <span
          style={{
            color: cell.row.original.activeCompany ? "blue" : "black",
          }}
        >
          {cell.row.original.is_moderated ? "Да" : "Нет"}
        </span>
      </Box>
    ),
  },
};

const CompaniesSideView = (props) => {
  const [activeCompany, setActiveCompanyAtom] = useAtom(activeCompanyAtom);
  const [search, setSearch] = useSearchParams();
  const [maxPage, setMaxPage] = useState(1);
  const [page, setPage] = useState(1);
  const [columnFilters, setColumnFilters] = useState([]);

  const [query, setQuery] = useState("");

  const [myCompanies, setMyComapnies] = useState([]);

  const [sendInviteMode, setSendInviteMode] = useState(false);
  const [myCompaniesIds, setMyCompaniesIds] = useState([]);
  const [isAdmin] = useAtom(isAdminAtom);

  const getMyCompanies = async () => {
    await axios_instance
      .get("/myCompanies")
      .then(({ data }) => {
        const myCompaniesIDS = [];
        data?.results?.map((company) => {
          myCompaniesIDS.push(company.entity);
        });
        setMyCompaniesIds(myCompaniesIDS);
      })
      .catch((error) => {});
  };

  const setIsModerated = async (_id, is_moderated) => {
    console.log(_id, is_moderated);
    await axios_instance
      .put(`/entity/premoderation/update/${_id}`, {
        is_moderated: is_moderated,
      })
      .then((response) => {
        getMyLegalEntities();
      })
      .catch((error) => {});
  };

  const getMyLegalEntities = async () => {
    props.setLoading(true);
    await axios_instance
      .get(
        `/myLegalEntities?page=${page}${
          query !== "" && query !== undefined ? `&query=${query}` : ""
        }`
      )
      .then(({ data }) => {
        const myCompaniesData = [];
        data?.results?.map((company) => {
          myCompaniesData.push({
            id: company.id,
            contacts: company.contacts,
            default_contact: company.default_contact,
            managers: company.managers,
            tax_number: company.tax_number,
            name: company.name,
            creator_email: company.creator_email,
            create_date_time_stamp: company.create_date_time_stamp,
            activeCompany: company.id === activeCompany.id ? true : false,
            is_moderated: company.premoderation[0].is_moderated,
            premoderation_create_date_time_stamp:
              company.premoderation[0].create_date_time_stamp,
            premoderation_updated_at: company.premoderation[0].updated_at,
            premoderation_id: company.premoderation[0].id,
          });
        });
        setMyComapnies(myCompaniesData);
        setMaxPage(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        props.setLoading(false);
      })
      .catch((error) => {
        setMyComapnies([]);
        props.setLoading(false);
      });
  };

  const getCompanyAddresses = async (_id) => {
    await axios_instance
      .get(`/myCompany/addresses/${_id}`)
      .then(({ data }) => {
        const default_address = data.find(address => address.is_default === true) || null;
        if (default_address) {
          props.setActiveCompanyAddress({
            id: default_address.id,
            entity: _id,
            exact_address: default_address.exact_address,
            geo_object: default_address.geo_object,
            geo_object_full: default_address.geo_object_full,
            name: default_address.name,
            is_pvz: default_address?.pvz,
            type: default_address?.type?.name,
            is_default: default_address?.is_default,
          });
        } else props.setActiveCompanyAddress({})
      })
      .catch((error) => {});
  };

  const setCompany = async (_id) => {
    await axios_instance
      .post(`/setMyCompany/${_id}`)
      .then((response) => {
        getCompanyAddresses(_id);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getMyLegalEntities();
    getMyCompanies();
  }, []);

  useEffect(() => {
    getMyLegalEntities();
    getMyCompanies();
  }, [page, columnFilters, query, props.legalEntitiesUpdate, activeCompany]);

  // useEffect(() => {
  //     setPage(1);
  // }, [columnFilters])

  return (
    <>
      <Table
        columns={isAdmin ? fields_admin : fields}
        data={myCompanies}
        enableEditing={false}
        enableColumnResizing
        enableColumnFilters={false}
        enableColumnOrdering={true}
        enableGrouping={false}
        enableColumnPinning={false}
        enableFacetedValues={false}
        enableStickyHeader
        enableRowActions
        enableGlobalFilter
        onGlobalFilterChange={(filterValue) => {
          setQuery(filterValue);
        }}
        className={"CompaniesSideView-table"}
        isLoading={props.loading}
        topToolbarCustomActions={
          <FlexBox justifyContent="Start" alignItems="Center">
            <Button
              design="Emphasized"
              onClick={() => {
                props.setCreateLegalEntityMode(true);
              }}
              style={{ marginRight: 10 }}
            >
              Создать новое юр. лицо
            </Button>
            <Button
              design="Default"
              onClick={() => {
                setSendInviteMode(true);
              }}
            >
              Запросить доступ
            </Button>
          </FlexBox>
        }
        pagination={{
          page: page,
          setPage: setPage,
          pageCount: maxPage,
        }}
        rowActionMenuItems={({ closeMenu, row }) => [
          <MenuItem
            key="view"
            onClick={() => {
              setSearch((prev) => ({ ...prev, company: row.original?.id }));
              props.setActiveCompany(row.original?.id);
              props.setPopUpCompanyName(row.original?.name);
              closeMenu();
            }}
          >
            Просмотр
          </MenuItem>,
          <MenuItem
            key="view"
            disabled={
              row?.original?.activeCompany ||
              !myCompaniesIds.includes(row?.original?.id)
            }
            onClick={() => {
              setActiveCompanyAtom(row?.original);
              setCompany(row?.original?.id);
              closeMenu();
            }}
          >
            Сделать компанию активной
          </MenuItem>,
          isAdmin && (
            <MenuItem
              key="update"
              onClick={() => {
                setIsModerated(
                  row.original.premoderation_id,
                  !row.original.is_moderated
                );
                closeMenu();
              }}
            >
              {row.original.is_moderated
                ? "Отключить премодерацию"
                : "Включить премодерацию"}
            </MenuItem>
          ),
        ]}
      />

      <ModalWindow
        state={sendInviteMode}
        setState={setSendInviteMode}
        title={"Отправить заявку на вступление в компанию"}
        children={
          <SendInviteForm
            legalEntitiesUpdate={props.legalEntitiesUpdate}
            setLegalEntitiesUpdate={props.setLegalEntitiesUpdate}
            setSendInviteMode={setSendInviteMode}
          />
        }
      />
    </>
  );
};

export default CompaniesSideView;
