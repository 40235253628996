import React, { useEffect, useState } from 'react'
import './CompanyEditForm.css'
import { Bar, Button, Card, Timeline, TimelineItem, Form, FormGroup, FormItem, Input, ObjectStatus, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults'


const CompanyEditForm = (props) => {
    const [nameValueState, setNameValueState] = useState('');

    const [taxNumberValueState, setTaxNumberValueState] = useState('');

    const [editedLegalEntity, setEditedLegalEntity] = useState({
        name: props.entity_name,
        tax_number: props.entity_tax_number
    });

    const [legalEntityNames, setLegalEntityNames] = useState([]);

    const [legalEntityTaxNumbers, setLegalEntityTaxNumbers] = useState([]);

    function isNaturalNumber(n) {
        var n1 = Math.abs(n),
            n2 = parseInt(n, 10);
        return !isNaN(n1) && n2 === n1 && n1.toString() === n;
    }

    const handleEditLegalEntity = async () => {
        await axios_instance.put(
            `/entity/edit/${props.activeCompany}`,
            editedLegalEntity
        )
            .then((response) => {
                props.setEditLegalEntityMode(false);
                props.setLegalEntitiesUpdate(!props.legalEntitiesUpdate);
                props.setCompanyUpdate(!props.companyUpdate);
                console.log(response);
            })
            .catch((error) => {
                let serializer_error = false;
                if (error.response?.data?.name){
                    setNameValueState(error.response.data.name[0]);
                    serializer_error = true;
                }
                if (error.response?.data?.tax_number){
                    setTaxNumberValueState(error.response.data.tax_number[0]);
                    serializer_error = true;
                }
                if (!serializer_error) {
                    setTaxNumberValueState("Не удалось отредактировать компанию");
                }
                console.log(error);
            })
    }

    const getLegalEntityNames = async () => {
        await axios_instance.get(
            `/entity/name/list/${props.activeCompany}`
        )
            .then((response) => {
                setLegalEntityNames(response.data);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getLegalEntityTaxNumbers = async () => {
        await axios_instance.get(
            `/entity/tax_number/list/${props.activeCompany}`
        )
            .then((response) => {
                setLegalEntityTaxNumbers(response.data);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        getLegalEntityNames();
        getLegalEntityTaxNumbers();
    }, [props.editLegalEntityMode])

    useEffect(() => {
        setEditedLegalEntity({
            name: props.entity_name,
            tax_number: props.entity_tax_number
        })
    }, [props.entity_name, props.entity_tax_number])

    return (
        <div style={{ margin: 20 }}>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanL={1}
                labelSpanM={1}
                labelSpanS={1}
                labelSpanXL={1}
                style={{
                    alignItems: 'left',

                }}
                // className='MyOfferCreateForm-form'
                titleText="Заполните форму"
            >
                <FormGroup>
                    <FormItem label="Наименование">
                        <Input
                            valueState={nameValueState ? "Error" : props.entity_name !== editedLegalEntity.name ? "Information" : ""}
                            onInput={
                                (e) => {
                                    setNameValueState("");
                                    setEditedLegalEntity((prev) => ({
                                        ...prev,
                                        name: e.target.value.trim()
                                    }))
                                }
                            }
                            placeholder={props.entity_name}
                            valueStateMessage={<div>Изменено</div>}
                            style={{ width: '100%' }}
                            value={editedLegalEntity.name}
                        />
                    </FormItem>
                    {nameValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {nameValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem label="ИНН">
                        <Input
                            valueState={taxNumberValueState ? "Error" : props.entity_tax_number !== editedLegalEntity.tax_number ? "Information" : ""}
                            onInput={
                                (e) => {
                                    setTaxNumberValueState("");
                                    setEditedLegalEntity((prev) => ({
                                        ...prev,
                                        tax_number: e.target.value.trim()
                                    }))
                                }
                            }
                            placeholder={props.entity_tax_number}
                            valueStateMessage={<div>Изменено</div>}
                            style={{ width: '100%' }}
                            maxlength={10}
                            value={editedLegalEntity.tax_number}
                        />
                    </FormItem>
                    {taxNumberValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {taxNumberValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                </FormGroup>
                <FormItem alignItems={"left"} label="">
                    <Button
                        design='Emphasized'
                        style={{
                            padding: "0.5rem 1rem",
                            width: "100%"
                        }}
                        onClick={() => {
                            let validate_status = true;
                            if (editedLegalEntity.name === "") {
                                setNameValueState("Обязательное поле");
                                validate_status = false;
                            }
                            if (editedLegalEntity.tax_number === "") {
                                setTaxNumberValueState("Обязательное поле");
                                validate_status = false;
                            }
                            else if (editedLegalEntity.tax_number.length < 10) {
                                setTaxNumberValueState("ИНН должен содержать не менее 10 цифр");
                                validate_status = false;
                            }
                            else if (!isNaturalNumber(editedLegalEntity.tax_number)) {
                                setTaxNumberValueState("Неверный формат ИНН");
                                validate_status = false;
                            }
                            if (validate_status) {
                                handleEditLegalEntity();
                            }
                        }}
                    >
                        Изменить
                    </Button>
                </FormItem>
            </Form>
            <h1>История изменений</h1>
            {legalEntityNames.length === 0 && legalEntityTaxNumbers.length === 0 &&
                <h4>История изменений пуста</h4>
            }
            {legalEntityNames.length !== 0 &&
                <h2>Наименование</h2>
            }
            <Timeline>
                {legalEntityNames.map((entity_name, index) => {
                    return (
                        <TimelineItem
                            style={{ fontSize: 'large' }}
                            name={entity_name?.creator?.email}
                            nameClickable
                        >
                            <div style={{ fontSize: 'large' }}>
                                {index === legalEntityNames.length - 1 ?
                                    <p style={{ color: 'green' }}>{`${entity_name?.name}`}</p> :
                                    <p>
                                        <span style={{ color: 'red', marginRight: 5 }}>{legalEntityNames[index + 1]?.name}</span><span>&gt;</span><span style={{ color: 'green', marginLeft: 5 }}>{entity_name?.name}</span>
                                    </p>
                                }
                                <p>{entity_name?.create_date_time_stamp}</p>
                            </div>
                        </TimelineItem>
                    )
                })}
            </Timeline>
            {legalEntityTaxNumbers.length !== 0 &&
                <h2>ИНН</h2>
            }
            <Timeline>
                {legalEntityTaxNumbers.map((entity_tax_number, index) => {
                    return (
                        <TimelineItem
                            name={entity_tax_number?.creator?.email}
                            nameClickable
                        >
                            <div style={{ fontSize: 'small' }}>
                                {index === legalEntityTaxNumbers.length - 1 ?
                                    <p style={{ color: 'green' }}>{`${entity_tax_number?.tax_number}`}</p> :
                                    <p>
                                        <span style={{ color: 'red', marginRight: 5 }}>{legalEntityTaxNumbers[index + 1]?.tax_number}</span><span>&gt;</span><span style={{ color: 'green', marginLeft: 5 }}>{entity_tax_number?.tax_number}</span>
                                    </p>
                                }
                                <p>{entity_tax_number?.create_date_time_stamp}</p>
                            </div>
                        </TimelineItem>
                    )
                })}
            </Timeline>

        </div>
    )
}

export default CompanyEditForm