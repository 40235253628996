import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import "./ExchangeElement.css";
import { Badge, Button } from "@ui5/webcomponents-react";

const ExchangeElement = (props) => {
  const nav = useNavigate();

  const exchangeClickHandler = (e) => {
    props.setExchange(e.target.id);
  };

  return (
    <div className="exchange-element-content">
      <div className="element">
        {props.name ? (
          <p
            id={props.id}
            className="exchange-name"
            onClick={(e) => exchangeClickHandler(e)}
          >
            {props.name}
          </p>
        ) : (
          <div className="upload-file-btn-container">
            <Button
              id={props.id}
              className="upload-file-btn"
              onClick={(e) => exchangeClickHandler(e)}
            >
              Выгрузить файл
            </Button>
          </div>
        )}
        {props.startTime && !props.processed && (
          <Badge colorScheme="6">В обработке</Badge>
        )}
        {!props.startTime && !props.processed && props.name && (
          <Badge colorScheme="10">Принята</Badge>
        )}
        {!props.startTime && !props.processed && !props.name && (
          <Badge colorScheme="1">Загрузите файл</Badge>
        )}
        {props.startTime && props.processed && (
          <Badge colorScheme="8">Завершена</Badge>
        )}
        <p>{props.rowCount ? props.rowCount : 0}</p>
      </div>
    </div>
  );
};

export default ExchangeElement;
