import { useState, useEffect } from "react"
import Select from "react-select"
import axios_instance from "../../../../requests/config_defaults"

const EntityFilter = ({ column }) => {
  const { filterValue, setFilterValue } = column
  const [selectedValues, setSelectedValues] = useState([])
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const handleChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : []
    setSelectedValues(values)
    setFilterValue(values)
  }
  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true)
      try {
        const response = await axios_instance.get(`/myLegalEntities`)
        const uniqueEntities = []
        for (const item of response.data.results) {
          uniqueEntities.push({ id: item.id, name: item.name })
        }
        setOptions(
          uniqueEntities.map((entity) => ({
            value: entity.id,
            label: entity.name,
          }))
        )
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchOptions()
  }, [])
  return (
    <Select
      isMulti
      value={
        options &&
        options.filter((option) => selectedValues.includes(option.value))
      }
      onChange={handleChange}
      options={options}
      placeholder="Выберите поставщиков..."
    />
  )
}

const BrandFilter = ({ column }) => {
  const { filterValue, setFilterValue } = column
  const [selectedValues, setSelectedValues] = useState([])
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true)
      try {
        const response = await axios_instance.get(`/brand_list`)
        console.log(response)
        const uniqueBrands = []

        for (const item of response.data) {
          if (item?.name) {
            uniqueBrands.push({
              id: item.id,
              name: item.name,
            })
          }
        }
        setOptions(
          uniqueBrands.map((brand) => ({
            value: brand.id,
            label: brand.name,
          }))
        )
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchOptions()
  }, [])

  const handleChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : []
    setSelectedValues(values)
    setFilterValue(values)
  }

  return (
    <Select
      isMulti
      value={options.filter((option) => selectedValues.includes(option.value))}
      onChange={handleChange}
      options={options}
      placeholder="Выберите Бренды..."
      isLoading={isLoading}
    />
  )
}

const ManufacturerFilter = ({ column }) => {
  const { filterValue, setFilterValue } = column
  const [selectedValues, setSelectedValues] = useState([])
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true)
      try {
        const response = await axios_instance.get(`/manufacture_list`)
        const uniqueManufacturers = []
        for (const item of response.data) {
          if (item.name) {
            uniqueManufacturers.push({ id: item.id, name: item.name })
          }
        }
        setOptions(
          uniqueManufacturers.map((manufacturer) => ({
            value: manufacturer.id,
            label: manufacturer.name,
          }))
        )
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchOptions()
  }, [])

  const handleChange = (selectedOptions) => {
    const values = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : []
    setSelectedValues(values)
    setFilterValue(values)
  }

  return (
    <Select
      isMulti
      value={options.filter((option) => selectedValues.includes(option.value))}
      onChange={handleChange}
      options={options}
      placeholder="Выберите производителей..."
      isLoading={isLoading}
    />
  )
}

export { EntityFilter }
export { BrandFilter }
export { ManufacturerFilter }
