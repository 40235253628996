import React, { useEffect, useState } from 'react'
import './MyOfferCreateForm.css'
import { Bar, Button, Card, CheckBox, Form, FormGroup, FormItem, Input, Label, Link, Option, Select, StepInput, Text, TextArea } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults'

const MyOfferCreateForm = (props) => {
    let warehouse = {}
    try {
        warehouse = props.addressesData[0];
    } catch {
        warehouse = {};
    }

    const [formData, setformData] = useState({
        product: props.productData?.id,
        current_rest: 0.0,
        needful_rest: 0.0,
        sale_price: 0.0,
        purchase_price: 0.0,
        warehouse: warehouse?.id
    });

    const getManufactor = () => {
        var name = "";
        props.productData?.properties.map((property) => {
            if (property.name === "Основной поставщик") {
                name = property?.value?.content || "Не указан";
            }
        });
        return name;
    };

    const getName = (product) => {
        var name = "";
        props.productData?.properties.map((property) => {
            if (property.name === "Наименование") {
                name = property?.value?.content || "Не указан";
            }
        });
        return name;
    };

    const createStock = async (e) => {
        e.preventDefault()
        await axios_instance.post(
            "/createStock",
            formData
        )
            .then((response) => {
                props.setOfferCreateMode(false)
                props.getOffers()
            })
            .catch((error) => {
                props.setOfferCreateMode(false)
            })
    }

    useEffect(() => {
        console.log(formData);
    }, [formData])

    return (
        <>
            <div className='MyOfferCreateForm-overlay' id='MyOfferCreateForm-overlay'></div>

            <Card
                header={
                    <Bar
                        endContent={
                            <div
                                onClick={() => props.setOfferCreateMode(false)}
                                className="MyOfferCreateForm-modal-close-btn"></div>
                        }
                        startContent={<span>Создание товара на складе</span>}
                        style={{
                            height: "50px"
                        }}
                    ></Bar>
                }
                style={{
                    height: "65vh"
                }}
                className='MyOfferCreateForm-form-layout MyOfferCreateForm-form-layout-open'
            >
                <div className="MyOfferCreateForm-modal-container">
                    <Form
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={2}
                        labelSpanL={4}
                        labelSpanM={2}
                        labelSpanS={12}
                        labelSpanXL={4}
                        style={{
                            alignItems: 'left',

                        }}
                        // className='MyOfferCreateForm-form'
                        titleText="Заполните форму"
                    >
                        <FormGroup
                            titleText="Информация о товаре">
                            <FormItem label="Артикул">
                                <Text>{props.productData?.code}</Text>
                            </FormItem>
                            <FormItem label="Бренд">
                                <Text>{props.productData?.brand?.name || "Не указан"}</Text>
                            </FormItem>
                            <FormItem label="Производитель">
                                <Text>{getManufactor()}</Text>
                            </FormItem>
                            <FormItem label="Наименование">
                                <Text>{getName()}</Text>
                            </FormItem>

                        </FormGroup>
                        <FormGroup titleText="Ввод остатков">
                            <FormItem label="Осталось на складе">
                                <StepInput
                                    valuePrecision={2}
                                    value={0}
                                    onChange={
                                        (e) => {
                                            setformData((prev) => ({
                                                ...prev,
                                                current_rest: e.target.value
                                            }))
                                        }
                                    }
                                    onInput={
                                        (e) => {
                                            setformData((prev) => ({
                                                ...prev,
                                                current_rest: e.target.value
                                            }))
                                        }
                                    }
                                />
                            </FormItem>
                            <FormItem label="Необходимо иметь на складе">
                                <StepInput
                                    valuePrecision={2}
                                    value={0}
                                    onChange={
                                        (e) => {
                                            setformData((prev) => ({
                                                ...prev,
                                                needful_rest: e.target.value
                                            }))
                                        }
                                    }
                                    onInput={
                                        (e) => {
                                            setformData((prev) => ({
                                                ...prev,
                                                needful_rest: e.target.value
                                            }))
                                        }
                                    }
                                />
                            </FormItem>
                        </FormGroup>
                        <FormGroup titleText="Ввод цен">
                            <FormItem label="Цена продажи">
                                <StepInput
                                    valuePrecision={2}
                                    value={0}
                                    onChange={
                                        (e) => {
                                            setformData((prev) => ({
                                                ...prev,
                                                sale_price: e.target.value
                                            }))
                                        }
                                    }
                                    onInput={
                                        (e) => {
                                            setformData((prev) => ({
                                                ...prev,
                                                sale_price: e.target.value
                                            }))
                                        }
                                    }
                                />
                            </FormItem>
                            <FormItem label="Цена покупки">
                                <StepInput
                                    valuePrecision={2}
                                    value={0}
                                    onChange={
                                        (e) => {
                                            setformData((prev) => ({
                                                ...prev,
                                                purchase_price: e.target.value
                                            }))
                                        }
                                    }
                                    onInput={
                                        (e) => {
                                            setformData((prev) => ({
                                                ...prev,
                                                purchase_price: e.target.value
                                            }))
                                        }
                                    }
                                />
                            </FormItem>
                        </FormGroup>
                        <FormGroup titleText="Выбор склада">
                            <FormItem label="Склад">
                                <Select
                                    onChange={(e) => {
                                        setformData((prev) => ({
                                            ...prev,
                                            warehouse: e.detail.selectedOption.dataset.id
                                        }))
                                    }}
                                >
                                    {props.addressesData.map((address) => (
                                        <Option
                                            key={address?.id} data-id={address?.id}
                                            value={address?.id}>
                                            {address?.name}
                                        </Option>
                                    ))}
                                </Select>
                            </FormItem>
                        </FormGroup>
                        <FormItem alignItems={"left"} label="">
                            <Button
                                design='Emphasized'
                                style={{
                                    padding: "0.5rem 1rem",
                                    width: "100%"
                                }}
                                onClick={async (e) => { await createStock(e) }}
                            >Создать</Button>
                        </FormItem>
                        <FormGroup titleText="Контактная информация">
                            <FormItem label="Веб-сайт">
                                <Link href="https://exzamarket.ru">
                                    https://exzamarket.ru
                                </Link>
                            </FormItem>
                            <FormItem label="E-mail">
                                <Link>
                                    invoice@exzamarket.ru
                                </Link>
                            </FormItem>
                            <FormItem label="Телефон">
                                <Link>
                                    +7 917 886-47-56
                                </Link>
                            </FormItem>
                            <FormItem label="Компания">
                                <Text>
                                    ООО "Экспресс Запчасть" (ООО "ЭКЗА", ИНН 1650384691) – дочерняя компания ООО "Цифровая платформа КАМАЗ", учредитель ПАО КАМАЗ
                                </Text>
                            </FormItem>
                            <FormItem label="Деятельность">
                                <Text>
                                    ООО "ЭКЗА" осуществляет свою деятельность совместно и в интересах ООО "Автозапчасть КАМАЗ" и его партнёров.
                                </Text>
                            </FormItem>
                            <FormItem label="Головной офис">
                                <Text>
                                    Россия, Респ. Татарстан, Набережные Челны
                                </Text>
                            </FormItem>
                        </FormGroup>
                    </Form>
                </div>
            </Card>

        </>
    )
}

export default MyOfferCreateForm