import {
  Bar,
  Button,
  FlexBox,
  Form,
  FormGroup,
  FormItem,
  Input,
  Card,
  Text,
} from "@ui5/webcomponents-react";
import { useState } from "react";
import axios_instance from "../../../../../requests/config_defaults";
import "./ProductsAdminPropertyAddForm.css";
import { Box } from "@mui/material";

const ProductsAdminPropertyAddForm = (props) => {
  const [properties, setProperties] = useState([]);
  const [newPropertiesData, setNewPropertiesData] = useState([
    { name: '', value: '', measure: ''},
  ]);

  const [addPropertyError, setAddPropertyError] = useState(false);
  const [addPropertyNullError, setAddPropertyNullError] = useState(false);

  const CreateUpdateProperties = async () => {
    await axios_instance
      .post(`product/properties/create/by_id`, {
        product_id: props?.activeProductID,
        properties: properties,
      })
      .then(({ data }) => {
        props.setOpenAddPropertiesForm(false)
      })
      .catch((error) => {});
  };

  const propertiesAddHandler = () => {
    let propertiesAddErrors = 0
    newPropertiesData.map((property) => {
      if (property.name === "" || property.value === "") {
        setAddPropertyError(true);
        propertiesAddErrors = propertiesAddErrors + 1
      };
      if (property.measure === "") {
        property.measure = "-"
      };
      properties.push({
        name: property.name,
        value: property.value,
        measure: property.measure
      })
    })
    if (properties.length === 0) {
      setAddPropertyNullError(true);
      propertiesAddErrors = propertiesAddErrors + 1
    } else if (propertiesAddErrors === 0) {
      CreateUpdateProperties();
    }
  }

  const propertyAddHandler = (target, name, index) => {
    let property = [...newPropertiesData];
    property[index][name] = target
    setNewPropertiesData(property);
  }

  const addFields = () => {
    let object = {
      name: '',
      value: '',
      measure: '',
    }
    setNewPropertiesData([...newPropertiesData, object])
  }

  const removeFields = (index) => {
    let property = [...newPropertiesData];
    property.splice(index, 1)
    setNewPropertiesData(property)
  }

  return (
    <>
      <div className="AdminProductUpadate-modal-container">
        <Form
          columnsL={1}
          columnsM={1}
          columnsS={1}
          columnsXL={1}
          labelSpanL={1}
          labelSpanM={1}
          labelSpanS={1}
          labelSpanXL={1}
          style={{
            alignItems: "left",
          }}
          titleText="Заполните форму"
        >
          <FormGroup titleText="Добавление характеристики">
            <FormItem
              style={{
                alignItems: "center",
              }}
            >
              {addPropertyError ? (
                <Text
                  style={{
                    fontSize: "1.1rem",
                    color: "red",
                    marginLeft: "50px",
                  }}
                >
                  Заполните все обязательные поля!
                </Text>
              ) : (
                null
              )}
              {addPropertyNullError ? (
                <Text
                  style={{
                    fontSize: "1.1rem",
                    color: "red",
                    marginLeft: "50px",
                  }}
                >
                  Добавьте хотя бы одну характеристику!
                </Text>
              ) : (
                null
              )}
            </FormItem>
            {newPropertiesData.map((form, index) => {
              return (
                <FormItem key={index}>
                  <FlexBox
                    style={{
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Input
                      className="AdminAddPropertyFormInputItem"
                      placeholder="Наименование"
                      valueState={(addPropertyError && form.name === "") ? "Error" : "None"}
                      valueStateMessage={<div>Обязательное поле</div>}
                      onInput={() => {
                        setAddPropertyError(false)
                      }}
                      name="name"
                      value={form.name}
                      onChange={(e) => {
                        propertyAddHandler(e.target.value, e.target.name, index);
                        setProperties([])
                      }}
                    />
                    <Input
                      className="AdminAddPropertyFormInputItem"
                      placeholder="Значение"
                      valueState={(addPropertyError && form.value === "") ? "Error" : "None"}
                      valueStateMessage={<div>Обязательное поле</div>}
                      onInput={() => {
                        setAddPropertyError(false)
                      }}
                      name="value"
                      value={form.value}
                      onChange={(e) => {
                        propertyAddHandler(e.target.value, e.target.name, index);
                        setProperties([])
                      }}
                    />
                    <Input
                      className="AdminAddPropertyFormInputItem"
                      placeholder="Единицы измерения"
                      name="measure"
                      value={form.measure}
                      onChange={(e) => {
                        propertyAddHandler(e.target.value, e.target.name, index);
                        setProperties([])
                      }}
                    />
                    <Button
                      onClick={() => (
                        removeFields(index),
                        setProperties([]),
                        setAddPropertyError(false)
                      )}
                      icon="delete"
                    />
                  </FlexBox>
                </FormItem>
              )
            })}
          </FormGroup>
          <FormItem alignItems={"left"} label="">
            <Button
              icon="add"
              style={{ width: "100%" }}
              onClick={() => (
                addFields(),
                setAddPropertyNullError(false)
              )}
            >
              Добавить характеристику...
            </Button>
          </FormItem>
          <FormItem alignItems={"left"} label="">
            <Button
              design="Emphasized"
              style={{
                padding: "0.5rem 1rem",
                width: "100%",
              }}
              onClick={() => {
                propertiesAddHandler();
              }}
            >
              Сохранить
            </Button>
          </FormItem>
        </Form>
      </div>
    </>
  );
};

export default ProductsAdminPropertyAddForm;
