import React, { useState } from 'react'
import './UsersAdminCreateModal.css'
import { Bar, Button, Card, CheckBox, FlexBox, Form, FormGroup, FormItem, Input, MessageStrip, Text, ThemeProvider, ToggleButton } from '@ui5/webcomponents-react'
import classNames from 'classnames'
import '@ui5/webcomponents-icons/dist/hide.js';
import axios_instance from '../../../../requests/config_defaults';

const defaultData = {
    email: "",
    password: "",
    last_name: "",
    first_name: "",
    middle_name: "",
    is_admin: false
}

const defaultValidation = {
    email: true,
    password: true,
    last_name: true,
    first_name: true
}

const UsersAdminCreateModal = (props) => {
    const [showPass, setShowPass] = useState(false)
    const [data, setData] = useState(defaultData)
    const [validation, setValidation] = useState(defaultValidation)
    const [formValid, setFormValid] = useState(true)
    const [incorrectEmail, setIncorrectEmail] = useState(false)

    function validatePassword(password) {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return password.match(passwordRegex)
    }

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const formValidate = () => {
        let emailV = data.email !== defaultData?.email && validateEmail(data.email)
        let passwordV = data.password !== defaultData?.password && validatePassword(data.password)
        let last_nameV = data.last_name !== defaultData?.last_name
        let first_nameV = data.first_name !== defaultData?.first_name
        setFormValid(true)
        setValidation({
            email: emailV,
            password: passwordV,
            last_name: last_nameV,
            first_name: first_nameV,
        })
        let _formValid = emailV && passwordV && last_nameV && first_nameV
        setFormValid(_formValid)
        return _formValid
    }

    const closeForm = () => {
        setValidation(defaultValidation)
        setFormValid(true)
        setIncorrectEmail(false)
        setData(defaultData)
        setShowPass(false)
        props.controller(false)
    }

    function clearEmptyValues(obj) {
        for (const key in obj) {
            if (obj[key] === "") {
                delete obj[key];
            }
        }
        return obj;
    }

    const formSubmit = async () => {
        setIncorrectEmail(false)
        const is_valid = formValidate()
        if (!is_valid) {
            return
        }
        await axios_instance.post(
            "users/full-create", clearEmptyValues(data)
        )
            .then(({ data }) => {
                if (data?.detail === "Email already used") {
                    setIncorrectEmail(true)
                }
                else {
                    closeForm();
                }
            })
            .catch((error) => {

            })
    }

    return (
            <ThemeProvider>
                <div style={{margin: 20}}>
                    <MessageStrip hideCloseButton style={{ margin: "10px 0" }}>Пользователь будет сразу подтвержден</MessageStrip>
                    {!formValid && <MessageStrip design='Negative' hideCloseButton style={{ margin: "10px 0" }}>Заполните обязательные поля</MessageStrip>}
                    {incorrectEmail && <MessageStrip design='Negative' hideCloseButton style={{ margin: "10px 0" }}>Данный E-mail занят</MessageStrip>}
                    <Form>
                        <FormGroup titleText="Данные аккаунта">
                            <FormItem label={"E-mail"}>
                                <Input

                                    valueState={validation.email ? "None" : "Error"}
                                    valueStateMessage={"Некорректный E-mail"}
                                    required={true} type='Email' onChange={
                                        (e) => setData((prev) => ({
                                            ...prev,
                                            email: e.target.value
                                        }))
                                    } value={data?.email}
                                    onInput={(e) => setData((prev) => ({
                                        ...prev,
                                        email: e.target.value
                                    }))} />
                            </FormItem>

                            <FormItem label={"Пароль"}>
                                <FlexBox
                                    alignItems='Center'
                                    justifyContent='Stretch'
                                    style={{
                                        gap: "10px"
                                    }}
                                >
                                    <Input
                                        autocomplete="new-password"
                                        valueState={validation.password ? "None" : "Error"}
                                        required type={showPass ? "Text" : 'Password'} onChange={
                                            (e) => setData((prev) => ({
                                                ...prev,
                                                password: e.target.value
                                            }))
                                        } value={data?.password}
                                        onInput={(e) => setData((prev) => ({
                                            ...prev,
                                            password: e.target.value
                                        }))} />
                                    <Button icon={showPass ? 'hide' : 'show'} design='Transparent' onClick={() => setShowPass(!showPass)} pressed={showPass} />
                                </FlexBox>
                            </FormItem>
                            <FormItem label={"Прмечание"}>
                                <Text>Пароль должен содержать минимум одну заглавну букву, одну строчную букву, одну цифру и один спец символ. Минимальная длина: 8 символов</Text>
                            </FormItem>
                        </FormGroup>

                        <FormGroup titleText="Личная информация">
                            <FormItem label={"Фамилия"}>
                                <Input
                                    valueState={validation.last_name ? "None" : "Error"}
                                    required={true} type='Text' onChange={
                                        (e) => setData((prev) => ({
                                            ...prev,
                                            last_name: e.target.value
                                        }))
                                    } value={data?.last_name}
                                    onInput={(e) => setData((prev) => ({
                                        ...prev,
                                        last_name: e.target.value
                                    }))} />
                            </FormItem>
                            <FormItem label={"Имя"}>
                                <Input
                                    valueState={validation.first_name ? "None" : "Error"}
                                    required={true} type='Text' onChange={
                                        (e) => setData((prev) => ({
                                            ...prev,
                                            first_name: e.target.value
                                        }))
                                    } value={data?.first_name}
                                    onInput={(e) => setData((prev) => ({
                                        ...prev,
                                        first_name: e.target.value
                                    }))} />
                            </FormItem>
                            <FormItem label={"Отчество"}>
                                <Input
                                    required={true} type='Text' onChange={
                                        (e) => setData((prev) => ({
                                            ...prev,
                                            middle_name: e.target.value
                                        }))
                                    } value={data?.middle_name}
                                    onInput={(e) => setData((prev) => ({
                                        ...prev,
                                        middle_name: e.target.value
                                    }))} />
                            </FormItem>
                        </FormGroup>

                        <FormGroup titleText="Права доступа">
                            <FormItem label={"Администратор"}>
                                <CheckBox checked={data?.is_admin} onChange={(e) => {
                                    setData((prev) => ({
                                        ...prev,
                                        is_admin: !prev.is_admin
                                    }))
                                }} />
                                <p>{data.is_admin ? "Да" : "Нет"}</p>
                            </FormItem>
                        </FormGroup>

                        <FormItem label={""}>
                            <MessageStrip hideCloseButton style={{ margin: "10px 0" }}>Если пользователь с указанным E-mail уже существует в системе IB-ELP, то данные из раздела "Личные данные" и Пароль учтены НЕ будут</MessageStrip>
                        </FormItem>

                        <FormItem label={""}>
                            <Button onClick={() => formSubmit()} style={{ width: "100%" }} design='Emphasized'>Создать</Button>
                        </FormItem>
                    </Form>
                </div>
            </ThemeProvider >
    )
}

export default UsersAdminCreateModal