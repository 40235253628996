import React, { useEffect } from "react";
import {
  ThemeProvider,
  AnalyticalTable,
  AnalyticalTableHooks,
  AnalyticalTableVisibleRowCountMode,
  FlexBox,
  Button,
  Badge,
} from "@ui5/webcomponents-react";

const OwnOrdersView = (props) => {
  const DATA = [];

  props.orderedOffers.map((offer) => {
    DATA.push({
      order: `Заказ 000${props.orderedOffers.indexOf(offer) + 1}`,
      direction: "Количество направлений: 1",
      recipient_address: "Количество адресов забора: 1",
      sender_address: "Количество адресов отгрузки: 1",
      warehouse: "Количество складов: 1",
      supplier: "Количество поставщиков: 1",
      code: "Количество позиций: 1",
      price: `${offer.price}`,
      count: `Количество мест: ${offer.count}`,
      subRows: [
        {
          order: `Заказ 000${props.orderedOffers.indexOf(offer) + 1}`,
          direction: `Направление 000${props.orderedOffers.indexOf(offer) + 1}`,
          recipient_address:
            "Ростовская область, Аксайский район, хутор Маяковского, ул. Заводская, дом 49",
          sender_address: "Количество адресов отгрузки: 1",
          warehouse: "Количество складов: 1",
          supplier: "Количество поставщиков: 1",
          code: `${offer.article}`,
          price: `${offer.price}`,
          count: `Количество мест: ${offer.count}`,
        },
      ],
    });
  });

  const COLUMNS = [
    {
      Header: "Статус",
      accessor: "status",
      id: "status",
      minWidth: 100,
      width: 100,
      headerTooltip: "Текущий статус доставки",
      Cell: (instance) => {
        const { cell, row, webComponentsReactProperties } = instance;
        // disable buttons if overlay is active to prevent focus
        const isOverlay = webComponentsReactProperties.showOverlay;
        // console.log('This is your row data', row.original);
        return (
          <FlexBox
            justifyContent="SpaceAround"
            fitContainer={true}
            alignItems="Center"
          >
            <Badge colorScheme={1}>Доставка</Badge>
          </FlexBox>
        );
      },
    },
    {
      Header: "Заказ",
      accessor: "order",
      enableMultiSort: true,
    },

    {
      Header: "Направление",
      accessor: "direction",
      enableMultiSort: true,
    },
    {
      Header: "Адрес забора",
      accessor: "recipient_address",
      enableMultiSort: true,
    },
    {
      Header: "Адрес отгрузки",
      accessor: "sender_address",
      enableMultiSort: true,
    },
    {
      Header: "Склад",
      accessor: "warehouse",
      enableMultiSort: true,
    },
    {
      Header: "Поставщик",
      accessor: "supplier",
      enableMultiSort: true,
    },
    {
      Header: "Артикул",
      accessor: "code",
      enableMultiSort: true,
    },
    {
      Header: "Цена",
      accessor: "price",
      enableMultiSort: true,
    },
    {
      Header: "Количество",
      accessor: "count",
      enableMultiSort: true,
    },
    {
      Header: "Подтвердить получение",
      accessor: ".",

      disableFilters: true,
      disableGroupBy: true,
      disableResizing: true,
      disableSortBy: true,
      id: "action_accept",
      minWidth: 100,
      Cell: (instance) => {
        const { cell, row, webComponentsReactProperties } = instance;
        // disable buttons if overlay is active to prevent focus
        const isOverlay = webComponentsReactProperties.showOverlay;
        // console.log('This is your row data', row.original);
        return (
          <FlexBox
            justifyContent="SpaceAround"
            fitContainer={true}
            alignItems="Center"
          >
            <Button icon="accept" />
          </FlexBox>
        );
      },
    },
  ];

  const useManualRowSelect = (hooks) => {
    const useInstanceAfterData = (instance) => {
      const { flatRows, toggleRowSelected } = instance;
      useEffect(() => {
        flatRows.forEach(({ id, original }) => {
          if ("isSelected" in original) {
            toggleRowSelected(id, original.isSelected);
          }
        });
      }, [flatRows, toggleRowSelected]);
    };
    hooks.useInstanceAfterData.push(useInstanceAfterData);
  };
  let orderedIds = [];
  COLUMNS.map((item) => {
    orderedIds.push(item.accessor);
  });
  return (
    <ThemeProvider
      style={{
        minHeight: "100%",
      }}
    >
      <AnalyticalTable
        style={{
          minHeight: "100%",
        }}
        columns={COLUMNS}
        data={DATA}
        selectionMode="MultiSelect"
        isTreeTable
        sortable
        selectionBehavior="RowSelector"
        tableHooks={[AnalyticalTableHooks.useOrderedMultiSort(orderedIds)]}
        reactTableOptions={{ selectSubRows: true }}
        withNavigationHighlight
        visibleRowCountMode={AnalyticalTableVisibleRowCountMode.Auto}
        draggable={false}
        onDragStart={() => {}}
        containerWidth={"auto"}
        minRows={10}
      />
    </ThemeProvider>
  );
};

export { OwnOrdersView };
