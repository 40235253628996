import React, { useEffect, useMemo, useState } from 'react'
import axios_instance from '../../requests/config_defaults';
import {
    MaterialReactTable, MRT_GlobalFilterTextField,
    MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton,
    MRT_EditActionButtons, MRT_ShowHideColumnsButton,
    MRT_ToolbarDropZone
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { Paper, TableContainer, Box, Typography, MenuItem } from "@mui/material";
import { createTheme, useTheme, lighten } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { lime, purple, cyan, grey } from "@mui/material/colors";
import Paginator from '../base/paginator/Paginator';

const fields = {
    product_full_name: {
        key: "product_full_name",
        header: "Полное наименование товара в каталоге",
        disabled: false,
    },
    for_sale_count: {
        key: "for_sale_count",
        header: "Количество в предложениях",
        disabled: true,
    },
    in_my_orders: {
        key: "in_my_orders",
        header: "В моих проектах заказа",
        disabled: false,
    },
    max_delivery_period: {
        key: "expire_of_needs_datetime",
        header: "Дата истечения запроса",
        disabled: false,
    },
    max_price_for_my_needs: {
        key: "max_price_for_my_needs",
        header: "Максимальная цена для моих потребностей",
        disabled: false,
    },
    sale_price: {
        key: "sale_price",
        header: "Цена",
        disabled: false,
    },
    with_delivery_price: {
        key: "with_delivery_price",
        header: "Цена с доставкой",
        disabled: false,
    },
    order_sum: {
        key: "order_sum",
        header: "Стоимость заказа",
        disabled: false,
    },
    warehouse: {
        key: "warehouse",
        header: "Склад",
        disabled: false,
    },

};

const SupplierOffersView = (props) => {
    const [data, setData] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);

    const globalTheme = useTheme({
        palette: {
            primary: lime,
            secondary: purple,
        },
    });

    const data_columns = [];

    for (let main_key in fields) {
        data_columns.push({
            accessorKey: fields[main_key].key, //simple recommended way to define a column
            header: fields[main_key].header,
            muiTableHeadCellProps: {
                sx: {
                    color: "black",
                },
            },
            muiTableBodyRowProps: {
                onHover(e, value) {
                    e.target.style.background = "#eee";
                },
            },
            muiTableBodyCellEditTextFieldProps: {
                disabled: fields[main_key].disabled,
            },
            muiTopToolbarProps: {
                background: "#333",
            },
        });
    }


    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: grey, //swap in the secondary color as the primary for the table
                    info: {
                        main: "rgb(255,122,0)", //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === "light"
                                ? "#fff" //random light yellow color for the background in light mode
                                : "#000", //pure black table in dark mode for fun
                    },
                },
                typography: {
                    button: {
                        textTransform: "none", //customize typography styles for all buttons in table by default
                        fontSize: "1.2rem",
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: "1.1rem", //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: "gray", //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme]
    );

    const getName = (product) => {
        var name = "";
        product?.properties.map((property) => {
            if (property.name === "Наименование") {
                name = property?.value?.content || "Не указан";
            }
        });
        return name;
    };

    const getOffers = async () => {
        setLoading(true)
        await axios_instance.get(
            "/suppliersOffersByProduct/" + props.product + `?page=${page}`
        )
            .then(({ data }) => {
                setMaxPage(data?.count % 20 === 0 ? data?.count / 20 : Math.floor(data?.count / 20) + 1);
                const data_items = [];
                console.log(data?.results)
                data?.results.map((item) => {
                    data_items.push({
                        id: item.id,
                        product_full_name: getName(item?.product),
                        needful_rest: item.needful_rest,
                        current_rest: item.current_rest,
                        // order_rest: item.to_order_count,
                        // total_rest: item.current_rest + item.ordered_count,
                        to_order_count: item.to_order_count,

                        request_actual_datetime: (
                            <input
                                type={"datetime-local"}
                                style={{
                                    border: "2px solid #333",
                                    backgroundColor: "#ccc",
                                    borderRadius: "5px",
                                    cursor: "pointer",
                                }}
                                onChange={(e) => {
                                    console.log(e.target.value);
                                }}
                            />
                        ),
                        purchase_price: item.purchase_price,
                        sale_price: item.sale_price,
                        for_sale_count: item.for_sale_count,
                        warehouse: item?.warehouse?.name
                    });
                });
                setData(data_items)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            })
    }


    useEffect(() => {
        getOffers();
    }, [])

    useEffect(() => {
        getOffers();
    }, [page, props.product, props.activeCompany])


    return (
        <>
            <TableContainer component={Paper} className=''>
                <MuiThemeProvider theme={tableTheme}>
                    <MaterialReactTable
                        columns={data_columns}
                        data={data && data}
                        localization={MRT_Localization_RU}
                        defaultColumn={{
                        }}
                        sx={{
                            fontSize: 36,
                        }}
                        editDisplayMode='cell'
                        enableEditing={false}
                        enableColumnResizing
                        enableColumnFilters={false}
                        enableColumnOrdering={true}
                        enableGrouping={false}
                        enableColumnPinning={false}
                        enableFacetedValues={false}

                        initialState={
                            {
                                pagination: { pageSize: 5, pageIndex: 0 }
                            }
                        }

                        state={{
                            isLoading: loading,
                        }}
                        renderTopToolbar={({ table }) => {
                            return (
                                <></>
                            );
                        }}
                        renderBottomToolbar={({ table }) => {
                            return (
                                <Paginator
                                    page={page}
                                    setPage={setPage}
                                    pages_count={maxPage}
                                    disabled={loading}
                                />
                            )
                        }}

                    />
                </MuiThemeProvider>
            </TableContainer>


        </>
    )
}

export default SupplierOffersView