import { Button } from "@ui5/webcomponents-react";
import "./ProductImageCard.css";
import axios_instance from "../../../../requests/config_defaults";
import { useEffect } from "react";
import "@ui5/webcomponents-icons/dist/zoom-in.js";

const ProductImageCard = (props) => {
  const selectedImageUpdate = async (isSelected) => {
    await axios_instance
      .put(`selected_image/update/` + props?.image?.id, {
        is_selected: isSelected,
      })
      .then(({ data }) => {
        props.getProduct();
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (props.imageDeleted === true) {
      props.getProduct();
      props.setImageDeleted(false);
    }
  }, [props.imageDeleted]);

  return (
    <div className="catalog-main-page-catalog-card">
      <div className="admin-product-card-photo">
        <img className="admin-product-card-photo-img" src={props?.image?.image} alt="EXZA" />
        {/* <Button
          onClick={() => {
            props.setActiveImageID(props?.image?.id);
          }}
          className="catalog-main-page-catalog-card-photo-zoom-in"
          icon="zoom-in"
        /> */}
        {!props.catalogView && (
          <Button
            onClick={() => {
              props.setImageDeleteOpen(true);
              props.setActiveImageID(props?.image?.id);
            }}
            className="catalog-main-page-catalog-card-photo-delete"
            icon="delete"
          />
        )}
        {!props.catalogView &&
          (props.image.is_selected ? (
            <Button
              onClick={() => {
                selectedImageUpdate(!props.image.is_selected);
              }}
              tooltip="Основное фото"
              className="catalog-main-page-catalog-card-photo-eye"
              style={{ color: "green" }}
              icon="show"
            />
          ) : (
            <Button
              onClick={() => {
                selectedImageUpdate(!props.image.is_selected);
              }}
              tooltip="Дополнительное фото"
              className="catalog-main-page-catalog-card-photo-eye"
              style={{ color: "red" }}
              icon="hide"
            />
          ))}
      </div>
    </div>
  );
};
export default ProductImageCard;
