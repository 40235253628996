import { React, useState, useRef, useEffect } from "react";
import "./Dashboard.css";
import { MyProducts } from "./my_products/MyProducts.jsx";
import { WindowComponent } from "../base/window/WindowComponent.jsx";
import { NavBar } from "./apps_bar/NavBar.jsx";
import { CatalogView } from "./catalog/CatalogView.jsx";
import WindowMenu from "../base/window/window-menu/WindowMenu.jsx";
import { OwnOrdersView } from "./orders/OwnOrdersView.jsx";
import ProductsOffers from "./products_offers/ProductsOffers.jsx";
import { OwnWarehousesView } from "./own-warehouses/OwnWarehousesView.jsx";
import { UserProfileView } from "./user-profile/UserProfileView.jsx";
import { OwnEntitiesView } from "./own-entities/OwnEntitiesView.jsx";
import { SupplierSalesView } from "./supplier-sales/SupplierSalesView.jsx";
import Authorization from "../authorization/Authorization.jsx";
import ProductCard from "./product-card/ProductCard.jsx";
import { ExzaLogo } from "../base/exza_logo/ExzaLogo.jsx";
import Registration from "../registration/Registration.jsx";
import axios_instance from "../../requests/config_defaults";
import { Forms } from "./forms/Forms.jsx";
import { ThemeProvider } from '@ui5/webcomponents-react';
import { CreateForm } from "./forms/create-form/CreateForm.jsx";
import ProductsStocksView from "../products-stocks-view/ProductsStocksView.jsx";

const windowsStartConfiguration = {
    requestProducts: {
        start_x: 0,
        start_y: 0,
        width: 600,
        height: 600,
    },
    offerProducts: {
        start_x: 600,
        start_y: 0,
        width: 980,
        height: 600,
    },
    myProducts: {
        start_x: 0,
        start_y: 0,
        width: 970,
        height: 600,
    },
    windowMenu: {
        start_x: 300,
        start_y: 0,
        width: 300,
        height: 300,
    },
};

function disableAllWindows() {
    const windows = Array.from(document.getElementById("main-page").children);
    windows.map((window) => (
        window.style.zIndex = "1"
    ));
}

const setActiveWindow = (componentId) => {
    disableAllWindows()
    var component = document.getElementById(componentId)
    component.style.zIndex = "10"

    var windowMenu = document.querySelector(".window-menu-wrapper")
    if (windowMenu) {
        windowMenu.style.zIndex = "100"
    }
};

const Dashboard = () => {
    const windowsBoundsContainer = useRef();
    const [openOffers, setIsOpenOffer] = useState([]);
    const [deliveryLoad, setDeliveryLoad] = useState(true);
    const [orderedOffers, setOrderedOffers] = useState([]);
    const [isAuthorized, setIsAuthorized] = useState(false);

    async function checkIsAuthorized() {
        await axios_instance
            .get('/is_authenticated')
            .then(response => {
                let result = response.data.status;
                setIsAuthorized(result);
            })
            .catch(error => {
                console.log(error);
                setIsAuthorized(false);
            });
    }

    useEffect(() => {
        checkIsAuthorized();
    })

    const orderOfferSet = (data) => {
        setOrderedOffers((prev) => [...prev, data]);
    };

    const [open, setOpen] = useState({
        myProducts: false,
        calalog: false,
        offerProducts: false,
        ownOrders: false,
        profile: false,
        interfaceSettings: false,
        notificationSettings: false,
        automationSettings: false,
        ownWarehouses: false,
        ownEntities: false,
        ownSales: false,
        sendedOrders: false,
        authorizationForm: false,
        registrationForm: false,
        productCard: false,
        forms: false,
        createForm: false
    });

    const [productCardID, setProductCardID] = useState();

    const showWindow = (component, close = false) => {
        if (!open[component] || close) {
            setOpen((prevState) => ({
                ...prevState,
                [component]: !open[`${component}`],
            }));
        }
        else {
            setActiveWindow(component)
        }
    };

    const setOpenOffer = (calculation) => {
        var existOffer = false;
        console.log("OpenOffers", openOffers);
        openOffers.map(async (item) => {
            console.log(item.article);
            if (calculation.article === item.article) {
                console.log("Exist");
                existOffer = true;
                return;
            }
        });
        console.log("Between", existOffer);
        if (existOffer === false) {
            console.log(calculation, existOffer);
            setIsOpenOffer((prev) => [...prev, calculation]);
        }
        console.log(openOffers);
    };

    return (
        <ThemeProvider>
            <div className="main-page">
                <NavBar showWindow={showWindow} open={open} className="" isAuthorized={isAuthorized} checkIsAuthorized={checkIsAuthorized} />
                <div
                    ref={windowsBoundsContainer}
                    id="main-page"
                    className="main_page__content"
                >
                    {open.myProducts && (
                        <WindowComponent
                            id="myProducts"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Управление заказами"
                            showWindow={showWindow}
                            childrenComponent={"myProducts"}
                        >
                            <ProductsStocksView/>
                        </WindowComponent>
                    )}

                    {open.offerProducts && (
                        <WindowComponent
                            id="offerProducts"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Подбор предложений (в разработке)"
                            showWindow={showWindow}
                            childrenComponent={"offerProducts"}
                        >
                            <ExzaLogo />
                        </WindowComponent>
                    )}

                    {open.calalog && (
                        <WindowComponent
                            id="calalog"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Каталог"
                            showWindow={showWindow}
                            childrenComponent={"calalog"}
                        >
                            <CatalogView
                                setProductCardID={setProductCardID}
                                showWindow={showWindow}
                                isOpenProductCard={open.productCard}
                                isAuthorized={isAuthorized}
                            />
                        </WindowComponent>
                    )}

                    {open.ownOrders && (
                        <WindowComponent
                            id="ownOrders"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Заказы"
                            showWindow={showWindow}
                            childrenComponent={"ownOrders"}
                        >
                            <OwnOrdersView orderedOffers={orderedOffers} />
                        </WindowComponent>
                    )}

                    {open.sendedOrders && (
                        <WindowComponent
                            id="sendedOrders"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Отправки"
                            showWindow={showWindow}
                            childrenComponent={"sendedOrders"}
                        >

                        </WindowComponent>
                    )}


                    {open.ownSales && (
                        <WindowComponent
                            id="ownSales"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Продажи"
                            showWindow={showWindow}
                            childrenComponent={"ownSales"}
                        >
                            <SupplierSalesView />
                        </WindowComponent>
                    )}


                    {open.profile && (
                        <WindowComponent
                            id="profile"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Профиль (в разработке)"
                            showWindow={showWindow}
                            childrenComponent={"profile"}
                        >
                            <UserProfileView />
                        </WindowComponent>
                    )}


                    {open.ownEntities && (
                        <WindowComponent
                            id="ownEntities"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Мои компании (в разработке)"
                            showWindow={showWindow}
                            childrenComponent={"ownEntities"}
                        >
                            <OwnEntitiesView />
                        </WindowComponent>
                    )}


                    {open.ownWarehouses && (
                        <WindowComponent
                            id="ownWarehouses"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Мои склады (в разработке)"
                            showWindow={showWindow}
                            childrenComponent={"ownWarehouses"}
                        >
                            <OwnWarehousesView />
                        </WindowComponent>
                    )}


                    {open.automationSettings && (
                        <WindowComponent
                            id="automationSettings"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Автоматизация (в разработке)"
                            showWindow={showWindow}
                            childrenComponent={"automationSettings"}
                        >
                        </WindowComponent>
                    )}


                    {open.notificationSettings && (
                        <WindowComponent
                            id="notificationSettings"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Уведомления (в разработке)"
                            showWindow={showWindow}
                            childrenComponent={"notificationSettings"}
                        >
                        </WindowComponent>
                    )}


                    {open.interfaceSettings && (
                        <WindowComponent
                            id="interfaceSettings"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Интерфейс (в разработке)"
                            showWindow={showWindow}
                            childrenComponent={"interfaceSettings"}
                        >
                        </WindowComponent>
                    )}

                    {open.authorizationForm && (
                        <WindowComponent
                            id="authorizationForm"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Войти"
                            showWindow={showWindow}
                            childrenComponent={"authorizationForm"}
                        >
                            <Authorization showWindow={showWindow} setIsAuthorized={setIsAuthorized} />
                        </WindowComponent>
                    )}

                    {open.registrationForm && (
                        <WindowComponent
                            id="registrationForm"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Регистрация"
                            showWindow={showWindow}
                            childrenComponent={"registrationForm"}
                        >
                            <Registration showWindow={showWindow} />
                        </WindowComponent>
                    )}

                    {open.productCard && (
                        <WindowComponent
                            id="productCard"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Карточка товара"
                            showWindow={showWindow}
                            childrenComponent={"productCard"}
                        >
                            <ProductCard
                                productCardID={productCardID}
                                showWindow={showWindow}
                            />
                        </WindowComponent>
                    )}

                    {open.forms && (
                        <WindowComponent
                            id="forms"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Формы"
                            showWindow={showWindow}
                            childrenComponent={"forms"}
                        >
                            <Forms showWindow={showWindow}/>
                        </WindowComponent>
                    )}

                    {open.createForm && (
                        <WindowComponent
                            id="createForm"
                            start_x={windowsStartConfiguration.myProducts.start_x}
                            start_y={windowsStartConfiguration.myProducts.start_y}
                            width={windowsStartConfiguration.myProducts.width}
                            height={windowsStartConfiguration.myProducts.height}
                            parent={windowsBoundsContainer}
                            title="Создание формы"
                            showWindow={showWindow}
                            childrenComponent={"createForm"}
                        >
                            <CreateForm />
                        </WindowComponent>
                    )}

                    {open.windowMenu && (
                        <WindowMenu />)}
                </div>
            </div>
        </ThemeProvider>
    );
};

export default Dashboard;
