import classNames from "classnames";
import React from "react";
import "./ModalWindow.css";

const ModalWindowV2 = ({ isOpen, onClose, children, className }) => {
  const modalStyle = {
    display: "block",
    position: "fixed",
    width: "100%",
    height: "100%",
    top: "50%",
    left: "50%",
    // transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    padding: "20px",
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
    zIndex: 999,
  };

  const backdropStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: 99,
  };

  return (
    <>
      <div style={backdropStyle} onClick={onClose}></div>
      <div
        className={classNames(
          "modal-window",
          isOpen && "modal-window-open",
          className
        )}
      >
        <div className="modal-window-body">
          <div className="modal-window-header">
            <div
              onClick={onClose}
              className="modal-window-close-btn"
            ></div>
          </div>
          <div className="modal-window-content">{children}</div>
        </div>
      </div>
    </>
  );
};

export default ModalWindowV2;
