import { Button, Menu, MenuItem, Icon } from "@ui5/webcomponents-react";
import React, { useState, useEffect, useRef } from "react";
import "./CommentCard.css";
import "@ui5/webcomponents-icons/dist/response.js";
import "@ui5/webcomponents-icons/dist/delete.js";
import "@ui5/webcomponents-icons/dist/edit.js";
import "@ui5/webcomponents-icons/dist/accept.js";
import "@ui5/webcomponents-icons/dist/document-text.js";
import CommentCardReply from "../CommentReplyCard/CommentReplyCard";
import { emailAtom, isAdminAtom } from "../../../../hoc/GlobalAtoms";
import { useAtom } from "jotai";
import axios_instance from "../../../../requests/config_defaults";

const CommentCard = ({
  comment,
  setReplyComment,
  getComments,
  setEditComment,
  scrollToComment,
  commentRefs,
  lastViewedCommentRef,
}) => {
  const [emailSelf] = useAtom(emailAtom);
  const [isAdmin] = useAtom(isAdminAtom);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [intersectionRef, isVisible] = useIntersectionObserver({
    threshold: 0.8,
  });
  const [isViewed, setIsViewed] = useState(comment.is_viewed);
  const localRef = useRef();
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };

  const commentDelete = async () => {
    await axios_instance
      .delete(`/comment/delete/${comment?.id}`)
      .then(({ data }) => {
        getComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const commentViewCreate = async () => {
    await axios_instance
      .post(`/comment/view/create`, {
        comment: comment.id,
      })
      .then(({ data }) => {
        setIsViewed(true);
      })
      .catch((error) => {});
  };

  function getFileType(fileName) {
    const match = fileName.match(/\.[^.]+$/);
    if (!match) return "unknown";

    const extension = match[0].toLowerCase().slice(1);
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];

    if (imageExtensions.includes(extension)) {
      return "image";
    } else {
      return "other";
    }
  }

  function extractFileName(path) {
    const match = path.match(/[^/]+$/);
    return match ? match[0] : null;
  }

  useEffect(() => {
    if (localRef.current) {
      intersectionRef.current = localRef.current;
    }
  }, [intersectionRef]);

  useEffect(() => {
    if (isVisible && !isViewed) {
      commentViewCreate();
    }
  }, [isVisible, isViewed, comment.id]);

  return (
    <div ref={lastViewedCommentRef} className="comment-card-view-container">
      <div
        ref={(el) => {
          commentRefs.current[comment.id] = el;
          localRef.current = el;
          intersectionRef.current = el;
        }}
        className="comment-card-view-content"
      >
        <img
          className="comment-card-view-content-user-icon"
          src="https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg"
          alt="USER LOGO"
        />
        <div className="comment-card-view-body">
          <span className="comment-card-view-body-username">
            {`${comment.creator.last_name} ${comment.creator.first_name}`}
          </span>

          {comment.related && (
            <CommentCardReply
              comment={comment.related}
              scrollToComment={scrollToComment}
            />
          )}

          <span className="comment-card-view-body-text">{comment.text}</span>
          <div className="comment-card-view-body-images">
            {comment.attachments &&
              comment.attachments.map(
                (file) =>
                  getFileType(file?.file?.name) === "image" && (
                    <img
                      onClick={() => openInNewTab(file.file.url)}
                      key={file.file.id}
                      className="comment-card-view-body-image"
                      src={file.file.url}
                      alt="COMMENT IMAGE"
                      title="Открыть в новой вкладке"
                    />
                  )
              )}
            {comment.attachments &&
              comment.attachments.map(
                (file) =>
                  getFileType(file?.file?.name) !== "image" && (
                    <div 
                      className="file-view" 
                      onClick={() => openInNewTab(file.file.url)}
                      title="Открыть в новой вкладке"
                    >
                      <Icon
                        name="document-text"
                        style={{
                          width: "50px",
                          height: "50px",
                        }}
                      />
                      <div className="file-preview-current-file-info">
                        <span style={{ fontWeight: "bold" }}>
                          {extractFileName(file.file.name)}
                        </span>
                        <span>{(file.file.size / 1024).toFixed(0)} КБ</span>
                      </div>
                    </div>
                  )
              )}
          </div>
        </div>
        <div
          title={
            comment.updated_at
              ? `Создано: ${comment.create_date_time_stamp}\nИзменено: ${comment.updated_at}`
              : null
          }
          className="comment-card-view-content-date"
        >
          {comment.updated_at
            ? `изменено ${comment.create_date_time_stamp}`
            : comment.create_date_time_stamp}
          {emailSelf === comment.creator.email && (
            <Icon
              title={comment.self_comment_is_viewed ? "Просмотрено" : "Не просмотрено"}
              name="accept"
              style={{ color: comment.self_comment_is_viewed ? "green" : null }}
            />
          )}
        </div>
        <Button
          icon="response"
          iconEnd
          design="Transparent"
          className="reply-button"
          onClick={() => {
            setReplyComment(comment);
            setEditComment(null);
          }}
        >
          Ответить
        </Button>
        {(emailSelf === comment.creator.email || isAdmin) && (
          <Button
            id={`menu-opener-btn-${comment.id}`}
            onClick={() => setMenuIsOpen((prev) => !prev)}
            design="Transparent"
            icon="overflow"
            className="comment-card-view-content-menu-button"
          />
        )}
        <Menu
          opener={`menu-opener-btn-${comment.id}`}
          open={menuIsOpen}
          onClose={() => {
            setMenuIsOpen(false);
          }}
          onItemClick={(e) => {
            switch (e.detail.item.id) {
              case "delete":
                commentDelete();
                setMenuIsOpen(false);
                return;
              case "edit":
                setEditComment(comment);
                setReplyComment(null);
                setMenuIsOpen(false);
                return;
            }
          }}
        >
          {emailSelf === comment.creator.email && (
            <MenuItem id="edit" icon="edit" text="Редактировать" />
          )}
          <MenuItem id="delete" icon="delete" text="Удалить" />
        </Menu>
      </div>
    </div>
  );
};

function useIntersectionObserver(options) {
  const [isIntersecting, setIsIntersecting] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsIntersecting(entry.isIntersecting),
      options
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [options]);

  return [ref, isIntersecting];
}

export { useIntersectionObserver, CommentCard };
