import React, { useState } from "react";
import { useEffect } from "react";
import { Badge } from "@ui5/webcomponents-react";
import { getLog } from "../../../requests/utils";

const Log = ({ uuid, type, conditionValue, colorScheme }) => {
  const [log, setLog] = useState([]);
  const getObjectLog = async () => {
    let log = await getLog({ uuid: uuid, type: type });
    console.log("Log data", log);
    setLog(log);
  };
  useEffect(() => {
    getObjectLog();
  }, []);
  return (
    <Badge colorScheme={colorScheme}>
      {((log !== null) | undefined) & (log.length > 0) && log[0]?.description
        ? log[0]?.description
        : conditionValue && conditionValue}
    </Badge>
  );
};

export default Log;
