import './CatalogMyProductsCard.css'
import { StepInput, FormItem, Form, ObjectPage, ObjectPageSection, ObjectPageSubSection, Text } from "@ui5/webcomponents-react";
/*import { fetchI18nBundle } from '@ui5/webcomponents-localization/dist/asset-registries/i18n.js';

fetchI18nBundle('@me/my-app', 'ru', {
  'incrementButtonTitle': 'Увеличить'
});*/

const CatalogMyProductsCard = (props) => {

    return (
        <div className="catalog-my-product-card">
            <ObjectPage
                style={{
                    width: '100%',
                    height: props.height
                }}
            >
                <h3>Купить</h3>
                <ObjectPageSection
                    aria-label="Управление заказами"
                    id="stocks-management"
                    titleText="Управление заказами"
                    className={"catalog-my-product-card-page-subsection"}
                    hideTitleText
                >
                    <Form
                        backgroundDesign="Transparent"
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={2}
                        labelSpanL={4}
                        labelSpanM={2}
                        labelSpanS={12}
                        labelSpanXL={4}
                        style={{
                            alignItems: 'center',
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'space-between',
                            fontSize: '2px'
                        }}
                    >
                        <FormItem label="Необходимое количество">
                            <StepInput className={"catalog-my-product-card-editable-step-input"} increaseButtonText="" decreaseButtonText="" display={{ pointerEvents: 'none' }} min={0} />
                        </FormItem>
                        <FormItem label="Цена покупки">
                            <StepInput className={"catalog-my-product-card-editable-step-input"} min={0} />
                        </FormItem>
                        <FormItem label="Необходимо купить">
                            <Text>0</Text>
                        </FormItem>
                        <FormItem label="Уже заказано">
                            <Text>0</Text>
                        </FormItem>
                        <FormItem label="Доступно на площадке">
                            <Text>0</Text>
                        </FormItem>
                    </Form>
                </ObjectPageSection>
                <h3>Продать</h3>
                <ObjectPageSection
                    aria-label="Управление продажами"
                    id="employment"
                    titleText="Управление продажами"
                    className={"catalog-my-product-card-page-subsection"}
                    hideTitleText
                >
                    <Form
                        backgroundDesign="Transparent"
                        style={{
                            alignItems: 'center',
                            display: 'inline-flex',
                            flexDirection: 'row',
                            width: '100%',
                        }}
                    >
                        <FormItem label="Необходимо продать">
                            <StepInput className={"catalog-my-product-card-editable-step-input"} i18n-bundle="@me/my-app" min={0} />
                        </FormItem>
                        <FormItem label="Цена продажи">
                            <StepInput className={"catalog-my-product-card-editable-step-input"} min={0} />
                        </FormItem>
                        <FormItem label="Мой остаток">
                            <Text>0</Text>
                        </FormItem>
                        <FormItem label="Продаю">
                            <Text>0</Text>
                        </FormItem>
                    </Form>
                </ObjectPageSection>
            </ObjectPage>
        </div>

    )
}

export default CatalogMyProductsCard