import React, { useState } from 'react'
import './WindowMenuPositionBackground.css'

const WindowMenuPositionBackground = (props) => {
    return (
        <div className='window-menu-position-back'>
            
        </div>
        
    )
}

export default WindowMenuPositionBackground;
