import React from 'react'
import usePvzSearcher from '../../../hooks/PvzSearcher';
import './IncomingRequestPvzChooseModal.css'
import { Button, ButtonDesign } from '@ui5/webcomponents-react';
import ModalWindow from '../../base/modal/ModalWindow';

const IncomingRequestPvzChooseModal = (
    { isOpen, closeModal, row, setPvz, aggregator, geo_object }
) => {
    const { pvzSearcher, pvzValue, pvzSetValue, geoObjectSet, aggregatorSet } =
        usePvzSearcher(
            {
                margin: "0 10px"
            },
            "",
            aggregator,
            geo_object,
            true
        );
    return (
        <ModalWindow
            className={"IncomingRequestPvzChooseModal"}
            state={isOpen}
            setState={closeModal}
        >
            {pvzSearcher}
            <Button
            style={{
                width: "calc(100% - 20px)",
                margin: "10px 10px"
            }}
            design={ButtonDesign.Emphasized}
            onClick={() => {
                console.log(pvzValue)
                setPvz(row, pvzValue.pvz.id, pvzValue.pvz.name);
            }}
            >Выбрать</Button>
        </ModalWindow>
    )
}

export default IncomingRequestPvzChooseModal