import React, { useState, useEffect } from 'react'
import axios from "axios";
import { BusyIndicator, IllustratedMessage } from "@ui5/webcomponents-react";
import ProductCardTabContainer from './product-card-tab-container.jsx/ProductCardTabContainer';
import "@ui5/webcomponents-fiori/dist/illustrations/NoData.js";

const ProductCard = (props) => {
    var [isAuthorized, setIsAuthorized] = useState(false);
    var [product, setProduct] = useState({
        card_header: '',
        article: '',
        properties: []
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        async function checkAuth1() {
            let status = false;
            await axios
                .get("https://ib-elp-it-psg.com/els_proxy/is_authenticated", {
                    withCredentials: true,
                    mode: "cors",
                })
                .then((response) => {
                    status = response.data.status;
                    console.log(status)
                })
                .catch((error) => {
                    console.log(error);
                });
            return status;
        }
        let getResult = async () => {
            let result = await checkAuth1()
            console.log('result: ', result)
            setIsAuthorized(result)
        }
        getResult()
    }, [])

    useEffect(() => {
        const getMyProductByIDFromServer = async () => {
            try {
                var data = [];
                var response = await axios.get(
                    `https://ib-elp-it-psg.com/products/api/product/get/by_id/${props.productCardID}`,
                    {
                        headers: {
                            Authorization: "Token bc899f9440d0ffa4d088d71192ec29ef9d4aefc3",
                        },
                    }
                )
                    .then((response) => {
                        data = response.data;
                    })
                    .catch((error) => {
                        data = null
                    })
                    ;
                return data;
            } catch {
                console.log("error")
                return [];
            }
        };
        const getMyProductByID = async () => {
            setIsLoading(true);
            const my_product = await getMyProductByIDFromServer();
            if (!my_product) {
                setIsLoading(true);
                return
            }
            console.log(my_product)
            console.log(my_product.properties)
            let properties = []
            let property_name = ''
            let property_manufactor = ''
            if (my_product.properties) {
                my_product.properties.map((prop) => {
                    console.log('mapping')
                    try {
                        if (prop.name === "Наименование") {
                            property_name = prop.value.content
                        }
                        else if (prop.name === 'Основной завод-поставщик') {
                            property_manufactor = prop.value.content
                        }
                        properties.push({
                            name: prop.name,
                            value: prop["value"].content,
                            measure: prop.measure.name
                        })
                    } catch {

                    }
                })
            }

            let product_card_info = {
                card_header: `${my_product.code} ${property_name} ${property_manufactor}`,
                article: `${my_product.code}`,
                properties: properties
            }
            setProduct(product_card_info);
            console.log(product)
        };
        getMyProductByID();
        setIsLoading(false);
    }, [props.productCardID]);



    return (
        <div style={{width: '100%'}}>
            {!isLoading && (
                <ProductCardTabContainer
                    isAuthorized={isAuthorized}
                    height={"100%"}
                    card_header={product.card_header}
                    article={product.article}
                    properties={product.properties}
                    properties_selected={true}
                />
            )}
            {isLoading && (
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', justifyContent:'center', alignItems: 'center', height: '100%'}}>
                    <IllustratedMessage name="NoData" />
                    <BusyIndicator active />
                </div>
            )
            }

        </div>

    )
}

export default ProductCard;
