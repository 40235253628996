import React, { useEffect, useState } from 'react'
import { FlexBox, ThemeProvider, Loader, MultiComboBox, MultiComboBoxItem, Form, FormGroup, FormItem, Input, Title, ObjectStatus, Icon, Button, Text, DatePicker, Option, Select } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';


const RegStep4 = (props) => {

    const [code, setCode] = useState("");

    const [codeValueState, setCodeValueState] = useState('');

    const [time, setTime] = useState({ minutes: 10, seconds: 0 });

    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            const { minutes, seconds } = time;
            if (minutes === 0 && seconds === 0) {
                clearInterval(interval);
            } else {
                if (seconds === 0) {
                    setTime({ minutes: minutes - 1, seconds: 59 });
                } else {
                    setTime({ minutes, seconds: seconds - 1 });
                }
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);

    async function emailVerify(code_new) {
        props.setIsLoading(true);
        await axios_instance
            .post(
                "/email/verify",
                {
                    email: props.registrationData.email,
                    code: code_new
                }
            )
            .then(response => {
                if (response.data.success && props.permissionRequest.entity && props.permissionRequest.permission){
                    permissionRequestDataSend();
                }
                else if (response.data.success) {
                    props.setStep(5);
                }
                else setCodeValueState("Неверный код");
                props.setIsLoading(false);
            })
            .catch(error => {
                console.log(error);
                setCodeValueState("Неверный код");
                props.setIsLoading(false);
            });
    }

    async function unfinishedRegistrationDataSend() {
        props.setIsLoading(true);
        await axios_instance
            .post(
                "/registration/unfinished",
                {
                    email: props.registrationData.email
                }
            )
            .then((response) => {
                props.setIsLoading(false);
                setTime({ minutes: 10, seconds: 0 });
            })
            .catch((error) => {
                console.log(error);
                props.setIsLoading(false);
            })
    }

    async function permissionRequestDataSend() {
        props.setIsLoading(true);
        await axios_instance
            .post(
                `/entity/permission/request/by/invite`,
                {
                    entity: props.permissionRequest.entity,
                    email: props.registrationData.email,
                    permission: props.permissionRequest.permission
                }
            )
            .then(response => {
                console.log(response);
                props.setIsLoading(false);
                props.setStep(8);
            })
            .catch(error => {
                console.log(error);
                props.setIsLoading(false);
                props.setStep(5);
            });
    }


    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Подтвердите свою почту
                </Title>
                <Text style={{ textAlign: 'center', marginTop: 10 }}>
                    Перейдите в почту и введите код аутентификации
                </Text>
                <div style={{ border: '1px solid gray', borderRadius: 10, padding: 5, marginTop: 10, marginBottom: 20 }}>
                    <Text style={{color: time.minutes > 1 ? 'blue' : 'red'}}>
                        {time.minutes}:{time.seconds < 10 ? `0${time.seconds}` : time.seconds}
                    </Text>
                </div>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >
                <FormGroup>
                    <FormItem>
                        <Input
                            name="code"
                            valueState={codeValueState ? "Error" : ""}
                            required
                            placeholder='Код'
                            disabled={props.isLoading ? true : false}
                            maxlength={6}
                            //value={login}
                            onInput={e => {
                                if (e.target.typedInValue.length === 6 && codeValueState === "") {
                                    emailVerify(e.target.typedInValue.trim());
                                }
                            }}
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0
                            }}
                            onChange={e => {
                                setCode(e.target.typedInValue);
                            }}
                        />
                    </FormItem>
                    {codeValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {codeValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="Center"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                onClick={() => {
                                    unfinishedRegistrationDataSend();
                                }}
                                design="Transparent" style={{ width: "100%", fontWeight: 'normal' }}>
                                Выслать код повторно
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>

                {codeValueState && <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="End"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                onClick={() => {
                                    emailVerify(code);
                                }}
                                design="Emphasized" style={{ width: 100, fontWeight: 'normal' }}>
                                Далее
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>}
            </Form>
        </>
    )
}

export default RegStep4