import React, { useEffect, useState } from "react";
import "./SideBar.css";
import classNames from "classnames";
import MainMenu from "./main-menu/MainMenu.jsx";
import ExtendedMenu from "./extended-menu/ExtendedMenu.jsx";
import {
  ProfileCard,
  ProfileCardMini,
} from "./side-bar-profile/SideBarProfile.jsx";
import { useAtom } from "jotai";
import {
  emailAtom,
  fullNameAtom,
  isAdminAtom,
} from "../../../hoc/GlobalAtoms.jsx";

const SideBar = (props) => {
  const [sidebarFullscreen, setSidebarFullscreen] = useState(
    window.screen.width < 1000 ? true : false
  );

  const [fullName] = useAtom(fullNameAtom);
  const [email] = useAtom(emailAtom);
  const [isAdmin] = useAtom(isAdminAtom);

  const [windowWidth, setWindowWidth] = useState(window.screen.width);
  window.onresize = () => {
    setWindowWidth(window.screen.width);
  };
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  const closeMenu = () => {
    props.setMenuIsOpen(false);
    windowWidth > 1000
      ? setSidebarFullscreen(false)
      : setSidebarFullscreen(true);
  };
  return (
    <>
      {props.menuIsOpen && (
        <div className="overlay" id="overlay" onClick={() => closeMenu()}></div>
      )}
      <div
        className={classNames(
          "main-side-bar-layout",
          "main-side-bar",
          props.menuIsOpen ? "expanded" : "",
          sidebarFullscreen ? "sidebar-full-screen" : ""
        )}
      >
        <div className="side-bar-container">
          <div className="main-side-bar-header">
            {!(windowWidth < 1000) && (
              <div
                className="main-side-bar-header-controller"
                onClick={() => setSidebarFullscreen(!sidebarFullscreen)}
              >
                <img
                  src={
                    "/static/" +
                    (sidebarFullscreen
                      ? "from_full_screen_btn.svg"
                      : "to_full_screen_btn.svg")
                  }
                  alt=""
                />
              </div>
            )}
            <div onClick={closeMenu} className="close-sb-btn"></div>
          </div>
          <div
            className="side-bar-content"
            style={
              !sidebarFullscreen || windowWidth < 1000
                ? {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }
                : {
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr 1fr",
                  gap: "1rem",
                  width: "100%",
                }
            }
          >
            {sidebarFullscreen && windowWidth > 1000 ? (
              <ProfileCard fullName={fullName} email={email} closeMenu={closeMenu} />
            ) : (
              <ProfileCardMini fullName={fullName} email={email} closeMenu={closeMenu} />
            )}
            <MainMenu
              sidebarFullscreen={sidebarFullscreen}
              closeMenu={closeMenu}
            />
            {/* <ExtendedMenu /> */}
          </div>
        </div>
      </div>
    </>
  );
};


export default SideBar;
