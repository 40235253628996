import React from 'react'


const SideNavigationHeader = (props) => {
    return (
        <div slot={props.slot}>
            <img alt='EXZA' className="logo" src={"/static/EXZA_LOGO_NAME_slogan.svg"} />
        </div>
    )
}

export default SideNavigationHeader;
