import React, { useEffect, useMemo, useState } from "react";
import axios_instance from "../../../../requests/config_defaults";
import {
  Badge,
  Button,
  FlexBox,
  Input,
  ThemeProvider,
  Toolbar,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/refresh.js";
import {
  TableContainer,
  ThemeProvider as MuiThemeProvider,
  Paper,
  useTheme,
  createTheme,
  MenuItem,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { grey, lime, purple } from "@mui/material/colors";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import Paginator from "../../../base/paginator/Paginator";
import { useAtom } from "jotai";
import {
  activeCompanyAtom,
  emailAtom,
  fullNameAtom,
  isAdminAtom,
  isImpersonatedAtom,
} from "../../../../hoc/GlobalAtoms";
import { useNavigate } from "react-router-dom";

const UserAdminTable = (props) => {
  const [email, setEmail] = useAtom(emailAtom);
  const [isImpersonated, setIsImpersonated] = useAtom(isImpersonatedAtom);
  const addtionalMenu = props.addtionalMenu || [];
  const globalTheme = useTheme({
    palette: {
      primary: lime,
      secondary: purple,
    },
  });
  const nav = useNavigate();

  const tableTheme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
          primary: grey, //swap in the secondary color as the primary for the table
          info: {
            main: "#e1f4ff", //add in a custom color for the toolbar alert background stuff
          },
          background: {
            default:
              globalTheme.palette.mode === "light"
                ? "#fff" //random light yellow color for the background in light mode
                : "#000", //pure black table in dark mode for fun
          },
          sx: {
            "&:hover": {
              backgroundColor: "#e1f4ff", // Replace this with your desired hover color
            },
          },
        },
        typography: {
          button: {
            textTransform: "none", //customize typography styles for all buttons in table by default
            fontSize: "1.2rem",
          },
        },
        components: {
          MuiTooltip: {
            styleOverrides: {
              tooltip: {
                fontSize: "1.1rem", //override to make tooltip font size larger
              },
            },
          },
          MuiSwitch: {
            styleOverrides: {
              thumb: {
                color: "gray", //change the color of the switch thumb in the columns show/hide menu to pink
              },
            },
          },
        },
      }),
    [globalTheme]
  );
  const data_columns = [];
  const fields = {
    email: {
      key: "user.email",
      header: "E-mail",
      editable: false,
      Cell: ({ renderedCellValue, row }) => (
        <FlexBox
          alignItems="Center"
          justifyContent="Stretch"
          direction="Row"
          style={{
            gap: "10px",
          }}
        >
          {row?.original?.user.email}
          {row?.original?.is_admin && <Badge colorScheme="6">Админ</Badge>}
          {row?.original?.user.email === email && (
            <Badge colorScheme="8">Вы</Badge>
          )}
          {(row?.original?.blocked != undefined || null) && (
            <Badge colorScheme="2">Заблокирован</Badge>
          )}
        </FlexBox>
      ),
    },
    last_name: {
      key: "user.last_name",
      header: "Фамилия",
      editable: false,
    },
    first_name: {
      key: "user.first_name",
      header: "Имя",
      editable: false,
    },
    middle_name: {
      key: "user.middle_name",
      header: "Отчество",
      editable: false,
    },
    register_at: {
      key: "create_date_time_stamp",
      header: "Дата регистрации",
      editable: false,
    },
    ...(props.additionalFields || {}),
  };
  for (let main_key in fields) {
    data_columns.push({
      accessorKey: fields[main_key].key, //simple recommended way to define a column
      header: fields[main_key].header,
      muiTableHeadCellProps: {
        sx: {
          color: "black",
        },
      },
      size: 300,
      muiTableBodyRowProps: {
        onHover(e, value) {
          e.target.style.background = "#e1f4ff";
        },
      },
      sx: {
        "&:hover": {
          backgroundColor: "#e1f4ff", // Replace this with your desired hover color
        },
      },
      enableEditing: fields[main_key]?.editable,
      muiTopToolbarProps: {
        background: "#e1f4ff",
      },
      enableClickToCopy: true,
      onColumnFilterFnsChange: fields[main_key].onColumnFilterFnsChange,
      Cell: fields[main_key].Cell,
    });
  }

  const [data, setData] = useState([]);
  const state = props.state;
  const [query, setQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [loading, setLoading] = useState(false);

  const [fullName, setFullName] = useAtom(fullNameAtom);
  const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
  const [activeCompany, setActiveCompany] = useAtom(activeCompanyAtom);

  const filterUsers = async () => {
    setLoading(true);
    await axios_instance
      .post(`/users/filter?page=${page}`, {
        state: state,
        query: query,
      })
      .then(({ data }) => {
        setPageCount(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        setData(data.results);
        console.log(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getMe = async () => {
    await axios_instance
      .get("/getMe")
      .then((response) => {
        console.log(response.data);
        setEmail(response.data.user.email);
        setFullName(
          `${response.data.user.last_name} ${response.data.user.first_name}`
        );
        setIsAdmin(response.data.is_admin || response.data.user.is_superuser);
        console.log(response.data);
      })
      .catch((error) => {});
  };

  const getActiveCompany = async () => {
    await axios_instance
      .get("/myCompany")
      .then((response) => {
        setActiveCompany(response.data);
      })
      .catch((error) => {
        setActiveCompany({})
      });
  };

  const impersonateUser = async (user_id) => {
    await axios_instance
      .post(`/users/impersonate/${user_id}`)
      .then(({ data }) => {
        if (data?.status) {
          getMe();
          getActiveCompany();
          setIsImpersonated(true);
          nav("/");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    filterUsers();
  }, [page, query, props.refreshTrigger]);

  return (
    <div className="UserAdminTable-content">
      <TableContainer
        component={Paper}
        style={{
          height: "100%",
        }}
      >
        <MuiThemeProvider theme={tableTheme}>
          <MaterialReactTable
            columns={data_columns}
            data={data}
            localization={MRT_Localization_RU}
            defaultColumn={{
              minSize: 80,
              size: 150, //default size is usually 180
            }}
            sx={{
              fontSize: 36,
              innerHeight: "100%",
              height: "100%",
            }}
            editDisplayMode="cell"
            enableEditing={false}
            enableColumnResizing
            enablePagination={false}
            enableColumnFilters={false}
            enableColumnOrdering={false}
            enableGrouping={false}
            enableColumnPinning={true}
            enableFacetedValues={false}
            enableRowActions={true}
            // onColumnFiltersChange={setColumnFilters}
            state={{
              isLoading: loading,
            }}
            renderTopToolbar={({ table }) => {
              return (
                <ThemeProvider>
                  <Toolbar
                    design="Transparent"
                    onClick={function _a() {}}
                    onOverflowChange={function _a() {}}
                    toolbarStyle="Clear"
                    style={{
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Input
                      onChange={(e) => {
                        setQuery(e.target.value);
                      }}
                      onInput={(e) => {
                        setQuery(e.target.value);
                      }}
                      value={query}
                      style={{
                        minWidth: "30vw",
                        position: "relative",
                      }}
                      placeholder="Поиск по E-mail / Фамилия / Имя / Отчество"
                    />
                    <Button
                      tooltip="Очистить поиск"
                      disabled={query === ""}
                      icon="decline"
                      design="Transparent"
                      onClick={async () => setQuery("")}
                    />
                    <Button
                      tooltip="Обновить"
                      disabled={loading}
                      icon="refresh"
                      design="Transparent"
                      onClick={async () => await filterUsers()}
                    ></Button>
                    {props.additionalToolBarElements || <></>}
                  </Toolbar>
                </ThemeProvider>
              );
            }}
            renderBottomToolbar={({ table }) => {
              return (
                <Paginator
                  page={page}
                  setPage={setPage}
                  pages_count={pageCount}
                  disabled={loading}
                />
              );
            }}
            renderRowActionMenuItems={({ row, closeMenu }) => [
              ...[
                <MenuItem
                  key="view"
                  onClick={() => {
                    props.setUserCardIsOpen(true);
                    props.setActiveUser(row?.original?.id);
                    closeMenu();
                  }}
                >
                  Профиль
                </MenuItem>,
              ],
              ...(row?.original?.user.email !== email
                ? state === "ACTIVE"
                  ? [
                      ...(!row?.original?.is_admin
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.setUserAsAdmin(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Назначить администратором
                            </MenuItem>,
                          ]
                        : []),
                      ...(row?.original?.is_admin &&
                      !row?.original?.user.is_superuser
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.unsetUserAsAdmin(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Лишить прав администратора
                            </MenuItem>,
                          ]
                        : []),
                      !row?.original?.user.is_superuser
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.blockUser(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Заблокировать
                            </MenuItem>,
                          ]
                        : [],
                    ]
                  : state === "ADMIN"
                  ? [
                      ...(row?.original?.is_admin &&
                      !row?.original?.user.is_superuser
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.unsetUserAsAdmin(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Лишить прав администратора
                            </MenuItem>,
                          ]
                        : []),

                      !row?.original?.user.is_superuser
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.blockUser(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Заблокировать
                            </MenuItem>,
                          ]
                        : [],
                    ]
                  : state === "PENDING"
                  ? [
                      <MenuItem
                        key="view"
                        onClick={() => {
                          props.approveUser(row?.original?.id);
                          closeMenu();
                        }}
                      >
                        Подтвердить
                      </MenuItem>,
                      <MenuItem
                        key="view"
                        onClick={() => {
                          props.blockUser(row?.original?.id);
                          closeMenu();
                        }}
                      >
                        Заблокировать
                      </MenuItem>,
                    ]
                  : state === "BLOCKED"
                  ? [
                      <MenuItem
                        key="view"
                        onClick={() => {
                          props.unblockUser(row?.original?.id);
                          closeMenu();
                        }}
                      >
                        Разблокировать
                      </MenuItem>,
                    ]
                  : state === "ALL"
                  ? [
                      ...(!row?.original?.is_admin &&
                      (row?.original?.blocked == undefined || null)
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.setUserAsAdmin(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Назначить администратором
                            </MenuItem>,
                          ]
                        : []),
                      ...(row?.original?.is_admin &&
                      !row?.original?.user.is_superuser &&
                      (row?.original?.blocked == undefined || null)
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.unsetUserAsAdmin(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Лишить прав администратора
                            </MenuItem>,
                          ]
                        : []),
                      !row?.original?.user.is_superuser &&
                      (row?.original?.blocked == undefined || null)
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.blockUser(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Заблокировать
                            </MenuItem>,
                          ]
                        : [],
                      !row?.original?.user.is_superuser &&
                      (row?.original?.blocked == undefined || null)
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                impersonateUser(row?.original?.user?.id);
                                closeMenu();
                              }}
                            >
                              Войти под пользователем
                            </MenuItem>,
                          ]
                        : [],
                      !row?.original?.user.is_superuser &&
                      (row?.original?.blocked != undefined || null)
                        ? [
                            <MenuItem
                              key="view"
                              onClick={() => {
                                props.unblockUser(row?.original?.id);
                                closeMenu();
                              }}
                            >
                              Разблокировать
                            </MenuItem>,
                          ]
                        : [],
                    ]
                  : []
                : []),
            ]}
          />
        </MuiThemeProvider>
      </TableContainer>
    </div>
  );
};

export default UserAdminTable;
