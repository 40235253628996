import React, { useState } from "react";
import { MultiComboBoxItem } from "@ui5/webcomponents-react";

const FilterItem = (props) => {

  
  return <MultiComboBoxItem text={props.value}/>;
};

export default FilterItem;
