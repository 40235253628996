import { IllustratedMessage } from "@ui5/webcomponents-react"
import './DocumentsView.css'

const DocumentView = () => {
  return (
    <div className="documents-view-main-container">
      <div className="documents-view-main-container-content">
        <IllustratedMessage  style={{ width: '100%', height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
          titleText='В разработке...'
          subtitleText=' '
        />
      </div>
    </div>
  )
}

export default DocumentView
