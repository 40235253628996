import React, { useEffect, useState } from "react";
import "./ProductSearcherResultCard.css";
import {
  Button,
  Icon,
  List,
  Loader,
  MessageStrip,
  StandardListItem,
  ThemeProvider,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/navigation-down-arrow.js";
import "@ui5/webcomponents-icons/dist/arrow-right.js";
import "@ui5/webcomponents-icons/dist/arrow-left.js";
import "@ui5/webcomponents-icons/dist/navigation-up-arrow.js";
import classNames from "classnames";
import {
  companyAddressAtom,
  geoObjectAtom,
} from "../../user-wizard/UserWizardAtoms";
import { useAtom } from "jotai";
import axios_instance from "../../../../requests/config_defaults";
import OrderProjectModal from "./ProductAddToOrderProjectModal/OrderProjectModal";
import SearcherProductImageCard from "./SearcherProductImageCard/SearcherProductImageCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  marketIconsAtom,
  activeCompanyAddressAtom,
  fullNameAtom
} from "../../../../hoc/GlobalAtoms";
import { CompanyAddressSelect } from "../../product-searcher-header/ProductSearchHeader";
import { userStateAtom } from "../../user-wizard/UserWizardAtoms";
import { useNavigate } from "react-router-dom";

const ProductSearcherResultCard = ({ searcher, product, isAuth, setIsOpen, geo_object }) => {
  const nav = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [expandedDeliveries, setExpandedDeliveries] = useState(false);
  const [deliveryData, setDeliveryData] = useState({});
  const [geoObject, setGeoObject] = useAtom(geoObjectAtom);
  const [loading, setLoading] = useState(true);
  const [geoObjectData, setGeoObjectData] = useState({});
  const [openOrderProjectModal, setOpenOrderProjectModal] = useState(false);
  const [other, setOther] = useState([]);
  const [icons] = useAtom(marketIconsAtom);
  const [activeCompanyAddress, setActiveCompanyAddress] = useAtom(activeCompanyAddressAtom);
  const [imageToFull, setImageToFull] = useState();
  const [userState, setUserState] = useAtom(userStateAtom);
  const [fullName] = useAtom(fullNameAtom);
  const [deliveryError, setDeliveryError] = useState(false)
  const [stocks, setStocks] = useState([])

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    centerPadding: "60px",
    slidesToScroll: 1,
    arrows: false,
  };

  const getStockInformation = async (_id) => {
    let _data = { "id": _id }
    await axios_instance
      .get(`/stock/get/by_id/${_id}`)
      .then(({ data }) => {
        _data = data
      })
      .catch((error) => {
      });
    return _data
  };

  const getStocksByProduct = async () => {
    let stocks_data = [];
    await axios_instance
      .get(`/stock/get/by_product`, {
        params: {
          product_id: product.id
        }
      })
      .then(({ data }) => {
        data?.map((stock) =>
          stock.modifications?.length > 0
            ? stock.modifications?.map((modification) => {
              let existingStock = stocks_data.find(item => item.name === modification.name);
              if (existingStock) {
                existingStock.value += `, #${modification.value}`;
              } else {
                stocks_data.push({
                  id: modification.id,
                  name: modification.name,
                  value: `#${modification.value}`,
                });
              }
            }) : null
        )
        setStocks(stocks_data)
      })
      .catch((error) => {
      });
  };

  const getDeliveryData = async () => {
    setLoading(true);
    setDeliveryData({});
    setOther(null);
    let other_items = [];
    await axios_instance
      .post("/fcs/calculate/product", {
        product: product.id,
        geo_object: geoObject,
      })
      .then(async ({ data }) => {
        let temp_data = { ...data }
        // data?.other?.map((other_item) => {
        //   other_item?.map((item) => {
        //     other_items.push(item);
        //   });
        // });
        // setOther(other_items);
        let temp_other = []
        data?.other?.map((item) => {
          item?.map((_item) => {
            temp_other.push(_item)
          })
        })
        temp_data = { ...temp_data, other: temp_other }

        for (const [key, value] of Object.entries(temp_data)) {
          if (key === "other") {
            for (let i = 0; i < temp_data?.other?.length; i++) {
              other_items.push({ ...temp_data?.other[i], stock: await getStockInformation(temp_data?.other[i].stock) });
            }
          } else {
            let _stock = await getStockInformation(value.stock)
            temp_data[key] = { ...value, stock: _stock }
          }
        }
        setOther(other_items);
        setDeliveryData(temp_data);
      })
      .catch((error) => {
        setLoading(false);
        setDeliveryError(true);
      });
    setLoading(false);
  };

  const getGeoObjectById = async () => {
    await axios_instance.get(
      `/geo_object/get/by_id/` + geoObject
    )
      .then((response) => {
        setGeoObjectData(response.data);
      })
      .catch((error) => { })
  }

  var rounded = function (number) {
    return +number.toFixed(2);
  };

  useEffect(() => {
    getDeliveryData();
    getStocksByProduct();
    setOpenOrderProjectModal(false);
    setDeliveryError(false);
  }, [product.id]);

  useEffect(() => {
    getStockInformation();
  }, [deliveryData]);

  useEffect(() => {
    if (activeCompanyAddress?.geo_object?.id) {
      setGeoObject(activeCompanyAddress?.geo_object?.id);
    } else {
      setGeoObject(geoObject);
    }
  }, []);

  useEffect(() => {
    setGeoObjectData();
    getDeliveryData();
    getGeoObjectById();
    setOpenOrderProjectModal(false);
    setDeliveryError(false);
  }, [geoObject, activeCompanyAddress]);

  return (
    <>
      {imageToFull ? (
        <>
          <div
            className="ProductSearcherCard-overlay"
            id="ProductSearcherCard-overlay"
            onClick={() => setImageToFull(false)}
          ></div>
          <div
            className="SearcherProductImageCard-card-full"
            onClick={() => setImageToFull(false)}
          >
            <img
              className="SearcherProductImageCard-photo-img-full"
              src={
                imageToFull
              }
              alt="EXZA"
            /> 
          </div>
        </>
      ) : (null)}
      <div className="product__searcher__result__card__container">
        <div className="product__searcher__result__card__header">
          <div className="product__searcher__result__card__header__content">
            <div className="product__searcher__result__card__header__content__left__side">
              <div className="product__searcher__result__card__header__title">
                {product.code}
              </div>
              <text className="product__searcher__result__card__header__body__elem">
                Наименование: <span>{product?.name}</span>
              </text>
              <text className="product__searcher__result__card__header__body__elem">
                Бренд товара: <span>{product?.brand?.name || "Не указан"}</span>
              </text>
              <text className="product__searcher__result__card__header__body__elem">
                Производитель:{" "}
                <span>{product?.manufacture?.name || "Не указан"}</span>
              </text>
            </div>

            <div className="product__searcher__result__card__header__content__center__side">
              {product?.image?.length > 1 ? (
                <Slider {...settings} style={{ width: 250 }}>
                  {product?.image?.map((image) => (
                    <SearcherProductImageCard
                      image={image}
                      key={product.id}
                      product={product}
                      imageToFull={imageToFull}
                      setImageToFull={setImageToFull}
                    />
                  ))}
                </Slider>
              ) : (
                <SearcherProductImageCard
                  image={product?.image[0] ? product?.image[0] : { image: icons?.default_product_img }}
                  key={product?.id}
                  product={product}
                  imageToFull={imageToFull}
                  setImageToFull={setImageToFull}
                />
              )}
            </div>

            <div className="product__searcher__result__card__header__tools">
              {openOrderProjectModal ? (
                <div className="product__searcher__result__card__header__tools__orders">
                  <Button
                    icon="arrow-left"
                    className="product__searcher__result__card__header__tools__button_second_step"
                    design="Emphasized"
                    onClick={() => { setOpenOrderProjectModal(false); }}
                  >
                    Назад
                  </Button>
                  <Button
                    icon="arrow-right"
                    iconEnd
                    className="product__searcher__result__card__header__tools__button_second_step"
                    design="Emphasized"
                    onClick={() => { nav('/orders') }}
                  >
                    Ваши проекты заказов
                  </Button>
                </div>
              ) : (
                <Button
                  icon="arrow-right"
                  iconEnd
                  className={
                    loading || deliveryError
                      ? "product__searcher__result__card__header__tools__button_buy_disable"
                      : "product__searcher__result__card__header__tools__button_buy_enable"
                  }
                  design="Emphasized"
                  disabled={loading || deliveryError ? true : false}
                  onClick={() => {
                    if (userState == "not_authenticated" || userState == "registration") {
                      setIsOpen(true)
                    } else {
                      setOpenOrderProjectModal(true);
                    }
                  }}
                >
                  Добавить в проект заказа
                </Button>
              )}
              <div className="product__searcher__result__card__header__product__properties">
                <div className="product__searcher__result__card__header__product__properties__top">
                  <span className="product__searcher__result__card__header__product__prop__value" title="Высота">
                    {product.properties?.filter(property => property.name === "Высота").map((property) => {
                      return (`${property?.value?.content} ${property.measure?.name}`);
                    })}
                  </span>
                  <img src="static/properties_3d_model.svg" alt="prop" />
                </div>
                <div className="product__searcher__result__card__header__product__properties__mid">
                  <span className="product__searcher__result__card__header__product__prop__value" title="Ширина">
                    {product.properties?.filter(property => property.name === "Ширина").map((property) => {
                      return (`${property?.value?.content} ${property.measure?.name}`);
                    })}
                  </span>
                  <span className="product__searcher__result__card__header__product__prop__value" title="Длина">
                    {product.properties?.filter(property => property.name === "Длина").map((property) => {
                      return (`${property?.value?.content} ${property.measure?.name}`);
                    })}
                  </span>
                </div>
                <div className="product__searcher__result__card__header__product__properties__bot">
                  <img src="static/prop_weight.svg" alt="weight" />
                  <span className="product__searcher__result__card__header__product__prop__value" title="Вес">
                    {product.properties?.filter(property => property.name === "Вес").map((property) => {
                      return (`${property?.value?.content} ${property.measure?.name}`);
                    })}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {openOrderProjectModal ? (
          <OrderProjectModal
            state={openOrderProjectModal}
            setState={() => setOpenOrderProjectModal(false)}
            address={activeCompanyAddress.id}
            product={product?.id}
            setIsOpen={setIsOpen}
            geoObjectData={geoObjectData}
            price={(deliveryData?.cheapest?.delivery + deliveryData?.cheapest?.price).toFixed(0)}
          />
        ) : (
          <div className="product__searcher__result__card__body">
            {geoObject ? (
              <>
                {fullName !== "" ? (
                  <CompanyAddressSelect setGeoObject={setGeoObject} setIsOpen={setIsOpen} />
                ) : (
                  <div style={{ width: "auto" }}>
                    {searcher}
                  </div>
                )}
                {!loading ? (
                  !deliveryError ? (
                    <>
                      <MessageStrip hideCloseButton={true} style={{ width: "auto" }}>
                        <div style={{ display: "flex", alignContent: "center", flexDirection: "column" }}>
                          <span>Цена расчитана с учетом доставки до выбранного Вами направления:</span>
                          <div style={{ display: "flex", alignContent: "center" }}>
                            <Icon style={{ marginLeft: 5, marginRight: 5 }} name="arrow-right" />
                            {`${geoObjectData ? geoObjectData?.full_name : geo_object?.name} `}
                            {activeCompanyAddress?.name ? ` (указан склад: "${activeCompanyAddress?.name}")` : null}
                          </div>
                        </div>
                      </MessageStrip>
                      <div className="product__searcher__result__card__body__deliveries">
                        <div className="product__searcher__result__card__body__deliveries__elem">
                          <img
                            className="product__searcher__result__card__body__deliveries__icon"
                            src="static/fast_delivery_icon.svg"
                            alt="EXZA"
                          />
                          Быстрая:
                          {deliveryData?.fastest?.delivery
                            ? ` ${rounded(
                              deliveryData?.fastest?.delivery +
                              deliveryData?.fastest?.price
                            )} руб. `
                            : " Неизвестно "
                          }
                          {deliveryData?.fastest?.period_min &&
                            deliveryData?.fastest?.period_max
                            ? `${deliveryData?.fastest?.period_min} - ${deliveryData?.fastest?.period_max} дн`
                            : "Неизвестно"
                          }
                        </div>
                        <div className="product__searcher__result__card__body__deliveries__elem">
                          <img
                            className="product__searcher__result__card__body__deliveries__icon"
                            src="static/cheapest_delivery_icon.svg"
                            alt="EXZA"
                          />
                          Выгодная:
                          {deliveryData?.cheapest?.delivery
                            ? ` ${rounded(
                              deliveryData?.cheapest?.delivery +
                              deliveryData?.cheapest?.price
                            )} руб. `
                            : " Неизвестно "
                          }
                          {deliveryData?.cheapest?.period_min &&
                            deliveryData?.cheapest?.period_max
                            ? `${deliveryData?.cheapest?.period_min} - ${deliveryData?.cheapest?.period_max} дн`
                            : "Неизвестно"
                          }
                        </div>
                        <div className="product__searcher__result__card__body__deliveries__elem">
                          <img
                            className="product__searcher__result__card__body__deliveries__icon"
                            src="static/optimal_delivery_icon.svg"
                            alt="EXZA"
                          />
                          Оптимальная:
                          {deliveryData?.optimal?.delivery
                            ? ` ${rounded(
                              deliveryData?.optimal?.delivery +
                              deliveryData?.optimal?.price
                            )} руб. `
                            : " Неизвестно "
                          }
                          {deliveryData?.optimal?.period_min &&
                            deliveryData?.optimal?.period_max
                            ? `${deliveryData?.optimal?.period_min} - ${deliveryData?.optimal?.period_max} дн`
                            : "Неизвестно"
                          }
                        </div>
                      </div>
                      <div className="product__searcher__result__card__body__deliveries__list__container">
                        <div
                          className={
                            !expandedDeliveries
                              ? "product__searcher__result__card__body__deliveries__list"
                              : "product__searcher__result__card__body__deliveries__list__big"
                          }
                        >
                          <div className="product__searcher__result__card__body__deliveries__list__elem">
                            <img
                              className="product__searcher__result__card__body__deliveries__icon"
                              src="static/fast_delivery_icon.svg"
                              alt="EXZA"
                            />
                            {deliveryData?.fastest?.delivery
                              ? ` ${rounded(
                                deliveryData?.fastest?.delivery +
                                deliveryData?.fastest?.price
                              )} руб. `
                              : "Неизвестно "
                            }
                            {deliveryData?.fastest?.period_min &&
                              deliveryData?.fastest?.period_max
                              ? `${deliveryData?.fastest?.period_min} - ${deliveryData?.fastest?.period_max} дн`
                              : "Неизвестно"
                            }
                            <span>
                              {deliveryData?.fastest?.stock?.warehouse?.geo_object_full?.name}
                              <Icon style={{ marginLeft: 5, marginRight: 5 }} name="arrow-right" />
                              {fullName !== "" ? `${geoObjectData?.name}` : geo_object?.name}
                            </span>
                            <span>
                              {deliveryData?.fastest?.stock?.current_rest} шт.
                            </span>
                          </div>
                          <div className="product__searcher__result__card__body__deliveries__list__elem">
                            <img
                              className="product__searcher__result__card__body__deliveries__icon"
                              src="static/cheapest_delivery_icon.svg"
                              alt="EXZA"
                            />
                            {deliveryData?.cheapest?.delivery
                              ? ` ${rounded(
                                deliveryData?.cheapest?.delivery +
                                deliveryData?.cheapest?.price
                              )} руб. `
                              : "Неизвестно "
                            }
                            {deliveryData?.cheapest?.period_min &&
                              deliveryData?.cheapest?.period_max
                              ? `${deliveryData?.cheapest?.period_min} - ${deliveryData?.cheapest?.period_max} дн`
                              : "Неизвестно"
                            }
                            <span>
                              {deliveryData?.cheapest?.stock?.warehouse?.geo_object_full?.name}
                              <Icon style={{ marginLeft: 5, marginRight: 5 }} name="arrow-right" />
                              {fullName !== "" ? `${geoObjectData?.name}` : geo_object?.name}
                            </span>
                            <span>
                              {deliveryData?.cheapest?.stock?.current_rest} шт.
                            </span>
                          </div>
                          <div className="product__searcher__result__card__body__deliveries__list__elem">
                            <img
                              className="product__searcher__result__card__body__deliveries__icon"
                              src="static/optimal_delivery_icon.svg"
                              alt="EXZA"
                            />
                            {deliveryData?.optimal?.delivery
                              ? ` ${rounded(
                                deliveryData?.optimal?.delivery +
                                deliveryData?.optimal?.price
                              )} руб. `
                              : "Неизвестно "
                            }
                            {deliveryData?.optimal?.period_min &&
                              deliveryData?.optimal?.period_max
                              ? `${deliveryData?.optimal?.period_min} - ${deliveryData?.optimal?.period_max} дн`
                              : "Неизвестно"
                            }
                            <span>
                              {deliveryData?.optimal?.stock?.warehouse?.geo_object_full?.name}
                              <Icon style={{ marginLeft: 5, marginRight: 5 }} name="arrow-right" />
                              {fullName !== "" ? `${geoObjectData?.name}` : geo_object?.name}
                            </span>
                            <span>
                              {deliveryData?.optimal?.stock?.current_rest} шт.
                            </span>
                          </div>
                          {other && other?.map((other_item) => (
                            <div className="product__searcher__result__card__body__deliveries__list__elem">
                              <img />
                              {other_item?.delivery
                                ? `${rounded(
                                  other_item?.delivery + other_item?.price)} руб. `
                                : "Неизвестно "
                              }
                              {other_item?.period_min &&
                                other_item?.period_max
                                ? `${other_item?.period_min} - ${other_item?.period_max} дн`
                                : "Неизвестно"
                              }
                              <span>
                                {other_item?.stock?.warehouse?.geo_object_full?.name}
                                <Icon style={{ marginLeft: 5, marginRight: 5 }} name="arrow-right" />
                                {fullName !== "" ? `${geoObjectData?.name}` : geo_object?.name}
                              </span>
                              <span>
                                {other_item?.stock?.current_rest} шт.
                              </span>
                            </div>
                          ))}
                        </div>
                        {other?.length > 0 &&
                          <Button
                            className="product__searcher__result__card__body__deliveries__list__expander"
                            onClick={() => setExpandedDeliveries(!expandedDeliveries)}
                          >
                            <Icon
                              style={{ color: "#0070F2" }}
                              title={expandedDeliveries ? "Скрыть" : "Показать"}
                              name={
                                expandedDeliveries ? "navigation-up-arrow" : "navigation-down-arrow"
                              }
                            />
                          </Button>
                        }
                      </div>
                    </>
                  ) : (
                    <>
                      <MessageStrip hideCloseButton={true} design="Warning">
                        <div style={{ display: "flex", alignContent: "center" }}>
                          Не удалось посчитать цену и время доставки. Проверьте наличие аналогов или попробуйте поменять(выбрать) склад(город) доставки.
                        </div>
                      </MessageStrip>
                    </>
                  )
                ) : (
                  <ThemeProvider>
                    <Loader size={50} />
                  </ThemeProvider>
                )}
              </>
            ) : (
              fullName !== "" ? (
                <CompanyAddressSelect setGeoObject={setGeoObject} />
              ) : (
                <div style={{ width: "auto" }}>
                  {searcher}
                </div>
              )
            )}
            <div className="product__searcher__result__card__body__address__select">
              Характеристики товара
              <Button
                className="product__searcher__result__card__body__address__select_btn"
                onClick={() => setExpanded(!expanded)}
              >
                <Icon
                  style={{ color: "#0070F2" }}
                  title={expanded ? "Скрыть свойства" : "Показать свойства"}
                  name={
                    expanded ? "navigation-up-arrow" : "navigation-down-arrow"
                  } />
              </Button>
            </div>
            <div
              className={classNames(
                "product__searcher__result__card__footer",
                expanded && "product__searcher__result__card__footer__expanded"
              )}
            >
              <div className="product__searcher__result__card__footer__content">
                {product?.properties?.map((property) => {
                  if (!property.is_primary) {
                    return (
                      <div className="product__card__property__block__item">
                        <div className="product__card__property__block__item__name">
                          {property.name}:
                        </div>
                        <div className="product__card__property__block__item__value">
                          {property.value?.content !== 'nan' ? property.value.content : "Не указано"}
                        </div>
                      </div>
                    );
                  }
                })}
                {stocks?.map((property) => {
                  return (
                    <div className="product__card__property__block__item">
                      <div className="product__card__property__block__item__name">
                        {property.name}:
                      </div>
                      <div className="product__card__property__block__item__value">
                        {property.value !== 'nan' ? `${property.value}` : "Не указано"}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ProductSearcherResultCard;
