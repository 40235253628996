import React, { useEffect, useState } from 'react'
import './CompanyManagerInviteForm.css'
import { Bar, Button, Card, CheckBox, Form, FormGroup, FormItem, Input, MultiComboBox, MultiComboBoxItem, Option, Select, StepInput, Text, SuggestionItem, ObjectStatus, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults'
import "@ui5/webcomponents/dist/features/InputSuggestions.js";
import "@ui5/webcomponents-icons/dist/customer.js";
import "@ui5/webcomponents-icons/dist/accept.js";


const CompanyManagerInviteForm = (props) => {
    const [newLegalEntityManager, setNewLegalEntityManager] = useState({
        email: "",
        permissions: [],
        entity: props.activeCompany
    });

    const [legalEntityPermissions, setLegalEntityPermissions] = useState([]);

    const [managers, setManagers] = useState([]);

    const [emailValueState, setEmailValueState] = useState('');

    const [permissionValueState, setPermissionValueState] = useState('');

    const [inviteValueState, setInviteValueState] = useState(false);

    const [inviteValueStateResult, setInviteValueStateResult] = useState(false);

    const emailValidator = require("email-validator");

    const [emailSuggestionItemSelectValueState, setEmailSuggestionItemSelectValueState] = useState(false);

    const handleAddLegalEntityManager = async (e) => {
        e.preventDefault()
        await axios_instance.post(
            "/entity/manager/invite",
            newLegalEntityManager
        )
            .then((response) => {
                setEmailValueState("");
                setEmailSuggestionItemSelectValueState(false);
                props.setAddLegalEntityManagerMode(false);
                props.setCompanyEmployeesUpdate(!props.companyEmployeesUpdate);
                console.log(response);
            })
            .catch((error) => {
                setEmailSuggestionItemSelectValueState(false);
                if (error.response.status === 400) {
                    setEmailValueState(error.response.data.status);
                }
                if (error.response.data.status === "Пользователя с такой почтой не существует.") {
                    setInviteValueState(true);
                }
                console.log(error);
            })
    }

    const getLegalEntityPermissionsList = async () => {
        await axios_instance.get(
            "/entity/permissions/list"
        )
            .then((response) => {
                setLegalEntityPermissions(response.data.results);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getManagersList = async (email) => {
        await axios_instance.post(
            "/users/filter",
            {
                state: "ACTIVE",
                query: email
            }
        )
            .then((response) => {
                // filter managers who isn`t manager of active company
                let managers_to_invite = response.data.results.filter(manager => !props.companyManagers.find(company_manager => manager?.user?.id === company_manager?.id));
                setManagers(managers_to_invite);
                console.log(response);
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const inviteManagerByEmail = async () => {
        await axios_instance.post(
            "/send/invite/on_email",
            {
                ...newLegalEntityManager,
                permission: newLegalEntityManager.permissions[0]
            }
        )
            .then((response) => {
                console.log(response);
                setInviteValueState(false);
                setInviteValueStateResult(true);
                setEmailValueState("");
            })
            .catch((error) => {
                console.log(error);
                setEmailValueState("При отправке приглашения возникла ошибка. Попробуйте снова.");
            })
    }

    useEffect(() => {
        getLegalEntityPermissionsList();
    }, [])

    return (
        <>
            <div className='MyOfferCreateForm-overlay' id='MyOfferCreateForm-overlay'></div>

            <Card
                header={
                    <Bar
                        endContent={
                            <div
                                onClick={() => props.setAddLegalEntityManagerMode(false)}
                                className="MyOfferCreateForm-modal-close-btn"></div>
                        }
                        startContent={<span>Добавление менеджера</span>}
                        style={{
                            height: "50px"
                        }}
                    ></Bar>
                }
                style={{
                    height: "65vh"
                }}
                className='MyOfferCreateForm-form-layout MyOfferCreateForm-form-layout-open'
            >
                <div className="MyOfferCreateForm-modal-container">
                    <Form
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={1}
                        labelSpanL={1}
                        labelSpanM={1}
                        labelSpanS={1}
                        labelSpanXL={1}
                        style={{
                            alignItems: 'left'
                        }}
                        // className='MyOfferCreateForm-form'
                        titleText="Заполните форму"
                    >
                        <FormGroup>
                            <FormItem label="Почта">
                                <Input
                                    required
                                    name="email"
                                    valueState={emailSuggestionItemSelectValueState ? "Success" : emailValueState ? "Error" : ""}
                                    onInput={
                                        (e) => {
                                            if (emailValidator.validate(e.target.value) && e.target.value !== "") {
                                                setEmailSuggestionItemSelectValueState(true);
                                            }
                                            else {
                                                setEmailSuggestionItemSelectValueState(false);
                                            }
                                            setEmailValueState("");
                                            setInviteValueState(false);
                                            setInviteValueStateResult(false);
                                            // setEmailSuggestionItemSelectValueState(false);
                                            setNewLegalEntityManager((prev) => ({
                                                ...prev,
                                                email: e.target.value
                                            }));
                                            getManagersList(e.target.value);
                                        }
                                    }
                                    icon={emailSuggestionItemSelectValueState ? <Icon design='Positive' name="accept" /> : ""}

                                    // placeholder='Почта'
                                    style={{ width: '100%' }}
                                    showSuggestions
                                    onSuggestionItemSelect={e => {
                                        setEmailSuggestionItemSelectValueState(true);
                                        setNewLegalEntityManager((prev) => ({
                                            ...prev,
                                            email: e.detail.item.attributes.text.value
                                        }));
                                    }}
                                >
                                    {managers.map((manager) => {
                                        return (
                                            <SuggestionItem
                                                description={`${manager?.user?.last_name ?? ''} ${manager?.user?.first_name ?? ''} ${manager?.user?.middle_name ?? ''}`}
                                                icon="customer"
                                                text={manager?.user?.email}
                                            />
                                        )
                                    })}
                                </Input>
                            </FormItem>
                            {emailValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {emailValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            {inviteValueStateResult &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Success"
                                        icon={<Icon name="accept" />}
                                    >
                                        Приглашение успешно отправлено!
                                    </ObjectStatus>
                                </FormItem>
                            }
                            {inviteValueState &&
                                <FormItem>
                                    <Button
                                        icon='paper-plane'
                                        onClick={() => inviteManagerByEmail()}
                                        style={{ width: '100%' }}
                                    >
                                        Отправить приглашение на почту
                                    </Button>
                                </FormItem>
                            }
                            <FormItem label="Право доступа">
                                <MultiComboBox
                                    onSelectionChange={e => {
                                        setPermissionValueState("");
                                        let permissions = [];
                                        for(let i = 0; i < e.detail.items.length; i++){
                                            permissions.push(e.detail.items[i].attributes.id.value);
                                        }
                                        setNewLegalEntityManager((prev) => ({
                                            ...prev,
                                            permissions: permissions
                                        }))
                                    }}
                                    valueState={permissionValueState}
                                    style={{ width: '100%' }}
                                    valueStateMessage={<div>Выберите право доступа</div>}
                                >
                                    {legalEntityPermissions.map((permission) => {
                                        return (
                                            <MultiComboBoxItem id={permission.id} text={permission.name} />
                                        )
                                    })}
                                </MultiComboBox>
                            </FormItem>
                        </FormGroup>
                        <FormItem alignItems={"left"} label="">
                            <Button
                                design='Emphasized'
                                style={{
                                    padding: "0.5rem 1rem",
                                    width: "100%"
                                }}
                                onClick={(e) => {
                                    if (newLegalEntityManager.email === "") {
                                        setEmailValueState("Введите почту");
                                    }
                                    else if (!emailValidator.validate(newLegalEntityManager.email)) {
                                        setEmailValueState("Неверный формат почты");
                                    }
                                    else if (newLegalEntityManager.permissions.length === 0) {
                                        setPermissionValueState("Error");
                                    }
                                    else {
                                        handleAddLegalEntityManager(e);
                                    }
                                }}
                            >
                                Добавить
                            </Button>
                        </FormItem>

                    </Form>
                </div>
            </Card>

        </>
    )
}

export default CompanyManagerInviteForm