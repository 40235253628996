import React, { useEffect, useMemo, useState } from "react";
import "./PVZSelector.css";
import { Label, FlexBox, Button } from "@ui5/webcomponents-react";
import axios_instance from "../../../../requests/config_defaults";

const usePVZSelector = (order_manager_id) => {
  const [pvz, setPVZ] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const pvzFilter = async () => {
    await axios_instance
      .get(`/aggregator/pvz/selector/${order_manager_id}`)
      .then(({ data }) => {
        setSuggestions(data?.results);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    pvzFilter();
  }, []);
  return {
    pvzSelector: (
      <div className="pvz-selector-container">
        <Label>Выберите ПВЗ</Label>
        {suggestions &&
          suggestions.map((sugg) => (
            <FlexBox>
              <Label>{sugg.pvz.name}</Label>
              <Label>{sugg.pvz.type.name}</Label>
              <Label>{sugg.pvz.aggregator.name}</Label>
              <Label>{sugg.exact_address}</Label>
              <Button onClick={() => setPVZ(sugg.pvz.id)}>Выбрать</Button>
            </FlexBox>
          ))}
      </div>
    ),
    value: pvz,
    setValue: setPVZ,
  };
};

export default usePVZSelector;
