import React, { useEffect, useState } from 'react'
import './ProductsStocksView.css'
import CatalogSideView from '../catalog-side-view/CatalogSideView'
import ProductDetailView from '../product-detail-view/ProductDetailView'
import { SplitterElement, SplitterLayout, SplitterElementContent, ThemeProvider, IllustratedMessage } from '@ui5/webcomponents-react'
import axios_instance from '../../requests/config_defaults'
import { ToastContainer } from 'react-toastify'
import { useAtom } from 'jotai'
import { activeCompanyAtom } from '../../hoc/GlobalAtoms'
import { useSearchParams } from 'react-router-dom'

const ProductsStocksView = (props) => {
  const [search, setSearch] = useSearchParams();
  const [activeCompany] = useAtom(activeCompanyAtom)
  const [activeProduct, setActiveProduct] = useState(search.get("product") || null);
  const [addressesData, setAddressesData] = useState([]);
  

  const getAddresses = async () => {
    await axios_instance.get(
      `/myAddresses`
    )
      .then(({ data }) => {
        setAddressesData(data)
      })
      .catch((error) => {
      })
  }

  useEffect(() => {
    getAddresses();
  }, [activeCompany])


  return (
    <>
      <ThemeProvider>
        <SplitterLayout
          style={{
            height: '100%',
            width: '100%'
          }}
        >
          <SplitterElement>
            <CatalogSideView
              stock={props.stock}
              myStock={props.myStock}
              activeProduct={activeProduct}
              setActiveProduct={setActiveProduct}
            />
          </SplitterElement>
          <SplitterElement style={{ position: "relative" }}>
            {activeProduct ? (<ProductDetailView
              activeProduct={activeProduct}
              setActiveProduct={setActiveProduct}
              addressesData={addressesData}
            />
            ) : (
              <div className="ProductsStocksView-nodata-content">
                <IllustratedMessage
                  titleText='Выберите товар из каталога'
                  subtitleText=' '
                />
              </div>
            )}
          </SplitterElement>

        </SplitterLayout>
      </ThemeProvider>
    </>
  )
}

export default ProductsStocksView