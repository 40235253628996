import React, {useState, useEffect} from "react";
import {Box, MenuItem, Typography} from "@mui/material";
import axios_instance from "../../../requests/config_defaults";
import Table from "../../base/table/Table";
import {FlexBox, SplitterLayout, SplitterElement} from "@ui5/webcomponents-react";


const ExchangeEmailReview = (props) => {
    const [data, setData] = useState([]);
    const [columnFilters, setColumnFilters] = useState([]);
    const [maxPage, setMaxPage] = useState(1);
    const [page, setPage] = useState(1);


    const fields = {
        email: {
            key: "email",
            header: "Почта",
            editable: false,
            size: 250,
        },
        date: {
            key: "date",
            header: "Дата",
            editable: false,
            size: 250,
            filterVariant: 'datetime-range',
        },
        file_name: {
            key: "file_name",
            header: "Название файла",
            editable: false,
            size: 250,
        },
        entity: {
            key: "entity",
            header: "Юр. Лицо",
            editable: false,
            size: 250,
        }
    };
    useEffect(() => {
        getEmails()
    }, [])

    useEffect(() => {
        getEmails()
        console.log(columnFilters);
    }, [columnFilters]);

    useEffect(() => {
        setPage(1);
    }, [columnFilters])



    const getEmails = async () => {
        await axios_instance
            .get(`/exchange/email/review/filter?page=${page}&filters=${JSON.stringify(
          columnFilters ?? []
        )}`)
            .then((response) => {
                setMaxPage(response.data?.count % 20 === 0 ? response.data?.count / 20 : Math.floor(response.data?.count / 20) + 1);
                console.log(response)
                formatTableData(response.data.results)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const formatTableData = (data) => {
        data.forEach((_obj) => {
            Object.entries(_obj).forEach(([key, value]) => {
                if (key === "date") {
                    _obj[key] = value.replace('T', ' ').replace('Z', '');
                }
                if (value === 'None' || value === null) {
                    _obj[key] = 'Не указан'
                }
            })

        })
        setData(data)
    }


    return (
        <>
            <Table
                className={"exchange-table"}
                columns={fields}
                data={data && data}
                defaultColumn={{
                    maxSize: 400,
                    minSize: 80,
                    size: 150, //default size is usually 180
                }}
                editDisplayMode='cell'
                enableEditing={false}
                enableColumnResizing
                enableColumnFilters
                // onIsFullScreenChange={setIsFullScreen}
                onColumnFiltersChange={setColumnFilters}
                enableColumnOrdering={true}
                enableGrouping={false}
                enableColumnPinning={true}
                enableFacetedValues={true}
                enableRowActions={true}
                enableStickyFooter={true}
                enableStickyHeader={true}
                renderDetailPanel={(row) => (
                    <Box
                        style={{
                            height: "100px"
                        }}>
                    </Box>
                )}
                // isFullScreen={isFullScreen}
                pagination={{
                    page: page,
                    setPage: setPage,
                    pageCount: maxPage,
                }}

                // rowActionMenuItems={({row, closeMenu}) => [
                //     <MenuItem
                //         key="view"
                //         onClick={() => {
                //             setIsFullScreen(false)
                //         }}>
                //         Просмотр
                //     </MenuItem>
                // ]
                //}
            />
        </>
    );
};

export default ExchangeEmailReview;
