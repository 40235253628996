import React, { useState } from "react";
import { FlexBox, Loader, ThemeProvider } from "@ui5/webcomponents-react";
import "./Authorization.css";
import { IdentificationForm } from "./identification-form/IdentificationForm";
import { AuthenticationForm } from "./authentication-form/AuthenticationForm";
import ConfirmResetPasswordForm from "./confirm-reset-password-form/ConfirmResetPasswordForm";
import ResetPasswordForm from "./reset-password-form/ResetPasswordForm";
import FinalResetPasswordForm from "./final-reset-password-form/FinalResetPasswordForm";

const Authorization = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const [validIdentification, setValidIdentification] = useState(false);

    const [stepResetPassword, setStepResetPassword] = useState(0);

    const [recoveryPassword, setRecoveryPassword] = useState('');

    return (
        <ThemeProvider>
            <FlexBox
                alignItems="Center"
                direction="Row"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <FlexBox
                    direction="Column"
                    justifyContent="Center"
                    wrap="NoWrap"
                    style={{ height: '100%' }}
                >
                    {isLoading && <Loader />}
                    <FlexBox
                        direction="Column"
                        justifyContent="Center"
                        wrap="NoWrap"
                        style={{
                            border: '1px solid #bbb',
                            borderRadius: 5,
                            padding: '5% 10% 10% 10%',
                            width: 450,
                            opacity: isLoading ? 0.6 : 1
                        }}
                    >
                        {!validIdentification &&
                            <IdentificationForm
                                setIsLoading={setIsLoading}
                                onRegClick={props.onRegClick}
                                showWindow={props.showWindow}
                                setValidIdentification={setValidIdentification}
                            />
                        }

                        {validIdentification && stepResetPassword === 0 &&
                            <AuthenticationForm
                                setIsLoading={setIsLoading}
                                showWindow={props.showWindow}
                                onSuccess={props.onSuccess}
                                setStepResetPassword={setStepResetPassword}
                            />
                        }

                        {validIdentification && stepResetPassword === 1 &&
                            <ResetPasswordForm
                                setStepResetPassword={setStepResetPassword}
                                setIsLoading={setIsLoading}
                                recoveryPassword={recoveryPassword}
                                setRecoveryPassword={setRecoveryPassword}
                            />
                        }

                        {validIdentification && stepResetPassword === 2 &&
                            <ConfirmResetPasswordForm
                                setIsLoading={setIsLoading}
                                recoveryPassword={recoveryPassword}
                                setStepResetPassword={setStepResetPassword}
                            />
                        }

                        {validIdentification && stepResetPassword === 3 &&
                            <FinalResetPasswordForm
                                setStepResetPassword={setStepResetPassword}
                                setValidIdentification={setValidIdentification}
                            />
                        }

                    </FlexBox>
                </FlexBox>
            </FlexBox>
        </ThemeProvider>
    )
}

export default Authorization;
