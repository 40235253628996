import {
  Button,
  Tab,
  TabContainer,
  TabSeparator,
} from "@ui5/webcomponents-react";
import React, { useRef, useState } from "react";
import { WindowComponent } from "../../base/window/WindowComponent";
import axios_instance from "../../../requests/config_defaults";
import UserAdminTable from "./user-admin-table/UserAdminTable";
import { MenuItem } from "@mui/material";
import "./UsersAdminView.css";
import UserAdminProfileView from "./user-admin-profile-view/UserAdminProfileView";
import UsersAdminCreateModal from "./users-admin-create-modal/UsersAdminCreateModal";
import ModalWindow from "../../base/modal/ModalWindow";

const UsersAdminView = () => {
  const windowsBoundsContainer = useRef();
  const [userCardIsOpen, setUserCardIsOpen] = useState(false);
  const [activeUser, setActiveUser] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(false);
  const [userCreateModalOpen, setUserCreateModalOpen] = useState(false);

  const getCurrentDate = () => {
    return new Date().toISOString();
  };

  const patchUser = async (user_id, data) => {
    await axios_instance
      .patch(`/users/patch/${user_id}`, data)
      .then(({ data }) => {
        setRefreshTrigger(!refreshTrigger);
      })
      .catch((error) => {});
  };

  const blockUser = async (user_id) => {
    await patchUser(user_id, { blocked: getCurrentDate() });
  };

  const unblockUser = async (user_id) => {
    await patchUser(user_id, { blocked: null });
  };

  const approveUser = async (user_id) => {
    await patchUser(user_id, { approved: getCurrentDate() });
  };

  const setUserAsAdmin = async (user_id) => {
    await patchUser(user_id, { is_admin: true });
  };

  const unsetUserAsAdmin = async (user_id) => {
    await patchUser(user_id, { is_admin: false });
  };

  return (
    <div
      className="UsersAdminView-content"
      ref={windowsBoundsContainer}
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      <ModalWindow
        id="adminPanelUserCard"
        state={userCardIsOpen}
        setState={setUserCardIsOpen}
        title={"Карточка пользователя"}
      >
        <UserAdminProfileView
          activeUser={activeUser}
          blockUser={blockUser}
          unblockUser={unblockUser}
          approveUser={approveUser}
          setUserAsAdmin={setUserAsAdmin}
          unsetUserAsAdmin={unsetUserAsAdmin}
          refreshTrigger={refreshTrigger}
        />
      </ModalWindow>

      <TabContainer
        className="UsersAdminView-TabContainer"
        style={{
          height: "100%",
          width: "100%",
        }}
        key={"UsersAdminView-TabContainer"}
      >
        <Tab selected text="Все">
          <UserAdminTable
            state={"ALL"}
            refreshTrigger={refreshTrigger}
            setRefreshTrigger={setRefreshTrigger}
            setActiveUser={setActiveUser}
            setUserCardIsOpen={setUserCardIsOpen}
            blockUser={blockUser}
            unblockUser={unblockUser}
            approveUser={approveUser}
            setUserAsAdmin={setUserAsAdmin}
            unsetUserAsAdmin={unsetUserAsAdmin}
            additionalToolBarElements={
              <>
                <Button onClick={() => setUserCreateModalOpen(true)}>
                  Добавить пользователя
                </Button>
              </>
            }
          />
        </Tab>
        <TabSeparator />
        <Tab text="Активные">
          <UserAdminTable
            state={"ACTIVE"}
            additionalFields={{
              approved: {
                key: "approved",
                header: "Дата подтверждения",
              },
            }}
            refreshTrigger={refreshTrigger}
            setRefreshTrigger={setRefreshTrigger}
            setActiveUser={setActiveUser}
            setUserCardIsOpen={setUserCardIsOpen}
            blockUser={blockUser}
            unblockUser={unblockUser}
            approveUser={approveUser}
            setUserAsAdmin={setUserAsAdmin}
            unsetUserAsAdmin={unsetUserAsAdmin}
          />
        </Tab>
        <TabSeparator />
        <Tab text="Администраторы">
          <UserAdminTable
            state={"ADMIN"}
            refreshTrigger={refreshTrigger}
            setRefreshTrigger={setRefreshTrigger}
            setActiveUser={setActiveUser}
            setUserCardIsOpen={setUserCardIsOpen}
            blockUser={blockUser}
            unblockUser={unblockUser}
            approveUser={approveUser}
            setUserAsAdmin={setUserAsAdmin}
            unsetUserAsAdmin={unsetUserAsAdmin}
          />
        </Tab>
        <TabSeparator />
        <Tab text="Ожидают подтверждения">
          <UserAdminTable
            state={"PENDING"}
            refreshTrigger={refreshTrigger}
            setRefreshTrigger={setRefreshTrigger}
            setActiveUser={setActiveUser}
            setUserCardIsOpen={setUserCardIsOpen}
            blockUser={blockUser}
            unblockUser={unblockUser}
            approveUser={approveUser}
            setUserAsAdmin={setUserAsAdmin}
            unsetUserAsAdmin={unsetUserAsAdmin}
          />
        </Tab>
        <TabSeparator />
        <Tab text="Заблокированные">
          <UserAdminTable
            state={"BLOCKED"}
            refreshTrigger={refreshTrigger}
            setRefreshTrigger={setRefreshTrigger}
            setActiveUser={setActiveUser}
            setUserCardIsOpen={setUserCardIsOpen}
            blockUser={blockUser}
            unblockUser={unblockUser}
            approveUser={approveUser}
            setUserAsAdmin={setUserAsAdmin}
            unsetUserAsAdmin={unsetUserAsAdmin}
          />
        </Tab>
      </TabContainer>

      <ModalWindow
        state={userCreateModalOpen}
        setState={setUserCreateModalOpen}
        title={"Создать пользователя"}
        children={
          <UsersAdminCreateModal
            isOpen={userCreateModalOpen}
            controller={setUserCreateModalOpen}
          />
        }
      />
    </div>
  );
};

export default UsersAdminView;
