import React, { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { checkIsAuthenticated } from '../requests/authorization'

const RequireAuth = ({ children }) => {
    const location = useLocation()
    const navigate = useNavigate()

    const [isAuth, setIsAuth] = useState(false)

    const isAuthenticated = async() => {
        let status = await checkIsAuthenticated();
        setIsAuth(status)

        if (!status) {
            console.log(isAuth)
            navigate("/login", {state: {from: location}})
        }
    }
    useEffect(() => {
        isAuthenticated();
    }, [isAuth])
    

    return children
}

export default RequireAuth