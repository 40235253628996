import React, { useEffect, useState } from "react";
import Header from "./header/Header.jsx";
import SideBar from "./side-bar/SideBar.jsx";
import "./Container.css";
import { Outlet } from "react-router-dom";
import axios_instance from "../../requests/config_defaults.js";
import { useAtom } from "jotai";
import { emailAtom, fullNameAtom, isAdminAtom, activeCompanyAddressAtom } from "../../hoc/GlobalAtoms.jsx";

const Container = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [email] = useAtom(emailAtom)
    const [isAdmin] = useAtom(isAdminAtom)
    const [fullName] = useAtom(fullNameAtom)
    const [activeCompanyAddress, setActiveCompanyAddress] = useAtom(activeCompanyAddressAtom);

  const getCompanyAddresses = async (_id) => {
    await axios_instance
      .get(`/myCompany/addresses/${_id}`)
      .then(({ data }) => {
        const default_address = data.find(address => address.is_default === true) || null;
        if (default_address) {
          setActiveCompanyAddress({
            id: default_address.id,
            entity: _id,
            exact_address: default_address.exact_address,
            geo_object: default_address.geo_object,
            geo_object_full: default_address.geo_object_full,
            name: default_address.name,
            is_pvz: default_address?.pvz,
            type: default_address?.type?.name,
            is_default: default_address?.is_default,
          });
        } else setActiveCompanyAddress({})
      })
      .catch((error) => {});
  };

  const getActiveCompany = async () => {
    await axios_instance
      .get("/myCompany")
      .then((response) => {
        props.setActiveCompany(response.data);
        getCompanyAddresses(response.data.id);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    getActiveCompany();
  }, []);

  return (
    <div>
      <Header
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
        email={email}
      />
      <SideBar
        menuIsOpen={menuIsOpen}
        setMenuIsOpen={setMenuIsOpen}
        email={email}
        fullName={fullName}
        isAdmin={isAdmin}
      />
      <div className="main-container">
        <Outlet />
      </div>
    </div>
  );
};

export { Container };
