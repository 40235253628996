import React, { useEffect, useMemo, useState } from "react";
import axios_instance from "../../../../requests/config_defaults";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_RU } from "material-react-table/locales/ru";
import { Paper, TableContainer, MenuItem } from "@mui/material";
import { createTheme, useTheme } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { lime, purple, grey } from "@mui/material/colors";
import Paginator from "../../../base/paginator/Paginator";
import "./OrderItemAddToOrderProjectView.css";
import { Modal, Loader } from "@mantine/core";
import {
  Select,
  Option,
  Card,
  Badge,
  FlexBox,
  Switch,
  Label,
  Text,
} from "@ui5/webcomponents-react";
import zIndex from "@mui/material/styles/zIndex";
import Table from "../../../base/table/Table";
import { ModalWindow } from "../../../base/modal/ModalWindow";
import ModalWindowV2 from "../../../base/modal/ModalWindowV2";

const fields = {
  code: {
    key: "code",
    header: "Артикул",
    disabled: false,
    editable: false,
    filterable: true,
  },
  need_to_order: {
    key: "need_to_order",
    header: "Необходимо заказать",
    disabled: false,
    editable: true,
    filterable: false,
  },
  market_rest: {
    key: "market_rest",
    header: "Доступно на площадке",
    disabled: true,
    editable: false,
    filterable: false,
  },
  product_full_name: {
    key: "product_full_name",
    header: "Полное наименование товара в каталоге",
    disabled: false,
    editable: false,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    disabled: false,
    editable: false,
    filterable: true,
  },
  manufacture: {
    key: "manufacture",
    header: "Производитель",
    disabled: false,
    editable: false,
    filterable: true,
  },
};

const OrderItemAddToOrderProjectView = (props) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [myOffersPage, setMyOffersPage] = useState(1);
  const [myOffersMaxPage, setMyOffersMaxPage] = useState(1);
  const [cellValueCheck, setCellValueCheck] = useState(false);
  const [addressValueCheck, setAddressValueCheck] = useState(false);
  const [cellValueCheckTrue, setCellValueCheckTrue] = useState(false);
  const [
    cellValueLessThanMarketCountCheck,
    setCellValueLessThanMarketCountCheckTrue,
  ] = useState(false);
  const [entityAddresses, setEntityAddresses] = useState(props.addressesData);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [isPVZ, setIsPVZ] = useState(false);
  const [productsTableFilters, setProductsTableFilters] = useState([]);

  const getAddresses = async () => {
    await axios_instance
      .get(`/myAddresses`)
      .then(({ data }) => {
        props.setAddressesData(data);
      })
      .catch((error) => {});
  };

  const data_columns = [];

  for (let main_key in fields) {
    data_columns.push({
      accessorKey: fields[main_key].key,
      header: fields[main_key].header,
      muiTableHeadCellProps: {
        sx: {
          color: "black",
        },
      },
      muiTableBodyRowProps: {
        onHover(e, value) {
          e.target.style.background = "#eee";
        },
      },
      enableEditing: fields[main_key].editable,
      muiEditTextFieldProps: ({ cell, row }) => ({
        type: "number",
      }),
    });
  }

  const getName = (product) => {
    var name = "";
    product?.properties.map((property) => {
      if (property.name === "Наименование") {
        name = property?.value?.content || "Не указан";
      }
    });
    return name;
  };

  const getEntityAddress = async () => {
    await axios_instance
      .get("/myAddresses")
      .then(({ data }) => {
        setEntityAddresses(data);
        try {
          setSelectedAddress(data[0]?.id);
        } catch (e) {
          setSelectedAddress(null);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getProducts = async () => {
    setLoading(true);
    console.log(getFilters());
    await axios_instance
      .post("/products/filter" + `?page=${myOffersPage}`, {
        ...getFilters(),
        is_standard: true,
      })
      .then(({ data }) => {
        setMyOffersMaxPage(
          data?.count % 20 === 0
            ? data?.count / 20
            : Math.floor(data?.count / 20) + 1
        );
        const data_items = [];
        data?.results.map((item) => {
          data_items.push({
            id: item.id,
            product_full_name: getName(item),
            need_to_order: 0,
            code: item.code,
            market_rest: item.count,
            brand: item.brand?.name || "Не указан",
            manufacture: item.manufacture?.name || "Не указан",
          });
        });
        setData(data_items);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const addOrderItemToProject = async (row) => {
    console.log(isPVZ);
    await axios_instance
      .post("/fcs/calculate/stock", {
        order_project: props.order_project_id,
        product: row?.original.id,
        count: row?.original.need_to_order,
        endpoint: selectedAddress,
        is_pvz: isPVZ,
      })
      .then((response) => {
        setCellValueCheckTrue(true);
      })
      .catch((error) => {
        console.log(error);
        setCellValueCheck(true);
      });
  };

  const handleSaveCell = (cell, value) => {
    const patch_data = {};
    patch_data[cell.column.id] = parseFloat(value);
    data[cell.row.index][cell.column.id] = value;
    setData([...data]);
  };

  const addOrderItemHandler = (row) => {
    console.log(row?.original.need_to_order);
    if (parseInt(row?.original.need_to_order) === 0) {
      setCellValueLessThanMarketCountCheckTrue(false);
      setAddressValueCheck(false);
      setCellValueCheck(true);
    } else if (!selectedAddress) {
      setAddressValueCheck(true);
    } else {
      addOrderItemToProject(row);
      setAddressValueCheck(false);
      setCellValueLessThanMarketCountCheckTrue(false);
      setCellValueCheck(false);
    }
  };

  useEffect(() => {
    getProducts();
  }, [myOffersPage, productsTableFilters]);

  useEffect(() => {
    getEntityAddress();
  }, []);

  const selectOnChangeHandler = (e) => {
    setSelectedAddress(e.target?.value);
  };

  useEffect(() => {
    getAddresses();
  }, [props.activeCompany]);

  console.log("Адреса", props.addressesData);

  const getFilters = () => {
    const filters = {};
    for (let i = 0; i < productsTableFilters?.length; i++) {
      if (productsTableFilters[i].id !== "product_full_name") {
        filters[productsTableFilters[i].id] = productsTableFilters[i].value;
      } else {
        filters.properties = [
          {
            name: "Наименование",
            value: productsTableFilters[i].value,
          },
        ];
      }
    }
    return filters;
  };

  return (
    <>
      <div
        className="OrderItemAddToOrderProjectView-overlay"
        id="OrderItemAddToOrderProjectView-overlay"
      ></div>

      <ModalWindowV2
        className="OrderItemAddToOrderProjectView-form-modal"
        isOpen={props.state}
        onClose={() => props.setState(false)}
      >
        <div className="OrderItemAddToOrderProjectView-form-modal-content">
          <div className="OrderItemAddToOrderProjectView-table-bounder">
            <Table
              className="OrderItemAddToOrderProjectView-table"
              columns={fields}
              data={data && data}
              editDisplayMode="cell"
              enableEditing={true}
              enablePagination={false}
              enableColumnFilters={true}
              enableColumnOrdering={true}
              enableGrouping={false}
              enableColumnPinning={true}
              enableFacetedValues={false}
              enableColumnResizing
              enableRowActions={true}
              onColumnFiltersChange={setProductsTableFilters}
              enableStickyHeader={true}
              isLoading={loading}
              pagination={{
                page: myOffersPage,
                setPage: setMyOffersPage,
                pageCount: myOffersMaxPage,
              }}
              state={{
                isLoading: loading,
                pagination: { pageSize: 5, pageIndex: 0 },
              }}
              muiTableBodyCellProps={({ cell }) => ({
                onBlur: (event) => {
                  let value = event.target.value;
                  if (value < 0) {
                    value = 0;
                  }
                  if (value) {
                    setCellValueCheckTrue(false);
                    handleSaveCell(cell, value);
                  }
                  // addOrderItemHandler(row);
                },
              })}
              rowActionMenuItems={({ row, closeMenu }) => [
                <MenuItem key="view">
                  <div
                    onClick={() => {
                      addOrderItemHandler(row);
                      closeMenu();
                    }}
                  >
                    Добавить в проект заказа
                  </div>
                </MenuItem>,
              ]}
              topToolbarCustomActions={<Label>Каталог</Label>}
            />
          </div>
          <div className="OrderItemAddToOrderProjectView-form-modal-content-left">
            <FlexBox
              direction="Row"
              alignItems="Left"
              justifyContent="FlexStart"
              style={{
                gap: "10px",
              }}
            >
              <Label>Проект заказа: </Label>
              <Text>{props.order_project_name}</Text>
            </FlexBox>
            <FlexBox
              direction="Column"
              alignItems="Left"
              justifyContent="FlexStart"
              style={{
                gap: "10px",
              }}
            >
              <Label>Склад для доставки</Label>
              <Select
                onChange={(e) => selectOnChangeHandler(e)}
                onSe
                onS
                zIndex={2}
                disabled={loading}
              >
                {props.addressesData?.map((address) => (
                  <Option
                    key={address?.id}
                    data-id={address?.id}
                    value={address?.id}
                  >
                    {address?.name}
                  </Option>
                ))}
              </Select>
            </FlexBox>
            <FlexBox
              alignItems="Center"
              justifyContent="FlexStart"
              style={{
                gap: "10px",
              }}
            >
              <Label>Доставка в ПВЗ</Label>
              <Switch
                design="Textual"
                name="ПВЗ/Дверь"
                onChange={() => setIsPVZ(!isPVZ)}
                checked={isPVZ}
                style={{
                  width: "300px",
                }}
                textOff="Нет"
                textOn="Да"
              />
            </FlexBox>
            {cellValueCheck ? (
              <Badge colorScheme="2">
                Значение "Необходимо заказать" не может быть меньшим или равным
                0
              </Badge>
            ) : (
              <></>
            )}
            {cellValueLessThanMarketCountCheck ? (
              <Badge colorScheme="2">
                Значение "Необходимо заказать" не может больше значения
                "Доступно на площадке"
              </Badge>
            ) : (
              <></>
            )}
            {addressValueCheck ? (
              <Badge colorScheme="2">Склад не выбран!</Badge>
            ) : (
              <></>
            )}
            {cellValueCheckTrue ? (
              <Badge colorScheme="8">
                Успешно добавлено в {props.order_project_name}
              </Badge>
            ) : (
              <></>
            )}
          </div>
        </div>
      </ModalWindowV2>
    </>
  );
};

export default OrderItemAddToOrderProjectView;
