import React, { useState, useEffect } from 'react'
import { Input, Text, Button, FlexBox, Title, ObjectStatus, FilterGroupItem, StepInput, Card, CardHeader, StandardListItem, List, CustomListItem, GroupHeaderListItem, Icon, Select, Option, Switch, Menu, MenuItem } from '@ui5/webcomponents-react';
import "@ui5/webcomponents-icons/dist/add.js";
import "@ui5/webcomponents-icons/dist/text.js";
import "@ui5/webcomponents-icons/dist/email.js";
import "@ui5/webcomponents-icons/dist/key.js";
import "@ui5/webcomponents-icons/dist/copy.js";
import "@ui5/webcomponents-icons/dist/delete.js";
import "@ui5/webcomponents-icons/dist/overflow.js";
import "@ui5/webcomponents-icons/dist/accept.js";
import "@ui5/webcomponents-icons/dist/undo.js";
import axios_instance from '../../../../requests/config_defaults';


const CreateForm = (props) => {
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const [fieldTypes, setFieldTypes] = useState([]);

    async function getFieldTypes() {
        await axios_instance
            .get(`/field/type/list?page=1`)
            .then(response => {
                setFieldTypes(response.data.results);
            })
            .catch(error => {
                console.log(error);
            });
    }

    useEffect(() => {
        getFieldTypes();
    }, [])

    const [form, setForm] = useState({
        key: '',
        name: '',
        fields: [
            {
                id: 0,
                name: "",
                placeholder: "text",
                type: "input_text",
                require: false,
                label: "",
                config: {
                    navigated: false,
                    placeholderIsOpen: false,
                    labelIsOpen: false,
                    icon: 'text'
                }
            }
        ]
    });

    const [questionnaires, setQuestionnaires] = useState([
        {
            name: '',
            fields: [
                {
                    name: "0",
                    placeholder: "",
                    type: "input_text",
                    require: false,
                    label: "",
                    config: {
                        navigated: false,
                        placeholderIsOpen: false,
                        labelIsOpen: false,
                        icon: 'text'
                    }
                }
            ]
        }
    ]
    )

    function setFormKey(event) {
        setForm({
            ...form,
            key: formKeyInput
        });
    }

    function setFormName(event) {
        setForm({
            ...form,
            name: formNameInput
        });
    }

    function onFormFieldDelete(field) {
        let delete_field_id = field.id
        //let target_field = form.fields.filter(field => field.id == copy_field_id)[0];
        let not_target_fields = form.fields.filter(field => field.id != delete_field_id);

        //not_target_fields.splice(delete_field_id, 1);

        for (let i = 0; i < not_target_fields.length; i++) {
            not_target_fields[i].id = i;
        }

        setForm({
            ...form,
            fields: [...not_target_fields]
        });
    }

    function onFormFieldAdd(event) {
        setForm({
            ...form,
            fields: [
                ...form.fields,
                {
                    id: form.fields.length,
                    name: '',
                    placeholder: "",
                    type: "input_text",
                    require: false,
                    label: "",
                    config: {
                        navigated: false,
                        placeholderIsOpen: false,
                        labelIsOpen: false,
                        icon: 'text'
                    }
                }
            ]
        });
    }

    function onFormFieldCopy(field) {
        let copy_field_id = field.id
        let target_field = form.fields.filter(field => field.id == copy_field_id)[0];
        let not_target_fields = form.fields.filter(field => field.id != copy_field_id);

        let new_field = Object.assign({}, target_field);
        new_field.config = Object.assign({}, target_field.config);
        new_field.id = form.fields.length;
        new_field.config.navigated = true;

        target_field.config.navigated = false;

        not_target_fields.splice(copy_field_id, 0, target_field);

        setForm({
            ...form,
            fields: [
                ...not_target_fields,
                new_field
            ]
        });
    }

    function onQuestionnaireAdd(event) {
        setForm({
            ...form,
            fields: [
                ...form.fields,
                {
                    id: form.fields.length,
                    name: '',
                    placeholder: "",
                    type: "input_text",
                    require: false,
                    label: "",
                    config: {
                        navigated: false,
                        placeholderIsOpen: false,
                        labelIsOpen: false
                    }
                }
            ]
        });
    }

    function onFormFieldSelectionChange(event) {
        let item_id = event.detail.targetItem.id;
        let target_field = form.fields.filter(field => field.id == item_id)[0];
        let not_target_fields = form.fields.filter(field => field.id != item_id);
        for (let i = 0; i < not_target_fields.length; i++) {
            not_target_fields[i].config.navigated = false;
        }
        target_field.config.navigated = true;
        not_target_fields.splice(item_id, 0, target_field);
        setForm({
            ...form,
            fields: [
                ...not_target_fields
            ]
        });
    }

    function onAdditionalSettingsMenuItemClick(event, field) {
        let text = event.detail.text;
        let field_id = field.id;
        let edited_field = form.fields.filter(field => field.id == field_id)[0];
        let not_edited_fields = form.fields.filter(field => field.id != field_id);

        if (text === 'Метка поля (label)') {
            edited_field.config.labelIsOpen = edited_field.config.labelIsOpen ? false : true;
        }
        else if (text === 'Заполнитель (placeholder)') {
            edited_field.config.placeholderIsOpen = edited_field.config.placeholderIsOpen ? false : true;
        }
        not_edited_fields.splice(field_id, 0, edited_field);
        setForm({
            ...form,
            fields: [
                ...not_edited_fields
            ]
        });
    }

    function onSwitchChange(field) {
        let field_id = field.id;
        let edited_field = form.fields.filter(field => field.id == field_id)[0];
        let not_edited_fields = form.fields.filter(field => field.id != field_id);

        edited_field.require = edited_field.require ? false : true;
        not_edited_fields.splice(field_id, 0, edited_field);
        setForm({
            ...form,
            fields: [
                ...not_edited_fields
            ]
        });
    }

    function onFieldTypeSelectChange(event, field) {
        let field_type = event.detail.selectedOption.id;

        let field_id = field.id;
        let edited_field = form.fields.filter(field => field.id == field_id)[0];
        let not_edited_fields = form.fields.filter(field => field.id != field_id);

        edited_field.type = field_type;
        edited_field.config.icon = getIconNameFromFieldTypeKey(field_type);
        not_edited_fields.splice(field_id, 0, edited_field);
        setForm({
            ...form,
            fields: [
                ...not_edited_fields
            ]
        });
    }

    function onFieldNameInputChange(event, field) {
        let field_name = event.target.typedInValue;

        let field_id = field.id;
        let edited_field = form.fields.filter(field => field.id == field_id)[0];
        let not_edited_fields = form.fields.filter(field => field.id != field_id);

        edited_field.name = field_name;
        not_edited_fields.splice(field_id, 0, edited_field);
        setForm({
            ...form,
            fields: [
                ...not_edited_fields
            ]
        });
    }

    function onFieldLabelInputChange(event, field) {
        let field_label = event.target.typedInValue;

        let field_id = field.id;
        let edited_field = form.fields.filter(field => field.id == field_id)[0];
        let not_edited_fields = form.fields.filter(field => field.id != field_id);

        edited_field.label = field_label;
        not_edited_fields.splice(field_id, 0, edited_field);
        setForm({
            ...form,
            fields: [
                ...not_edited_fields
            ]
        });
    }

    function onFieldPlaceholderInputChange(event, field) {
        let field_placeholder = event.target.typedInValue;

        let field_id = field.id;
        let edited_field = form.fields.filter(field => field.id == field_id)[0];
        let not_edited_fields = form.fields.filter(field => field.id != field_id);

        edited_field.placeholder = field_placeholder;
        not_edited_fields.splice(field_id, 0, edited_field);
        setForm({
            ...form,
            fields: [
                ...not_edited_fields
            ]
        });
    }

    function getIconNameFromFieldTypeKey(field_type_key) {
        switch (field_type_key) {
            case 'input_text':
                return 'text';
            case 'input_password':
                return 'key';
            case 'input_email':
                return 'email';
            default:
                return;
        }
    }

    function getInputTypeFromFieldTypeKey(field_type_key) {
        switch (field_type_key) {
            case 'input_text':
                return 'Text';
            case 'input_password':
                return 'Password';
            case 'input_email':
                return 'Email';
            default:
                return;
        }
    }

    const [formNameInput, setFormNameInput] = useState('Новая форма');

    const [formKeyInput, setFormKeyInput] = useState('key');

    const [formNameEdit, setFormNameEdit] = useState(false);

    return (
        <>
            <List
                mode="SingleSelect"
                onSelectionChange={onFormFieldSelectionChange}
                separators="Inner"
                indent
                header={
                    <FlexBox
                        direction="Column"
                        alignItems='Center'
                        onDoubleClick={() => setFormNameEdit(true)}
                    //onBlur={() => setFormNameEdit(false)}
                    >
                        {formNameEdit &&
                            <>
                                <Input
                                    onChange={setFormKey}
                                    onInput={event => {
                                        setFormKeyInput(event.target.typedInValue);
                                    }}
                                    placeholder="Ключ"
                                    value={form.key}
                                />
                                <Input
                                    onChange={setFormName}
                                    onInput={event => {
                                        setFormNameInput(event.target.typedInValue);
                                    }}
                                    placeholder="Имя"
                                    value={form.name}
                                />
                                <Button
                                    onClick={() => setFormNameEdit(false)}
                                    icon='undo'
                                />
                                {/*
                                <FlexBox alignItems='Center'>
                                    <Button onClick={() => {
                                        setFormKey(formKeyInput);
                                        setFormName(formNameInput);
                                        setFormNameEdit(false);
                                    }}>
                                        Сохранить
                                    </Button>
                                    <Button onClick={() => setFormNameEdit(false)}>
                                        Отменить
                                    </Button>
                                </FlexBox>*/}

                            </>
                        }
                        {!formNameEdit &&
                            <>
                                <Title level='H1'>{form.name ? form.name : 'Новая форма'}</Title>
                                <Title level='H3'>{form.key ? form.key : ''}</Title>
                            </>
                        }
                    </FlexBox>
                }
            >
                {form.fields.length !== 0 && (form.fields.map((field, index) => {
                    if (field.config.navigated === true) {
                        return (
                            <CustomListItem
                                id={index}
                                navigated={field.config.navigated}
                            >
                                <List>
                                    <StandardListItem
                                        icon={field.config.icon}
                                        additionalText={field.require ? 'Обязательно' : 'Не обязательно'}
                                        additionalTextState={field.require ? 'Error' : 'None'}
                                        description={`${index + 1} из ${form.fields.length}`}
                                        type="Inactive"
                                    >
                                        {field.name ? field.name : 'Новое поле'}
                                    </StandardListItem>
                                    <CustomListItem type="Inactive" style={{ padding: '1%' }}>
                                        <FlexBox direction='Column' style={{ width: '100%' }}>
                                            <FlexBox justifyContent='SpaceAround' style={{ width: '100%' }}>
                                                <Input
                                                    placeholder='Имя'
                                                    onChange={event => onFieldNameInputChange(event, field)}
                                                    value={field.name}
                                                />
                                                <Select
                                                    onChange={event => onFieldTypeSelectChange(event, field)}
                                                >
                                                    {fieldTypes.length !== 0 && (fieldTypes.map((fieldType, index) => {
                                                        let icon = getIconNameFromFieldTypeKey(fieldType.key);
                                                        return (
                                                            <Option
                                                                id={fieldType.key}
                                                                icon={icon}
                                                                selected={field.type === fieldType.key ? true : false}
                                                            >
                                                                {fieldType.name}
                                                            </Option>
                                                        )
                                                    }))}
                                                </Select>
                                            </FlexBox>
                                            <FlexBox justifyContent='SpaceAround' style={{ width: '100%' }}>
                                                {field.config.labelIsOpen && (
                                                    <Input
                                                        placeholder='Метка поля (label)'
                                                        value={field.label}
                                                        onChange={event => onFieldLabelInputChange(event, field)}
                                                    />
                                                )}
                                                {field.config.placeholderIsOpen && (
                                                    <Input
                                                        placeholder='Заполнитель (placeholder)'
                                                        value={field.placeholder}
                                                        onChange={event => onFieldPlaceholderInputChange(event, field)}
                                                    />
                                                )}
                                            </FlexBox>
                                        </FlexBox>
                                    </CustomListItem>
                                    <CustomListItem type="Inactive" style={{ padding: '1%' }}>
                                        <FlexBox direction='Column'>
                                            <Title level='H4'>Превью</Title>
                                            <Input
                                                //type={getInputTypeFromFieldTypeKey(field.type)}
                                                placeholder={field.placeholder}
                                                disabled
                                            />
                                        </FlexBox>
                                    </CustomListItem>
                                    <CustomListItem type="Inactive" style={{ padding: '1%' }}>
                                        <FlexBox justifyContent='SpaceAround' style={{ width: '100%' }}>
                                            <Button
                                                icon="copy"
                                                onClick={() => onFormFieldCopy(field)}
                                            />
                                            <Button
                                                icon="delete"
                                                design="Negative"
                                                onClick={() => onFormFieldDelete(field)}
                                            />
                                            <FlexBox alignItems='Center'>
                                                <Text>Обязательное поле</Text>
                                                <Switch
                                                    checked={field.require}
                                                    onChange={() => onSwitchChange(field)}
                                                />
                                            </FlexBox>
                                            <Button
                                                id='openAdditionalSettingsMenuButton'
                                                icon='overflow'
                                                onClick={() => setMenuIsOpen(true)}
                                            />
                                            <Menu
                                                opener={'openAdditionalSettingsMenuButton'}
                                                open={menuIsOpen}
                                                onAfterClose={() => setMenuIsOpen(false)}
                                                onItemClick={event => onAdditionalSettingsMenuItemClick(event, field)}
                                            >
                                                <MenuItem
                                                    icon={field.config.labelIsOpen ? 'accept' : ''}
                                                    text='Метка поля (label)'
                                                />
                                                <MenuItem
                                                    icon={field.config.placeholderIsOpen ? 'accept' : ''}
                                                    text='Заполнитель (placeholder)'
                                                />
                                            </Menu>
                                        </FlexBox>
                                    </CustomListItem>
                                </List>
                            </CustomListItem>
                        )
                    }
                    return (
                        <StandardListItem
                            id={index}
                            navigated={field.config.navigated}
                            icon={field.config.icon}
                            additionalText={field.require ? 'Обязательно' : 'Не обязательно'}
                            additionalTextState={field.require ? 'Error' : 'None'}
                            description={`${index + 1} из ${form.fields.length}`}
                        >
                            {field.name ? field.name : 'Новое поле'}
                        </StandardListItem>
                    )
                }))}
            </List>
            <FlexBox justifyContent='Center' alignItems='Center'>
                <Button
                    icon="add"
                    onClick={onFormFieldAdd}
                />
            </FlexBox>
        </>

    )
}

export { CreateForm };