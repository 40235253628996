import { useMemo, useState, useEffect } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable
} from 'material-react-table';
import { Box, Typography, MenuItem } from '@mui/material';
import axios_instance from '../../../../requests/config_defaults';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import Table from '../../../base/table/Table';
import { Button, FlexBox, TabContainer, Tab } from '@ui5/webcomponents-react';

// import { data } from './makeData';


const fields = {
    status: {
        key: "status",
        header: "Статус",
        disabled: false
    },
    permission: {
        key: "permission",
        header: "Право доступа",
        disabled: false
    },
    processing_user: {
        key: "processing_user",
        header: "Обработал",
        disabled: true
    },
    create_date_time_stamp: {
        key: "create_date_time_stamp",
        header: "Время заявки",
        disabled: true
    },
    processed_datetime_stamp: {
        key: "processed_datetime_stamp",
        header: "Время обработки",
        disabled: true
    }
};



const PermissionRequestsHistoryTable = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    const [permissionRequestsProcessed, setPermissionRequestsProcessed] = useState([]);

    const [dataTable, setDataTable] = useState([]);

    const [page, setPage] = useState(1);

    useEffect(() => {
        let data_table = [];

        permissionRequestsProcessed.map((permission_request) => {
            data_table.push({
                id: permission_request.id,
                status: permission_request.status ? "Подтверждена" : "Отклонена",
                permission: permission_request.permission ? permission_request.permission.key : "Не указано",
                processing_user: permission_request.processing_user ? permission_request.processing_user.email : "Не указан",
                processed_datetime_stamp: permission_request.processed_datetime_stamp ? permission_request.processed_datetime_stamp : "Не указано",
                create_date_time_stamp: permission_request.create_date_time_stamp ? permission_request.create_date_time_stamp : "Не указано"
            })
        })

        setDataTable(data_table);
    }, [permissionRequestsProcessed])

    const getPermissionRequestProcessed = async () => {
        setIsLoading(true);
        await axios_instance.post(
            `/entity/permission/request/processed/list/by_manager?page=${page}`,
            {
                entity: props.activeCompany,
                email: props.manager.email
            }
        )
            .then((response) => {
                console.log(response)
                setIsLoading(false);
                setPermissionRequestsProcessed(response.data.results);
            })
            .catch((error) => {
                console.log(error)
                setIsLoading(false);
            })
    }

    useEffect(() => {
        getPermissionRequestProcessed();
    }, [])

    useEffect(() => {
        getPermissionRequestProcessed();
    }, [props.permissionRequestsUpdate])

    return (
        <>
            <Table
                columns={fields}
                data={dataTable}
                enableEditing={false}
                enableColumnResizing
                enableColumnFilters={false}
                enableColumnOrdering={true}
                enableGrouping={false}
                enableColumnPinning={false}
                enableFacetedValues={false}
                enableStickyHeader
                className={"permissions-table-container"}

                isLoading={isLoading}

                pagination={{
                    page: page,
                    setPage: setPage,
                    pageCount: props.maxPage
                }}

                topToolbarCustomActions={
                    <FlexBox direction='Column'>
                        <Typography color="success.main" component="span" variant="h6">
                            История заявок
                        </Typography>
                    </FlexBox>
                }

            />
        </>
    )
}

export default PermissionRequestsHistoryTable