import { IllustratedMessage } from "@ui5/webcomponents-react";


const UserAgreement = (e) => {

    return (
        <>
            <h1>Пользовательское соглашение</h1>
            <IllustratedMessage titleText="В разработке" subtitle={<div></div>}/>
        </>
    )
}


export default UserAgreement