import React, { useEffect, useState } from "react"
import UploadFilesList from "../UploadFilesList/UploadFilesList"
import { Button, Select, Option, Badge } from "@ui5/webcomponents-react"
import Table from "../../base/table/Table"
import axios_instance from "../../../requests/config_defaults"
import "./ExchangeCreatePage.css"
import { MenuItem } from "@mui/material"
import { useAtom } from "jotai"
import { activeCompanyAtom } from "../../../hoc/GlobalAtoms"

const fields = {
  name: {
    key: "exchange_name",
    header: "Название",
    editable: false,
  },
  status: {
    key: "exchange_status",
    header: "Статус выполнения",
    editable: false,
  },
  rows: {
    key: "row_count",
    header: "Количество строк",
    editable: false,
  },
}

const ExchangeCreatePage = (props) => {
  const [entityExchanges, setEntityExchanges] = useState([])
  const [entityAddresses, setEntityAddresses] = useState([])
  const [selectedAddress, setSelectedAddress] = useState("")
  const [isGettedList, setIsGettedList] = useState(false)
  const [maxPage, setMaxPage] = useState(1)
  const [page, setPage] = useState(1)
  const [data, setData] = useState([])
  const [columnFilters, setColumnFilters] = useState([])
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [activeCompany] = useAtom(activeCompanyAtom)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getEntityAddress()
  }, [])

  useEffect(() => {
    getEntitiesList()
  }, [props.updater, props.activeCompany, page])

  const checkStatus = (data) => {
    let status = ""
    if (data.start_process_dt && !data.processed) {
      status = <Badge colorScheme="6">В обработке</Badge>
    } else if (
      !data.start_process_dt &&
      !data.processed &&
      data.file.split("/").pop()
    ) {
      status = <Badge colorScheme="10">Принята</Badge>
    } else if (
      !data.start_process_dt &&
      !data.processed &&
      !data.file.split("/").pop()
    ) {
      status = <Badge colorScheme="1">Загрузите файл</Badge>
    } else if (data.start_process_dt && data.processed) {
      status = <Badge colorScheme="8">Завершена</Badge>
    }
    return status
  }

  const getEntitiesList = async () => {
    setLoading(true)
    await axios_instance
      .get(`/exchange/entity/list?page=${page}`)
      .then((response) => {
        const exchanges = []
        setMaxPage(
          response.data?.count % 20 === 0
            ? response.data?.count / 20
            : Math.floor(response.data?.count / 20) + 1
        )
        setEntityExchanges(response.data.results)
        setIsGettedList(true)
        response.data?.results.map((exch) => {
          exchanges.push({
            id: exch.id,
            exchange_name: exch.file ? (
              exch.file.split("/").pop()
            ) : (
              <Badge colorScheme="1">Загрузите файл</Badge>
            ),
            exchange_status: checkStatus(exch),
            row_count: exch.row_count ? (
              exch.row_count
            ) : (
              <Badge colorScheme="1">Загрузите файл</Badge>
            ),
          })
        })
        setData(exchanges)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }

  const getEntityAddress = async () => {
    await axios_instance
      .get("/myAddresses")
      .then((response) => {
        setSelectedAddress(response.data[0].id)
        setEntityAddresses(response.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getEntityAddress()
  }, [activeCompany])

  const createExchange = async () => {
    await axios_instance
      .post(
        `/exchange/create`,
        {
          default_warehouse: selectedAddress,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(({ data }) => {
        getEntitiesList()
        props.setExchange(data?.id)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const selectOnChangeHandler = (e) => {
    setSelectedAddress(e.detail.selectedOption.id)
  }

  const deleteExchange = async (id) => {
    await axios_instance
      .delete("/exchange/delete/" + id)
      .then(({ data }) => {
        getEntitiesList()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    // <div className="exchange-create-page-container">
    //   <div className="exchange-create-tools">
    //     <Button onClick={createExchange}>Создать выгрузку</Button>
    //     <Select onChange={(e) => selectOnChangeHandler(e)}>
    //       {entityAddresses?.map((address) => (
    //         <Option id={address.id}>{address.name}</Option>
    //       ))}
    //     </Select>
    //   </div>
    //   <UploadFilesList
    //     setExchange={props.setExchange}
    //     entityExchanges={entityExchanges}
    //     isGettedList={isGettedList}
    //   />
    // </div>
    <div className="exchange-create-page-container">
      <Table
        className={"CatalogSideView-table"}
        columns={fields}
        data={data}
        defaultColumn={{
          maxSize: 400,
          minSize: 80,
          size: 150, //default size is usually 180
        }}
        editDisplayMode="cell"
        enableEditing={false}
        enableColumnResizing
        enableColumnFilters
        enableColumnOrdering={true}
        enableGrouping={false}
        enableColumnPinning={true}
        enableFacetedValues={true}
        enableRowActions={true}
        enableStickyFooter={true}
        enableStickyHeader={true}
        isFullScreen={isFullScreen}
        onColumnFiltersChange={setColumnFilters}
        isLoading={loading}
        pagination={{
          page: page,
          setPage: setPage,
          pageCount: maxPage,
        }}
        topToolbarCustomActions={
          <div className="exchange-create-tools">
            <Button
              disabled={
                entityAddresses?.length === 0 ||
                !Boolean(
                  props.permissionData?.admin ||
                    props.permissionData?.manager ||
                    props.permissionData?.residuals_control_manager
                )
              }
              onClick={() => {
                props.setExchangeVisible(true)
                createExchange()
              }}>
              Создать выгрузку
            </Button>
            {entityAddresses?.length > 0 ? (
              <Select onChange={(e) => selectOnChangeHandler(e)}>
                {entityAddresses?.map((address) => (
                  <Option id={address.id}>{address.name}</Option>
                ))}
              </Select>
            ) : (
              <Button
                disabled={!Boolean(props.permissionData?.admin)}
                onClick={() => props.setAddressCreateModalIsOpen(true)}>
                Добавить склад
              </Button>
            )}
          </div>
        }
        rowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            key="view"
            onClick={() => {
              setIsFullScreen(false)
              props.setExchangeVisible(true)
              props.setExchange(row.original?.id)
              closeMenu()
            }}>
            Просмотр
          </MenuItem>,
          <MenuItem
            disabled={
              !Boolean(
                props.permissionData?.admin ||
                  props.permissionData?.manager ||
                  props.permissionData?.residuals_control_manager
              )
            }
            key="delete"
            onClick={() => {
              deleteExchange(row.original?.id)
              props.setExchangeVisible(false)
              closeMenu()
            }}>
            Удалить
          </MenuItem>,
        ]}
      />
    </div>
  )
}

export default ExchangeCreatePage
