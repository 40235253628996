import Table from "../../base/table/Table"
import { ThemeProvider } from "@ui5/webcomponents-react"
import { useState, useEffect } from "react"
import axios_instance from "../../../requests/config_defaults"
import { MenuItem } from "@mui/material"
import { Button, FlexBox } from "@ui5/webcomponents-react"
import CustomInput from "./CustomInput/CustomInput"
import dayjs from "dayjs"
import {
  EntityFilter,
  BrandFilter,
  ManufacturerFilter,
} from "./СustomFilters/CustomFilters"

import {
  ConfirmationModal,
  PublicationPeriodModal,
  ResetByEntityModal,
  ResetByProductModal,
} from "./Modals/Modals"

const fields = {
  entity: {
    key: "entity",
    header: "Поставщик",
    disabled: true,
    editable: false,
    Filter: EntityFilter,
  },
  manufacture: {
    key: "manufacture",
    header: "Производитель",
    disabled: false,
    editable: false,
    Filter: ManufacturerFilter,
  },
  brand: {
    key: "brand",
    header: "Бренд",
    disabled: false,
    editable: false,
    Filter: BrandFilter,
  },
  product_name: {
    key: "product_name",
    header: "Наименование",
    disabled: false,
    editable: false,
  },
  code: {
    key: "code",
    header: "Артикул",
    disabled: false,
    editable: false,
  },
  warehouse: {
    key: "warehouse",
    header: "Склад",
    disabled: false,
    editable: false,
    filterable: false,
  },
  current_rest: {
    key: "current_rest",
    header: "Текущий остаток",
    disabled: false,
    editable: false,
    filterable: false,
    enableClickToCopy: false,
  },
  reserved_rest: {
    key: "reserved_rest",
    header: "Зарезервированный остаток",
    disabled: false,
    editable: false,
    filterable: false,
    enableClickToCopy: false,
  },
  needful_rest: {
    key: "needful_rest",
    header: "Необходимый остаток",
    disabled: false,
    editable: false,
    filterable: false,
    enableClickToCopy: false,
  },
  to_order_count: {
    key: "to_order_count",
    header: "Необходимо заказать",
    disabled: false,
    editable: false,
    filterable: false,
    enableClickToCopy: false,
  },
  for_sale_count: {
    key: "for_sale_count",
    header: "Доступно для продажи",
    disabled: false,
    editable: false,
    filterable: false,
    enableClickToCopy: false,
  },
  purchase_price: {
    key: "purchase_price",
    header: "Цена закупки",
    disabled: false,
    editable: false,
    filterable: false,
    enableClickToCopy: false,
  },
  clean_sale_price: {
    key: "clean_sale_price",
    header: "Чистая цена продажи",
    disabled: false,
    editable: false,
    filterable: false,
    enableClickToCopy: false,
  },
  sale_price: {
    key: "sale_price",
    header: "Цена продажи с наценкой",
    disabled: false,
    editable: false,
    filterable: false,
    enableClickToCopy: false,
  },
}

const EntityTable = () => {
  const [loading, setLoading] = useState(false)
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const [openDateTimePickerModal, setDateTimePickerOpenModal] = useState(false)
  const [openUnPublishModal, setUnPublishOpenModal] = useState(false)
  const [openPublishModal, setPublishOpenModal] = useState(false)
  const [openResetModal, setResetOpenModal] = useState(false)
  const [openResetByEntityModal, setResetByEntityOpenModal] = useState(false)
  const [openResetByProductModal, setResetByProductOpenModal] = useState(false)
  const [activeRemnant, setActiveRemnant] = useState("")
  const [activeProduct, setActiveProduct] = useState("")
  const [activeEntity, setActiveEntity] = useState("")

  const [columnFilters, setColumnFilters] = useState([])
  const [dataTable, setDataTable] = useState([])
  const [entityRemnants, setEntityRemnants] = useState([])
  const [legalEntities, setLegalEntities] = useState([])
  const [options, setOptions] = useState([{ value: "", label: "" }])

  const handleSetPeriod = async (remnantId, startDate, endDate) => {
    try {
      await axios_instance
        .put(`/update/activity/period/${remnantId}`, {
          start_time: startDate?.toISOString(),
          end_time: endDate?.toISOString() !== undefined ? endDate?.toISOString() : null,
        })
        .then(getEntityRemnantsList())
    } catch (error) {
      console.error("Ошибка сети:", error)
    }
  }

  const handlePeriod = async (remnantId) => {
    try {
      await axios_instance.get(`/get/history/stock/${remnantId}`, {})
      getEntityRemnantsList()
    } catch (error) {
      console.error("Ошибка сети:", error)
    }
  }

  const handleResetByEntity = async (activeEntity) => {
    try {
      await axios_instance
        .put(`/resetStock/by_entity`, {
          entity: activeEntity,
        })
        .then(getEntityRemnantsList())
    } catch (error) {
      console.error("Ошибка сети:", error)
    }
  }

  const handleResetByProduct = async (activeProduct) => {
    try {
      await axios_instance.put(`/resetStock/by_product`, {
        product: activeProduct,
      })
      .then(getEntityRemnantsList())

    } catch (error) {
      console.error("Ошибка сети:", error)
    }
  }

  useEffect(() => {
    let data_table = []
    entityRemnants.map((remnant) => {
      data_table.push({
        id: remnant.id,
        entity: remnant.entity.name,
        product_name: remnant.product.name ? remnant.product.name : "Не указан",
        code: remnant.product.code ? remnant.product.code : "Не указан",
        warehouse: remnant.warehouse.exact_address
          ? remnant.warehouse.exact_address
          : "Не указано",
        description: remnant.description ? remnant.description : "Не указано",
        current_rest: (
          <CustomInput
            activeRemnant={remnant.id}
            label={remnant.current_rest ?? 0}
            fieldName="current_rest"
            getEntityRemnantsList={getEntityRemnantsList}
          />
        ),
        purchase_price: (
          <CustomInput
            activeRemnant={remnant.id}
            label={remnant.purchase_price ?? 0}
            fieldName="purchase_price"
            getEntityRemnantsList={getEntityRemnantsList}
          />
        ),
        clean_sale_price: (
          <CustomInput
            activeRemnant={remnant.id}
            label={remnant.clean_sale_price ?? 0}
            fieldName="clean_sale_price"
            getEntityRemnantsList={getEntityRemnantsList}
          />
        ),
        brand: remnant.product.brand?.name
          ? remnant.product.brand?.name
          : "Не указано",
        manufacture: remnant.product.manufacture?.name
          ? remnant.product.manufacture?.name
          : "Не указано",
        reserved_rest: (
          <CustomInput
            activeRemnant={remnant.id}
            label={remnant.reserved_rest ?? 0}
            fieldName="reserved_rest"
            getEntityRemnantsList={getEntityRemnantsList}
          />
        ),
        for_sale_count: remnant.for_sale_count ?? 0,
        sale_price: remnant.sale_price ?? 0,
        needful_rest: (
          <CustomInput
            activeRemnant={remnant.id}
            label={remnant.needful_rest ?? 0}
            fieldName="needful_rest"
            getEntityRemnantsList={getEntityRemnantsList}
          />
        ),
        activity_period: remnant.activity_period,
        entity_id: remnant.entity.id,
        product_id: remnant.product.id,
        to_order_count: remnant.to_order_count ?? 0,
      })
    })

    setDataTable(data_table)
    setOptions(
      entityRemnants
        .map((remnant) => remnant.entity.name)
        .filter((value, index, self) => self.indexOf(value) === index)
        .map((entityName) => ({ value: entityName, label: entityName }))
    )
  }, [entityRemnants])
  useEffect(() => {
    getEntityRemnantsList()
  }, [page, columnFilters])

  const getEntityRemnantsList = async () => {
    setLoading(true)
    await axios_instance
      .post(
        `/supplier/products/filter?page=${page}${`&filters=${JSON.stringify(
          columnFilters ?? []
        )}`}`
      )
      .then((response) => {
        setMaxPage(
          response?.data.count % 20 === 0
            ? response?.data.count / 20
            : Math.floor(response?.data.count / 20) + 1
        )
        setEntityRemnants(response.data.results)
        setLoading(false)
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
      })
  }
  useEffect(() => {
    getEntityRemnantsList()
  }, [])

  const removeEntityRemnant = async () => {
    await axios_instance
      .put("/resetStock", {
        product: activeProduct,
        entity: activeEntity,
      })
      .then(({ data }) => {
        getEntityRemnantsList()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  return (
    <ThemeProvider>
      <ConfirmationModal
        isOpen={openResetModal}
        onClose={() => setResetOpenModal(false)}
        message="Вы уверены, что хотите обнулить остаток?"
        onConfirm={() => {
          removeEntityRemnant(activeRemnant)
          setResetOpenModal(false)
        }}
      />
      <ResetByEntityModal
        isOpen={openResetByEntityModal}
        onClose={() => setResetByEntityOpenModal(false)}
        onReset={handleResetByEntity}
      />
      <ResetByProductModal
        isOpen={openResetByProductModal}
        onClose={() => setResetByProductOpenModal(false)}
        onReset={handleResetByProduct}
      />

      <PublicationPeriodModal
        isOpen={openDateTimePickerModal}
        onClose={() => setDateTimePickerOpenModal(false)}
        activeRemnant={activeRemnant}
        onSetPeriod={handleSetPeriod}
      />
      <ConfirmationModal
        isOpen={openPublishModal}
        onClose={() => setPublishOpenModal(false)}
        message="Вы уверены, что хотите выставить на продажу?"
        onConfirm={() => {
          const today = dayjs()
          handleSetPeriod(activeRemnant, today, undefined) // endDate = null
          handlePeriod(activeRemnant)
          setPublishOpenModal(false)
        }}
      />
      <ConfirmationModal
        isOpen={openUnPublishModal}
        onClose={() => setUnPublishOpenModal(false)}
        message="Вы уверены, что хотите снять с продажи?"
        onConfirm={() => {
          const yesterday = dayjs().subtract(1, "day")
          const today = dayjs()
          handleSetPeriod(activeRemnant, today, yesterday, "")
          setUnPublishOpenModal(false)
        }}
      />
      <Table
        className={"entity-permissions-table"}
        columns={fields}
        data={dataTable}
        enableEditing={true}
        enableColumnResizing
        enableColumnFilters
        enableColumnOrdering={true}
        enableGrouping={false}
        enableColumnPinning={false}
        enableFacetedValues={false}
        enableStickyHeader
        enableRowActions
        isLoading={loading}
        topToolbarCustomActions={
          <>
            <FlexBox gap="small">
              <Button
                style={{ marginRight: "0.5rem" }}
                onClick={() => setResetByEntityOpenModal(true)}>
                Обнулить все остатки поставщика
              </Button>
              <Button onClick={() => setResetByProductOpenModal(true)}>
                Обнулить все остатки товара
              </Button>
            </FlexBox>
          </>
        }
        onColumnFiltersChange={setColumnFilters}
        pagination={{
          page: page,
          setPage: setPage,
          pageCount: maxPage,
        }}
        rowActionMenuItems={({ row, closeMenu }) => [
          <MenuItem
            onClick={() => {
              setResetOpenModal(true)
              closeMenu()
              setActiveRemnant(row.original?.id)
              setActiveEntity(row.original.entity_id)
              setActiveProduct(row.original.product_id)
            }}
            key="view">
            Обнулить остаток
          </MenuItem>,
          <MenuItem
            onClick={() => {
              setUnPublishOpenModal(true)
              closeMenu()
              setActiveRemnant(row.original?.id)
            }}
            key="view"
            style={{
              display:
                row.original?.activity_period === null ||
                dayjs(row.original?.activity_period?.end_time).isAfter(dayjs()) || row.original?.activity_period?.end_time==null
                  ? "block"
                  : "none",
            }}>
            Снять с продажи
          </MenuItem>,
          <MenuItem
            onClick={() => {
              setPublishOpenModal(true)
              closeMenu()
              setActiveRemnant(row.original?.id)
            }}
            key="view"
            style={{
              display:
                row.original?.activity_period !== null &&
                dayjs(row.original?.activity_period?.end_time).isBefore(dayjs())
                  ? "block"
                  : "none",
            }}>
            Выставить на продажу
          </MenuItem>,
          <MenuItem
            onClick={() => {
              setDateTimePickerOpenModal(true)
              closeMenu()
              setActiveRemnant(row.original?.id)
            }}
            key="view">
            Установить период публикации
          </MenuItem>,
        ]}
      />
    </ThemeProvider>
  )
}

export default EntityTable
