import axios_instance from "../../../../../../requests/config_defaults"
import React, { useState, useEffect } from "react"
import { Button, StepInput } from "@ui5/webcomponents-react"

const StepInputCount = (props) => {
  const [needCount, setNeedCount] = useState(props?.total_product_count);
  const [isEditing, setIsEditing] = useState(false);

  const removeFromProjectOrderManager = async (id) => {
    await axios_instance
      .delete("/orders/order_manager/remove/" + id)
      .then(({ data }) => {
      })
      .catch((error) => {
      });
  };

  const getPVZLocation = async (id, count, isPVZ, chosen_pvz) => {
    await axios_instance
      .get("/aggregator/pvz/location/get/by_id/" + chosen_pvz)
      .then(({ data }) => {
        fcsCalculateOnEdit(id, count, isPVZ, chosen_pvz, data.geo_object)
      })
      .catch((error) => {
      });
  };

  const fcsCalculateOnEdit = async (id, count, isPVZ, chosen_pvz, chosen_pvz_geo_object) => {
    if (count > 0) {
      await axios_instance
        .post(`/fcs/calculate/stock/on_edit/` + id, {
          count: count,
          is_pvz: isPVZ,
          chosen_pvz: chosen_pvz,
          chosen_pvz_geo_object: chosen_pvz_geo_object ? chosen_pvz_geo_object : null
        })
        .then(({ data }) => {
        })
        .catch((error) => {
        });
    }
  };

  const setActiveOrderProject = () => {
    props?.updateProject(props?.project_id)
    props?.setActiveOrderProject(props?.project_id)
    props?.setUserState("orderProject")
    props?.delayTimeout(props?.project_id)
  };

  const countChangeHandler = async () => {
    if (needCount === props.total_product_count) {
      return null
    }
    else if (needCount === 0) {
      props?.showToast();
      setActiveOrderProject();
      props?.managers.map((manager) => {
        removeFromProjectOrderManager(manager.id)
      })
    }
    else if (needCount > props.total_product_count) {
      props?.addOrderItemToProject(props?.project_id, needCount - props?.total_product_count);
      setActiveOrderProject();
    }
    else if (needCount < props.total_product_count) {
      props?.showToast();
      setActiveOrderProject();
      let productCount = props.total_product_count - needCount
      props?.managers.map((manager) => {
        if (productCount === 0) {
          return null
        }
        else if (productCount >= manager.count) {
          removeFromProjectOrderManager(manager.id)
          productCount -= manager.count
        }
        else if (productCount < manager.count) {
          {manager.is_pvz_delivery & manager.chosen_pvz != undefined
            ? getPVZLocation(manager.id, manager.count - productCount,
                manager.is_pvz_delivery, manager.chosen_pvz)
            : fcsCalculateOnEdit(manager.id, manager.count - productCount,
                manager.is_pvz_delivery, manager.chosen_pvz)
          }
          productCount = 0
          }
        }
      )
    };
  }

  return (
    <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
      <StepInput
        onChange={(e) => {
          setNeedCount(e.detail.value);
          setIsEditing(true);
        }}
        style={{ width: 150 }}
        min={0}
        value={needCount}
      />
      {isEditing && (
        <>
          <Button
            disabled={needCount < 0}
            onClick={() => {
              setIsEditing(false)
              props.setCount(needCount)
              props?.setOrderProjectName(props?.project_name)
              countChangeHandler()
            }}
            tooltip="Применить изменения"
            style={{ color: "green" }}
            icon="accept"
          />
          <Button
            onClick={() => {setIsEditing(false); setNeedCount(props?.total_product_count)}}
            tooltip="Отменить изменения"
            style={{ color: "red" }}
            icon="decline" 
          />
        </>
      )}
      <span>
        {needCount != props.total_product_count 
        ? needCount > props.total_product_count
            ? `+ от ${(needCount - props.total_product_count) * props.price} руб.`
            : `- от ${(props.total_product_count - needCount) * props.price} руб.`
        : (null)}
      </span>
    </div>
  )
}

export default StepInputCount
