import {
  Button,
  Icon,
  IllustratedMessage,
  Panel,
} from "@ui5/webcomponents-react";
import React, { useEffect, useState, useRef } from "react";
import "./CommentView.css";
import { CommentCard } from "./CommentCard/CommentCard";
import CommentCardReply from "./CommentReplyCard/CommentReplyCard";
import EditedCommentCard from "./EditedCommentCard/EditedCommentCard";
import "@ui5/webcomponents-icons/dist/comment.js";
import "@ui5/webcomponents-icons/dist/delete.js";
import "@ui5/webcomponents-icons/dist/attachment.js";
import "@ui5/webcomponents-icons/dist/document-text.js";
import "@ui5/webcomponents-icons/dist/arrow-bottom.js";
import "@ui5/webcomponents-icons/dist/navigation-down-arrow.js";
import "@ui5/webcomponents-icons/dist/navigation-up-arrow.js";
import "@ui5/webcomponents-fiori/dist/illustrations/SimpleMail.js";
import axios_instance from "../../../requests/config_defaults";

const FormData = require("form-data");

const CommentView = (props) => {
  const commentRefs = useRef({});
  const newCommentsRef = useRef(null);
  const lastViewedCommentRef = useRef(null);
  const [text, setText] = useState("");
  const [imagePreviews, setImagePreviews] = useState([]);
  const [editedImages, setEditedImages] = useState([]);
  const [files, setFiles] = useState([]);
  const [editedFiles, setEditedFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [viewedComments, setViewedComments] = useState([]);
  const [unviewedComments, setUnviewedComments] = useState([]);
  const [replyComment, setReplyComment] = useState(null);
  const [editComment, setEditComment] = useState(null);
  const [deletedAttachments, setDeletedAttachments] = useState([]);

  const getComments = async () => {
    await axios_instance
      .get(`/comment/list/object/by_id/${props.object_id}`)
      .then(({ data }) => {
        const viewed = data.filter((comment) => comment.is_viewed);
        const unviewed = data.filter((comment) => !comment.is_viewed);

        setViewedComments(viewed);
        setUnviewedComments(unviewed);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const commentCreate = async () => {
    if (
      text?.trim() === "" &&
      imagePreviews?.length === 0 &&
      files?.length === 0
    ) {
      return;
    }
    await axios_instance
      .post(`/comment/create`, {
        text: text,
        object_model: props.object_id,
        related: replyComment?.id,
      })
      .then(({ data }) => {
        {
          imagePreviews?.length > 0 &&
            imagePreviews.forEach((image, index) => {
              attachmentCreate(image.file, data.id);
              if (index === imagePreviews?.length - 1) {
                setImagePreviews([]);
              }
            });
        }
        {
          files?.length > 0 &&
            files.forEach((file, index) => {
              attachmentCreate(file, data.id);
              if (index === files?.length - 1) {
                setFiles([]);
              }
            });
        }
        getComments();
        setText("");
        setReplyComment(null);
        setTimeout(() => {
          scrollToComment(data.id);
        }, 800);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const commentUpdate = async () => {
    if (
      text === editComment?.text &&
      imagePreviews?.length === 0 &&
      files?.length === 0 &&
      deletedAttachments?.length === 0
    ) {
      setEditComment(null);
      return;
    }
    await axios_instance
      .put(`/comment/update/${editComment?.id}`, {
        text: text,
      })
      .then(({ data }) => {
        setEditComment(null);
        {
          deletedAttachments.length > 0 &&
            deletedAttachments.forEach((attachment, index) => {
              attachmentDelete(attachment);
              if (index === deletedAttachments.length - 1) {
                setDeletedAttachments([]);
              }
            });
        }
        {
          imagePreviews?.length > 0 &&
            imagePreviews.forEach((image, index) => {
              attachmentCreate(image.file, data.id);
              if (index === imagePreviews?.length - 1) {
                setImagePreviews([]);
              }
            });
        }
        {
          files?.length > 0 &&
            files.forEach((file, index) => {
              attachmentCreate(file, data.id);
              if (index === files?.length - 1) {
                setFiles([]);
              }
            });
        }
        getComments();
        setText("");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const commentDelete = async () => {
    await axios_instance
      .delete(`/comment/delete/${editComment?.id}`)
      .then(({ data }) => {
        setEditComment(null);
        if (deletedAttachments.length > 0) {
          deletedAttachments.forEach((attachment, index) => {
            attachmentDelete(attachment?.id);
            if (index === deletedAttachments.length - 1) {
              getComments();
            }
          });
        } else getComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const attachmentDelete = async (_id) => {
    await axios_instance
      .delete(`/attachment/delete/${_id}`)
      .then(({ data }) => {
        getComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const attachmentCreate = async (file, comment_id) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("comment", comment_id);
    await axios_instance
      .post(`/comment/attachment/create`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        getComments();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFileAdd = (event) => {
    const file = event.target.files[0];
    const maxSizeInBytes = 1 * 1024 * 1024;

    if (file) {
      if (file.size > maxSizeInBytes) {
        // здесь показывать сообщение что слишком большой файл, макс размер 1 МБ
        console.log("Файл слишком большой. Максимальный размер файла: 1MB");
        return null;
      }
      const fileType = file.type;
      if (fileType.startsWith("image/")) {
        const files = Array.from(event.target.files);
        const imageFiles = files.filter((file) =>
          file.type.startsWith("image/")
        );
        if (imageFiles?.length) {
          const newImagePreviews = imageFiles.map((file) => {
            return new Promise((resolve) => {
              const reader = new FileReader();
              reader.onload = (e) => resolve({ src: e.target.result, file });
              reader.readAsDataURL(file);
            });
          });

          Promise.all(newImagePreviews).then((results) => {
            setImagePreviews((prevImages) => [...prevImages, ...results]);
          });
        }
      } else {
        setFiles((prevFiles) => [...prevFiles, file]);
      }
    }
  };

  function getFileType(fileName) {
    const match = fileName.match(/\.[^.]+$/);
    if (!match) return "unknown";

    const extension = match[0].toLowerCase().slice(1);
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];

    if (imageExtensions.includes(extension)) {
      return "image";
    } else {
      return "other";
    }
  }

  function extractFileName(path) {
    const match = path.match(/[^/]+$/);
    return match ? match[0] : null;
  }

  const handleEditedImageRemove = (fileToRemove) => {
    setEditedImages((prevImages) =>
      prevImages.filter((image) => image?.id !== fileToRemove)
    );
    setDeletedAttachments((prevDeletedAttachments) => [
      ...prevDeletedAttachments,
      fileToRemove,
    ]);
  };

  const handleImageRemove = (fileToRemove) => {
    setImagePreviews((prevImages) =>
      prevImages.filter((image) => image.file !== fileToRemove)
    );
  };

  const handleEditedFileRemove = (fileToRemove) => {
    setEditedFiles((prevFiles) =>
      prevFiles.filter((file) => file?.id !== fileToRemove)
    );
    setDeletedAttachments((prevDeletedAttachments) => [
      ...prevDeletedAttachments,
      fileToRemove,
    ]);
  };

  const handleFileRemove = (fileToRemove) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToRemove));
  };

  const handleKeyDown = (e) => {
    if (
      text?.trim() === "" &&
      imagePreviews?.length === 0 &&
      files?.length === 0
    ) {
      return;
    }
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      if (!editComment) {
        commentCreate();
      } else if (
        editComment &&
        text?.trim() === "" &&
        imagePreviews?.length === 0 &&
        files?.length === 0 &&
        editedFiles?.length === 0 &&
        editedImages?.length === 0
      ) {
        commentDelete();
      } else {
        commentUpdate();
      }
    }
  };

  const scrollToComment = (commentId) => {
    const element = commentRefs.current[commentId];
    if (element) {
      element?.scrollIntoView({ behavior: "auto", block: "center" });
      element.classList.add("highlight");
      setTimeout(() => {
        element.classList.remove("highlight");
      }, 1000);
    }
  };

  useEffect(() => {
    setDeletedAttachments([]);
    setImagePreviews([]);
    setFiles([]);
    const images = [];
    const otherFiles = [];
    if (editComment) {
      setText(editComment?.text);
      editComment?.attachments?.forEach((attachment) => {
        const attachmentType = getFileType(attachment.file.name);
        if (attachmentType === "image") {
          images.push({ id: attachment.id, src: attachment.file.url });
        } else {
          otherFiles.push({
            id: attachment.id,
            name: extractFileName(attachment.file.name),
            size: attachment.file.size,
          });
        }
      });
    } else {
      setText("");
    }
    setEditedImages(images);
    setEditedFiles(otherFiles);
  }, [editComment]);

  useEffect(() => {
    if (newCommentsRef.current && isOpen) {
      newCommentsRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else if (isOpen) {
      lastViewedCommentRef?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [isOpen]);

  useEffect(() => {
    getComments();
  }, []);

  return (
    <div className="comment-view-container">
      <Panel
        onToggle={() => setIsOpen(!isOpen)}
        collapsed={!isOpen}
        accessibleRole="Form"
        headerLevel="H2"
        headerText={`Комментарии ${unviewedComments.length > 0 ? `(${unviewedComments.length})` : " "}`}
      >
        <div className={`comment-view-content`}>
          {viewedComments.length > 0 || unviewedComments.length > 0 ? (
            <>
              {viewedComments.map((comment, index) => (
                <CommentCard
                  key={comment.id}
                  comment={comment}
                  setReplyComment={setReplyComment}
                  getComments={getComments}
                  setEditComment={setEditComment}
                  commentRefs={commentRefs}
                  scrollToComment={scrollToComment}
                  lastViewedCommentRef={
                    index === viewedComments.length - 1
                      ? lastViewedCommentRef
                      : null
                  }
                />
              ))}
              {unviewedComments.length > 0 && (
                <>
                  <div
                    ref={newCommentsRef}
                    className="comment-view-content-unread-comments-text"
                  >
                    Непрочитанные комментарии
                  </div>
                  {unviewedComments.map((comment) => (
                    <CommentCard
                      key={comment.id}
                      comment={comment}
                      setReplyComment={setReplyComment}
                      getComments={getComments}
                      setEditComment={setEditComment}
                      commentRefs={commentRefs}
                      scrollToComment={scrollToComment}
                    />
                  ))}
                </>
              )}
            </>
          ) : (
            <IllustratedMessage
              design="Dialog"
              name="SimpleMail"
              titleText="Пока нет комментариев"
              subtitleText=" "
            />
          )}
        </div>
        <div className={"comment-view-footer"}>
          {replyComment && (
            <div className="reply-comment">
              <CommentCardReply
                comment={replyComment}
                is_reply={true}
                setReplyComment={setReplyComment}
                scrollToComment={scrollToComment}
              />
            </div>
          )}
          {editComment && (
            <div className="reply-comment">
              <EditedCommentCard
                comment={editComment}
                setEditComment={setEditComment}
                commentRefs={commentRefs}
                scrollToComment={scrollToComment}
              />
            </div>
          )}
          <div className={"comment-view-footer-main"}>
            <Button
              style={{ height: "60px", width: "60px" }}
              tooltip="Прикрепить"
              design="Transparent"
              icon="attachment"
              onClick={() => document.getElementById("file-upload").click()}
            />
            <textarea
              className="comment-view-input"
              placeholder="Написать комментарий..."
              onInput={(e) => setText(e.target.value)}
              onChange={(e) => setText(e.target.value)}
              value={text}
              onKeyDown={handleKeyDown}
            />

            <input
              id="file-upload"
              type="file"
              name="image"
              accept="file/*"
              className="image-upload"
              onChange={(e) => handleFileAdd(e)}
            />
            <Button
              onClick={() => {
                editComment &&
                text?.trim() === "" &&
                imagePreviews?.length === 0 &&
                files?.length === 0 &&
                editedFiles?.length === 0 &&
                editedImages?.length === 0
                  ? commentDelete()
                  : editComment
                  ? commentUpdate()
                  : commentCreate();
              }}
              style={{
                height: "60px",
                width: "60px",
                color:
                  editComment &&
                  text?.trim() === "" &&
                  imagePreviews?.length === 0 &&
                  files?.length === 0 &&
                  editedFiles?.length === 0 &&
                  editedImages?.length === 0
                    ? "red"
                    : null,
              }}
              tooltip={
                editComment &&
                text?.trim() === "" &&
                imagePreviews?.length === 0 &&
                files?.length === 0 &&
                editedFiles?.length === 0 &&
                editedImages?.length === 0
                  ? "Удалить это сообщение"
                  : "Отправить"
              }
              design="Transparent"
              icon={
                editComment &&
                text?.trim() === "" &&
                imagePreviews?.length === 0 &&
                files?.length === 0 &&
                editedFiles?.length === 0 &&
                editedImages?.length === 0
                  ? "delete"
                  : "comment"
              }
            />
          </div>
          <div className="image-preview">
            {editComment &&
              editedImages?.length > 0 &&
              editedImages.map((image) => (
                <div className="image-preview-current">
                  <img
                    src={image.src}
                    alt="Preview"
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      borderRadius: "10px",
                    }}
                  />
                  <Button
                    tooltip="Удалить"
                    onClick={() => {
                      handleEditedImageRemove(image.id);
                    }}
                    className="comment-card-view-body-reply-decline-btn"
                    icon="decline"
                  />
                </div>
              ))}
            {imagePreviews?.length > 0 &&
              imagePreviews.map((image) => (
                <div className="image-preview-current">
                  <img
                    src={image.src}
                    alt="Preview"
                    style={{
                      maxWidth: "150px",
                      maxHeight: "150px",
                      borderRadius: "10px",
                    }}
                  />
                  <Button
                    tooltip="Удалить"
                    onClick={() => handleImageRemove(image.file)}
                    className="comment-card-view-body-reply-decline-btn"
                    icon="decline"
                  />
                </div>
              ))}
          </div>
          <div className="files-preview">
            {editComment &&
              editedFiles?.length > 0 &&
              editedFiles.map((file) => (
                <div className="file-preview-current">
                  <Icon
                    name="document-text"
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                  <div className="file-preview-current-file-info">
                    <span style={{ fontWeight: "bold" }}>{file.name}</span>
                    <span>{(file.size / 1024).toFixed(0)} КБ</span>
                  </div>
                  <Button
                    tooltip="Удалить"
                    onClick={() => handleEditedFileRemove(file.id)}
                    className="comment-card-view-body-reply-decline-btn"
                    icon="decline"
                  />
                </div>
              ))}
            {files?.length > 0 &&
              files.map((file) => (
                <div className="file-preview-current">
                  <Icon
                    name="document-text"
                    style={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                  <div className="file-preview-current-file-info">
                    <span style={{ fontWeight: "bold" }}>{file.name}</span>
                    <span>{(file.size / 1024).toFixed(0)} КБ</span>
                  </div>
                  <Button
                    tooltip="Удалить"
                    onClick={() => handleFileRemove(file)}
                    className="comment-card-view-body-reply-decline-btn"
                    icon="decline"
                  />
                </div>
              ))}
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default CommentView;
