import { useState } from "react";
import axios_instance from "../../../../../requests/config_defaults";
import { Bar, Card, Form, FormGroup, FormItem, Input, Button } from "@ui5/webcomponents-react";
import "./AdminManufactureCreateForm.css";

const AdminManufactureCreateForm = (props) => {
  const [manufactureName, setManufactureName] = useState("")
  const [manufactureNameError, setManufactureNameError] = useState(false)

  const manufactureCreate = async () => {
    await axios_instance
      .post(`manufacture/create`, { name: manufactureName })
      .then(({ data }) => {
        setManufactureName("");
        if (props.getManufactures) {
          props.getManufactures();
        }
        props.setManufactureCreateFormOpen(false)
      })
      .catch((error) => {});
  };

  const manufactureCreateHandler = () => {
    if (manufactureName !== "") {
      manufactureCreate();
    } else {
      setManufactureNameError(true);
    }
  };

  return (
    <>
      <div className="AdminManufactureCreateForm-modal-container">
        <Form
          columnsL={1}
          columnsM={1}
          columnsS={1}
          columnsXL={1}
          labelSpanL={1}
          labelSpanM={1}
          labelSpanS={1}
          labelSpanXL={1}
          style={{
            alignItems: "left",
          }}
          titleText="Заполните форму"
        >
          <FormGroup>
            <FormItem>
              <Input
                style={{ width: "100%" }}
                placeholder="Наименование производителя"
                value={manufactureName}
                valueState={
                  manufactureNameError ? "Error" : "None"
                }
                onChange={(e) =>
                  setManufactureName(e.target.value)
                }
                onInput={(e) => {
                  setManufactureName(e.target.value);
                  setManufactureNameError(false);
                }}
                valueStateMessage={<div>Обязательное поле!</div>}
              />
            </FormItem>
            <FormItem alignItems={"left"} label="">
              <Button
                design="Emphasized"
                style={{
                  padding: "0.5rem 1rem",
                  width: "100%",
                }}
                onClick={() => {
                  manufactureCreateHandler();
                }}
              >
                Создать
              </Button>
            </FormItem>
          </FormGroup>
        </Form>
      </div>
    </>
  );
};

export default AdminManufactureCreateForm;
