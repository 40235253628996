import * as THREE from "three";
import { useMemo } from "react";
import { useGLTF } from "@react-three/drei";

const VolumeModel = (props) => {
  const { nodes } = useGLTF("/static/headless.glb");
  console.log(nodes)
  const edges = useMemo(
    () => new THREE.EdgesGeometry(nodes?.Cube?.geometry, 15),
    [nodes]
  );
  return (
    <group dispose={null}>
      <mesh geometry={nodes?.Cube?.geometry}>
        <meshStandardMaterial transparent />
      </mesh>
      <lineSegments geometry={edges} renderOrder={100}>
        <lineBasicMaterial color="black" />
      </lineSegments>
    </group>
  );
};

export { VolumeModel };
