import React, { useState, useEffect } from 'react';
import './WindowComponent.css'
import { Rnd } from "react-rnd";
import Cookie from 'js-cookie'

function disableAllWindows() {
    const windows = Array.from(document.getElementById("main-page").children);
    windows.map((window) => (
        window.style.zIndex = "1"
    ));
}

const setActiveWindow = (componentId) => {
    disableAllWindows()
    var component = document.getElementById(componentId)
    component.style.zIndex = "10"

    var windowMenu = document.querySelector(".window-menu-wrapper")
    if (windowMenu) {
        windowMenu.style.zIndex = "100"
    }
};

const WindowComponent = (props) => {
    const [bounds] = useState(props.bounds || "body")
    const [cookie, setCookie] = useState(JSON.parse(Cookie.get(props.id) || '{}'))
    const [size, setSize] = useState({
        width: cookie.size === undefined ? props.width || 400 : cookie.size.width,
        height: cookie.size === undefined ? props.height || 300 : cookie.size.height
    });
    const [coordinates, setCoordinates] = useState(
        {
            x: cookie.coordinates === undefined ? props.start_x || 0 : cookie.coordinates.x,
            y: cookie.coordinates === undefined ? props.start_y || 0 : cookie.coordinates.y
        }
    )

    const [previousSize, setPreviousSize] = useState({
        width: size.width,
        height: size.height
    });
    const [previousCoordinates, setPreviousCoordinates] = useState(
        {
            x: coordinates.x,
            y: coordinates.y
        }
    )
    const [fullSize, setFullSize] = useState(false)

    const [pinned, setPinned] = useState(false)
    const classes = "window-container " + props.className

    const [headerResizeButtonBackColor, setHeaderResizeButtonBackColor] = useState('#fff')

    // useEffect(() => {
    //     setActiveWindow(props.id)
    // })

    const pinWindow = (width, height, pos_x, pos_y) => {
        setSize({
            width: width,
            height: height
        })
        setCoordinates({
            x: pos_x,
            y: pos_y
        })
        setPinned(true)
    }

    const setSizeOnDragStop = (event, component) => {
        const boundsSize = props.parent.current.getBoundingClientRect()
        const windowMenu = document.querySelector('.window-menu')

        // if (windowMenu) {
        //     const windowMenuCenter_x = windowMenu.getBoundingClientRect().x + windowMenu.getBoundingClientRect().width / 2
        //     const windowMenuCenter_y = windowMenu.getBoundingClientRect().y + windowMenu.getBoundingClientRect().height / 2

        //     // disable WindowMenu
        //     if (event.x >= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 2 || event.x <= windowMenuCenter_x - windowMenu.getBoundingClientRect().width / 2 || event.y <= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2 || event.y >= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2) {
        //         props.showWindow("windowMenu", true)
        //     }

        //     // left
        //     if (event.x <= windowMenuCenter_x - windowMenu.getBoundingClientRect().width / 4 && event.x >= windowMenuCenter_x - windowMenu.getBoundingClientRect().width / 2 && event.y <= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2 && event.y >= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2) {
        //         pinWindow(boundsSize.width / 2, boundsSize.height, 0, 0)
        //         props.showWindow("windowMenu", true)
        //     }
        //     // right
        //     if (event.x <= windowMenuCenter_x && event.x >= windowMenuCenter_x - windowMenu.getBoundingClientRect().width / 4 && event.y <= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2 && event.y >= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2) {
        //         pinWindow(boundsSize.width / 2, boundsSize.height, boundsSize.width / 2, 0)
        //         props.showWindow("windowMenu", true)
        //     }
        //     // left-top
        //     if (event.x >= windowMenuCenter_x && event.x <= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 4 && event.y <= windowMenuCenter_y && event.y >= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2) {
        //         pinWindow(boundsSize.width / 2, boundsSize.height / 2, 0, 0)
        //         props.showWindow("windowMenu", true)
        //     }
        //     // right-top
        //     if (event.x >= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 4 && event.x <= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 2 && event.y <= windowMenuCenter_y && event.y >= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2) {
        //         pinWindow(boundsSize.width / 2, boundsSize.height / 2, boundsSize.width / 2, 0)
        //         props.showWindow("windowMenu", true)
        //     }
        //     // left-bottom
        //     if (event.x >= windowMenuCenter_x && event.x <= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 4 && event.y <= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2 && event.y >= windowMenuCenter_y) {
        //         pinWindow(boundsSize.width / 2, boundsSize.height / 2, 0, boundsSize.height / 2)
        //         props.showWindow("windowMenu", true)
        //     }
        //     // right-bottom
        //     if (event.x >= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 4 && event.x <= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 2 && event.y <= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2 && event.y >= windowMenuCenter_y) {
        //         pinWindow(boundsSize.width / 2, boundsSize.height / 2, boundsSize.width / 2, boundsSize.height / 2)
        //         props.showWindow("windowMenu", true)
        //     }
        // }
        setCoordinates({
            x: component.x,
            y: component.y
        })
    }

    const showWindow = () => {
        const cookieDict = {
            size: size,
            coordinates: coordinates,
            pinned: pinned
        }
        Cookie.set(props.id, JSON.stringify(cookieDict))
        props.showWindow(props.childrenComponent, true);
        setCookie(JSON.parse(Cookie.get(props.id)))
    };

    const handleWindowMouseDown = () => {
        // setActiveWindow(props.id)
    }

    const handleDragStart = (event, component) => {
        setCoordinates({
            x: component.x,
            y: component.y
        })
    }

    const deactivateAllWindowMenuComponents = () => {
        const components = document.querySelectorAll('.window-menu-component')
        for (let i = 0; i < components.length; i++) {
            components[i].classList.remove("component-active")
        }
        /*const groupCancel = document.querySelector('.window-menu-group.group-cancel')
        groupCancel.classList.remove("group-active")
        groupCancel.innerHTML = "Чтобы выйти, потяните вниз &#8595;"*/

        const componentBack = document.querySelector('.window-menu-position-back')
        if (componentBack) {
            for (let i = 0; i < componentBack.classList.length; i++) {
                componentBack.classList.remove(componentBack.classList[i])
            }
            componentBack.classList.add("window-menu-position-back")
        }

    }

    const handleDrag = (event, component) => {
        const boundsSize = props.parent.current.getBoundingClientRect()
        const windowMenu = document.querySelector('.window-menu')
        const componentBack = document.querySelector('.window-menu-position-back')

        // if (windowMenu) {
        //     const windowMenuCenter_x = windowMenu.getBoundingClientRect().x + windowMenu.getBoundingClientRect().width / 2
        //     const windowMenuCenter_y = windowMenu.getBoundingClientRect().y + windowMenu.getBoundingClientRect().height / 2

        //     // disable WindowMenu
        //     if (event.x >= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 2 || event.x <= windowMenuCenter_x - windowMenu.getBoundingClientRect().width / 2 || event.y <= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2 || event.y >= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2) {

        //     }
        //     // left-left
        //     if (event.x <= windowMenuCenter_x - windowMenu.getBoundingClientRect().width / 4 && event.x >= windowMenuCenter_x - windowMenu.getBoundingClientRect().width / 2 && event.y <= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2 && event.y >= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2) {
        //         const rectangleLeft = document.querySelector('.rectangle-left')
        //         rectangleLeft.classList.add("component-active")

        //         componentBack.classList.add("component-rectangle-back")
        //         componentBack.classList.add("rectangle-left-back")
        //     }
        //     // left
        //     if (event.x <= windowMenuCenter_x && event.x >= windowMenuCenter_x - windowMenu.getBoundingClientRect().width / 4 && event.y <= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2 && event.y >= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2) {
        //         const rectangleRight = document.querySelector('.rectangle-right')
        //         rectangleRight.classList.add("component-active")

        //         componentBack.classList.add("component-rectangle-back")
        //         componentBack.classList.add("rectangle-right-back")
        //     }
        //     // right-top
        //     if (event.x >= windowMenuCenter_x && event.x <= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 4 && event.y <= windowMenuCenter_y && event.y >= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2) {
        //         const squareTopLeft = document.querySelector('.square-top-left')
        //         squareTopLeft.classList.add("component-active")

        //         componentBack.classList.add("component-square-back")
        //         componentBack.classList.add("square-top-left-back")
        //     }
        //     // right-right-top
        //     if (event.x >= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 4 && event.x <= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 2 && event.y <= windowMenuCenter_y && event.y >= windowMenuCenter_y - windowMenu.getBoundingClientRect().height / 2) {
        //         const squareTopRight = document.querySelector('.square-top-right')
        //         squareTopRight.classList.add("component-active")

        //         componentBack.classList.add("component-square-back")
        //         componentBack.classList.add("square-top-right-back")
        //     }
        //     // right-bottom
        //     if (event.x >= windowMenuCenter_x && event.x <= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 4 && event.y <= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2 && event.y >= windowMenuCenter_y) {
        //         const squareBottomLeft = document.querySelector('.square-bottom-left')
        //         squareBottomLeft.classList.add("component-active")

        //         componentBack.classList.add("component-square-back")
        //         componentBack.classList.add("square-bottom-left-back")
        //     }
        //     // right-right-bottom
        //     if (event.x >= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 4 && event.x <= windowMenuCenter_x + windowMenu.getBoundingClientRect().width / 2 && event.y <= windowMenuCenter_y + windowMenu.getBoundingClientRect().height / 2 && event.y >= windowMenuCenter_y) {
        //         const squareBottomRight = document.querySelector('.square-bottom-right')
        //         squareBottomRight.classList.add("component-active")

        //         componentBack.classList.add("component-square-back")
        //         componentBack.classList.add("square-bottom-right-back")
        //     }
        //     // deactivateAllWindowMenuComponents()
        // }

        // top - enable WindowMenu
        // if (event.y <= 10 && !windowMenu) {
        //     props.showWindow("windowMenu")
        //     pinWindow(30, 30, 0, 0)
        // }

        // setCoordinates({
        //     x: component.x,
        //     y: component.y
        // })

        // console.log(event, component)
    }

    const handleResizeStop = (ref, position) => {
        setSize({
            width: ref.style.width,
            height: ref.style.height
        });
        setCoordinates({
            x: position.x,
            y: position.y
        })
    }

    return (
        <Rnd
            id={props.id}
            position={coordinates}
            onDrag={(e, component) => {
                handleDrag(e, component)
            }}
            bounds={bounds}
            dragHandleClassName={"window-header"}
            minWidth={props.minWidth || 400}
            minHeight={props.minHeight || 300}
            size={size}
            onResizeStop={(e, direction, ref, delta, position) => {
                handleResizeStop(ref, position)
            }}
            // onMouseDown={handleWindowMouseDown}
            // onDragStart={(e, component) => {
            //     setPinned(false)
            //     handleDragStart(e, component)
            // }}
            onDragStop={(e, component) => {
                setSizeOnDragStop(e, component)
            }}
            className={classes}
            style={{
                borderRadius: 5
            }}>
            < div
                className="window-content">
                <div
                    className="window-header"
                    onDoubleClick={(e, component) => {
                        if (fullSize) {
                            pinWindow(previousSize.width, previousSize.height, previousCoordinates.x, previousCoordinates.y)

                            setFullSize(false)
                        }
                        else {
                            setPreviousSize({
                                width: size.width,
                                height: size.height
                            })
                            setPreviousCoordinates({
                                x: coordinates.x,
                                y: coordinates.y
                            })

                            const boundsSize = props.parent.current.getBoundingClientRect()

                            pinWindow(boundsSize.width, boundsSize.height, 0, 0)

                            setFullSize(true)
                        }
                    }}>
                    <div
                        className="window-header-content">
                        <p className="window-title">
                            <span>
                                {props.title}
                            </span>
                        </p>
                        <div style={{ display: 'flex' }}>
                            <div
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer', marginRight: 5, width: 24, backgroundColor: headerResizeButtonBackColor }}
                                onMouseEnter={() => setHeaderResizeButtonBackColor('#c7c7c7')}
                                onMouseLeave={() => setHeaderResizeButtonBackColor('#fff')}
                                onMouseDown={() => setHeaderResizeButtonBackColor('#d7d7d7')}
                                onMouseUp={() => {
                                    if (fullSize) {
                                        pinWindow(previousSize.width, previousSize.height, previousCoordinates.x, previousCoordinates.y)

                                        setFullSize(false)
                                    }
                                    else {
                                        setPreviousSize({
                                            width: size.width,
                                            height: size.height
                                        })
                                        setPreviousCoordinates({
                                            x: coordinates.x,
                                            y: coordinates.y
                                        })

                                        const boundsSize = props.parent.current.getBoundingClientRect()

                                        pinWindow(boundsSize.width, boundsSize.height, 0, 0)

                                        setFullSize(true)
                                    }

                                    setHeaderResizeButtonBackColor('#fff')
                                }} >
                                <img
                                    src={process.env.PUBLIC_URL + 'static/' + (fullSize ? 'from_full_screen_btn.svg' : 'to_full_screen_btn.svg')}
                                    alt=""
                                    style={{ width: '70%', backgroundColor: headerResizeButtonBackColor }}
                                />
                            </div>
                            <div onClick={() => props.closeHandler()} className="cl-btn-4"></div>
                        </div>
                    </div>
                </div>
                <div className="window-body">
                    {props.children}
                </div>
                <div className="window-footer"></div>
            </div>
        </Rnd>
    )
        ;
};

export { WindowComponent, disableAllWindows, setActiveWindow };