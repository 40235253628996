import React, { useEffect, useState } from 'react'
import { FlexBox, ThemeProvider, Loader, MultiComboBox, MultiComboBoxItem, Form, FormGroup, FormItem, Input, Title, ObjectStatus, Icon, Button, Text, DatePicker, Option, Select } from '@ui5/webcomponents-react';
import { useNavigate } from 'react-router-dom';


const RegStepFinal = (props) => {

    const nav = useNavigate();

    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Информация принята!
                </Title>
                <Text style={{ textAlign: "center", marginTop: 10, marginBottom: 20 }}>
                    {props.titleDescription}
                </Text>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >

                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="Center"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                onClick={() => nav("/login")}
                                design="Emphasized" style={{ width: 100, fontWeight: 'normal' }}>
                                Войти
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export default RegStepFinal