import React, { useEffect, useMemo, useState } from 'react'
import axios_instance from '../../../requests/config_defaults';
import Table from '../../base/table/Table';
import "./CompanyDetailContactsView.css";
import { Button, TabContainer, Tab, MessageStrip, FlexBox, MessageBox } from '@ui5/webcomponents-react';
import { Box, MenuItem } from '@mui/material';
import ModalWindow from '../../base/modal/ModalWindow';
import ContactUpdateForm from '../company-detail-contact-update-form/CompanyDetailContactUpdateForm';
import { createPortal } from 'react-dom';



const fields = {
    last_name: {
        key: "last_name",
        header: "Фамилия",
        disabled: false,
    },
    first_name: {
        key: "first_name",
        header: "Имя",
        disabled: true,
    },
    middle_name: {
        key: "middle_name",
        header: "Отчество",
        disabled: false,
    },
    email: {
        key: "email",
        header: "Почта",
        disabled: false,
    },
    phone_number: {
        key: "phone_number",
        header: "Телефон",
        disabled: false,
    },
    default: {
        key: "default",
        header: "По умолчанию",
        disabled: false,
        Cell: ({ renderedCellValue, cell }) => (
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '1rem',
                    borderRadius: 20,
                    padding: '5px',
                    color: "#fff",
                }}
            >
                <span
                    style={{
                        color: renderedCellValue === true ? "green" : "red"
                    }}
                >
                    {renderedCellValue === true ? "Да" : "Нет"}
                </span>
            </Box>
        ),
    },
};


const CompanyDetailContactsView = (props) => {
    const [dataTable, setDataTable] = useState([]);

    useEffect(() => {
        let data_table = [];

        props.companyContacts.map((contact) => {
            data_table.push({
                id: contact.id,
                last_name: contact.last_name ? contact.last_name : "Не указана",
                first_name: contact.first_name ? contact.first_name : "Не указано",
                middle_name: contact.middle_name ? contact.middle_name : "Не указано",
                email: contact.email ? contact.email : "Не указана",
                phone_number: contact.phone_number ? contact.phone_number : "Не указан",
                default: contact.default ? contact.default : "Не указано",
            })
        })

        setDataTable(data_table);
    }, [props.companyContacts])

    const setContactDefault = async (contact_id, default_status) => {
        await axios_instance.put(
            `/entity/contact/set/default/${contact_id}`,
            {
                default: default_status
            }
        )
            .then((response) => {
                props.setCompanyContactsUpdate(!props.companyContactsUpdate);
                console.log(response)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const deleteContactHandler = async () => {
        await axios_instance.delete(
            `/entity/contact/delete/${contactToDelete}`
        )
            .then((response) => {
                props.setCompanyContactsUpdate(!props.companyContactsUpdate);
                console.log(response)
                setContactToDelete(null);
            })
            .catch((error) => {
                console.log(error)
                setContactToDelete(null);
                props.setCompanyContactsUpdate(!props.companyContactsUpdate);
            })
    }

    const [contactToDelete, setContactToDelete] = useState(null);

    const [openDeleteContactMessageBox, setOpenDeleteContactMessageBox] = useState(false);

    const handleCloseMessageBox = async (event) => {
        if (event.detail.action === "OK") {
            deleteContactHandler();
        }
        setOpenDeleteContactMessageBox(false);
    };

    return (
        <>
            {createPortal(
                <MessageBox
                    onClose={handleCloseMessageBox}
                    type="Confirm"
                    open={openDeleteContactMessageBox}
                >
                    Вы действительно хотите удалить контакт?
                </MessageBox>,
                document.body
            )}

            <Table
                className={"contacts-table"}
                columns={fields}
                data={dataTable}
                enableEditing={false}
                enableColumnResizing
                enableColumnFilters={false}
                enableColumnOrdering={true}
                enableGrouping={false}
                enableColumnPinning={false}
                enableFacetedValues={false}
                enableStickyHeader
                enableRowActions

                isLoading={props.loading}

                topToolbarCustomActions={
                    <div>
                        <Button
                            disabled={!Boolean(props.permissionData?.admin)}
                            design="Emphasized"
                            onClick={() => {
                                props.setCreateLegalEntityContactMode(true);
                            }}
                            className='add-contact-button'
                        >
                            Добавить контакт
                        </Button>
                        <Button
                            onClick={() => {
                                props.setCompanyContactsUpdate(!props.companyContactsUpdate);
                            }}
                            icon='refresh'
                        >
                            Обновить
                        </Button>
                    </div>

                }
                pagination={{
                    page: props.page,
                    setPage: props.setPage,
                    pageCount: props.maxPage
                }}
                rowActionMenuItems={({ closeMenu, row }) => [
                    <MenuItem
                        disabled={!Boolean(props.permissionData?.admin)}
                        key="edit"
                        onClick={() => {
                            props.setContactUpdateMode(true);
                            props.setUpdatedContact(row.original);
                            closeMenu();
                        }}>
                        Редактировать
                    </MenuItem>,
                    <MenuItem
                        disabled={!Boolean(props.permissionData?.admin)}
                        key="delete"
                        onClick={() => {
                            // props.setContactUpdateMode(true);
                            // props.setUpdatedContact(row.original);
                            setOpenDeleteContactMessageBox(true);
                            setContactToDelete(row.original.id);
                            closeMenu();
                        }}>
                        Удалить
                    </MenuItem>,
                ]
                }
                renderDetailPanel={(row, table) => (
                    <>
                        <TabContainer
                            contentBackgroundDesign="Solid"
                            headerBackgroundDesign="Solid"
                            onTabSelect={function _a() { }}
                            tabLayout="Standard"
                            style={{ width: "2000px" }}
                        >
                            <Tab
                                selected
                                text="Общая информация"
                            >
                                <Box
                                    sx={{
                                        alignItems: 'center',
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        left: '30px',
                                        maxWidth: '1000px',
                                        position: 'sticky',
                                        width: '100%',
                                    }}
                                >

                                    <Box sx={{ textAlign: 'start', marginLeft: '50px' }}>
                                        <p><b>Фамилия:</b> {row.row.original.last_name}</p>
                                        <p><b>Имя:</b> {row.row.original.first_name}</p>
                                        <p><b>Отчество:</b> {row.row.original.middle_name}</p>
                                        <p><b>Почта:</b> {row.row.original.email}</p>
                                        <p><b>Телефон:</b> {row.row.original.phone_number}</p>
                                    </Box>
                                </Box>
                            </Tab>
                            <Tab
                                text="По умолчанию"
                            >
                                <FlexBox direction='Column' style={{ width: 700 }}>
                                    <p>По умолчанию:
                                        <span
                                            style={{ color: row.row.original.default === true ? "green" : "red" }}
                                        >
                                            {row.row.original.default === true ? " Да" : " Нет"}
                                        </span>
                                    </p>
                                    <MessageStrip
                                        design="Information"
                                        onClose={function _a() { }}
                                        style={{ width: '100%', marginBottom: 20 }}
                                    >
                                        При установке по умолчанию, предыдущй контакт по умолчанию отменится
                                    </MessageStrip>
                                    <>
                                        {row.row.original.default === true ?
                                            <Button disabled={!Boolean(props.permissionData?.admin)} design='Negative' onClick={e => {
                                                setContactDefault(row.row.original.id, false);
                                            }
                                            }>
                                                Отменить по умолчанию
                                            </Button>
                                            :
                                            <Button disabled={!Boolean(props.permissionData?.admin)} design='Positive' onClick={e => {
                                                setContactDefault(row.row.original.id, true);
                                            }
                                            }>
                                                Установить по умолчанию
                                            </Button>
                                        }
                                    </>
                                    {/* <ManagerPermissionsManagementTable
                                        manager={row.row.original}
                                        activeCompany={props.activeCompany}
                                        setLoading={props.setLoading}
                                    /> */}
                                </FlexBox>

                            </Tab>
                        </TabContainer>
                    </>

                )}
            />
        </>
    )
}

export default CompanyDetailContactsView