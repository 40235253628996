import React, { useState } from "react";
import {
  UploadCollection,
  UploadCollectionItem,
  Icon,
  Text,
  Button,
  IllustratedMessage,
  Select,
  Option,
  ThemeProvider,
  FileUploader,
  Input,
  FlexBox,
  Label,
  StepInput,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-fiori/dist/illustrations/UploadCollection.js";
import axios_instance from "../../../requests/config_defaults";
import "@ui5/webcomponents-icons/dist/excel-attachment.js";
import "@ui5/webcomponents-icons/dist/paper-plane.js";
import "@ui5/webcomponents-icons/dist/upload-to-cloud.js";
import "./UploadFile.css";

const UploadFile = (props) => {
  const [file, setFile] = useState();
  const [fileName, setFilename] = useState("");
  const [startRow, setStartRow] = useState(1);
  const [skippedFooter, setSkippedFooter] = useState(0);

  const setFileUploaded = async () => {
    // console.log(field_list);
    // props.setUploadedFields(field_list);
    // props.setUploaded(true);
    let resp = await props.getExchangeByID(props.exchangeID);
    await props.getFieldsExchangeByID(props.exchangeID, resp);
  };

  const handleClickPushButton = async (e) => {
    const formData = new FormData();
    setFilename("");
    formData.append("file", file);
    formData.append("start_row", startRow);
    formData.append("skipped_footer", skippedFooter);

    await axios_instance
      .post(`/exchange/file/upload/${props.exchangeID}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        setFileUploaded();
        props.setUpdater((prev) => !prev);
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fileImportHandle = (event) => {
    setFile(event.target.files[0]);
    setFilename(event.target.value);
  };

  return (
    <>
      <h1>Выгрузка товарных предложений</h1>
      {/*<input style={{marginBottom: '10px'}} type="file" onChange={(e) => fileImportHandle(e)} />*/}
      <div className={"button-wrap"}>
        <ThemeProvider>
          <FlexBox style={{ alignItems: "center", gap: "1rem" }}>
            <FileUploader
              hideInput
              onChange={(e) => fileImportHandle(e)}
              valueState="None"
              accept=".xlsx,.xls"
            >
              <Button
                icon="upload-to-cloud"
                design="Emphasized"
                style={{ marginRight: "10px" }}
              >
                {fileName === ""
                  ? "Выберите файл (xlsx/xls)"
                  : fileName.replace(/"/g, "")}
              </Button>
            </FileUploader>
            <FlexBox style={{ flexDirection: "column" }}>
              <Label>Количество строк заголовка</Label>
              <StepInput
                placeholder="Введите число"
                onChange={(e) => {
                  console.log(e.target.value);
                  setStartRow(e.target.value);
                }}
                value={startRow}
              ></StepInput>
            </FlexBox>
            <FlexBox style={{ flexDirection: "column" }}>
              <Label>Количество строк футера</Label>
              <StepInput
                placeholder="Введите число"
                onChange={(e) => {
                  console.log(e.target.value);
                  setSkippedFooter(e.target.value);
                }}
                value={skippedFooter}
              ></StepInput>
            </FlexBox>
            <Button
              onClick={handleClickPushButton}
              icon="paper-plane"
              design="Emphasized"
              className="push-button"
            >
              Отправить
            </Button>
          </FlexBox>
        </ThemeProvider>
      </div>
    </>
  );
};

export default UploadFile;
