import React, { useEffect, useState } from 'react'
import './CompanyCreateForm.css'
import '../../product-searcher-with-wizard/user-wizard/UserWizard.css'
import { Bar, Button, Card, CheckBox, Form, FormGroup, FormItem, Input, ObjectStatus, Icon } from '@ui5/webcomponents-react'
import axios_instance from '../../../requests/config_defaults'
import { useAtom } from 'jotai'
import { activeCompanyAtom } from '../../../hoc/GlobalAtoms'


const CompanyCreateForm = (props) => {
    const [nameValueState, setNameValueState] = useState('');

    const [taxNumberValueState, setTaxNumberValueState] = useState('');

    const [activeCompany, setActiveCompany] = useAtom(activeCompanyAtom);

    const [newLegalEntity, setNewLegalEntity] = useState({
        name: "",
        tax_number: ""
    });

    function isNaturalNumber(n) {
        var n1 = Math.abs(n),
            n2 = parseInt(n, 10);
        return !isNaN(n1) && n2 === n1 && n1.toString() === n;
    }

    const handleCreateLegalEntity = async () => {
        await axios_instance.post(
            "/entity/create",
            newLegalEntity
        )
            .then((response) => {
                if (props.setCreateLegalEntityMode && props.setLegalEntitiesUpdate){
                    props.setCreateLegalEntityMode(false);
                    props.setLegalEntitiesUpdate(!props.legalEntitiesUpdate);
                }
                else {
                    setActiveCompany(response.data.id);
                }
                console.log(response);
            })
            .catch((error) => {
                if (error.response.data?.status){
                    setTaxNumberValueState(error.response.data?.status);
                }
                else{
                    setTaxNumberValueState("Не удалось создать компанию");
                }
                console.log(error);
            })
    }

    return (
        <>
            {props.searchPage !== "searchPage" ? (
                <div className='MyOfferCreateForm-overlay' id='MyOfferCreateForm-overlay'></div>
            ) : (null)}
            <Card
                header={ 
                    props.searchPage !== "searchPage" ? (
                        <Bar
                            endContent={
                                <div
                                    onClick={() => props.setCreateLegalEntityMode(false)}
                                    className="MyOfferCreateForm-modal-close-btn"></div>
                            }
                            startContent={<span>Создание юр. лица</span>}
                            style={{
                                height: "50px"
                            }}
                        ></Bar>
                    ) : null
                }
                style={{
                    height: "65vh"
                }}
                className={props.searchPage !== "searchPage" ? 'MyOfferCreateForm-form-layout MyOfferCreateForm-form-layout-open' : 'UserWizard'}
            >
                <div className="MyOfferCreateForm-modal-container">
                    <Form
                        columnsL={1}
                        columnsM={1}
                        columnsS={1}
                        columnsXL={1}
                        labelSpanL={1}
                        labelSpanM={1}
                        labelSpanS={1}
                        labelSpanXL={1}
                        style={{
                            alignItems: 'left',

                        }}
                        // className='MyOfferCreateForm-form'
                        titleText={props.searchPage !== "searchPage" ? "Заполните форму" : "Создание юр. лица"}
                    >
                        <FormGroup>
                            <FormItem label="Наименование">
                                <Input
                                    valueState={nameValueState ? "Error" : ""}
                                    onInput={
                                        (e) => {
                                            setNameValueState("");
                                            setNewLegalEntity((prev) => ({
                                                ...prev,
                                                name: e.target.value
                                            }))
                                        }
                                    }
                                    style={{ width: '100%' }}
                                />
                            </FormItem>
                            {nameValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {nameValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                            <FormItem label="ИНН">
                                <Input
                                    valueState={taxNumberValueState ? "Error" : ""}
                                    onInput={
                                        (e) => {
                                            setTaxNumberValueState("");
                                            setNewLegalEntity((prev) => ({
                                                ...prev,
                                                tax_number: e.target.value
                                            }))
                                        }
                                    }
                                    style={{ width: '100%' }}
                                    maxlength={10}
                                />
                            </FormItem>
                            {taxNumberValueState &&
                                <FormItem>
                                    <ObjectStatus
                                        onClick={function Ta() { }}
                                        state="Error"
                                        icon={<Icon name="alert" />}
                                    >
                                        {taxNumberValueState}
                                    </ObjectStatus>
                                </FormItem>
                            }
                        </FormGroup>
                        <FormItem alignItems={"left"} label="">
                            <Button
                                design='Emphasized'
                                style={{
                                    padding: "0.5rem 1rem",
                                    width: "100%"
                                }}
                                onClick={() => {
                                    if (newLegalEntity.name === ""){
                                        setNameValueState("Обязательное поле");
                                    }
                                    else if (newLegalEntity.tax_number.length < 10){
                                        setTaxNumberValueState("ИНН должен содержать не менее 10 цифр");
                                    }
                                    else if (!isNaturalNumber(newLegalEntity.tax_number)){
                                        setTaxNumberValueState("Неверный формат ИНН");
                                    }
                                    else{
                                        handleCreateLegalEntity();
                                    }
                                }}
                            >
                                Создать
                            </Button>
                        </FormItem>

                    </Form>
                </div>
            </Card>

        </>
    )
}

export default CompanyCreateForm