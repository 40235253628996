import "./PlusMainPage.css";
import PlusElemMainPage from './PlusElemMainPage/PlusElemMainPage';

const PlusMainPage = () => {
  return (
    <>
      <div className="main-page-body-fourth-container-plus">
        <div className="main-page-body-fourth-container-plus-elem">
          <PlusElemMainPage
            logo_name="fob-watch"
            text_header="Выгодная доставка"
            text_body="Работаем с любыми транспортными компаниями и курьерскими сервисами"
          />
          <PlusElemMainPage
            logo_name="arrow-down"
            text_header="Высокий уровень наличия"
            text_body="до 95% запчастей КАМАЗ, которые ищут наши партнёры, есть на exzamarket.ru.
              Оставьте заявку, и мы предложим вам остальные запчасти, либо более низкую цену"
          />
        </div>
        <div className="main-page-body-fourth-container-plus-elem-center">
          <PlusElemMainPage
            logo_name="crm-service-manager"
            text_header="Цифровые решения на службе Вашего бизнеса"
            center="true"
          />
        </div>
        <div className="main-page-body-fourth-container-plus-elem">
          <PlusElemMainPage
            logo_name="WarehouseIcon"
            text_header="Всегда на складе"
            text_body="40 тыс уникальных артикулов КАМАЗ 100 тыс предложений поставщиков"
          />
          <PlusElemMainPage
            logo_name="select-appointments"
            text_header="Запчасть ко времени ремонта"
            text_body="Интеграция с системами мониторинга, онлайн-записи и сервисными контрактами обеспечит минимальные простои в сервисе"
          />
        </div>
      </div>
    </>
  )
}
export default PlusMainPage
