import React, { useEffect, useState } from 'react'
import { FlexBox, ThemeProvider, Loader, MultiComboBox, MultiComboBoxItem, Form, FormGroup, FormItem, Input, Title, ObjectStatus, Icon, Button, Text, DatePicker, Option, Select } from '@ui5/webcomponents-react';


const RegStep2 = (props) => {
    const [nameValueState, setNameValueState] = useState('');

    const [surnameValueState, setSurnameValueState] = useState('');

    const [patronymicValueState, setPatronymicValueState] = useState('');
    

    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Создать аккаунт EXZA
                </Title>
                <Text>
                    Введите свое имя
                </Text>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >
                <FormGroup>
                    <FormItem>
                        <Input
                            name="name"
                            valueState={nameValueState ? "Error" : ""}
                            required
                            placeholder='Имя'
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0
                            }}
                            onChange={(e) => {
                                setNameValueState("");
                                props.setRegistrationData({
                                    ...props.registrationData,
                                    first_name: e.target.typedInValue.trim()
                                });
                            }}
                        />
                    </FormItem>
                    {nameValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {nameValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem>
                        <Input
                            name="surname"
                            valueState={surnameValueState ? "Error" : ""}
                            required
                            placeholder='Фамилия'
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0
                            }}
                            onChange={(e) => {
                                setSurnameValueState("");
                                props.setRegistrationData({
                                    ...props.registrationData,
                                    last_name: e.target.typedInValue.trim()
                                });
                            }}
                        />
                    </FormItem>
                    {surnameValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {surnameValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem>
                        <Input
                            name="patronymic"
                            placeholder='Отчество (не обязательно)'
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0
                            }}
                            onChange={(e) => {
                                props.setRegistrationData({
                                    ...props.registrationData,
                                    middle_name: e.target.typedInValue.trim()
                                });
                            }}
                        />
                    </FormItem>
                    {patronymicValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                            </ObjectStatus>
                        </FormItem>
                    }
                </FormGroup>
                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="End"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                //onClick={() => { setIsLoading(true); performIdentification() }}
                                onClick={() => {
                                    if (props.registrationData.first_name === "") {
                                        setNameValueState("Введите имя");
                                    }
                                    if (props.registrationData.last_name === "") {
                                        setSurnameValueState("Введите фамилию");
                                    }
                                    if (props.registrationData.first_name !== "" && props.registrationData.last_name !== "") {
                                        props.setStep(3);
                                    }
                                }}
                                design="Emphasized" style={{ fontWeight: 'normal', width: 100 }}>
                                Далее
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export default RegStep2