import React, {useEffect, useRef, useState} from "react";
import "./ProductSearchHeader.css";
import axios_instance from "../../../requests/config_defaults";
import UserCompanyController from "../../base/user-company-controller/UserCompanyController";
import {
    activeCompanyAtom,
    activeCompanyAddressAtom,
    emailAtom,
    fullNameAtom,
    isAdminAtom,
    isImpersonatedAtom,
    marketIconsAtom,
} from "../../../hoc/GlobalAtoms";
import {useAtom} from "jotai";
import {
    geoObjectAtom,
    userStateAtom,
} from "../user-wizard/UserWizardAtoms";
import UserProfileActionSheet from "../../base/user-profile-action-sheet/UserProfileActionSheet";
import useGeoSearcher from "../../../hooks/GeoSearcher";
import SideBar from "../../container/side-bar/SideBar";
import {
    Button,
    FlexBox,
    Icon,
    Input,
    Option,
    Select,
    SuggestionItem,
} from "@ui5/webcomponents-react";
import "@ui5/webcomponents-icons/dist/product.js";
import {addressesRefresh} from "../../companies-view/company-detail-view/CompanyDetailView";
import {useNavigate, useLocation} from "react-router-dom";
import classNames from "classnames";
import "@ui5/webcomponents-icons/dist/employee-rejections.js";
import "@ui5/webcomponents-icons/dist/visits.js";

const CompanyAddressSelect = (props) => {
    const [activeCompanyAddress, setActiveCompanyAddress] = useAtom(activeCompanyAddressAtom);
    const [activeCompany] = useAtom(activeCompanyAtom);
    const [userState, setUserState] = useAtom(userStateAtom);
    const [addresses, setAddresses] = useState([]);
    const [refresher] = useAtom(addressesRefresh);
    const [icons, setIcons] = useAtom(marketIconsAtom);

    const getCompanyAddresses = async () => {
        if (!activeCompany.id) {
            return;
        }
        await axios_instance
            .get(`/myCompany/addresses/${activeCompany.id}`)
            .then(({data}) => {
                setAddresses(data);
                if (data.length === 0) {
                    setUserState("addressCreate");
                } else setUserState("completed");
            })
            .catch((error) => {
            });
    };

    useEffect(() => {
        getCompanyAddresses();
    }, [refresher]);

    useEffect(() => {
        getCompanyAddresses();
        props.setGeoObject(activeCompanyAddress?.geo_object?.id)
    }, [activeCompany]);

    return (
        <div>
            <FlexBox
                style={{
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                }}
            >
                <Select
                    onChange={(e) => {
                        let index = e.detail.selectedOption.dataset.index;
                        props.setGeoObject(addresses[index]?.geo_object?.id);
                        setActiveCompanyAddress(addresses[index]);
                        setUserState("orderCard");
                    }}
                    value={activeCompanyAddress?.id}
                >
                    <Option disabled="disabled">Выберите склад</Option>
                    {addresses &&
                        addresses.map((address) => (
                            <Option
                                data-index={addresses.indexOf(address)}
                                value={address?.id}
                                selected={address?.id === activeCompanyAddress?.id}
                            >
                                {address.name}
                            </Option>
                        ))}
                    ;
                </Select>
                <Button
                    icon="add"
                    onClick={() => {
                        setUserState("addressCreate")
                        props.setIsOpen(true)
                    }}
                />
            </FlexBox>
        </div>
    );
};

const ProductSearchHeader = (
    {
        searcher,
        geo_object,
        query,
        setQuery,
        activeProduct,
        setActiveProduct,
        setIsAuthenticated,
        setIsOpen,
        suggestions,
        searchError,
        searchErrorMessage,
        setSearchErrorMessage,
        setSearchError
    }
) => {
    const [showSuggestions, setShowSuggestions] = useState(false);
    const suggestionsRef = useRef();
    const [activeCompany, setActiveCompany] = useAtom(activeCompanyAtom);
    const [userState, setUserState] = useAtom(userStateAtom);
    const [email, setEmail] = useAtom(emailAtom);
    const [geoObject, setGeoObject] = useAtom(geoObjectAtom);
    // const {searcher, geo_object, setValue} = useGeoSearcher(
    //     {
    //         width: "100%",
    //         gap: "10px",
    //     },
    //     ""
    // );

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
    const [fullName, setFullName] = useAtom(fullNameAtom);
    const [isImpersonated, setIsImpersonated] = useAtom(isImpersonatedAtom);
    const location = useLocation();
    const nav = useNavigate();
    const [icons, setIcons] = useAtom(marketIconsAtom);

    useEffect(() => {
        setGeoObject(geo_object?.id);
    }, [geo_object]);

    const getMe = async () => {
        await axios_instance
            .get("/getMe")
            .then((response) => {
                console.log(response.data);
                setEmail(response.data.user.email);
                setFullName(
                    `${response.data.user.last_name} ${response.data.user.first_name}`
                );
                setIsAdmin(response.data.is_admin || response.data.user.is_superuser);
                console.log(response.data);
            })
            .catch((error) => {
            });
    };

    const getActiveCompany = async () => {
        await axios_instance
            .get("/myCompany")
            .then((response) => {
                setActiveCompany(response.data);
            })
            .catch((error) => {
                setActiveCompany({});
            });
    };

    const stopImpersonation = async () => {
        await axios_instance
            .post("/users/impersonate/stop")
            .then((response) => {
                setIsImpersonated(false);
                getMe();
                getActiveCompany();
                nav("/admin-users");
            })
            .catch((error) => {
                setIsImpersonated(true);
            });
    };
    const onSuggestionItemSelect = (e) => {
        const selectedItemId = e.detail.item.attributes.id.value
        setActiveProduct(selectedItemId);
        nav(`/search?pid=${encodeURIComponent(selectedItemId)}`)
    }

    return (
        <>
            <div className="ProductSearchHeader-content">
                <div className="ProductSearchHeader__logo">
                    <a href="/" className="ProductSearchHeader__logo__img_a">
                        <img
                            alt="EXZA"
                            className="ProductSearchHeader__logo__img"
                            // src={"/static/EXZA_LOGO_on.svg"}
                            src={icons?.icon_mini}
                        />
                    </a>
                </div>
                <div className="ProductSearchHeader-container">
                    {activeProduct ? <Input
                        name="searcher"
                        value={query}
                        onInput={(e) => {
                            setQuery(e.target.value);
                            setSearchError(false);
                            setSearchErrorMessage("");
                        }}
                        icon={<Icon name="search"/>}
                        showClearIcon
                        placeholder="Введите артикул"
                        style={{width: 250}}
                        showSuggestions
                        valueState={searchError ? "Error" : "None"}
                        valueStateMessage={searchError ? <div>{searchErrorMessage}</div> : null}
                        onSuggestionItemSelect={onSuggestionItemSelect}
                    >
                        {suggestions &&
                            suggestions.map((suggestion) => {
                                return (
                                    <SuggestionItem
                                        id={suggestion?.id}
                                        // description={suggestion?.tax_number}
                                        icon="product"
                                        text={`Артикул: ${suggestion?.code} | Наименование: ${suggestion?.name ? suggestion?.name : "Не указано"
                                        } | Бренд: ${
                                            suggestion?.brand ? suggestion?.brand : "Не указан"
                                        } | Производитель: ${
                                            suggestion?.manufacture
                                                ? suggestion?.manufacture
                                                : "Не указан"
                                        }`}
                                    />
                                );
                            })}
                    </Input> : <div></div>}

                    <div className="ProductSearchHeader-controllers-side">
                        {userState == "not_authenticated" || userState == "registration" ? (
                            <>
                                {searcher}
                                <Button
                                    title="Войти"
                                    icon="visits"
                                    onClick={() => {
                                        nav("/login");
                                    }}
                                />
                            </>
                        ) : (
                            <CompanyAddressSelect setGeoObject={setGeoObject} setIsOpen={setIsOpen}/>
                        )}
                        {userState !== "not_authenticated" &&
                            userState !== "registration" && (
                                <button
                                    onClick={() => {
                                        console?.log(location);
                                        nav(`/legal-entities?company=${activeCompany?.id}`, {
                                            state: {prevPath: location.pathname + location.search},
                                        });
                                    }}
                                    className={classNames(
                                        "user-company-control-btn",
                                        !activeCompany?.name && "fuser-company-control-btn-empty"
                                    )}
                                >
                                    {activeCompany?.name || "Выбрать компанию"}
                                </button>
                            )}
                        {isImpersonated && (
                            <Button
                                icon="employee-rejections"
                                title="Выйти из режима"
                                className="impersonate-button"
                                onClick={() => {
                                    stopImpersonation();
                                    nav("/");
                                }}
                            ></Button>
                        )}
                        {userState !== "not_authenticated" &&
                            userState !== "registration" && (
                                <UserProfileActionSheet
                                    email={email}
                                    onLogout={() => {
                                        setUserState("not_authenticated");
                                        setIsAuthenticated(false);
                                    }}
                                />
                            )}
                        {userState !== "not_authenticated" &&
                            userState !== "registration" && (
                                <Button
                                    className="main-header-controller"
                                    icon="menu2"
                                    onClick={() => setMenuIsOpen(true)}
                                />
                            )}
                        {userState !== "not_authenticated" &&
                            userState !== "registration" && (
                                <SideBar
                                    menuIsOpen={menuIsOpen}
                                    setMenuIsOpen={setMenuIsOpen}
                                    email={email}
                                    fullName={fullName}
                                    isAdmin={isAdmin}
                                />
                            )}
                    </div>
                </div>
            </div>
        </>
    );
};

export {ProductSearchHeader, CompanyAddressSelect}