import React, { useState, useEffect } from 'react';
import './UserProfileActionSheet.css';
import { Button } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';
import { useNavigate } from 'react-router-dom';
import { checkIsAuthenticated } from '../../../requests/authorization';
import { activeCompanyAtom, emailAtom, fullNameAtom, isAdminAtom, activeCompanyAddressAtom } from '../../../hoc/GlobalAtoms';
import { useAtom } from 'jotai';
import { geoObjectAtom } from '../../product-searcher-with-wizard/user-wizard/UserWizardAtoms';

const UserProfileActionSheetPopup = (props) => {
    const nav = useNavigate();

    const [isAuth, setIsAuth] = useState(false);

    const [email, setEmail] = useAtom(emailAtom);
    const [fullName, setFullName] = useAtom(fullNameAtom);
    const [isAdmin, setIsAdmin] = useAtom(isAdminAtom);
    const [activeCompany, setActiveCompany] = useAtom(activeCompanyAtom);
    const [activeCompanyAddress, setActiveCompanyAddress] = useAtom(activeCompanyAddressAtom);

    const [geoObject, setGeoObject] = useAtom(geoObjectAtom);

    const isAuthenticated = async () => {
        let status = await checkIsAuthenticated();
        setIsAuth(status);
    }

    useEffect(() => {
        isAuthenticated();
    }, []);

    const handleClickLogOutButton = async (event) => {
        await axios_instance
            .get('/logout')
            .then((response) => {
                setEmail('');
                setFullName('');
                setIsAdmin(false);
                setActiveCompany({});
                setActiveCompanyAddress({});
                props.onLogout();
                setGeoObject();
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div
            // onMouseLeave={() => props.setActionIsOpen(false)}
            className="user-profile-action-sheet-popup"
        >
            <div className="user-profile-action-sheet-popup-content">
                <img
                    onClick={() => props.setActionIsOpen(false)}
                    src="static/multiply.svg"
                    alt=""
                    className="user-profile-action-sheet-popup-close"
                />

                <div className="user-profile-action-sheet-popup-container">
                    <a href="/account">
                        <img
                            src="https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg"
                            alt=""
                            className="user-profile-action-sheet-popup-img"
                        />
                    </a>
                    <p className='user-profile-action-sheet-popup-email'>{email}</p>
                    {isAuth && 
                        <a href="/account" className='link-to-profile'>Профиль</a>
                    }
                    <Button design="Emphasized" onClick={() => {handleClickLogOutButton()}} style={{ width: '100%', borderRadius: 10 }}>
                        {isAuth ? "Выйти" : "Войти"}
                    </Button>
                    <a href="/user-agreement" className="user-profile-action-sheet-popup-license">Пользовательское соглашение</a>
                </div>
            </div>
        </div>
    )
}

const UserProfileActionSheet = (props) => {
    const [actionIsOpen, setActionIsOpen] = useState(false);

    return (
        <div className='user-profile-action-sheet'>
            <img
                onClick={() => { setActionIsOpen(!actionIsOpen) }}
                className='user-profile-action-sheet-control'
                src="https://androidinsider.ru/wp-content/uploads/2021/02/google_contacts.jpg"
                alt=""
            />


            {/* {actionIsOpen === true && <div 
            onClick={() => setActionIsOpen(false)}
            id='user-profile-action-sheet-overlay'></div>} */}
            {actionIsOpen === true && <UserProfileActionSheetPopup
                setActionIsOpen={setActionIsOpen}
                email={props.email}
                onLogout={props.onLogout}
            />}
        </div>
    )
}

export default UserProfileActionSheet