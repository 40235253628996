import {
    Button,
    FlexBox,
    Icon,
    Input,
    Label,
    MessageStrip,
    Option,
    Select,
    SplitterElement,
    SplitterLayout,
    StepInput,
    ThemeProvider, Toast,
} from "@ui5/webcomponents-react";
import { useAtom } from "jotai";
import React, {useEffect, useRef, useState} from "react";
import { activeCompanyAtom } from "../../hoc/GlobalAtoms";
import axios_instance from "../../requests/config_defaults";
import AdminManufactureCreateForm from "../admin-panel/products-admin-panel/admin-catalog-view/admin-manufacture-create-form/AdminManufactureCreateForm";
import AdminBrandCreateForm from "../admin-panel/products-admin-panel/admin-catalog-view/admin-brand-create-form/AdminBrandCreateForm";
import Table from "../base/table/Table";
import ModalWindow from "../base/modal/ModalWindow";
import PermissionManager from "../base/permission_manager/PermissionManager";
import { HOST_IP_ADDRESS } from "../../requests/constants";


const fields = {
  user_field: {
    key: "user_field",
    header: "Пользовательское поле",
    editable: false,
  },
  icon: {
    key: "icon",
    editable: false,
    size: 80,
  },
  system_field: {
    key: "system_field",
    header: "Cистемное поле",
    editable: false,
  },
};

const EditFieldConfig = ({ id, user_variant, getConfigByID }) => {
  const [editedField, setEditedField] = useState({
    id: id,
    user_variant: user_variant,
  });
  const editFieldConfig = async () => {
    console.log(editedField);
    axios_instance
      .put(`/field/config/update/${editedField?.id}`, {
        user_variant: editedField?.user_variant,
      })
      .then(({ data }) => {
        getConfigByID();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const changeConfigHandler = (e) => {
    setEditedField({ ...editedField, user_variant: e.target.value });
    console.log(editedField);
  };

  return (
    <div
      className="user-field-edit"
      style={{ display: "flex", gap: "1rem", alignItems: "center" }}
    >
      <Input
        id={id}
        onInput={(e) => {
          changeConfigHandler(e);
        }}
        value={user_variant}
      />
      <Button onClick={() => editFieldConfig()} icon="accept"></Button>
    </div>
  );
};

const ExchangeConfigView = ({ id }) => {
  const [config, setConfig] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const getConfigByID = () => {
    setLoading(true);
    axios_instance
      .get(`/exchange/config/get/by_id/${id}`)
      .then(({ data }) => {
        const row_items = [];
        console.log(data);
        data.exchange_fields.map((field) => {
          row_items.push({
            server_field_id: field?.id,
            user_field: (
              <EditFieldConfig
                id={field?.id}
                user_variant={field?.user_variant}
                getConfigByID={getConfigByID}
              />
            ),
            icon: <Icon name="chain-link" />,
            system_field: field?.field?.name,
          });
        });
        setData(row_items);
        setConfig(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getConfigByID();
    // console.log(ws);
  }, [id]);

  return (
    <div
      className="config-content"
      style={{ width: "100%", height: "100%", padding: "0 1rem" }}
    >
      <Label
        style={{
          fontSize: "1.5rem",
        }}
      >{`${config?.creator} ${config?.create_datetime_stamp}`}</Label>
      <Table
        className={"exchange-table"}
        columns={fields}
        data={data}
        defaultColumn={{
          maxSize: 400,
          minSize: 80,
          size: 150, //default size is usually 180
        }}
        editDisplayMode="cell"
        enableEditing={false}
        enableColumnResizing
        enableColumnFilters
        enableColumnOrdering={true}
        enableGrouping={false}
        enableColumnPinning={true}
        enableFacetedValues={true}
        isLoading={loading}
        // enableRowActions={true}
        enableStickyFooter={true}
        enableStickyHeader={true}
      // renderDetailPanel={(row) => (
      //   <Box
      //     style={{
      //       height: "100px",
      //     }}
      //   ></Box>
      // )}
      // isFullScreen={isFullScreen}
      // pagination={{
      //   page: page,
      //   setPage: setPage,
      //   pageCount: maxPage,
      // }}
      // rowActionMenuItems={({ row, closeMenu }) => [
      //   <MenuItem
      //     key="view"
      //     onClick={() => {
      //       setIsFullScreen(false);
      //     }}
      //   >
      //     Просмотр
      //   </MenuItem>,
      // ]}
      />
    </div>
  );
};

const EmailExchangeView = () => {
  const formDataEmpty = {
    email: "",
    default_brand: null,
    default_warehouse: null,
    exchange_config: null,
    default_manufacture: null,
    download_url: null,
    start_row: 1,
    skipped_footer: 0,
  };
  const [activeCompany] = useAtom(activeCompanyAtom);
  const [defaultConfig, setDefaultConfig] = useState(null);
  const [configs, setConfigs] = useState(null);
  const [config, setConfig] = useState(null);
  const [formData, setFormData] = useState({});
  const [data, setData] = useState({});
  const [dataChanged, setDataChanged] = useState(false);
  const [permissionData, setPermissionData] = useState({});
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const toast = useRef(null);

  const [warehouses, setWarehouses] = useState(null);

  const [brands, setBrands] = useState([]);
  const [brandCreateFormOpen, setBrandCreateFormOpen] = useState(false);
  const [changedBrand, setChangedBrand] = useState(false);

  const [manufactures, setManufactures] = useState([]);
  const [manufactureCreateFormOpen, setManufactureCreateFormOpen] =
    useState(false);

  const [changeOrCreateError, setChangeOrCreateError] = useState(false);
  const [succesfullChangeOrCreate, setSuccesfullChangeOrCreate] =
    useState(false);

  const [errorText, setErrorText] = useState("");

  useEffect(() => {
    console.log(HOST_IP_ADDRESS);
  }, []);

  const getConfig = async () => {
    await axios_instance
      .get(`/exchange/default/config/get`)
      .then(({ data }) => {
        setDefaultConfig(data);
        setFormData((prev) => ({
          email: data?.market_entity?.exchange_email || "",
          default_brand: data?.default_brand?.id || null,
          default_warehouse: data?.default_warehouse?.id || null,
          exchange_config: data?.exchange_config?.id || null,
          default_manufacture: data?.default_manufacture?.id || null,
          download_url: data?.download_url || null,
          start_row: data?.start_row || 1,
          skipped_footer: data?.skipped_footer || 0,
        }));
        setData((prev) => ({
          email: data?.market_entity?.exchange_email || "",
          default_brand: data?.default_brand?.id || null,
          default_warehouse: data?.default_warehouse?.id || null,
          exchange_config: data?.exchange_config?.id || null,
          default_manufacture: data?.default_manufacture?.id || null,
          download_url: data?.download_url || null,
          start_row: data?.start_row || 1,
          skipped_footer: data?.skipped_footer || 0,
        }));
        // setConfigData(data?.exchange_config);
        // setConfig(data?.exchange_config?.id);
        // setWarehouseData(data?.default_warehouse);
        // setWarehouse(data?.default_warehouse?.id);
        // setBrandData(data?.default_brand);
        // setActiveBrand(data?.default_brand?.id);
        // setManufactureData(data?.default_manufacture);
        // setActiveManufacture(data?.default_manufacture?.id);
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const isChanged = (value, key) =>
    data["email"] !== formData["email"] ||
    data["default_brand"] !== formData["default_brand"] ||
    data["default_warehouse"] !== formData["default_warehouse"] ||
    data["exchange_config"] !== formData["exchange_config"] ||
    data["default_manufacture"] !== formData["default_manufacture"] ||
    data["download_url"] !== formData["download_url"] ||
    data["start_row"] !== formData["start_row"] ||
    data["skipped_footer"] !== formData["skipped_footer"];

  const getWarehouses = async () => {
    await axios_instance
      .get(`/myAddresses`)
      .then(({ data }) => {
        setWarehouses(data);
      })
      .catch((error) => { });
  };

  const getBrands = async () => {
    await axios_instance
      .get(`brand_list`)
      .then(({ data }) => {
        setBrands(data);
      })
      .catch((error) => { });
  };

  const getConfigs = async () => {
    await axios_instance
      .get(`/myConfigs`)
      .then(({ data }) => {
        console.log(data);
        setConfigs(data.results);
      })
      .catch((error) => { });
  };

  const getManufactures = async () => {
    await axios_instance
      .get(`manufacture_list`)
      .then(({ data }) => {
        setManufactures(data);
      })
      .catch((error) => { });
  };

  const createDefaultConfig = async () => {
    console.log(formData);
    await axios_instance
      .post(`/exchange/default/config/create`, formData)
      .then(({ data }) => {
        getConfig();
        setChangeOrCreateError(false);
        setSuccesfullChangeOrCreate(true);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setErrorText("Данная почта уже привязана!");
        } else if (error.response.status === 422) {
          setErrorText("Не заполнено обязательное поле!");
        } else {
          setErrorText("Технические неполадки, повторите попытку позже");
        }
        setChangeOrCreateError(true);
        setSuccesfullChangeOrCreate(false);
        console.log(error);
      });
  };

  const editDefaultConfig = async () => {
    await axios_instance
      .patch(`/exchange/default/config/update/${defaultConfig?.id}`, formData)
      .then(({ data }) => {
        getConfig();
        setChangeOrCreateError(false);
        setSuccesfullChangeOrCreate(true);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setErrorText("Данная почта уже привязана!");
        } else if (error.response.status === 422) {
          setErrorText("Не заполнено обязательное поле!");
        }
        setChangeOrCreateError(true);
        setSuccesfullChangeOrCreate(false);
        console.log(error);
      });
  };

  useEffect(() => {
    setDefaultConfig(null);
    setFormData(formDataEmpty);
    setConfigs(null);
    setConfig(null);
    setWarehouses(null);
    setBrands(null);
    setBrandCreateFormOpen(false);
    setManufactures(null);
    setManufactureCreateFormOpen(false);
    getConfig();
    getBrands();
    getManufactures();
    getWarehouses();
    getConfigs();
    setChangeOrCreateError(false);
    setSuccesfullChangeOrCreate(false);
  }, [activeCompany]);

    const handleCopy = () => {
        if (formData.download_url) {
            navigator.clipboard.writeText(formData.download_url)
        }
    };
    const showToast = () => {
        toast.current.show();
    };

  return (
    <ThemeProvider>
      <SplitterLayout
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <SplitterElement>
          <div className="default-config-content">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
              }}
              className="default-config-create-content"
            >
              <Label
                style={{
                  fontSize: "1.2rem",
                  alignItems: "center",
                }}
              >
                {!defaultConfig
                  ? "Создать конфигурацию по умолчанию"
                  : "Редактирование конфигурации по умолчанию"}
              </Label>
                <FlexBox
                    style={{
                        gap: "5px",
                        justifyContent: "space-between",
                    }}
                >
                    <Label
                        style={{
                            fontSize: "1.1rem",
                            alignItems: "center",
                        }}
                    >
                        Почта:
                    </Label>
                    <Input
                        placeholder="Введите email"
                        onChange={(e) => {
                            setFormData((prev) => ({
                                ...prev,
                                email: e.target.value,
                            }));
                            setDataChanged(isChanged(e.target.value, "email"));
                        }}
                        onInput={(e) => {
                            setFormData((prev) => ({
                                ...prev,
                                email: e.target.value,
                            }));
                            setDataChanged(isChanged(e.target.value, "email"));
                        }}
                        value={formData?.email && formData.email !== 'None' ? formData.email : ''}
                    ></Input>
                </FlexBox>
                <FlexBox
                    style={{
                        gap: "5px",
                        justifyContent: "space-between",
                    }}
              >
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Бренд:
                </Label>
                {
                  <FlexBox
                    style={{
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Select
                      onChange={(e) => {
                        console.log(e.detail.selectedOption.id);
                        setFormData((prev) => ({
                          ...prev,
                          default_brand: e.detail.selectedOption.id,
                        }));
                        setChangedBrand(true);
                        setDataChanged(
                          isChanged(e.detail.selectedOption.id, "default_brand")
                        );
                      }}
                    >
                      <Option>Выберите бренд</Option>
                      {brands?.map((brand) => (
                        <Option
                          selected={formData?.default_brand === brand?.id}
                          id={brand?.id}
                        >
                          {brand.name}
                        </Option>
                      ))}
                    </Select>
                    <Button
                      icon="add"
                      onClick={() => setBrandCreateFormOpen(true)}
                    />
                    {brandCreateFormOpen ? (
                      <ModalWindow
                        className="brand-manufacture-create-form-modal"
                        state={brandCreateFormOpen}
                        setState={setBrandCreateFormOpen}
                        title={"Создание бренда"}
                        children={
                          <AdminBrandCreateForm
                            getBrands={getBrands}
                            setBrandCreateFormOpen={setBrandCreateFormOpen}
                          />
                        }
                      />
                    ) : null}
                  </FlexBox>
                }
              </FlexBox>
              <FlexBox
                style={{
                  gap: "5px",
                  justifyContent: "space-between",
                }}
              >
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Производитель:
                </Label>
                {
                  <FlexBox
                    style={{
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <Select
                      onChange={(e) => {
                        console.log(e.detail.selectedOption.id);
                        setFormData((prev) => ({
                          ...prev,
                          default_manufacture: e.detail.selectedOption.id,
                        }));
                        setDataChanged(
                          isChanged(
                            e.detail.selectedOption.id,
                            "default_manufacture"
                          )
                        );
                      }}
                    >
                      <Option>Выберите производителя</Option>
                      {manufactures?.map((manufacture) => (
                        <Option
                          selected={
                            formData?.default_manufacture === manufacture?.id
                          }
                          id={manufacture?.id}
                        >
                          {manufacture.name}
                        </Option>
                      ))}
                    </Select>
                    <Button
                      icon="add"
                      onClick={() => setManufactureCreateFormOpen(true)}
                    />
                    {manufactureCreateFormOpen ? (
                      <ModalWindow
                        className="brand-manufacture-create-form-modal"
                        state={manufactureCreateFormOpen}
                        setState={setManufactureCreateFormOpen}
                        title={"Создание производителя"}
                        children={
                          <AdminManufactureCreateForm
                            getManufactures={getManufactures}
                            setManufactureCreateFormOpen={
                              setManufactureCreateFormOpen
                            }
                          />
                        }
                      />
                    ) : null}
                  </FlexBox>
                }
              </FlexBox>
              <FlexBox
                style={{
                  gap: "5px",
                  justifyContent: "space-between",
                }}
              >
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Склад:
                </Label>
                <Select
                  onChange={(e) => {
                    console.log(e.detail.selectedOption.id);
                    setFormData((prev) => ({
                      ...prev,
                      default_warehouse: e.detail.selectedOption.id,
                    }));
                    setDataChanged(
                      isChanged(e.detail.selectedOption.id, "default_warehouse")
                    );
                  }}
                >
                  <Option>Выберите склад</Option>
                  {warehouses?.map((warehouse) => (
                    <Option
                      selected={formData?.default_warehouse === warehouse?.id}
                      id={warehouse.id}
                    >
                      {warehouse.name}
                    </Option>
                  ))}
                </Select>
              </FlexBox>
              <FlexBox
                style={{
                  gap: "5px",
                  justifyContent: "space-between",
                }}
              >
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Конфигурация:
                </Label>
                <Select
                  onChange={(e) => {
                    console.log(e.detail.selectedOption.id);
                    setFormData((prev) => ({
                      ...prev,
                      exchange_config: e.detail.selectedOption.id,
                    }));
                    setConfig(e.detail.selectedOption.id);
                    setDataChanged(
                      isChanged(e.detail.selectedOption.id, "exchange_config")
                    );
                  }}
                >
                  <Option>Выберите конфигурацию</Option>
                  {configs?.map((config) => (
                    <Option
                      selected={formData?.exchange_config === config.id}
                      id={config.id}
                    >{`${config.creator} ${config.create_datetime_stamp}`}</Option>
                  ))}
                </Select>
              </FlexBox>
              <FlexBox
                style={{
                  gap: "5px",
                  justifyContent: "space-between",
                }}
              >
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Количество строк заголовка:
                </Label>
                <StepInput
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFormData((prev) => ({
                      ...prev,
                      start_row: e.target.value,
                    }));
                    setDataChanged(isChanged(e.target.value, "start_row"));
                  }}
                  value={formData?.start_row}
                ></StepInput>
              </FlexBox>
              <FlexBox
                style={{
                  gap: "5px",
                  justifyContent: "space-between",
                }}
              >
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Количество строк футера:
                </Label>
                <StepInput
                  onChange={(e) => {
                    console.log(e.target.value);
                    setFormData((prev) => ({
                      ...prev,
                      skipped_footer: e.target.value,
                    }));
                    setDataChanged(isChanged(e.target.value, "skipped_footer"));
                  }}
                  value={formData?.skipped_footer}
                ></StepInput>
              </FlexBox>
              <FlexBox
                style={{
                  alignItems: "center",
                  justifyContent: "space-between"
                }}
              >
                <Label
                  style={{
                    fontSize: "1.1rem",
                    alignItems: "center",
                  }}
                >
                  Ссылка:
                </Label>
                  <div>
                <Input
                  placeholder="Введите URL"
                  onChange={(e) => {
                    let value = e.target.value === "" ? null : e.target.value;
                    console.log(value)
                    setFormData((prev) => ({
                      ...prev,
                      download_url: value,
                    }));
                    setDataChanged(isChanged(value, "download_url"));
                  }}
                  onInput={(e) => {
                    let value = e.target.value === "" ? null : e.target.value;
                    console.log(value)
                    setFormData((prev) => ({
                      ...prev,
                      download_url: value,
                    }));
                    setDataChanged(isChanged(value, "download_url"));
                  }}
                  value={formData?.download_url ?? ""}
                ></Input>
                  <Button
                      tooltip="Скопировать ссылку"
                      icon="copy"
                      onClick={()=> {
                          handleCopy()
                          showToast()
                      }}
                  ></Button></div>
              </FlexBox>
              <Toast duration={1000} ref={toast}>Ссылка скопирована!</Toast>
            </div>

            <Button
              design="Emphasized"
              style={{
                padding: "0.5rem 1rem",
                width: "100%",
                marginTop: "1rem",
              }}
              onClick={() => {
                !defaultConfig ? createDefaultConfig() : editDefaultConfig();
              }}
              disabled={
                !(
                  data["email"] !== formData["email"] ||
                  data["default_brand"] !== formData["default_brand"] ||
                  data["default_warehouse"] !== formData["default_warehouse"] ||
                  data["exchange_config"] !== formData["exchange_config"] ||
                  data["default_manufacture"] !== formData["default_manufacture"] ||
                  data["download_url"] !== formData["download_url"]
                ) || !Boolean(permissionData?.admin)
              }
            >
              Сохранить
            </Button>
            <br />
            <br />
            {changeOrCreateError && (
              <MessageStrip design="Negative" hideCloseButton>
                {errorText}
              </MessageStrip>
            )}
            {succesfullChangeOrCreate && (
              <MessageStrip design="Positive" hideCloseButton>
                Конфигурация успешно сохранена
              </MessageStrip>
            )}
          </div>
        </SplitterElement>
        <SplitterElement>
          {config && <ExchangeConfigView id={config} />}
        </SplitterElement>
      </SplitterLayout>
      <PermissionManager
        keys={["admin", "manager"]}
        setPermissionData={setPermissionData}
      />
    </ThemeProvider>
  );
};

export default EmailExchangeView;
