import React, { useEffect, useState } from 'react'
import { FlexBox, ThemeProvider, Loader, MultiComboBox, MultiComboBoxItem, Form, FormGroup, FormItem, Input, Title, ObjectStatus, Icon, Button, Text, DatePicker, Option, Select, CheckBox } from '@ui5/webcomponents-react';
import axios_instance from '../../../requests/config_defaults';


const RegStep3 = (props) => {

    const [passwordRepeat, setPasswordRepeat] = useState("");

    const [passwordValueState, setPasswordValueState] = useState('');

    const [passwordRepeatValueState, setPasswordRepeatValueState] = useState('');

    const [passwordIsShown, setPasswordIsShown] = useState(false);


    function checkNumberInString(string) {
        if (string.includes("0") || string.includes("1") || string.includes("2") || string.includes("3") || string.includes("4") || string.includes("5") || string.includes("6") || string.includes("7") || string.includes("8") || string.includes("9")) return true
        return false
    }

    async function registrationDataSend() {
        props.setIsLoading(true);
        await axios_instance
            .post(
                "/registration",
                props.registrationData
            )
            .then((response) => {
                console.log(response.data);
                props.setIsLoading(false);
                props.setStep(4);
            })
            .catch((error) => {
                console.log(error);
                props.setIsLoading(false);
            })
    }


    return (
        <>
            <FlexBox
                alignItems="Center"
                direction="Column"
                justifyContent="Center"
                wrap="NoWrap"
            >
                <img src="/EXZA_LOGO_NAME_slogan.svg" alt="exza_logo" style={{ width: '40%', marginBottom: 30 }} />
                <Title level='H3'>
                    Создайте надежный пароль
                </Title>
                <Text style={{ textAlign: 'center', marginTop: 10, marginBottom: 20 }}>
                    Придумайте надежный пароль, состоящий из букв, цифр и других символов
                </Text>
            </FlexBox>
            <Form
                columnsL={1}
                columnsM={1}
                columnsS={1}
                columnsXL={1}
                labelSpanS={0}
                labelSpanM={0}
                labelSpanL={0}
                labelSpanXL={0}
            >
                <FormGroup>
                    <FormItem>
                        <Input
                            name="password"
                            valueState={passwordValueState ? "Error" : ""}
                            required
                            placeholder='Пароль'
                            type={passwordIsShown ? 'Text' : 'Password'}
                            //value={login}
                            //onInput={handleLoginInput}
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0
                            }}
                            onChange={(e) => {
                                setPasswordValueState("");
                                props.setRegistrationData({
                                    ...props.registrationData,
                                    password: e.target.typedInValue.trim()
                                });
                            }}
                        />
                    </FormItem>
                    {passwordValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {passwordValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem>
                        <Input
                            name="repeat_password"
                            valueState={passwordRepeatValueState ? "Error" : ""}
                            required
                            placeholder='Повторите пароль'
                            type={passwordIsShown ? 'Text' : 'Password'}
                            //value={login}
                            //onInput={handleLoginInput}
                            style={{
                                width: '100%',
                                height: 50,
                                fontSize: 16,
                                padding: 0
                            }}
                            onChange={(e) => {
                                setPasswordRepeatValueState("");
                                setPasswordRepeat(e.target.typedInValue.trim());
                            }}
                        />
                    </FormItem>
                    {passwordRepeatValueState &&
                        <FormItem>
                            <ObjectStatus
                                onClick={function Ta() { }}
                                state="Error"
                                icon={<Icon name="alert" />}
                            >
                                {passwordRepeatValueState}
                            </ObjectStatus>
                        </FormItem>
                    }
                    <FormItem>
                        <CheckBox
                            onChange={() => { setPasswordIsShown(!passwordIsShown) }}
                            text="Показать пароль"
                        />
                    </FormItem>
                </FormGroup>

                <FormGroup>
                    <FormItem>
                        <FlexBox
                            alignItems="Center"
                            direction="Row"
                            justifyContent="End"
                            wrap="NoWrap"
                            style={{ width: '100%' }}
                        >
                            <Button
                                //onClick={() => { setIsLoading(true); performIdentification() }}
                                onClick={() => {
                                    if (props.registrationData.password === "") {
                                        setPasswordValueState("Введите пароль");
                                    }
                                    if (passwordRepeat === "") {
                                        setPasswordRepeatValueState("Повторите пароль");
                                    }
                                    else if (props.registrationData.password !== passwordRepeat) {
                                        setPasswordRepeatValueState("Пароли не совпадают");
                                    }
                                    else if (props.registrationData.password.length < 8) {
                                        setPasswordValueState("Пароль должен содержать не менее 8 символов");
                                    }
                                    else if (!isNaN(Number(props.registrationData.password))) {
                                        setPasswordValueState("Пароль должен содержать хотя бы 1 букву");
                                    }
                                    else if (!checkNumberInString(props.registrationData.password)) {
                                        setPasswordValueState("Пароль должен содержать хотя бы 1 цифру");
                                    }
                                    else {
                                        registrationDataSend();
                                    }
                                }}
                                design="Emphasized" style={{ width: 100, fontWeight: 'normal' }}>
                                Далее
                            </Button>
                        </FlexBox>
                    </FormItem>
                </FormGroup>
            </Form>
        </>
    )
}

export default RegStep3